import React, { useState } from "react";
import axios from "axios";
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL

function EmailMarketing({ userId, onSubscriptionChange }) {

    const [subscriptions, setSubscriptions] = useState({
        newDeals: false,
        top10Deals: false,
        uniqueItineraries: false,
        newShipOnlyDeals: false,
        exoticLocations: false,
        shortCruiseDeals: false,
        longCruiseDeals: false,
    });
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleSubmit = async () => {
        try {
            const response = await axios.post(
                `https://${apiBaseUrl}/update-subscriptions/${userId}`,
                subscriptions
            );
            if (response.status === 200) {
                setSuccess("Your subscriptions have been successfully updated!");
                setError(null); // Resetting error state
                onSubscriptionChange();
            } else {
                setSuccess(null); // Resetting success state
                setError("Failed to update subscriptions. Please try again."); 
            }
        } catch (error) {
            console.error("Error during subscription update:", error);
            setSuccess(null); // Resetting success state
            setError(
                "An error occurred while updating subscriptions. Please try again."
            ); 
        }
    };

    const handleCheckboxChange = (event) => {
        setSubscriptions({
            ...subscriptions,
            [event.target.name]: event.target.checked,
        });
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full bg-white p-6 rounded-md shadow-md">
                <div>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        Select your Email Subscriptions
                    </h2>
                    <form className="mt-8 space-y-6">
                        <div className="rounded-md -space-y-px">
                            {[
                                {
                                    name: "newDeals",
                                    label: "Email me when new deals are loaded",
                                },
                                {
                                    name: "top10Deals",
                                    label: "Top 10 deals email (Weekly - Monday)",
                                },
                                {
                                    name: "uniqueItineraries",
                                    label: "Unique itineraries email (Weekly - Tuesday)",
                                },
                                {
                                    name: "newShipOnlyDeals",
                                    label: "New ship only deals email (Weekly - Wednesday)",
                                },
                                {
                                    name: "exoticLocations",
                                    label: "Exotic locations email (Weekly - Thursday)",
                                },
                                {
                                    name: "shortCruiseDeals",
                                    label: "Short 3-4 day cruise deals email (Weekly - Friday)",
                                },
                                {
                                    name: "longCruiseDeals",
                                    label: "Long 12+ day cruise deals email (Weekly - Saturday)",
                                },
                            ].map(({ name, label }) => (
                                <div key={name} className="py-2">
                                    <label className="inline-flex items-center">
                                        <input
                                            type="checkbox"
                                            name={name}
                                            checked={subscriptions[name]}
                                            onChange={handleCheckboxChange}
                                            className="form-checkbox h-5 w-5 text-indigo-600"
                                        />
                                        <span className="ml-2 text-gray-700">
                                            {label}
                                        </span>
                                    </label>
                                </div>
                            ))}
                        </div>
                        <div>
                            <button
                                type="button"
                                onClick={handleSubmit}
                                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Update Preferences
                            </button>
                        </div>
                    </form>
                </div>
                {error && (
                    <>
                        <h2 className="text-3xl font-extrabold text-gray-900">
                            Error
                        </h2>
                        <p className="mt-2 text-gray-700">{error}</p>
                    </>
                )}
                {success && (
                    <>
                        <h2 className="text-3xl font-extrabold text-gray-900">
                            Success
                        </h2>
                        <p className="mt-2 text-gray-700">{success}</p>
                    </>
                )}
            </div>
        </div>
    );
}

export default EmailMarketing;
