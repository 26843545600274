import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateCruise, deleteCruise } from "../../store/thunks/cruisesThunk";
import { fetchCruises } from "../../store/thunks/fetchCruises";
import { fetchCruiseDetails } from "../../store/thunks/widgety/fetchCruiseDetails";

import shipList from "../cruiseList/shipList";

function EditCruiseModal({
  isOpen,
  onClose,
  cruise,
  onEditSuccess,
  onDeleteSuccess,
}) {
  const dispatch = useDispatch();
  const activeAgent = useSelector((state) => state.activeAgent.data.agent);

  const [formData, setFormData] = useState(cruise);
  const [selectedCurrency, setSelectedCurrency] = useState(cruise.Currency);
  const [selectedRegion, setSelectedRegion] = useState(cruise.Region);
  const [selectedCategoryClass, setSelectedCategoryClass] = useState(
    cruise.CategoryClass
  );
  const [selectedDate, setSelectedDate] = useState(cruise.SailDate);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEsc);

    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, [onClose]);

  const handleSaveClick = () => {
    // Update selectedDate state based on the formData.SailDate value
    const selectedDateWithoutTime = selectedDate
      ? selectedDate
      : formData.SailDate
      ? formData.SailDate.split("T")[0]
      : "";
    console.log("Selected date is:", selectedDateWithoutTime);

    const isoTime = "T04:00:00.000"; // Desired time component

    // Concatenate date and time if it's not already included
    const isoDate = selectedDateWithoutTime.includes("T")
      ? selectedDateWithoutTime
      : selectedDateWithoutTime + isoTime;

    console.log("isodate", isoDate);

    // Parse numeric fields to numbers
    const updatedFormData = {
      ...formData,
      SailDate: isoDate, // Use isoDate for backend
    };

    console.log("Updated Form Data after final adjustments:", updatedFormData);

    dispatch(updateCruise({ id: formData._id, updatedCruise: updatedFormData }))
      .then(() => {
        console.log("Cruise updated successfully");
        onClose();
        dispatch(fetchCruises({ sellingAgent: activeAgent.email }));
        onEditSuccess(); // Call the callback function to show success feedback
      })
      .catch((error) => {
        console.error("Failed to update cruise:", error);
      });
  };

  const handleDeleteClick = () => {
    console.log("cruise id to delete:", cruise._id);
    dispatch(deleteCruise({ id: cruise._id }))
      .then(() => {
        console.log("Cruise deleted successfully");
        dispatch(fetchCruises({ sellingAgent: activeAgent.email }));
        onClose();
        onDeleteSuccess();
      })
      .catch((error) => {
        console.error("Failed to delete cruise:", error);
      });
  };

  // Derive unique cruise lines
  const uniqueCruiseLines = [
    ...new Set(shipList.map((ship) => ship.cruiseLine)),
  ];

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black opacity-60"></div>
      <div className="relative z-10 bg-white p-6 rounded-lg shadow-xl max-w-sm md:max-w-3xl w-full overflow-y-auto max-h-full">
        <form>
          <div className="space-y-12">
            <div className="pb-12">
              <>
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  Cruise Information
                </h2>
                <p className="mt-1 mb-6 text-sm leading-6 text-gray-600">
                  Edit fields as necessary. Hidden means your clients will not
                  see this information.
                </p>
              </>

              {/* Form inputs */}
              <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-8">
                <>
                  {/* Group Id */}
                  <div>
                    <label
                      htmlFor="group-id"
                      className="block text-sm font-medium leading-5 text-gray-700">
                      Group ID (Hidden)
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        id="group-id"
                        type="text"
                        value={formData.GroupId || ""}
                        onChange={(e) =>
                          setFormData({ ...formData, GroupId: e.target.value })
                        }
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  {/* Group Name */}
                  <div>
                    <label
                      htmlFor="group-name"
                      className="block text-sm font-medium leading-5 text-gray-700">
                      Group Name (Hidden)
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        id="group-name"
                        type="text"
                        value={formData.GroupName || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            GroupName: e.target.value,
                          })
                        }
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  {/* Cruise Line */}
                  <div>
                    <label
                      htmlFor="cruiseLine"
                      className="block text-sm font-medium leading-5 text-gray-700">
                      Cruise Line
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <select
                        id="cruiseLine"
                        value={formData.CruiseLine || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            CruiseLine: e.target.value,
                          })
                        }
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <option value="">Select cruise line</option>
                        {uniqueCruiseLines.map((cruiseLine) => (
                          <option key={cruiseLine} value={cruiseLine}>
                            {cruiseLine}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {/* Cruise Ship */}
                  <div>
                    <label
                      htmlFor="ship"
                      className="block text-sm font-medium leading-5 text-gray-700">
                      Cruise Ship
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <select
                        id="ship"
                        value={formData.Ship || ""}
                        onChange={(e) =>
                          setFormData({ ...formData, Ship: e.target.value })
                        }
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <option value="">Select ship</option>
                        {formData.CruiseLine &&
                          shipList
                            .filter(
                              (ship) => ship.cruiseLine === formData.CruiseLine
                            )
                            .map((ship) => (
                              <option key={ship.ship} value={ship.ship}>
                                {ship.ship}
                              </option>
                            ))}
                      </select>
                    </div>
                  </div>

                  {/* Product */}
                  <div>
                    <label
                      htmlFor="product"
                      className="block text-sm font-medium leading-5 text-gray-700">
                      Product (Hidden)
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        id="product"
                        type="text"
                        value={formData.Product || ""}
                        onChange={(e) =>
                          setFormData({ ...formData, Product: e.target.value })
                        }
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  {/* Region */}
                  <div>
                    <label
                      htmlFor="region"
                      className="block text-sm font-medium leading-5 text-gray-700">
                      Region
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <select
                        id="region"
                        value={formData.Region || ""}
                        onChange={(e) =>
                          setFormData({ ...formData, Region: e.target.value })
                        }
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                        <option value="Alaska">Alaska</option>
                        <option value="Asia">Asia</option>
                        <option value="Australia/New Zealand/South Pacific">
                          Australia/New Zealand/South Pacific
                        </option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Canada">Canada</option>
                        <option value="Canals">Canals</option>
                        <option value="Caribbean">Caribbean</option>
                        <option value="Europe">Europe</option>
                        <option value="Hawaii">Hawaii</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Repositioning/Transatlantic">
                          Repositioning/Transatlantic
                        </option>
                        <option value="West Coast Short">
                          West Coast Short
                        </option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>

                  {/* Sail Date */}
                  <div>
                    <label
                      htmlFor="sail-date"
                      className="block text-sm font-medium leading-5 text-gray-700">
                      Sail Date (YYYY-MM-DD)
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        id="sail-date"
                        type="date"
                        value={
                          selectedDate
                            ? new Date(selectedDate).toISOString().split("T")[0]
                            : ""
                        }
                        onChange={(e) => setSelectedDate(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  {/* Nights */}
                  <div>
                    <label
                      htmlFor="nights"
                      className="block text-sm font-medium leading-5 text-gray-700">
                      Nights
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        id="nights"
                        type="number"
                        value={formData.Nights || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            Nights: parseInt(e.target.value),
                          })
                        }
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  {/* Cruise Name */}
                  <div>
                    <label
                      htmlFor="product"
                      className="block text-sm font-medium leading-5 text-gray-700">
                      Cruise Name
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        id="name"
                        type="text"
                        value={formData.name || ""}
                        onChange={(e) =>
                          setFormData({ ...formData, name: e.target.value })
                        }
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  {/* Category Class */}
                  <div>
                    <label
                      htmlFor="category-class"
                      className="block text-sm font-medium leading-5 text-gray-700">
                      Category Class
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <select
                        id="category-class"
                        value={formData.CategoryClass || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            CategoryClass: e.target.value,
                          })
                        }
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <option value="I">I - Inside</option>
                        <option value="O">O - Oceanview</option>
                        <option value="B">B - Balcony</option>
                        <option value="N">N - Neighbourhood</option>
                        <option value="S">S - Suite</option>
                      </select>
                    </div>
                  </div>

                  {/* Category */}
                  <div>
                    <label
                      htmlFor="category"
                      className="block text-sm font-medium leading-5 text-gray-700">
                      Category
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        id="category"
                        type="text"
                        value={formData.Category || ""}
                        onChange={(e) =>
                          setFormData({ ...formData, Category: e.target.value })
                        }
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  {/* Category Name */}
                  <div>
                    <label
                      htmlFor="category-name"
                      className="block text-sm font-medium leading-5 text-gray-700">
                      Category Name
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        id="categoryName"
                        type="text"
                        value={formData.CategoryName || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            CategoryName: e.target.value,
                          })
                        }
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  {/* Currency */}
                  <div>
                    <label
                      htmlFor="currency"
                      className="block text-sm font-medium leading-5 text-gray-700">
                      Currency
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <select
                        id="currency"
                        value={formData.Currency || ""}
                        onChange={(e) =>
                          setFormData({ ...formData, Currency: e.target.value })
                        }
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                        <option value="USD">USD</option>
                        <option value="CAD">CAD</option>
                      </select>
                    </div>
                  </div>

                  {/* Group Rate */}
                  <div>
                    <label
                      htmlFor="group-rate"
                      className="block text-sm font-medium leading-5 text-gray-700">
                      Group Rate
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
                        $
                      </span>
                      <input
                        id="group-rate"
                        type="number"
                        value={formData.GroupRate || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            GroupRate: parseFloat(e.target.value),
                          })
                        }
                        className="pl-7 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="0"
                      />
                    </div>
                  </div>

                  {/* Market Rate */}
                  <div>
                    <label
                      htmlFor="market-rate"
                      className="block text-sm font-medium leading-5 text-gray-700">
                      Market Rate
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
                        $
                      </span>
                      <input
                        id="market-rate"
                        type="number"
                        value={formData.MarketRate || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            MarketRate: parseFloat(e.target.value),
                          })
                        }
                        className="pl-7 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="0"
                      />
                    </div>
                  </div>

                  {/* Price Advantage */}
                  <div>
                    <label
                      htmlFor="price-advantage"
                      className="block text-sm font-medium leading-5 text-gray-700">
                      Price Advantage
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
                        $
                      </span>
                      <input
                        id="price-advantage"
                        type="number"
                        value={formData.CurrentPriceAdv || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            CurrentPriceAdv: parseFloat(e.target.value),
                          })
                        }
                        className="pl-7 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="0"
                      />
                    </div>
                  </div>

                  {/* Percent Advantage */}
                  <div>
                    <label
                      htmlFor="percent-advantage"
                      className="block text-sm font-medium leading-5 text-gray-700">
                      Percent Advantage
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
                        %
                      </span>
                      <input
                        id="percent-advantage"
                        type="number"
                        value={formData.CurrentPercentAdv || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            CurrentPercentAdv: parseFloat(e.target.value),
                          })
                        }
                        className="pl-7 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="0"
                      />
                    </div>
                  </div>

                  {/* Rooms Available */}
                  <div>
                    <label
                      htmlFor="rooms-available"
                      className="block text-sm font-medium leading-5 text-gray-700">
                      Rooms Available
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        id="rooms-available"
                        type="number"
                        value={formData.RoomsAvailable || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            RoomsAvailable: parseInt(e.target.value),
                          })
                        }
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  {/* Perks */}
                  <div className="col-span-1 sm:col-span-3">
                    <label
                      htmlFor="perks"
                      className="block text-sm font-medium leading-5 text-gray-700">
                      Perks{" "}
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        id="perks"
                        type="text"
                        value={formData.Perks || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            Perks: e.target.value,
                          })
                        }
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </>
              </div>

              <div className="border-b border-gray-900/10 pb-12"></div>

              {/* Delete, Cancel, Save button */}
              <div className="mt-6 flex sm:items-center sm:justify-between flex-wrap gap-3 sm:flex-nowrap">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md bg-gray-100 border border-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-full sm:w-auto"
                  onClick={onClose}>
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleSaveClick}
                  className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 w-full sm:w-auto">
                  Save
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 w-full sm:w-auto"
                  onClick={handleDeleteClick}>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditCruiseModal;
