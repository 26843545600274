// Import necessary dependencies
import axios from 'axios';
import sha256 from 'crypto-js/sha256';

// Define your action types
const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
const ADD_TO_CART_FAILURE = 'ADD_TO_CART_FAILURE';

// Define your initial state (optional)
const initialState = {
  success: false,
  error: null,
};

// Define your reducer (optional)
const addToCartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART_SUCCESS:
      return {
        ...state,
        success: true,
        error: null,
      };
    case ADD_TO_CART_FAILURE:
      return {
        ...state,
        success: false,
        error: action.error,
      };
    default:
      return state;
  }
};

// Define your action creators (optional)
const addToCartSuccess = () => ({
  type: ADD_TO_CART_SUCCESS,
});

const addToCartFailure = (error) => ({
  type: ADD_TO_CART_FAILURE,
  error,
});

// Define your thunk function to send the Facebook Conversion API call
export const addToCart = (activeUser) => {
  return async (dispatch) => {
    try {
      // Define your Facebook Conversion API endpoint
      const apiEndpoint = 'https://graph.facebook.com/v18.0/your_conversion_event_id';
      
      const hashedEmail = sha256(activeUser.email);

      // Define the event parameters
      const facebookEvent = {
        data: [
          {
            event_name: 'AddToCart',
            event_time: Math.floor(Date.now() / 1000),
            action_source: "website",
            user_data: {
              em: [hashedEmail]
            },
            custom_data: {
              currency: "CAD",
              value: "29.00",
            },
          },
        ],
      };

      // Send the API request to Facebook
      await axios.post(apiEndpoint, facebookEvent);

      // Dispatch a success action
      dispatch(addToCartSuccess());
    } catch (error) {
      // Dispatch a failure action with the error
      dispatch(addToCartFailure(error));
    }
  };
};

// Export your reducer (if used) and thunk function
export default addToCartReducer;
