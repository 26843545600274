import { createSlice } from "@reduxjs/toolkit";
import { fetchUserQuote } from "../thunks/userQuoteThunk"; // Import the fetchClientQuotes thunk

const initialState = {
  quotes: [],
  loading: false,
  error: null,
};

const userQuoteSlice = createSlice({
  name: "userQuotes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserQuote.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserQuote.fulfilled, (state, action) => {
        state.loading = false;
        state.quotes = action.payload;
      })
      .addCase(fetchUserQuote.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default userQuoteSlice.reducer;
