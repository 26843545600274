import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon, BellIcon } from "@heroicons/react/24/outline";
import { logoutAgent } from "../../store/thunks/logoutAgent";
import { persistor } from "../../store";
import { Fragment } from "react";
import Logo from "../Logo";

const AgentHeader = () => {
  const dispatch = useDispatch();
  const apiToken = useSelector((state) => state.activeAgent.data.agent?.token);
  const activeAgent = useSelector((state) => state.activeAgent.data.agent);
  const [selectedMenu, setSelectedMenu] = useState("Admin");

  // console.log("the active agent is:", activeAgent);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handleMenuItemClick = (item) => {
    setSelectedMenu(item.name);
  };

  const signOut = async () => {
    try {
      // Dispatch the logoutAgent action
      dispatch(logoutAgent({ token: apiToken, userId: activeAgent._id }));
      persistor.purge();
      console.log(
        "Persistor purged of agent data. Logout dispatched & completed."
      );

      // Redirect to the home page
      window.location.href = "https://agents.cruisecompare.ca";
      // window.location.href = "http://localhost:3000";

      // Open a new blank tab
      // window.open("about:blank", "_blank");
    } catch (err) {
      console.error(err);
    }
  };

  const navigation = [{ name: "Agent Panel", href: "#", current: true }];
  const userNavigation = [
    // { name: "Your Profile", href: "#" },
    // { name: "Settings", href: "#" },
    { name: "Log out", href: "#", onClick: signOut },
    // { name: "Log out using route", href: "/agent/logout" },
  ];

  return (
    <>
      <div style={{zIndex: 2}}>
        <div className="min-h-full">
          <Disclosure as="nav" className="border-b border-gray-200 bg-white">
            {({ open }) => (
              <>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                  <div className="flex h-16 justify-between">
                    <div className="flex">
                      <div className="flex flex-shrink-0 pl-100">
                        {/* <Logo height="auto" width="8rem"/> */}
                      </div>
                      <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8"></div>
                    </div>
                    <div className="hidden sm:ml-6 sm:flex sm:items-center">
                      <button
                        type="button"
                        className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>

                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="flex max-w-xs items-center rounded-full bg-color2 text-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <span className="sr-only">Open user menu</span>
                            {/* {activeAgent && ( */}
                            <div className="h-8 w-8 rounded-full flex items-center justify-center">
                              {activeAgent?.first_name[0].toUpperCase()}
                              {activeAgent?.last_name[0].toUpperCase()}
                            </div>
                            {/* )} */}
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95">
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <a
                                    href={item.href}
                                    className={classNames(
                                      active
                                        ? "bg-gray-100 rounded-sm font-semibold"
                                        : "",
                                      "block px-4 py-2 text-sm text-gray-700"
                                    )}
                                    onClick={(e) => {
                                      console.log(
                                        "Profile dropdown clicked " + e
                                      );
                                      e.preventDefault();
                                      handleMenuItemClick(item);
                                      if (item.onClick) item.onClick();
                                    }}>
                                    {item.name}
                                  </a>
                                )}
                              </Menu.Item>
                            ))}
                            {activeAgent?.user_type === "admin" && (
                              <>
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      href="/admin"
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 rounded-sm font-semibold"
                                          : "",
                                        "block px-4 py-2 text-sm text-gray-700"
                                      )}
                                      style={{ borderTop: "1px solid #ccc" }}>
                                      Admin
                                    </a>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      href="/agent"
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 rounded-sm font-semibold"
                                          : "",
                                        "block px-4 py-2 text-sm text-gray-700"
                                      )}>
                                      Agent
                                    </a>
                                  )}
                                </Menu.Item>
                              </>
                            )}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                    <div className="-mr-2 flex items-center sm:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        ) : (
                          <Bars3Icon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        )}
                      </Disclosure.Button>
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className="sm:hidden">
                  <div className="space-y-1 pb-3 pt-2">
                    {navigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className={classNames(
                          item.current
                            ? "border-indigo-500 bg-indigo-50 text-indigo-700"
                            : "border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800",
                          "block border-l-4 py-2 pl-3 pr-4 text-base font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}>
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                  <div className="border-t border-gray-200 pb-3 pt-4">
                    <div className="flex items-center px-4">
                      <div className="flex-shrink-0">
                        <img
                          className="h-10 w-10 rounded-full"
                          src={activeAgent?.imageUrl}
                          alt=""
                        />
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium text-gray-800">
                          {activeAgent?.first_name} {activeAgent?.last_name}
                        </div>
                        <div className="text-sm font-medium text-gray-500">
                          {activeAgent?.email}
                        </div>
                      </div>
                      <button
                        type="button"
                        className="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="mt-3 space-y-1">
                      {userNavigation.map((item) => (
                        <Disclosure.Button
                          key={item.name}
                          as="a"
                          href={item.href}
                          className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800">
                          {item.name}
                        </Disclosure.Button>
                      ))}
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>
    </>
  );
};

export default AgentHeader;
