import { createSlice } from "@reduxjs/toolkit";
import { fetchCruisePricing } from "../thunks/fetchCruisePricing";  // Adjust the import path accordingly

// Initial state for cruisePricing slice
const initialState = {
  data: [],
  status: "idle",  // idle, loading, succeeded, failed
  error: null
};

const cruisePricingSlice = createSlice({
  name: "cruisePricing",
  initialState,
  reducers: {}, // Add any synchronous reducers if needed
  extraReducers: (builder) => {
    builder
      .addCase(fetchCruisePricing.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCruisePricing.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchCruisePricing.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// Export the reducer
export default cruisePricingSlice.reducer;
