import { useState, useEffect } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function CruiseShipDropdown({
  shipList,
  selectedCruiseLine,
  onCruiseShipChange,
  subscriptionActive,
  selectedShip, // <-- Added
  setSelectedShip, // <-- Added
  setCurrentPage,
}) {
  const [query, setQuery] = useState("");

  // const filteredShips = query
  //   ? shipList.filter((ship) =>
  //       ship.ship.toLowerCase().includes(query.toLowerCase())
  //     )
  //   : shipList;
  const [filteredShips, setFilteredShips] = useState(shipList);

  useEffect(() => {
    setFilteredShips(getFilteredShips(selectedCruiseLine));
  }, [selectedCruiseLine]);

  function getFilteredShips(cruiseLine) {
    if (cruiseLine === "Royal Caribbean") {
      return shipList.filter(
        (ship) =>
          ship.cruiseLine === "Royal Caribbean" &&
          ship.ship.endsWith("of the Seas")
      );
    } else if (cruiseLine === "Celebrity") {
      return shipList.filter(
        (ship) =>
          ship.cruiseLine === "Celebrity" && ship.ship.startsWith("Celebrity")
      );
    } else if (cruiseLine === "Virgin Voyages") {
      return shipList.filter((ship) => ship.cruiseLine === "Virgin Voyages");
    } else {
      return shipList; // Return all ships if no specific cruise line is selected
    }
  }

  return (
    <div className="relative">
      {!subscriptionActive && (
        <div
          className="absolute inset-0 flex items-center justify-center z-10 opacity-75"
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 75,
            backgroundColor: "rgba(243, 244, 246, 1)", // This gives a slight whitewash look. Remove if you don't want it.
            zIndex: 1,
          }}></div>
      )}
      <Combobox
        as="div"
        value={selectedShip.ship || "All 123"}
        onChange={(selectedValue) => {
          const selectedShipObject = shipList.find(
            (ship) => ship.ship === selectedValue
          );
          setSelectedShip(selectedShipObject);
          setCurrentPage(1);
          onCruiseShipChange(selectedValue);
        }}>
        <Combobox.Label className="block text-base font-semibold leading-6 text-gray-900">
          Cruise Ship
        </Combobox.Label>
        <div className="relative mt-2 mb-4">
          <Combobox.Input
            disabled={!subscriptionActive}
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(ship) => ship.ship || selectedShip}
            readOnly={true}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>

          {filteredShips.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredShips.map((shipItem) => (
                <Combobox.Option
                  key={shipItem.ship}
                  value={shipItem.ship}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9",
                      active ? "bg-indigo-600 text-white" : "text-gray-900"
                    )
                  }>
                  {({ active, selected }) => (
                    <>
                      <span
                        className={classNames(
                          "block truncate",
                          selected && "font-semibold"
                        )}>
                        {shipItem.ship}
                      </span>

                      {selected && (
                        <span
                          className={classNames(
                            "absolute inset-y-0 right-0 flex items-center pr-4",
                            active ? "text-white" : "text-indigo-600"
                          )}>
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    </div>
  );
}

export default CruiseShipDropdown;
