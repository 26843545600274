import React, { useState, useEffect } from "react";

const PasswordRequirements = ({ password }) => {
    const [requirementsMet, setRequirementsMet] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        digit: false,
        specialChar: false,
    });

    useEffect(() => {
        isValidPassword(password);
    }, [password]);

    const isValidPassword = (password) => {
        // Define regular expressions for each requirement
        const lengthRegex = /.{8,}/;
        const uppercaseRegex = /[A-Z]/;
        const lowercaseRegex = /[a-z]/;
        const digitRegex = /\d/;
        const specialCharRegex = /[@$!%*?&]/;

        // Update the requirementsMet object based on password checks
        setRequirementsMet({
            length: lengthRegex.test(password),
            uppercase: uppercaseRegex.test(password),
            lowercase: lowercaseRegex.test(password),
            digit: digitRegex.test(password),
            specialChar: specialCharRegex.test(password),
        });
    };

    return (
        <div>
            <div>
                <h3>Password Requirements:</h3>
                <ul>
                    <li>
                        {requirementsMet.length ? (
                            <span className="text-green-500">&#x2713;</span>
                        ) : (
                            <span className="text-red-500">&#x2717;</span>
                        )}
                        Minimum 8 characters{" "}
                    </li>
                    <li>
                        {requirementsMet.uppercase ? (
                            <span className="text-green-500">&#x2713;</span>
                        ) : (
                            <span className="text-red-500">&#x2717;</span>
                        )}
                        At least one uppercase letter{" "}
                    </li>
                    <li>
                        {requirementsMet.lowercase ? (
                            <span className="text-green-500">&#x2713;</span>
                        ) : (
                            <span className="text-red-500">&#x2717;</span>
                        )}
                        At least one lowercase letter{" "}
                    </li>
                    <li>
                        {requirementsMet.digit ? (
                            <span className="text-green-500">&#x2713;</span>
                        ) : (
                            <span className="text-red-500">&#x2717;</span>
                        )}
                        At least one digit{" "}
                    </li>
                    <li>
                        {requirementsMet.specialChar ? (
                            <span className="text-green-500">&#x2713;</span>
                        ) : (
                            <span className="text-red-500">&#x2717;</span>
                        )}
                        At least one special character (@, $, !, %, *, ?, &){" "}
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default PasswordRequirements;
