import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL

const loginUser = createAsyncThunk("user/login", async (loginData) => {
  const systemUserToken = process.env.SYSTEM_USER_TOKEN;
  const systemUserID = process.env.SYSTEM_USER_ID;
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-api-token": systemUserToken, // Add the System User Token header
      "x-user-id": systemUserID, // Add the System User ID header
      // Add other headers as needed
    },
  };

  const response = await axios.post(
    `https://${apiBaseUrl}/login`,
    { username: loginData.email, password: loginData.password },
    config
  );

  return response.data;
});

export { loginUser };
