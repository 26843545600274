import React, { useEffect, useState } from "react";
import Logo from "../components/Logo";
import FlowRider from "../assets/FlowRider.webp";
import { resetPassword, verifyResetToken } from "../store/thunks/resetPassword";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

function ResetPassword() {
    const [error, setError] = useState("");
    const [email, setEmail] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [message, setMessage] = useState("");
    const [passwordUpdated, setPasswordUpdated] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Extract the token from the URL query parameters
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get("token");
        console.log("location.search: " + location.search); // Log the search string
        console.log("Token: " + token);

        // If there is no token, show the email input form
        if (!token) {
            setPasswordUpdated(false);
        } else {
            // If there is a token, validate it and switch to password reset form
            const fetchEmailAndSet = async () => {
                try {
                    const user = await dispatch(verifyResetToken(token));
                    setEmail(user.email); // Replace with the actual email obtained
                    setPasswordUpdated(true);
                } catch (error) {
                    console.error("Error verifying reset token:", error);
                    setPasswordUpdated(false);
                    // Handle the error as needed, e.g., redirect to an error page
                }
            };

            // Call the asynchronous function
            fetchEmailAndSet();
        }
    }, [location, dispatch]);
    const handleSubmit = async (e) => {
        e.preventDefault();
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get("token");
        const resetData = { email: email, newPassword: confirmPassword, token: token };
        console.log(resetData);
        try {
            // If passwordUpdated is true, this means we are resetting the password
            if (passwordUpdated) {
                // Check if the passwords match
                if (newPassword !== confirmPassword) {
                    setError("Passwords do not match");
                    return;
                }
                try {
                    const action = await dispatch(resetPassword(resetData));

                    // Check if the action was successful
                    if (
                        action.payload &&
                        action.payload.message === "Password reset successfully"
                    ) {
                        setPasswordUpdated(true);
                        setMessage("Password updated successfully.");
                        setError("");
                    } else {
                        setError("Unexpected response from server.");
                        setMessage("");
                    }
                } catch (error) {
                    setError(error.message);
                    setMessage("");
                }
            } else {
                // If passwordUpdated is false, this means we are requesting a reset link
                const action = await dispatch(resetPassword(resetData));

                // Check if the action was successful based on the returned message
                if (
                    action.payload &&
                    action.payload.message === "Password Reset Email Sent"
                ) {
                    setError("");
                    setMessage("Password reset link sent successfully.");
                } else {
                    setError("Invalid credentials.");
                    setMessage("");
                }
            }
        } catch (error) {
            console.log(error.text);
        }
    };

    return (
        <>
            <div className="flex min-h-full flex-1">
                <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                            <Logo />
                            <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                {passwordUpdated
                                    ? "Reset Your Password"
                                    : "Reset Password"}
                            </h2>
                            {!passwordUpdated ? (
                                <p className="mt-2 text-sm leading-6 text-gray-500">
                                    Back to{" "}
                                    <a
                                        href="/login"
                                        className="font-semibold text-indigo-600 hover:text-indigo-500"
                                    >
                                        Sign In
                                    </a>
                                </p>
                            ) : null}
                        </div>

                        {error && (
                            <div className="mt-4 flex items-center">
                                <p className="text-red-500 mr-2">{error}</p>
                                <button
                                    className="text-red-500 font-semibold"
                                    onClick={() => setError("")}
                                >
                                    Dismiss
                                </button>
                            </div>
                        )}

                        {message && (
                            <div className="mt-4 flex items-center">
                                <p className="text-green-500 mr-2">{message}</p>
                                <button
                                    className="text-green-500 font-semibold"
                                    onClick={() => setMessage("")}
                                >
                                    Dismiss
                                </button>
                            </div>
                        )}

                        <div className="mt-10">
                            <div>
                                <form
                                    onSubmit={handleSubmit}
                                    className="space-y-6"
                                >
                                    {!passwordUpdated ? (
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium leading-6 text-gray-900"
                                            >
                                                Email address
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="email"
                                                    name="email" // Add the name attribute
                                                    type="email"
                                                    autoComplete="email"
                                                    required
                                                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    value={email}
                                                    onChange={(e) =>
                                                        setEmail(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div>
                                                <label
                                                    htmlFor="newPassword"
                                                    className="block text-sm font-medium leading-6 text-gray-900"
                                                >
                                                    New Password
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="newPassword"
                                                        name="newPassword" // Add the name attribute
                                                        type="password"
                                                        autoComplete="new-password"
                                                        required
                                                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        value={newPassword}
                                                        onChange={(e) =>
                                                            setNewPassword(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <label
                                                    htmlFor="confirmPassword"
                                                    className="block text-sm font-medium leading-6 text-gray-900"
                                                >
                                                    Confirm Password
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="confirmPassword"
                                                        name="confirmPassword"
                                                        type="password"
                                                        autoComplete="new-password"
                                                        required
                                                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        value={confirmPassword}
                                                        onChange={(e) =>
                                                            setConfirmPassword(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    <div>
                                        <button
                                            type="submit"
                                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            {passwordUpdated
                                                ? "Change Password"
                                                : "Send Reset Link"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative hidden w-0 flex-1 lg:block">
                    <img
                        className="absolute inset-0 h-full w-full object-cover"
                        src={FlowRider}
                        alt=""
                    />
                </div>
            </div>
        </>
    );
}

export default ResetPassword;
