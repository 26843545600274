import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL

const registerUser = createAsyncThunk("user/register", async (formData) => {
  const systemUserToken = process.env.SYSTEM_USER_TOKEN;
  const systemUserID = process.env.SYSTEM_USER_ID;

  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-api-token": systemUserToken,
      "x-user-id": systemUserID,
    },
  };

  const response = await axios.post(
    `https://${apiBaseUrl}/register`,
    formData,
    config
  );
  return response.data;
});

export { registerUser };
