import React from "react";
import { Gantt, GanttDataType } from "react-virtual-gantt";

const cruises = [
    {
        key: "task-1",
        title: "Some task without data",
        children: [
          {
            key: "task-1-1",
            title: "Some non repeating task",
            data: {
              startDate: "2023-09-12T08:00:00.000Z",
              endDate: "2023-09-17T01:00:00.000Z",
            },
            children: [
              {
                key: "task-1-1-1",
                title: "Some weekly repeating task",
                data: {
                  repeatType: "WEEK",
                  fromTime: 28800,
                  endDate: 64800,
                  weekdays: [1, 3, 6],
                },
              },
            ],
          },
          {
            key: "task-1-2",
            title: "Some daily repeating task",
            data: {
              repeatType: "DAY",
              fromTime: 28800,
              endDate: 64800,
            },
          },
        ],
      },
      {
        key: "task-2",
        title: "Some monthly repeating task",
        data: {
          repeatType: "MONTH",
          fromTime: 28800,
          endDate: 64800,
          monthdays: [1, 3, 5, 9, 11, 14, 21, 31],
        },
      },
  // Add more cruises as needed
];

const CruiseCalendar = () => {
  return (
    <div>
      <Gantt>
        <Gantt.Controls />
        <Gantt.Chart data={cruises} />
      </Gantt>
    </div>
  );
};

export default CruiseCalendar;
