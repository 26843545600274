import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL

const fetchAgentCruises = createAsyncThunk(
  "cruises/fetchAgentCruises",
  async ({ sellingAgent }, { getState }) => {
    const state = getState();
    const { token, _id } = state.activeAgent?.data?.agent;

    if (!token || !_id) {
      throw new Error("No active agent found");
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-agent-api-token": token,
        "x-agent-id": _id,
      },
      params: {
        sellingAgent: sellingAgent, // Pass sellingAgent as a query parameter
      },
    };

    try {
      const response = await axios.get(
        `https://${apiBaseUrl}/agentcruises`,
        config
      );

      // console.log("response.data from /agentcruises", response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export { fetchAgentCruises };
