import React, { useState, useEffect } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

const GridTileCarousel = ({ images, shipImage }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const autoScrollTime = 7500; // time in milliseconds

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      images ? (prevIndex === images.length - 1 ? 0 : prevIndex + 1) : 0
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      images ? (prevIndex === 0 ? images.length - 1 : prevIndex - 1) : 0
    );
  };

  useEffect(() => {
    const autoScroll = setInterval(() => {
      nextSlide();
    }, autoScrollTime);

    return () => clearInterval(autoScroll); // Clear interval on component unmount
  }, [currentIndex, images]);

  return (
    <div className="relative w-full">
      <div
        className="overflow-hidden "
        style={{
          borderBottomLeftRadius: "6px",
          borderBottomRightRadius: "6px",
        }}>
        <div
          className="flex transition-transform duration-300 ease-in-out"
          style={{
            display: "flex",
            alignItems: "center",
            transform: `translateX(-${currentIndex * 100}%)`,
          }}>
          {/* Ship Image */}
          <div className="w-full h-auto flex-shrink-0 relative">
            <img
              src={shipImage}
              alt="Ship"
              className={`w-full h-auto max-w-[500px] max-h-[265px] `}
            />
          </div>

          {/* Port Images */}
          {images &&
            images.map((portImage, index) => (
              <div
                key={index}
                className="w-full h-auto rounded-md flex-shrink-0 relative">
                <img
                  src={portImage.href["500x500"]}
                  alt={portImage.name}
                  className="w-full h-auto rounded-md max-w-[500px] max-h-[265px]"
                />
              </div>
            ))}
        </div>
      </div>
      {images && images.length > 1 && (
        <>
          <button
            className="absolute left-0 top-1/2 transform -translate-y-1/2 p-1 text-gray-800 hover:text-gray-600 bg-white rounded-full mx-2"
            onClick={prevSlide}>
            <ChevronLeftIcon className="h-4 w-4" />
          </button>
          <button
            className="absolute right-0 top-1/2 transform -translate-y-1/2 p-1 text-gray-800 hover:text-gray-600 bg-white rounded-full mx-2"
            onClick={nextSlide}>
            <ChevronRightIcon className="h-4 w-4" />
          </button>
        </>
      )}
    </div>
  );
};

export default GridTileCarousel;
