import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAgentCruises } from "../../store/thunks/fetchAgentCruises";
import { fetchActiveAgent } from "../../store/thunks/fetchActiveAgent";
import {
  BarsArrowUpIcon,
  BarsArrowDownIcon,
  Bars3BottomLeftIcon,
} from "@heroicons/react/20/solid";
import { ClipLoader } from "react-spinners";
import EditCruiseModal from "../../components/agent/EditCruiseModal";
import NoSubscriptionWarning from "../../components/agent/NoSubscriptionWarning";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

const YourCruises = () => {
  // Extract cruises data and associated state from Redux store
  const dispatch = useDispatch();
  const cruises = useSelector((state) => state.agentCruises.cruises.cruises);
  // const agentCruises = useSelector((state) => state.agentCruises.cruisesList.cruises);
  const error = useSelector((state) => state.cruises.cruisesList.error);
  const status = useSelector((state) => state.cruises.cruisesList.status);
  const subscriptionActive = useSelector((state) => {
    const subscriptionStatus =
      state.subscriptionStatus.data.status === "active";
    // const userIsAdmin = state.activeUser.data.user.user_type === "admin";
    const userIsAgent = state.activeAgent.data.agent.user_type === "agent";

    return subscriptionStatus || userIsAgent;
  });
  const activeAgent = useSelector((state) => state.activeAgent.data.agent);

  // console.log("These are the cruises from the selector", agentCruises);

  const [isSubscribed, setIsSubscribed] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null); // true for ascending, false for descending
  const [itemsPerPage, setItemsPerPage] = useState(5);
  // const [totalPages, setTotalPages] = useState(1); // Set initial value after fetching the first time
  const [feedback, setFeedback] = useState("");
  const [feedbackColor, setFeedbackColor] = useState("");
  const [editFormData, setEditFormData] = useState({
    GroupId: "",
    GroupName: "",
    Ship: "",
    SailDate: "",
    Product: "",
    Nights: null,
    Currency: "",
    CategoryClass: "",
    Category: "",
    CategoryName: "",
    RoomsAvailable: null,
    GroupRate: null,
    CurrentGroupRate: null,
    CurrentPriceAdv: null,
    CurrentPercentAdv: null,
    CombinedKey: "",
  });
  const [editMode, setEditMode] = useState(null);
  const [cruiseToSaveIndex, setCruiseToSaveIndex] = useState(null);
  const [showEditConfirmModal, setShowEditConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [filters, setFilters] = useState(initialFilters);
  const [selectedCruise, setSelectedCruise] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [showSuccessFeedback, setShowSuccessFeedback] = useState(false);
  const [showDeleteFeedback, setShowDeleteFeedback] = useState(false);

  const handleEditClick = (cruise) => {
    setSelectedCruise(cruise);
    setShowEditModal(true);
  };

  const handleEditSuccess = () => {
    setShowSuccessFeedback(true);
    setTimeout(() => {
      setShowSuccessFeedback(false);
    }, 3000); // Set timeout to hide feedback after 3 seconds
  };

  const handleDeleteSuccess = () => {
    setShowDeleteFeedback(true);
    setTimeout(() => {
      setShowDeleteFeedback(false);
    }, 3000); // Set timeout to hide feedback after 3 seconds
  };
  const [sort, setSort] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCruiseLine, setSelectedCruiseLine] = useState("");
  const [selectedShip, setSelectedShip] = useState("");
  const [nights, setNights] = useState("");
  const [region, setRegion] = useState("");

  const [excludeSoldOut, setExcludeSoldOut] = useState(true);
  const [filters, setFilters] = useState({}); // Default empty filters or your base filters
  // const [filteredCruises, setFilteredCruises] = useState([]);

  // useEffect to dispatch using selling agent
  useEffect(() => {
    if (activeAgent?.email) {
      dispatch(fetchAgentCruises({ sellingAgent: activeAgent.email }));
    }
  }, [dispatch, activeAgent]);

  // Refresh data handler
  const refreshData = () => {
    dispatch(fetchAgentCruises({ sellingAgent: activeAgent.email }));
    dispatch(fetchActiveAgent());
  };

  // useEffect to determine agent subscription status
  useEffect(() => {
    if (activeAgent.subscription == null || activeAgent.subscription === "") {
      setIsSubscribed(false);
      console.log("no sub");
    } else {
      setIsSubscribed(true);
    }
  }, [activeAgent]);

  if (status === "loading") return <p>Loading...</p>;
  if (error === "failed") return <p>Error: {error}</p>;

  const giveFeedback = (success, errorMessage) => {
    const message = success ? "Data fetched" : errorMessage;
    const color = success ? "green" : "red";

    setFeedback(message);
    setFeedbackColor(color);

    setTimeout(() => {
      setFeedback("");
    }, 1750);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  }

  function highlightTerm(text, term) {
    if (!term) return text; // Return original text if term is empty

    const escapedTerm = escapeRegExp(term);
    const splitText = String(text).split(new RegExp(`(${escapedTerm})`, "gi"));

    return splitText.map((part, i) =>
      part.toLowerCase() === term.toLowerCase() ? (
        <span key={i} className="bg-yellow-300">
          {part}
        </span>
      ) : (
        part
      )
    );
  }

  function handleSort(column) {
    if (sort === column) {
      if (sortOrder === "desc") {
        setSort(null); // Reset sorting on third click
        setSortOrder(null);
      } else {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      }
    } else {
      setSort(column);
      setSortOrder("asc"); // Default order when clicking a new column
    }
  }

  const resetSort = () => {
    setSortField(null);
    setSortDirection(null);
  };

  const getSortIcon = (field) => {
    if (sort === field) {
      if (sortOrder === "asc") {
        return <BarsArrowUpIcon className="h-4 w-4 inline ml-1" />;
      } else if (sortOrder === "desc") {
        return <BarsArrowDownIcon className="h-4 w-4 inline ml-1" />;
      }
    }
    return <Bars3BottomLeftIcon className="h-3.5 w-3.5 inline ml-1" />;
  };

  let displayedCruises = []; // Define displayedCruises variable outside the conditional block

  if (cruises && cruises.length > 0 && searchTerm) {
    displayedCruises = cruises.filter((cruise) => {
      return Object.values(cruise).some((val) =>
        String(val).toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    // Use displayedCruises in your rendering logic
  } else {
    // Handle the case where cruises or searchTerm is not defined
  }

  // Combine existing logic with pagination
  const paginatedCruises = displayedCruises.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrev = () => {
    if (currentPage > 0) {
      // to ensure it doesn't go into negative
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePage = (pageNum) => {
    setCurrentPage(pageNum);
  };

  console.log("selected cruise is", selectedCruise);
  
  return (
    <div className="pt-4 ml-6 mr-6 sm:px-6 lg:pr-10 lg:pl-0">
      {/* Header and Refresh DB button */}
      <div className="sm:flex sm:items-center justify-between">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Your Cruises
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            These are the cruises you have added that your clients can see.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <div className="flex items-center">
            <p
              className="text-base font-semibold leading-6 mr-2.5"
              style={{ color: feedbackColor }}>
              {feedback}
            </p>
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={refreshData}>
              Refresh Your Cruises
            </button>
          </div>
        </div>
      </div>

      {/* Search and Pagination */}
      <div className="my-4 flex justify-between">
        <div className="w-1/2 sm:w-1/4">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search by any field"
            className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        {/* <div className="inline-flex items-center rounded-md">
          <div className="px-3 py-2">
            <label htmlFor="itemsPerPage" className="sr-only">
              Items per page
            </label>
            <select
              id="itemsPerPage"
              className="border-gray-300 rounded-md shadow-sm"
              onChange={handleItemsPerPageChange}
              value={itemsPerPage}>
              <option value={5}>5 per page</option>
              <option value={10}>10 per page</option>
              <option value={20}>20 per page</option>
              <option value={50}>50 per page</option>
            </select>
          </div>
        </div> */}
      </div>

      {/* Success feedback */}
      {showSuccessFeedback && (
        <div className="rounded-md bg-green-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-green-800">
                Successfully updated
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Delete feedback */}
      {showDeleteFeedback && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                Cruise deleted
              </h3>
            </div>
          </div>
        </div>
      )}

      {/* Subscription warning */}
      {isSubscribed ? null : <NoSubscriptionWarning />}

      {/* <CruiseFilter
        onPageChange={handlePageChange}
        onFiltersChanged={handleFiltersChanged}
        subscriptionActive={subscriptionActive}
      /> */}

      {/* Table */}
      <div className="mt-8 flow-root pb-20">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 relative">
            {status === "loading" ? (
              <tr>
                <td colSpan="5" className="text-center py-5">
                  <ClipLoader color="#4535be" size={75} />
                </td>
              </tr>
            ) : error ? (
              <div className="error-container">
                <p>Error fetching your cruises: {error}</p>
                {/* <button onClick={handleClearError}>Clear Error</button> */}
              </div>
            ) : (
              <div
                className={`relative ${
                  !isSubscribed && "pointer-events-none"
                }`}>
                {!isSubscribed && (
                  <div className="absolute inset-0 bg-opacity-50 backdrop-blur-sm z-10"></div>
                )}
                <table className=" divide-y divide-gray-300 pr-8">
                  {/* Table Header */}
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="whitespace-nowrap py-3.5 pl-4 pr-3
                      text-left text-sm font-semibold text-gray-400 sm:pl-0
                      cursor-pointer"></th>
                      <th
                        scope="col"
                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 cursor-pointer"
                        onClick={() => handleSort("GroupId")}>
                        Group ID {getSortIcon("GroupId")}
                      </th>

                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                        onClick={() => handleSort("GroupName")}>
                        Group Name {getSortIcon("GroupName")}
                      </th>

                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                        onClick={() => handleSort("Ship")}>
                        Cruise Ship {getSortIcon("Ship")}
                      </th>

                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                        onClick={() => handleSort("SailDate")}>
                        Sail Date {getSortIcon("SailDate")}
                      </th>

                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                        onClick={() => handleSort("Product")}>
                        Product {getSortIcon("Product")}
                      </th>

                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                        onClick={() => handleSort("name")}>
                        Cruise Name {getSortIcon("name")}
                      </th>

                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                        onClick={() => handleSort("Nights")}>
                        Nights {getSortIcon("Nights")}
                      </th>

                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                        onClick={() => handleSort("Currency")}>
                        Currency {getSortIcon("Currency")}
                      </th>

                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                        onClick={() => handleSort("Category")}>
                        Category {getSortIcon("Category")}
                      </th>

                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                        onClick={() => handleSort("CategoryName")}>
                        Category Name {getSortIcon("CategoryName")}
                      </th>

                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                        onClick={() => handleSort("RoomsAvailable")}>
                        Rooms Available {getSortIcon("RoomsAvailable")}
                      </th>

                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                        onClick={() => handleSort("GroupRate")}>
                        Group Rate {getSortIcon("GroupRate")}
                      </th>

                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                        onClick={() => handleSort("CurrentGroupRate")}>
                        Market Rate {getSortIcon("CurrentGroupRate")}
                      </th>

                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                        onClick={() => handleSort("CurrentPriceAdv")}>
                        Price Advantage {getSortIcon("CurrentPriceAdv")}
                      </th>

                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                        onClick={() => handleSort("CurrentPercentAdv")}>
                        Percentage Advantage {getSortIcon("CurrentPercentAdv")}
                      </th>

                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                        onClick={() => handleSort("Perks")}>
                        Perks{getSortIcon("Perks")}
                      </th>
                    </tr>
                  </thead>

                  {/* Table Body */}
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {/* Loading State */}
                    {status === "loading" && (
                      <tr>
                        <td colSpan="10" className="text-center py-5">
                          <ClipLoader color="#4535be" size={75} />
                        </td>
                      </tr>
                    )}
                    {/* Error State */}
                    {error && (
                      <tr>
                        <td
                          colSpan="10"
                          className="text-center py-5 text-red-500">
                          Error: {error}
                        </td>
                      </tr>
                    )}
                    {/* Successful fetch but no cruises */}
                    {status === "succeeded" && cruises.length === 0 && (
                      <tr>
                        <td
                          colSpan="10"
                          className="text-center py-5 text-sm text-gray-500">
                          Could not find a cruise that matches your criteria
                        </td>
                      </tr>
                    )}
                    {/* Displaying the cruises data */}
                    {cruises && cruises.length > 0 ? (
                      cruises.map((cruise) => (
                        <tr key={cruise._id} className="even:bg-gray-100">
                          <td>
                            <button
                              className="whitespace-nowrap py-2 text-sm text-gray-400 pr-2"
                              onClick={() => {
                                setSelectedCruise(cruise);
                                setShowEditModal(true);
                                setIsModalOpen(true);
                              }}>
                              Edit
                            </button>
                          </td>
                          <td className="whitespace-nowrap py-2 text-sm font-medium text-gray-900">
                            {highlightTerm(cruise.GroupId, searchTerm)}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {highlightTerm(cruise.GroupName, searchTerm)}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {highlightTerm(cruise.Ship, searchTerm)}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {highlightTerm(
                              cruise.SailDate.split("T")[0], // Extracts the "YYYY-MM-DD" part and avoids time zone conversion
                              searchTerm
                            )}
                          </td>

                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {highlightTerm(cruise.Product, searchTerm)}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {highlightTerm(cruise.name, searchTerm)}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {highlightTerm(cruise.Nights, searchTerm)}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {highlightTerm(cruise.Currency, searchTerm)}
                          </td>

                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {highlightTerm(cruise.Category, searchTerm)}
                          </td>

                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {cruise.CategoryClass
                              ? highlightTerm(cruise.CategoryName, searchTerm)
                              : "-"}
                          </td>

                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {highlightTerm(cruise.RoomsAvailable, searchTerm)}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {highlightTerm(`$${cruise.GroupRate}`, searchTerm)}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {highlightTerm(
                              `$${
                                cruise.CurrentGroupRate || cruise.MarketRate
                              }`,
                              searchTerm
                            )}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {highlightTerm(
                              `$${cruise.CurrentPriceAdv}`,
                              searchTerm
                            )}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {highlightTerm(
                              `${cruise.CurrentPercentAdv}%`,
                              searchTerm
                            )}
                          </td>

                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {cruise.Perks}
                          </td>
                        </tr>
                      ))
                    ) : (
                      // Handle the case where cruises is undefined or empty
                      <tr>
                        <td colSpan="3">
                          <p className="mt-2 text-sm text-gray-700">
                            You have no custom cruises.
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Editing modal */}
      {/* {showEditModal && selectedCruise && (
        <EditCruiseModal
          key={selectedCruise?.id}
          isOpen={isModalOpen}
          onClose={toggleEditModal}
          cruise={selectedCruise}
        />
      )} */}

      {showEditModal && selectedCruise && (
        <EditCruiseModal
          key={selectedCruise?.id}
          isOpen={showEditModal}
          onClose={() => setShowEditModal(false)}
          cruise={selectedCruise}
          onEditSuccess={handleEditSuccess}
          onDeleteSuccess={handleDeleteSuccess}
        />
      )}
    </div>
  );
};

export default YourCruises;
