import { createSlice } from "@reduxjs/toolkit";
import { fetchInquiries } from "../thunks/inquiriesThunk"; // Import your fetchInquiries thunk

const inquiriesSlice = createSlice({
  name: "inquiries",
  initialState: {
    data: [], // An array to store inquiries data
    status: "idle", // Consider renaming this to fetchStatus if you want
    error: null,
  },
  reducers: {}, // You can add additional reducers if needed
  extraReducers: (builder) => {
    builder
      // fetchInquiries thunk
      .addCase(fetchInquiries.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchInquiries.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload; // Set the inquiries data in the state
      })
      .addCase(fetchInquiries.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default inquiriesSlice.reducer;
