import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchCruisePricing } from "../../store/thunks/fetchCruisePricing";
import { US, CA } from "country-flag-icons/react/3x2";
import roomList from "../cruiseList/roomList";
import { fetchCruisePricingWithoutCruiseId } from "../../store/thunks/fetchCruisePricingWithoutCruiseId";
import ContactUsModal from "./ContactUsModal";
import { isDev } from "../../config";
import ReactGA from "react-ga4";

function PricingTab({ cruiseId, cruiseLine, cruiseShip, cruiseDate }) {
  const dispatch = useDispatch();

  const [showContactModal, setShowContactModal] = useState(false);
  const [selectedCruiseData, setSelectedCruiseData] = useState({});

  // Define a state to hold the pricing data
  const [pricingData, setPricingData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch pricing data for the specified cruiseId and store it in the state
    async function fetchPricingData() {
      try {
        console.log(`Fetching pricing data for cruise ID: ${cruiseId}`);
        const response = await dispatch(fetchCruisePricing(cruiseId));
        console.log(`Received pricing data for cruise ID: ${cruiseId}`);
        console.log("Pricing Data:", response); // Log the pricing data
        setPricingData(response.payload);
      } catch (error) {
        // Handle API request errors
        console.error(
          `Error fetching pricing data for cruise ID: ${cruiseId}`,
          error
        );
        setError(error.message);
      }
    }

    // Fetch function
    async function fetchPricingBySailDate() {
      const dateWithoutTime = cruiseDate.split(" ")[0]; // Extract date without time
      const modifiedData = {
        CruiseLine: cruiseLine,
        Ship: cruiseShip,
        TextSailDate: dateWithoutTime,
      }; // Modified data
      console.log("Modified data:", modifiedData); // Log the modified data
      try {
        const response = await dispatch(
          fetchCruisePricingWithoutCruiseId(modifiedData)
        );
        setPricingData(response.payload);
      } catch (error) {
        console.error(
          `Error fetching pricing data for cruise: ${modifiedData}`
        );
        setError(error.message);
      }
    }

    if (cruiseId) {
      fetchPricingData();
    } else {
      fetchPricingBySailDate();
    }
  }, [cruiseId, dispatch]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Function to get category name from item.Category
  function getCategoryName(categoryCode, cruiseLine) {
    const room = roomList.find((room) => room.categoryCode === categoryCode);
    if (!room) return categoryCode;

    // Check for "w/" and "(" as potential break points
    const breakpoints = [" w/ ", " ("];
    let splitName;

    for (const breakpoint of breakpoints) {
      splitName = room.categoryName.split(breakpoint);
      if (splitName.length > 1) {
        const separator = breakpoint === " w/ " ? " w/ " : " (";
        return (
          <>
            {splitName[0]} <br /> {separator}
            {splitName[1]}
          </>
        );
      }
    }

    return room.categoryName;
  }

  const onInquireClick = (
    id,
    Ship,
    SailDate,
    Nights,
    Product,
    // Category,
    CurrentPriceAdv,
    CurrentPercentAdv,
    GroupRate,
    CategoryClass
  ) => {
    if (!isDev) {
      ReactGA.event({
        category: "User",
        action: "Inquiry Clicked",
      });
    }
    setSelectedCruiseData({
      cruiseId: id,
      cruiseShip: Ship,
      sailDate: SailDate,
      nights: Nights,
      product: Product,
      // category: Category,
      currentPriceAdv: CurrentPriceAdv,
      currentPercentAdv: CurrentPercentAdv,
      groupRate: GroupRate,
      category: CategoryClass,
    });
    setShowContactModal(true);
  };

  return (
    <div className="overflow-x-auto overflow-y-auto">
      <h3 className="text-lg font-medium text-gray-900">Pricing</h3>
      <div className="shadow-md overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-300 mt-4">
          <thead className="hidden sm:table-header-group">
            <tr>
              <th
                scope="col"
                className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                Current Price PP
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                Our Price PP
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                Savings PP
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                Category
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                Rooms
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span className="sr-only">Book</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {pricingData && pricingData.length > 0 ? (
              pricingData.map((item) => (
                <tr key={item._id}>
                  {/* Original price */}
                  <td className="whitespace-nowrap px-2 py-2 mt-2 text-sm text-gray-900">
                    <div className="sm:hidden pb-1 font-semibold">
                      Original Price:
                    </div>
                    <div className="sm:flex items-center">
                      <span
                        className={
                          parseFloat(item.CurrentGroupRate) === 0
                            ? "inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-500 ring-1 ring-inset ring-red-600/20"
                            : "inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-500 ring-1 ring-inset ring-gray-600/20"
                        }>
                        {parseFloat(item.CurrentGroupRate) === 0 ? (
                          <span
                            className="text-red-500"
                            title="Cabin no longer available through the cruise line">
                            SOLD OUT
                          </span>
                        ) : (
                          <>
                            {item.Currency === "CAD" ? (
                              <>
                                <CA title="Canada" className="w-4 mr-1" />$
                                {parseFloat(item.CurrentGroupRate).toFixed(2)} +
                                taxes
                              </>
                            ) : (
                              <>
                                <US
                                  title="United States"
                                  className="w-4 mr-1"
                                />
                                ${parseFloat(item.CurrentGroupRate).toFixed(2)}{" "}
                                + taxes
                              </>
                            )}
                          </>
                        )}
                      </span>
                    </div>
                  </td>

                  {/* Our Price/Group Rate */}
                  <td className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900">
                    <div className="sm:hidden pb-1 font-semibold">
                      Our Price:
                    </div>
                    <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                      {/* This will only be visible on small screens and will cause a line break */}
                      {item.Currency === "CAD" ? (
                        <>
                          <CA title="Canada" className="w-4 mr-1" />
                        </>
                      ) : (
                        <US title="United States" className="w-4 mr-1" />
                      )}
                      ${parseFloat(item.GroupRate).toFixed(2)} + taxes
                    </span>
                  </td>

                  {/* Savigngs */}
                  <td className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900">
                    <div className="sm:hidden pb-1 font-semibold">
                      You Save:
                    </div>

                    <div className="inline-flex flex-col rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                      {isNaN(parseFloat(item.CurrentPriceAdv)) ? (
                        <span
                          className="font-medium text-red-500"
                          title="Cabin no longer available through the cruise line">
                          SOLD OUT
                        </span>
                      ) : (
                        <span className="pt-1">
                          Save $
                          <span className="font-semibold ">
                            {parseFloat(item.CurrentPriceAdv).toFixed(2)}
                          </span>
                        </span>
                      )}
                      <div className="flex items-center text-gray-500 pb-1 mt-1">
                        {isNaN(item.CurrentPercentAdv)
                          ? "SOLD OUT"
                          : `${item.CurrentPercentAdv}% OFF`}
                      </div>
                    </div>
                  </td>

                  {/* Category */}
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                    <div className="sm:hidden pb-1 font-semibold text-gray-900">
                      Category:{" "}
                    </div>
                    {item.Category} -{" "}
                    {getCategoryName(item.Category, item.CruiseLine)}
                  </td>

                  {/* Rooms available */}
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                    <div className="sm:hidden pb-1 font-semibold text-gray-900">
                      Rooms Available:{" "}
                    </div>
                    {item.RoomsAvailable}
                  </td>

                  {/* Your Inquire Button */}
                  <td className="relative whitespace-nowrap py-2 sm:py-5 pl-3 pr-4 text-sm font-medium sm:pr-0 sm:text-right text-left">
                    <button
                      onClick={() => {
                        if (item.CruiseLine === "Virgin Voyages") {
                          // Open a URL in a new window for Virgin Voyages
                          window.open(item.Url, "_blank");
                        } else {
                          onInquireClick(
                            item._id,
                            item.Ship,
                            item.SailDate,
                            item.Nights,
                            item.Product,
                            item.CurrentPriceAdv,
                            item.CurrentPercentAdv,
                            item.GroupRate
                          );
                        }
                      }}
                      className="text-primary-color hover:text-indigo-900">
                      {item.CruiseLine === "Virgin Voyages"
                        ? "Book"
                        : "Inquire"}
                      <span className="sr-only">, {item.Url}</span>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">
                  <br />
                  To view pricing data, you must sign in with a valid account.
                  <br /> <br />
                  Contact{" "}
                  <b>
                    <i>admin@cruisecompare.ca</i>
                  </b>{" "}
                  if you think this is a mistake.
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* Your ContactUsModal */}
        {showContactModal && (
          <ContactUsModal
            onClose={() => setShowContactModal(false)}
            {...selectedCruiseData}
          />
        )}
      </div>
    </div>
  );
}

export default PricingTab;
