import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import loginUserReducer from "./slices/loginUserSlice";
import loginAgentReducer from "./slices/loginAgentSlice";
import usersReducer from "./slices/usersSlice";
import agentsReducer from "./slices/agentsSlice";
import cruisesReducer from "./slices/cruisesSlice";
import agentCruisesReducer from "./slices/agentCruisesSlice";
import fetchCruiseLinesReducer from "./slices/fetchCruiseLinesSlice";
import fetchSubscriptionStatusReducer from "./slices/fetchSubscriptionStatusSlice";
import fetchCruiseDetailsReducer from "./slices/widgety/fetchCruiseDetailsSlice";
import fetchShipReducer from "./slices/widgety/fetchShipDetailsSlice";
import fetchCruiseReducer from "./slices/fetchCruiseSlice";
import fetchCruisePricingReducer from "./slices/fetchCruisePricing";
import fetchCruisePricingWithoutCruiseIdReducer from "./slices/fetchCruisePricingWithoutCruiseId";
import editActiveUserReducer from "./slices/editActiveUserSlice";
import sendClientQuoteReducer from "./slices/sendClientQuoteSlice";
import sendGa4EventReducerReducer from "./slices/google/sendGa4EventSlice";
import fetchAdminMetricsReducer from "./slices/admin/fetchAdminMetricsSlice";
import fetchAgentMetricsReducer from "./slices/agent/fetchAgentMetricsSlice";
import fetchUsersAgentReducer from "./slices/fetchUsersAgentSlice";
import fetchInquiriesReducer from "./slices/inquiriesSlice";
import fetchAgentQuotesReducer from "./slices/fetchAgentQuotesSlice";
import fetchUserQuoteReducer from "./slices/userQuoteSlice";
import faqsReducer from "./slices/faqsSlice";
import thunk from "redux-thunk";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = persistReducer(
  persistConfig,
  combineReducers({
    activeUser: loginUserReducer,
    activeAgent: loginAgentReducer,
    usersAgent: fetchUsersAgentReducer,
    users: usersReducer,
    agents: agentsReducer,
    editUser: editActiveUserReducer,
    cruises: cruisesReducer,
    agentCruises: agentCruisesReducer,
    quotes: fetchAgentQuotesReducer,
    userQuotes: fetchUserQuoteReducer,
    cruise: fetchCruiseReducer,
    ship: fetchShipReducer,
    cruiseDetails: fetchCruiseDetailsReducer,
    cruiseLines: fetchCruiseLinesReducer,
    subscriptionStatus: fetchSubscriptionStatusReducer,
    clientQuotes: sendClientQuoteReducer,
    cruisePricing: fetchCruisePricingReducer,
    cruisePricingWithoutCruiseId: fetchCruisePricingWithoutCruiseIdReducer,
    ga4Event: sendGa4EventReducerReducer,
    adminMetrics: fetchAdminMetricsReducer,
    agentMetrics: fetchAgentMetricsReducer,
    inquiries: fetchInquiriesReducer, // Add the inquiries reducer here
    faqs: faqsReducer,
  })
);

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

const persistor = persistStore(store);

export { store, persistor };

export * from "./thunks/loginUser";
export * from "./thunks/loginAgent";
export * from "./thunks/logoutUser";
export * from "./thunks/logoutAgent";
export * from "./thunks/registerUser";
export * from "./thunks/registerAgent";
export * from "./thunks/fetchCruises";
export * from "./thunks/widgety/fetchCruiseLines";
export * from "./thunks/fetchSubscriptionStatus";
export * from "./thunks/fetchCruise";
export * from "./thunks/fetchCruisePricing";
export * from "./thunks/fetchCruisePricingWithoutCruiseId";
export * from "./thunks/widgety/fetchCruiseDetails";
export * from "./thunks/widgety/fetchShipDetails";
export * from "./thunks/sendClientQuoteThunk";
export * from "./thunks/google/sendGa4Event";
export * from "./thunks/admin/fetchAdminMetrics";
export * from "./thunks/agent/fetchAgentMetrics";
export * from "./thunks/inquiriesThunk";
export * from "./thunks/faqsThunk";
export * from "./thunks/fetchUsersAgent";
export * from "./thunks/userQuoteThunk";
