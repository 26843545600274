// this is a combination of usersSlice and editActiveUsersSlice

import { createSlice } from "@reduxjs/toolkit";
import {
  fetchUsersList,
  editActiveUser,
  updateUserThunk,
} from "../thunks/usersThunk";

const usersSlice = createSlice({
  name: "users",
  initialState: {
    usersList: [],
    user: null, // From editActiveUserSlice
    status: "idle", // Consider renaming this to fetchStatus if you want
    isLoading: false, // From editActiveUserSlice
    error: null,
  },
  reducers: {
    setActiveUser: (state, action) => {
      state.activeUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchUsersList thunks
      .addCase(fetchUsersList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUsersList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.usersList = action.payload;
      })
      .addCase(fetchUsersList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // editActiveUser thunks
      .addCase(editActiveUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(editActiveUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLoading = false;
      })
      .addCase(editActiveUser.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      })
      // Inside the usersSlice
      .addCase(updateUserThunk.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUserThunk.fulfilled, (state, action) => {
        // Here, you should update the user in your Redux state with the updated data.
        // For simplicity, you can fetch the users again or replace the user at the specific index.
        state.status = "succeeded";
      })
      .addCase(updateUserThunk.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setActiveUser } = usersSlice.actions;
export default usersSlice.reducer;
