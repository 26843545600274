import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL
import { useSelector } from "react-redux";

const ContactUsTab = ({
  cruiseId,
  cruiseShip,
  sailDate,
  nights,
  product,
  currentPriceAdv,
  currentPercentAdv,
  groupRate,
  onClose,
  cruiseData,
}) => {
  const apiToken = useSelector((state) => state.activeUser.data.user?.token);
  const activeUser = useSelector((state) => state.activeUser.data.user);

  // Initialize userId conditionally
  const userId = activeUser ? activeUser._id : null;

  const ship_title = cruiseData.cruises[0].ship_title;
  const starts_at = cruiseData.cruises[0].starts_at;
  const starts_on = cruiseData.cruises[0].starts_on;
  const vacation_days = cruiseData.cruises[0].vacation_days;
  const name = cruiseData.cruises[0].name;

  const [formData, setFormData] = useState({
    firstName: activeUser?.first_name || "",
    lastName: activeUser?.last_name || "",
    email: activeUser?.email || "",
    phone: "",
    message: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (activeUser) {
      setFormData({
        firstName: activeUser.first_name || "",
        lastName: activeUser.last_name || "",
        email: activeUser.email || "",
        phone: "",
        message: "",
      });
    }
  }, [activeUser]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    console.log("this is the cruise id", cruiseId);
    
    if (!formData.phone) {
      setErrorMessage("Phone number is required!");
      return;
    }

    if (!formData.message) {
      setErrorMessage("A message about your inquiry is required!");
      return;
    }

    try {
      const response = await axios.post(
        `https://${apiBaseUrl}/contactAgent`,
        {
          ...formData,
          cruiseId,
          userId,
          name: cruiseData.cruises[0].name,
          ship_title: cruiseData.cruises[0].ship_title,
          starts_on: cruiseData.cruises[0].starts_on,
          starts_at: cruiseData.cruises[0].starts_at,
          vacation_days: cruiseData.cruises[0].vacation_days,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-token": apiToken,
            "x-user-id": userId,
            // Add other headers as needed
          },
        }
      );

      if (response.status === 200) {
        console.log("Form data sent successfully");
        setIsSubmitted(true);
        setErrorMessage("");
        resetForm();
      } else {
        console.error("Failed to send form data");
        setErrorMessage("Failed to send form data");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setErrorMessage(`An error occurred: ${error.message}`);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const resetForm = () => {
    setFormData({
      firstName: activeUser?.first_name || "",
      lastName: activeUser?.last_name || "",
      email: activeUser?.email || "",
      phone: "",
      message: "",
    });
  };

  // import React, { useState } from "react";

  // import axios from "axios";
  // import { useSelector } from "react-redux";
  // import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL

  // const ContactUsTab = ({
  //   cruiseId,
  //   cruiseShip,
  //   sailDate,
  //   nights,
  //   product,
  //   currentPriceAdv,
  //   currentPercentAdv,
  //   groupRate,
  //   onClose,
  //   cruiseData,
  // }) => {
  //   const apiToken = useSelector((state) => state.activeUser.data.user?.token);
  //   const activeUser = useSelector((state) => state.activeUser.data.user);
  //   const userId = activeUser._id;
  //   const ship_title = cruiseData.cruises[0].ship_title;
  //   const starts_at = cruiseData.cruises[0].starts_at;
  //   const starts_on = cruiseData.cruises[0].starts_on;
  //   const vacation_days = cruiseData.cruises[0].vacation_days;
  //   const name = cruiseData.cruises[0].name;

  //   const [formData, setFormData] = useState({
  //     firstName: activeUser.first_name,
  //     lastName: activeUser.last_name,
  //     email: activeUser.email,
  //     phone: "",
  //     message: "",
  //     // details: cruiseData.cruises[0].starts_on,
  //   });

  //   const [isSubmitted, setIsSubmitted] = useState(false);
  //   const [errorMessage, setErrorMessage] = useState("");

  //   const handleFormSubmit = async (event) => {
  //     event.preventDefault();

  //     if (!formData.phone) {
  //       setErrorMessage("Phone number is required!");
  //       return;
  //     }

  //     if (!formData.message) {
  //       setErrorMessage("A message about your inquiry is required!");
  //       return;
  //     }

  //     try {
  //       const response = await axios.post(
  //         `https://${apiBaseUrl}/contactAgent`,
  //         {
  //           ...formData,
  //           cruiseId,
  //           userId,
  //           name,
  //           ship_title,
  //           starts_on,
  //           starts_at,
  //           vacation_days,
  //         },
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             "x-api-token": apiToken,
  //             "x-user-id": activeUser._id,
  //             // Add other headers as needed
  //           },
  //         }
  //       );

  //       if (response.status === 200) {
  //         // Changed from response.ok to response.status === 200
  //         console.log("Form data sent successfully");
  //         setIsSubmitted(true);
  //         setErrorMessage("");
  //         resetForm();
  //       } else {
  //         console.error("Failed to send form data");
  //         setErrorMessage("Failed to send form data");
  //       }
  //     } catch (error) {
  //       console.error("An error occurred:", error);
  //       setErrorMessage(`An error occurred: ${error.message}`);
  //     }
  //   };

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  // Existing resetForm - worked for authenticated
  // const resetForm = () => {
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     phone: "",
  //     message: "",
  //   }));
  // };

  return (
    <div className="relative isolate bg-white px-6 py-12 sm:py-12 lg:px-8 text-left">
      {/* Cool grid background */}
      <svg
        className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
        aria-hidden="true">
        <defs>
          <pattern
            id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
            width={200}
            height={200}
            x="50%"
            y={-64}
            patternUnits="userSpaceOnUse">
            <path d="M100 200V.5M.5 .5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y={-64} className="overflow-visible fill-gray-50">
          <path
            d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M299.5 800h201v201h-201Z"
            strokeWidth={0}
          />
        </svg>
        <rect
          width="100%"
          height="100%"
          strokeWidth={0}
          fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
        />
      </svg>

      {/* Content and form */}
      <div className="mx-auto max-w-xl lg:max-w-4xl text-left">
        {/* Form and form text */}
        <div className=" flex flex-col items-start gap-16 sm:gap-y-10 lg:flex-row">
          <form action="#" method="POST" className="lg:flex-auto">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              {/* FName */}
              <div>
                <label
                  htmlFor="first-name"
                  className="block text-sm font-semibold leading-6 text-gray-900">
                  First Name
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="firstName"
                    id="first-name"
                    value={formData.firstName}
                    // readOnly={true}
                    readOnly={!activeUser === false} // Set readOnly based on activeUser
                    onChange={handleInputChange}
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              {/* LName */}
              <div>
                <label
                  htmlFor="last-name"
                  className="block text-sm font-semibold leading-6 text-gray-900">
                  Last Name
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="lastName"
                    id="last-name"
                    value={formData.lastName}
                    // readOnly={true}
                    readOnly={!activeUser === false} // Set readOnly based on activeUser
                    onChange={handleInputChange}
                    autoComplete="family-name"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              {/* Email */}
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-semibold leading-6 text-gray-900">
                  Email
                </label>
                <div className="mt-2.5">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    readOnly={!activeUser === false} // Set readOnly based on activeUser
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              {/* Phone */}
              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-semibold leading-6 text-gray-900">
                  Phone Number
                </label>
                <div className="mt-2.5">
                  <input
                    id="phone"
                    name="phone"
                    type="tel" // Use type="tel" for phone numbers
                    value={formData.phone}
                    onChange={handleInputChange}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              {/* Message */}
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-semibold leading-6 text-gray-900">
                  Message
                </label>
                <div className="mt-2.5">
                  <textarea
                    id="message"
                    name="message"
                    rows={4}
                    value={formData.message}
                    onChange={handleInputChange}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    // defaultValue={""}
                  />
                </div>
              </div>
            </div>
            <div className="mt-10">
              <button
                type="button"
                className="block w-full rounded-md bg-primary-color px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={handleFormSubmit}>
                Let's talk{" "}
              </button>
              <p className="mt-4 text-sm leading-6 text-gray-500">
                By submitting this form, I agree to the{" "}
                <a
                  href="https://www.cruisecompare.ca/privacy_policy"
                  target="_blank" // This attribute opens the link in a new window or tab
                  rel="noopener noreferrer" // Recommended for security when using target="_blank"
                  className="font-semibold text-indigo-600">
                  privacy&nbsp;policy
                </a>
                .
              </p>
            </div>
            {/* Display success or error message */}
            {isSubmitted && (
              <p className="mt-4 text-sm text-green-500">
                Form submitted successfully!
              </p>
            )}
            {errorMessage && (
              <p className="mt-4 text-sm text-red-500">{errorMessage}</p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUsTab;
