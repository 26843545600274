import React from "react";
import { BookingStepsTexts } from "./DataLists";
import { MapTrifold } from "@phosphor-icons/react";
import SignUpButton from "./SignUpButton";
import { CheckIcon } from "@heroicons/react/24/solid";

const steps = [
  {
    name: "Step 1 - Join",
    description: BookingStepsTexts.listOfSteps[0].text,
    icon: <MapTrifold size={18} color="currentColor" weight="fill" />,
  },
  {
    name: "Step 2 - Discover",
    description: BookingStepsTexts.listOfSteps[1].text,
    icon: <MapTrifold size={18} color="currentColor" weight="fill" />,
  },
  {
    name: "Step 3 - Personalize",
    description: BookingStepsTexts.listOfSteps[2].text,
    icon: <MapTrifold size={18} color="currentColor" weight="fill" />,
  },
];

function BookingSteps() {
  return (
    <section className="flex items-center relative w-full h-auto flex flex-col items-start justify-center lg:px-24 md:px-10 px-6 my-28 gap-5">
      <h1 className="text-3xl font-bold tracking-tight text-color3 sm:text-4xl">
        {BookingStepsTexts.secondText}
      </h1>

      <h1 className="lg:text-2xl md:text-xl text-xl text-color1 font-medium">
        {BookingStepsTexts.thirdText}
      </h1>

      <div className="z-10 isolate mx-auto grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        {steps.map((step) => (
          <div
            key={step.name}
            className="bg-gray-50 border border-purple-500 rounded-3xl p-8 relative z-20">
            <h3 className="text-gray-900 text-2xl font-semibold leading-8">
              {step.name}
            </h3>
            <p className="mt-4 text-xl leading-7 text-gray-600 tracking-wider">
              {step.description.split(/\. |! /).map((sentence, index, arr) => (
                <span key={index}>
                  {sentence}
                  {index !== arr.length - 1 && (
                    <>
                      {step.description.charAt(
                        step.description.indexOf(sentence) + sentence.length
                      ) === "."
                        ? "."
                        : "!"}
                      <br />
                      <br />
                    </>
                  )}
                </span>
              ))}
            </p>
          </div>
        ))}
      </div>

      <div className="flex items-center flex-col">
        <div className="mb-4 text-center">
          <h1 className="lg:text-2xl md:text-xl text-xl text-color1 font-medium">
            {BookingStepsTexts.fourthText}
          </h1>
        </div>
        <div className="text-center">
          <SignUpButton props={"Let's get started"} />
        </div>
      </div>
    </section>
  );
}

export default BookingSteps;
