import React from "react";
import { CheckIcon } from "@heroicons/react/24/solid";
import { BookingStepsTexts } from "./DataLists";

import "./NewBookingSteps.css";

const steps = [
  { id: "01", name: "Join", href: "#", status: "current" },
  { id: "02", name: "Discover", href: "#", status: "current" },
  { id: "03", name: "Personalize", href: "#", status: "current" },
];

export default function NewBookingSteps() {
  return (
    <nav aria-label="Progress" className="nav-container">
      <div>
        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          {BookingStepsTexts.secondText}
        </p>
        <h2 className="pl-1 mt-4 mb-4 text-base font-semibold leading-7 text-indigo-600">
          {BookingStepsTexts.thirdText}
        </h2>
      </div>
      <ol
        role="list"
        className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
        {steps.map((step, stepIdx) => (
          <li
            key={step.name}
            className="step-item md:flex md:flex-1 sm:pb-4 sm:pt-4">
            {step.status === "complete" ? (
              <a href={step.href} className="group flex w-full items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                    <CheckIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-900">
                    {step.name}
                  </span>
                </span>
              </a>
            ) : step.status === "current" ? (
              <a
                href={step.href}
                className="flex items-center px-6 py-4 text-sm font-medium"
                aria-current="step">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                  <span className="text-indigo-600">{step.id}</span>
                </span>
                <span className="ml-4 text-sm font-medium text-indigo-600">
                  {step.name}
                </span>
              </a>
            ) : (
              <a href={step.href} className="group flex items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                    <span className="text-gray-500 group-hover:text-gray-900">
                      {step.id}
                    </span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                    {step.name}
                  </span>
                </span>
              </a>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div
                  className="absolute right-0 top-0 bottom-0 hidden h-full w-5 md:block"
                  aria-hidden="true">
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 -10 22 100"
                    fill="none"
                    preserveAspectRatio="none">
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}

            {/* Extend a div under each step */}
            <div className="step-description text-gray-500 mt-2 px-6 pb-2">
              {BookingStepsTexts.listOfSteps[stepIdx].text
                .split("\n")
                .map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    {index !==
                      BookingStepsTexts.listOfSteps[stepIdx].text.split("\n")
                        .length -
                        1 && <br />}
                  </React.Fragment>
                ))}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
