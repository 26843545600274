// this is a combination of usersThunk and editActiveUsersThunk

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL

export const fetchInquiries = createAsyncThunk(
  "users/fetchInquiries",
  async (_, { getState }) => {
    try {
      const state = getState();
      const { token, _id } = state.activeUser.data.user; // Update this according to your user info structure

      const config = {
        headers: {
          "Content-Type": "application/json",
          "x-api-token": token,
          "x-user-id": _id,
          // Add other headers as needed
        },
      };

      const response = await fetch(`https://${apiBaseUrl}/users`, config);
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Could not fetch users");
      }

      // Filter users who have at least one inquiry
      const usersWithInquiries = data.filter(
        (user) => user.inquiries && user.inquiries.length > 0
      );

      console.log("Users with Inquiries:", usersWithInquiries); // Log the result

      return usersWithInquiries;
    } catch (error) {
      throw new Error("Network response was not ok");
    }
  }
);
