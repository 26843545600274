import React, { useState, useEffect } from "react";
import { useRoutes, useLocation } from "react-router-dom";
import Metrics from "./agent/Metrics";
import Database from "./agent/Database";
import Clients from "./agent/Clients";
import UploadFile from "./agent/UploadFile";
import Inquiries from "./agent/Inquiries";
import Settings from "./agent/Settings";
import Subscriptions from "./agent/Subscriptions";
import CompleteProfile from "./agent/CompleteProfile";
import AddCruise from "./agent/AddCruise";
import YourCruises from "./agent/YourCruises";
import AddQuote from "./agent/AddQuote";
import YourQuotes from "./agent/YourQuotes";
import AgentHeader from "../components/agent/AgentHeader";
import AgentSidebar from "../components/agent/AgentSidebar";
import { useSelector } from "react-redux";
import { Disclosure } from "@headlessui/react";

const AgentPage = () => {
  const activeAgent = useSelector((state) => state.activeAgent.data.agent);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const location = useLocation();

  const isCompleteProfilePage = location.pathname.includes("complete-profile");

  // Check if agent is subscribed already or not
  useEffect(() => {
    if (
      activeAgent.subscription_plan === null ||
      activeAgent.subscription_plan === ""
    ) {
      setIsSubscribed(false); // This is called only once, when the component mounts or when activeAgent changes
    } else {
      setIsSubscribed(true);
    }
  }, [activeAgent]);

  let routes = useRoutes([
    {
      path: "metrics",
      element: <Metrics />,
    },
    {
      path: "clients",
      element: <Clients />,
    },
    {
      path: "inquiries",
      element: <Inquiries />,
    },
    {
      path: "database",
      element: <Database />,
    },
    {
      path: "uploadFile",
      element: <UploadFile />,
    },
    {
      path: "settings",
      element: <Settings />,
    },
    {
      path: "subscriptions",
      element: <Subscriptions />,
    },
    {
      path: "complete-profile",
      element: <CompleteProfile />,
    },
    {
      path: "add-cruise",
      element: <AddCruise />,
    },
    {
      path: "your-cruises",
      element: <YourCruises />,
    },
    {
      path: "add-quote",
      element: <AddQuote />,
    },
    {
      path: "your-quotes",
      element: <YourQuotes />,
    },
  ]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const navigation = [{ name: "Metrics", href: "", current: true }];

  return (
    <div className="flex flex-col h-screen">
      <div className="flex flex-col flex-grow">
        <AgentHeader />
        {!isCompleteProfilePage && ( // Conditionally render AgentSidebar based on isCompleteProfilePage
          <div className="flex flex-col lg:flex-row">
            <AgentSidebar />
            <div className="flex-grow overflow-auto">{routes}</div>
          </div>
        )}
        {isCompleteProfilePage && (
          <div className="flex-grow overflow-auto">{routes}</div>
        )}
        {/* Render routes without sidebar if isCompleteProfilePage is true */}
        <Disclosure>
          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "border-indigo-500 bg-indigo-50 text-indigo-700"
                      : "border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800",
                    "block border-l-4 py-2 pl-3 pr-4 text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}>
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
            <div className="border-t border-gray-200 pb-3 pt-4"></div>
          </Disclosure.Panel>
        </Disclosure>
      </div>
    </div>
  );
};

export default AgentPage;
