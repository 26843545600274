import React, { useState, useEffect } from "react";
import Button from "./Button";
import { NavButtons } from "./DataLists";
import List from "./List";
import { NavLink, useNavigate } from "react-router-dom";
import { ArrowCircleRight, CirclesFour } from "@phosphor-icons/react";
import { Slide } from "react-awesome-reveal";
import Logo from "../Logo";
import SaleBanner from "./SaleBanner";

const CCNavBar = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [navBarColor, setNavBarColor] = useState(false);
  const [showSaleBanner, setShowSaleBanner] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const listenScrollEvent = () => {
    if (window.scrollY > window.innerHeight) {
      setShowSaleBanner(true);
    } else {
      setShowSaleBanner(false);
    }
    window.scrollY > 10 ? setNavBarColor(true) : setNavBarColor(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  return (
    <header className="w-full h-auto bg-transparent overflow-x-hidden fixed z-50 top-0 left-0">
      <Slide direction="down">
        <nav
          className={`w-full md:h-24 h-20 ${
            navBarColor ? "bg-white" : "bg-transparent"
          } lg:px-24 md:px-12 px-8 flex justify-between items-center`}
        >
          <Logo height="auto" width="8rem" />
          <div className="lg:flex hidden items-center gap-20">
            <ul className="flex items-center justify-center gap-6">
              {NavButtons.map((navbutton, index) => (
                <List className="w-full" key={index}>
                  <Button
                    onClick={() => navigate(navbutton.url)}
                    type="button"
                    className={`${
                      navbutton.name === "Signup"
                        ? "border-2 border-gray-950 before:top-0 hover:text-white"
                        : "before:bottom-0 border-b-2 border-transparent hover:border-gray-950 hover:text-white"
                    } py-2 px-8 relative z-10 before:content-[''] before:absolute before:left-0 before:w-full before:h-0 before:bg-color2 before:-z-10 hover:before:h-full before:transition-all before:duration-300 before:ease-in text-base`}
                  >
                    {navbutton.name}
                  </Button>
                </List>
              ))}
            </ul>
          </div>
          <div className="lg:hidden flex gap-4 items-center">
            <div
              className="hamburger text-gray-950 cursor-pointer"
              onClick={handleToggle}
            >
              <CirclesFour size={30} color="currentColor" weight="fill" />
            </div>
          </div>
        </nav>
      </Slide>

      {showSaleBanner && <SaleBanner />}
      {/* Mobile Nav  */}
      <nav
        className={`flex justify-end lg:hidden h-screen w-full bg-gray-950/90 fixed top-0  ${
          open ? "right-0" : "-right-[120vw]"
        } transition-all duration-700 ease-out`}
      >
        <div
          className={`w-[70%] h-screen bg-white flex flex-col justify-between items-center relative ${
            open ? "right-0" : "-right-[120vw]"
          } transition-all duration-0 ease-out`}
        >
          <section className="w-full px-4 py-6 flex flex-col gap-16">
            <div className="w-full flex justify-between items-center">
              <Logo height="auto" width="8rem" />
              <div
                className="hamburger text-gray-950 cursor-pointer"
                onClick={handleToggle}
              >
                <ArrowCircleRight
                  size={25}
                  color="currentColor"
                  weight="fill"
                />
              </div>
            </div>
          </section>
          <ul className="w-full flex flex-col items-center justify-center pb-24 gap-4">
            {NavButtons.map((navbutton, index) => (
              <List className="w-auto" key={index}>
                <Button
                  onClick={() => navigate(navbutton.url)}
                  type="button"
                  className={`${
                    navbutton.name === "Signup"
                      ? "border-2 border-gray-950 before:top-0 hover:text-white hover:bg-gray-200 transform hover:scale-105"
                      : "before:bottom-0 border-b-2 border-white hover:border-gray-950 hover:text-white hover:bg-gray-200 transform hover:scale-105"
                  } py-1.5 px-5 relative z-10 before:content-[''] before:absolute before:left-0 before:w-full before:h-0 before:bg-color2 before:-z-10 hover:before:h-full before:transition-all before:duration-300 before:ease-in text-base`}
                >
                  {navbutton.name}
                </Button>
              </List>
            ))}
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default CCNavBar;
