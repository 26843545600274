const roomList = [
  // Celebrity rooms

  //   The Retreat (FILTERCATEGORY S = SUITE)
  { filterCategoryCode: "S", categoryCode: "IC", categoryName: "Iconic Suite" },
  {
    filterCategoryCode: "S",
    categoryCode: "RF",
    categoryName: "Reflection Suite",
  },
  {
    filterCategoryCode: "S",
    categoryCode: "PS",
    categoryName: "Penthouse Suite",
  },
  { filterCategoryCode: "S", categoryCode: "EV", categoryName: "Edge Villa" },
  { filterCategoryCode: "S", categoryCode: "RS", categoryName: "Royal Suite" },
  {
    filterCategoryCode: "S",
    categoryCode: "HS",
    categoryName: "Horizon Suite",
  },
  {
    filterCategoryCode: "S",
    categoryCode: "SG",
    categoryName: "Signature Suite",
  },
  {
    filterCategoryCode: "S",
    categoryCode: "CS",
    categoryName: "Celebrity Suite",
  },
  {
    filterCategoryCode: "S",
    categoryCode: "AS",
    categoryName: "AquaClass Sky Suite",
  },
  {
    filterCategoryCode: "S",
    categoryCode: "SS",
    categoryName: "Sunset Sky Suite",
  },
  {
    filterCategoryCode: "S",
    categoryCode: "S1",
    categoryName: "Sky Suite Suite",
  },
  {
    filterCategoryCode: "S",
    categoryCode: "MS",
    categoryName: "Magic Carpet Sky Suite",
  },
  {
    filterCategoryCode: "S",
    categoryCode: "W",
    categoryName: "Suite Guarantee",
  },

  //   AquaClass (FILTERCATEGORY B)
  {
    filterCategoryCode: "B",
    categoryCode: "A1",
    categoryName: "Prime AquaClass",
  },
  { filterCategoryCode: "B", categoryCode: "A2", categoryName: "AquaClass" },
  {
    filterCategoryCode: "B",
    categoryCode: "XA",
    categoryName: "AquaClass Guarantee",
  },

  //   Concierge Class (FILTERCATEGORY B)
  {
    filterCategoryCode: "B",
    categoryCode: "UC",
    categoryName: "Ultra Deluxe Concierge Class",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "SC",
    categoryName: "Sunset Concierge Class",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "C1",
    categoryName: "Prime Concierge Class",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "C2",
    categoryName: "Concierge Class",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "C3",
    categoryName: "Concierge Class (Partial View)",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "XC",
    categoryName: "Concierge Class Guarantee",
  },

  //   Edge Infinite Verdana (Edge Series) (FILTERCATEGORY B)
  {
    filterCategoryCode: "B",
    categoryCode: "E1",
    categoryName: "Prime Edge Stateroom w/ Infinite Veranda",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "E2",
    categoryName: "Edge Stateroom w/ Infinite Veranda",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "E3",
    categoryName: "Edge Stateroom w/ Infinite Veranda",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "ES",
    categoryName: "Edge Single Stateroom w/ Infinite Veranda",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "EX",
    categoryName: "Edge Infinite Veranda Guarantee",
  },

  //  Verdana (FILTERCATEGORY B)
  {
    filterCategoryCode: "B",
    categoryCode: "UV",
    categoryName: "Ultra Deluxe Verdana",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "SV",
    categoryName: "Sunset Veranda",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "DV",
    categoryName: "Deluxe Veranda",
  },
  { filterCategoryCode: "B", categoryCode: "V1", categoryName: "Iconic Suite" },
  { filterCategoryCode: "B", categoryCode: "V2", categoryName: "Veranda" },
  {
    filterCategoryCode: "B",
    categoryCode: "V3",
    categoryName: "Veranda (Partial View)",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "P1",
    categoryName: "Deluxe Porthole View w/ Veranda",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "P2",
    categoryName: "Deluxe Porthole View w/ Veranda",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "X",
    categoryName: "Veranda Guarantee",
  },

  //  Oceanview (FILTERCATEGORY O)
  {
    filterCategoryCode: "O",
    categoryCode: "PO",
    categoryName: "Panoramic Ocean View",
  },
  {
    filterCategoryCode: "O",
    categoryCode: "DO",
    categoryName: "Deluxe Ocean View",
  },
  {
    filterCategoryCode: "O",
    categoryCode: "O1",
    categoryName: "Prime Ocean View",
  },
  { filterCategoryCode: "O", categoryCode: "O2", categoryName: "Ocean View" },
  {
    filterCategoryCode: "O",
    categoryCode: "Y",
    categoryName: "Ocean view Guarantee",
  },

  //  Inside (FILTERCATEGORY I)
  {
    filterCategoryCode: "I",
    categoryCode: "DI",
    categoryName: "Deluxe Inside",
  },
  { filterCategoryCode: "I", categoryCode: "I1", categoryName: "Prime Inside" },
  { filterCategoryCode: "I", categoryCode: "I2", categoryName: "Inside" },
  {
    filterCategoryCode: "I",
    categoryCode: "IS",
    categoryName: "Single Inside",
  },
  {
    filterCategoryCode: "I",
    categoryCode: "Z",
    categoryName: "Inside Guarantee",
  },

  // Royal Carribean categories
  { filterCategoryCode: "O", categoryCode: "O", categoryName: "Oceanview" },
  { filterCategoryCode: "N", categoryCode: "N", categoryName: "Neighbourhood" },
  { filterCategoryCode: "I", categoryCode: "I", categoryName: "Inside" },
  { filterCategoryCode: "B", categoryCode: "B", categoryName: "Balcony" },

  // Royal Carribean detailed categories
  {
    filterCategoryCode: "S",
    categoryCode: "RL",
    categoryName: "Royal Loft Suite",
  },
  {
    filterCategoryCode: "S",
    categoryCode: "VS",
    categoryName: "Villa Suite - 4 Bedrooms",
  },
  {
    filterCategoryCode: "S",
    categoryCode: "TL",
    categoryName: "Star Loft Suite",
  },
  {
    filterCategoryCode: "S",
    categoryCode: "A1",
    categoryName: "Spac AqTheater Suite LgBal 2BR",
  },
  {
    filterCategoryCode: "S",
    categoryCode: "A2",
    categoryName: "AqTheater Suite w/LgBal 2BR",
  },
  {
    filterCategoryCode: "S",
    categoryCode: "A3",
    categoryName: "Spac Aquatheater Suite 1BRy",
  },
  {
    filterCategoryCode: "S",
    categoryCode: "L1",
    categoryName: "Crown Loft Suite w/Balcony",
  },
  {
    filterCategoryCode: "S",
    categoryCode: "OS",
    categoryName: "Owner's Suite - 1 Bedroom",
  },
  {
    filterCategoryCode: "S",
    categoryCode: "GT",
    categoryName: "Grand Suite - 2 Bedroom's",
  },
  {
    filterCategoryCode: "S",
    categoryCode: "GS",
    categoryName: "Grand Suite - 1 Bedroom",
  },
  { filterCategoryCode: "S", categoryCode: "J3", categoryName: "Junior Suite" },
  { filterCategoryCode: "S", categoryCode: "J4", categoryName: "Junior Suite" },
  {
    filterCategoryCode: "S",
    categoryCode: "WS",
    categoryName: "Deluxe/Suite Guarantee",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "SB",
    categoryName: "Spa Large Balcony Cabin",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "AS2",
    categoryName: "Spa Mid Ship",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "AS4",
    categoryName: "Spa Fwd And Aft",
  },
  { filterCategoryCode: "B", categoryCode: "AXS", categoryName: "Spa Gty" },
  {
    filterCategoryCode: "B",
    categoryCode: "A1A",
    categoryName: "Ultra Spacious OV w/Lg Balcony",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "1C",
    categoryName: "Ocean View with Large Balcony",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "2C",
    categoryName: "Ocean View with Large Balcony",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "CB",
    categoryName: "Ocean View Balcony",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "1D",
    categoryName: "Ocean View Balcony",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "2D",
    categoryName: "Ocean View Balcony",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "3D",
    categoryName: "Ocean View Balcony",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "4D",
    categoryName: "Ocean View Balcony",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "5D",
    categoryName: "Ocean View Balcony",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "XB",
    categoryName: "Ocean View Balcony Guarantee",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "1I",
    categoryName: "Boardwalk View Balcony",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "2I",
    categoryName: "Boardwalk View Balcony",
  },
  {
    filterCategoryCode: "I",
    categoryCode: "IF",
    categoryName: "Infinite Central Park Balcony",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "1J",
    categoryName: "Central Park View Balcony",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "2J",
    categoryName: "Central Park View Balcony",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "XN",
    categoryName: "Neighborhood Balcony Guarantee",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "H5",
    categoryName: "Surfside Family View Balcony",
  },
  {
    filterCategoryCode: "O",
    categoryCode: "1K",
    categoryName: "Ultra Spacious Ocean View",
  },
  { filterCategoryCode: "O", categoryCode: "1N", categoryName: "Ocean View" },
  {
    filterCategoryCode: "O",
    categoryCode: "2N",
    categoryName: "Ocean View",
  },
  { filterCategoryCode: "O", categoryCode: "4N", categoryName: "Ocean View" },
  {
    filterCategoryCode: "O",
    categoryCode: "YO",
    categoryName: "Oceanview Guarantee",
  },
  {
    filterCategoryCode: "I",
    categoryCode: "1R",
    categoryName: "Spacious Interior",
  },
  {
    filterCategoryCode: "I",
    categoryCode: "2S",
    categoryName: "Central Park View Interior",
  },
  {
    filterCategoryCode: "I",
    categoryCode: "2T",
    categoryName: "Promenade View Interior",
  },
  {
    filterCategoryCode: "I",
    categoryCode: "CI",
    categoryName: "Connecting inside cabin",
  },
  {
    filterCategoryCode: "I",
    categoryCode: "4U",
    categoryName: "Interior with Virtual Balcony",
  },
  {
    filterCategoryCode: "I",
    categoryCode: "Q2",
    categoryName: "Interior Plus",
  },
  { filterCategoryCode: "I", categoryCode: "1V", categoryName: "Interior" },
  { filterCategoryCode: "I", categoryCode: "3V", categoryName: "Interior" },
  {
    filterCategoryCode: "I",
    categoryCode: "2V",
    categoryName: "Interior",
  },
  { filterCategoryCode: "I", categoryCode: "4V", categoryName: "Interior" },
  {
    filterCategoryCode: "I",
    categoryCode: "ZI",
    categoryName: "Interior Guarantee",
  },

  // ROYAL CATEGORIES BUT FLIPPED (needed for PricingTab)
  {
    filterCategoryCode: "S",
    categoryCode: "LR",
    categoryName: "Royal Loft Suite",
  },
  {
    filterCategoryCode: "S",
    categoryCode: "SV",
    categoryName: "Villa Suite - 4 Bedrooms",
  },
  {
    filterCategoryCode: "S",
    categoryCode: "LT",
    categoryName: "Star Loft Suite",
  },
  {
    filterCategoryCode: "S",
    categoryCode: "1A",
    categoryName: "Spac AqTheater Suite LgBal 2BR",
  },
  {
    filterCategoryCode: "S",
    categoryCode: "2A",
    categoryName: "AqTheater Suite w/LgBal 2BR",
  },
  {
    filterCategoryCode: "S",
    categoryCode: "3A",
    categoryName: "Spac Aquatheater Suite 1BRy",
  },
  {
    filterCategoryCode: "S",
    categoryCode: "1L",
    categoryName: "Crown Loft Suite w/Balcony",
  },
  {
    filterCategoryCode: "S",
    categoryCode: "SO",
    categoryName: "Owner's Suite - 1 Bedroom",
  },
  {
    filterCategoryCode: "S",
    categoryCode: "TG",
    categoryName: "Grand Suite - 2 Bedroom's",
  },
  {
    filterCategoryCode: "S",
    categoryCode: "SG",
    categoryName: "Grand Suite - 1 Bedroom",
  },
  { filterCategoryCode: "S", categoryCode: "3J", categoryName: "Junior Suite" },
  { filterCategoryCode: "S", categoryCode: "4J", categoryName: "Junior Suite" },
  {
    filterCategoryCode: "S",
    categoryCode: "SW",
    categoryName: "Deluxe/Suite Guarantee",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "BS",
    categoryName: "Spa Large Balcony Cabin",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "2SA",
    categoryName: "Spa Mid Ship",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "4SA",
    categoryName: "Spa Fwd And Aft",
  },
  { filterCategoryCode: "B", categoryCode: "SXA", categoryName: "Spa Gty" },
  {
    filterCategoryCode: "B",
    categoryCode: "A1A",
    categoryName: "Ultra Spacious OV w/Lg Balcony",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "C1",
    categoryName: "Ocean View with Large Balcony",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "C2",
    categoryName: "Ocean View with Large Balcony",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "BC",
    categoryName: "Ocean View Balcony",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "D1",
    categoryName: "Ocean View Balcony",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "D2",
    categoryName: "Ocean View Balcony",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "D3",
    categoryName: "Ocean View Balcony",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "D4",
    categoryName: "Ocean View Balcony",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "D5",
    categoryName: "Ocean View Balcony",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "BX",
    categoryName: "Ocean View Balcony Guarantee",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "I1",
    categoryName: "Boardwalk View Balcony",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "I2",
    categoryName: "Boardwalk View Balcony",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "J1",
    categoryName: "Central Park View Balcony",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "J2",
    categoryName: "Central Park View Balcony",
  },
  {
    filterCategoryCode: "B",
    categoryCode: "NX",
    categoryName: "Neighborhood Balcony Guarantee",
  },
  {
    filterCategoryCode: "O",
    categoryCode: "K1",
    categoryName: "Ultra Spacious Ocean View",
  },
  { filterCategoryCode: "O", categoryCode: "N1", categoryName: "Ocean View" },
  {
    filterCategoryCode: "O",
    categoryCode: "N2",
    categoryName: "Studio Ocean View",
  },
  { filterCategoryCode: "O", categoryCode: "N4", categoryName: "Ocean View" },
  {
    filterCategoryCode: "O",
    categoryCode: "OY",
    categoryName: "Oceanview Guarantee",
  },
  {
    filterCategoryCode: "I",
    categoryCode: "R1",
    categoryName: "Spacious Interior",
  },
  {
    filterCategoryCode: "I",
    categoryCode: "S2",
    categoryName: "Central Park View Interior",
  },
  {
    filterCategoryCode: "I",
    categoryCode: "T2",
    categoryName: "Promenade View Interior",
  },
  {
    filterCategoryCode: "I",
    categoryCode: "IC",
    categoryName: "Connecting inside cabin",
  },
  {
    filterCategoryCode: "I",
    categoryCode: "FI",
    categoryName: "Infinite Central Park Balcony",
  },
  {
    filterCategoryCode: "I",
    categoryCode: "U4",
    categoryName: "Interior with Virtual Balcony",
  },
  {
    filterCategoryCode: "I",
    categoryCode: "2Q",
    categoryName: "Interior Plus",
  },
  { filterCategoryCode: "I", categoryCode: "V1", categoryName: "Interior" },
  { filterCategoryCode: "I", categoryCode: "V3", categoryName: "Interior" },
  {
    filterCategoryCode: "I",
    categoryCode: "V2",
    categoryName: "Studio Interior",
  },
  { filterCategoryCode: "I", categoryCode: "V4", categoryName: "Interior" },
  {
    filterCategoryCode: "I",
    categoryCode: "IZ",
    categoryName: "Interior Guarantee",
  },

  // Virgin Voyages rooms
  {
    filterCategoryCode: "S",
    categoryCode: "S",
    categoryName: "Mega Rock Star Suite",
  },
  {
    filterCategoryCode: "S",
    categoryCode: "S",
    categoryName: "Rock Star Suite",
  },
];

module.exports = roomList;
