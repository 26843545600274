import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon, BellIcon } from "@heroicons/react/24/outline";
import CruiseList from "../../components/cruiseList/CruiseList";
import GridCruiseList from "../../components/cruiseList/GridCruiseList";
import GridQuoteList from "../../components/quotes/GridQuoteList";
import Logo from "../../components/Logo";
import Profile from "../../components/profile/Profile";
import { useLocation } from "react-router-dom";
import { fetchSubscriptionStatus } from "../../store/thunks/fetchSubscriptionStatus";
import { logoutUser } from "../../store/thunks/logoutUser";
import { persistor } from "../../store";
import CruiseDetailModal from "../../components/cruiseDetail/CruiseDetailModal";
import ContactUs from "../../components/contact/ContactUs";
import { fetchActiveUser } from "../../store/thunks/fetchActiveUser";
import CruiseCalendar from "../../components/cruiseCalendar/CruiseCalendar";
import "../../App.css";
import { Switch } from "@headlessui/react";
import { ListIcon, GridIcon } from "@heroicons/react/solid"; // Importing the list and grid icons from Heroicons
import { QueueListIcon, TableCellsIcon } from "@heroicons/react/20/solid";
import Footer from "../../components/landingPage/Footer";
import { fetchUserQuote } from "../../store/thunks/userQuoteThunk";
import { ResponseCookies } from "next/dist/compiled/@edge-runtime/cookies";

function Dashboard() {
  const location = useLocation();
  const checkoutSessionId = new URLSearchParams(location.search).get(
    "checkout_session_id"
  );
  const dispatch = useDispatch();
  const apiToken = useSelector((state) => state.activeUser.data.user?.token);
  const activeUser = useSelector((state) => state.activeUser.data.user);
  const activeAgent = useSelector((state) => state.activeAgent.data.agent);

  const subscriptionStatus = useSelector((state) => state.subscriptionStatus);
  const [selectedMenu, setSelectedMenu] = useState("Dashboard");
  const [showDetails, setShowDetails] = useState(false);
  const [showContactUs, setShowContactUs] = useState(false);
  const [selectedCruiseId, setSelectedCruiseId] = useState(null);
  const [selectedCruiseLine, setSelectedCruiseLine] = useState(null);
  const [selectedCruiseShip, setSelectedCruiseShip] = useState(null);
  const [selectedSailDate, setSelectedSailDate] = useState(null);
  const [selectedNights, setSelectedNights] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCurrentPriceAdv, setSelectedCurrentPriceAdv] = useState(null);
  const [selectedCurrentPercentAdv, setSelectedCurrentPercentAdv] =
    useState(null);
  const [selectedGroupRate, setSelectedGroupRate] = useState(null);
  const [primaryColor, setPrimaryColor] = useState("");

  const [isGridView, setIsGridView] = useState(true);
  const [enabled, setEnabled] = useState(false);

  // const [userQuotes, setUserQuotes] = useState([]);
  const userQuotes = useSelector((state) => state.userQuotes);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    dispatch(fetchUserQuote({ token: activeUser.token, _id: activeUser._id }));
  }, [dispatch, activeUser]);

  console.log("user quotes are:", userQuotes.quotes.quote);

  const signOut = async () => {
    try {
      console.log("signOut Button Clicked");
      // Dispatch the logoutUser action
      dispatch(logoutUser({ token: apiToken, userId: activeUser._id }));
      persistor.purge();

      // Redirect to the home page
      window.location.href = "https://www.cruisecompare.ca";
    } catch (err) {
      console.error(err);
    }
  };

  // const signOut = async () => {
  //   console.log("2nd sign out clicked");
  // };

  // Define the navigation state variable
  const [navigation, setNavigation] = useState([
    { name: "Cruise List", href: "", current: true },
    {
      name: "Favourites",
      href: "#",
      current: false,
    },
    {
      name: "Quotes",
      href: "#",
      current: false,
    },
  ]);

  const userNavigation = [
    { name: "Your Profile", href: "#" },
    // { name: "Settings", href: "#" },
    { name: "Logout", href: "/logout" },
    // { name: "Logout using func", onClick: signOut },
  ];

  // Function to handle menu item click and update the selected menu
  const handleMenuItemClick = (item) => {
    setSelectedMenu(item.name);

    const updatedNavigation = navigation.map((navItem) => {
      return {
        ...navItem,
        current: navItem.name === item.name,
      };
    });

    setNavigation(updatedNavigation);
  };

  useEffect(() => {
    const savedColor = localStorage.getItem("primaryColor");
    if (savedColor) {
      setPrimaryColor(savedColor);
      document.documentElement.style.setProperty("--primary-color", savedColor);
    }
  }, []);

  useEffect(() => {
    const handleBackButton = (event) => {
      // console.log("Handle Back Button");
      if (showDetails) {
        setShowDetails(false);
        event.preventDefault();
      }
    };
    window.addEventListener("popstate", handleBackButton);

    return () => {
      // Cleanup the event listener when the component unmounts
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [checkoutSessionId, dispatch, showDetails]);

  // Function to handle the toggle between list view and grid view
  const handleViewToggle = () => {
    setIsGridView((prevState) => !prevState);
  };

  const openContactUsModal = () => {
    setShowContactUs(true);
  };

  const closeContactUsModal = () => {
    setShowContactUs(false);
  };

  // Assuming you have functions to handle details and inquire clicks
  const handleDetailsClick = (quote) => {
    // Handle details click logic
    console.log("Details clicked for quote:", quote);
  };

  const handleInquireClick = (quote) => {
    // Handle inquire click logic
    console.log("Inquire clicked for quote:", quote);
  };

  return (
    <>
      <div className="min-h-full ">
        {/* Header and stuff */}
        <Disclosure as="nav" className="border-b border-gray-200 bg-white">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 justify-between">
                  <div className="flex">
                    <div className="flex flex-shrink-0 items-center">
                      <Logo height="auto" width="8rem" />
                    </div>
                    <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "border-primary-color text-primary-color" // Use border-primary-color and text-primary-color
                              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                            "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                          onClick={() => handleMenuItemClick(item)} // Call handleMenuItemClick on click
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                  <div className="hidden sm:ml-6 sm:flex sm:items-center">
                    {/* <button
                      type="button"
                      className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button> */}

                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="flex max-w-xs items-center rounded-full bg-primary-color text-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                          <span className="sr-only">Open user menu</span>
                          <div className="h-8 w-8 rounded-full flex items-center justify-center">
                            {(
                              activeUser || activeAgent
                            )?.first_name[0].toUpperCase()}
                            {(
                              activeUser || activeAgent
                            )?.last_name[0].toUpperCase()}
                          </div>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95">
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 rounded-sm font-semibold"
                                      : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                  onClick={(e) => {
                                    console.log("ITEM CLICKED - " + e);
                                    // e.preventDefault();
                                    handleMenuItemClick(item);
                                    if (item.onClick) item.onClick();
                                  }}>
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                          <div> </div>

                          {activeUser && activeUser.user_type === "admin" && (
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/admin"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 rounded-sm font-semibold"
                                      : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                  style={{ borderTop: "1px solid #ccc" }}>
                                  Admin
                                </a>
                              )}
                            </Menu.Item>
                          )}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>

                  {/* Mobile menu button */}
                  <div className="-mr-2 flex items-center sm:hidden">
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="sm:hidden">
                <div className="space-y-1 pb-3 pt-2">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current
                          ? "border-indigo-500 bg-indigo-50 text-indigo-700"
                          : "border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800",
                        "block border-l-4 py-2 pl-3 pr-4 text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                      onClick={() => handleMenuItemClick(item)}>
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className="border-t border-gray-200 pb-3 pt-4">
                  <div className="flex items-center px-4">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={(activeUser || activeAgent)?.imageUrl || ""}
                        alt=""
                      />
                      <div className="ml-3">
                        <div className="text-base font-medium text-gray-800">
                          {activeUser || activeAgent
                            ? `${(activeUser || activeAgent).first_name} ${
                                (activeUser || activeAgent).last_name
                              }`
                            : ""}
                        </div>
                        <div className="text-sm font-medium text-gray-500">
                          {(activeUser || activeAgent)?.email || ""}
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-3 space-y-1">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800">
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <div className="py-10 pb-20">
          <main>
            <div
              className="mx-auto max-w-7xl sm:px-6 lg:px-8"
              style={{ translateY: "20px" }}>
              {/* This div is for the toggle of views - between list / grid */}
              {selectedMenu === "Dashboard" && (
                <div className="flex justify-end items-center px-4">
                  <p className="mr-2 text-sm text-gray-700">List</p>
                  <Switch
                    checked={isGridView}
                    onChange={handleViewToggle}
                    className={classNames(
                      isGridView ? "bg-indigo-600" : "bg-gray-200", // Changing background color based on grid or list view
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                    )}>
                    <span
                      className={classNames(
                        isGridView ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}>
                      <span
                        className={classNames(
                          isGridView
                            ? "opacity-0 duration-100 ease-out"
                            : "opacity-100 duration-200 ease-in",
                          "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                        )}
                        aria-hidden="true">
                        <QueueListIcon className="h-3 w-3 text-gray-400" />
                      </span>
                      <span
                        className={classNames(
                          isGridView
                            ? "opacity-100 duration-200 ease-in"
                            : "opacity-0 duration-100 ease-out",
                          "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                        )}
                        aria-hidden="true">
                        <TableCellsIcon className="h-3 w-3 text-indigo-600" />
                      </span>
                    </span>
                  </Switch>
                  <p className="ml-2 text-sm text-gray-700">Grid</p>
                </div>
              )}

              {selectedMenu === "Dashboard" && (
                <>
                  {isGridView ? (
                    <GridCruiseList
                      selectedMenu={selectedMenu}
                      onDetailsClick={(
                        cruiseId,
                        cruiseLine,
                        cruiseShip,
                        sailDate,
                        nights,
                        product,
                        currentPriceAdv,
                        currentPercentAdv,
                        groupRate
                      ) => {
                        setShowDetails(true);
                        setShowContactUs(false);
                        setSelectedCruiseId(cruiseId);
                        setSelectedCruiseLine(cruiseLine);
                        setSelectedCruiseShip(cruiseShip);
                        setSelectedSailDate(sailDate);
                        setSelectedNights(nights);
                        setSelectedProduct(product);
                        setSelectedCurrentPriceAdv(currentPriceAdv);
                        setSelectedCurrentPercentAdv(currentPercentAdv);
                        setSelectedGroupRate(groupRate);
                      }}
                      onInquireClick={(
                        cruiseId,
                        cruiseLine,
                        cruiseShip,
                        sailDate,
                        nights,
                        product,
                        currentPriceAdv,
                        currentPercentAdv,
                        groupRate
                      ) => {
                        setShowContactUs(true);
                        setShowDetails(false);
                        setSelectedCruiseId(cruiseId);
                        setSelectedCruiseLine(cruiseLine);
                        setSelectedCruiseShip(cruiseShip);
                        setSelectedSailDate(sailDate);
                        setSelectedNights(nights);
                        setSelectedProduct(product);
                        setSelectedCurrentPriceAdv(currentPriceAdv);
                        setSelectedCurrentPercentAdv(currentPercentAdv);
                        setSelectedGroupRate(groupRate);
                      }}
                    />
                  ) : (
                    <CruiseList
                      selectedMenu={selectedMenu}
                      onDetailsClick={(
                        cruiseId,
                        cruiseLine,
                        cruiseShip,
                        sailDate,
                        nights,
                        product,
                        currentPriceAdv,
                        currentPercentAdv,
                        groupRate
                      ) => {
                        setShowDetails(true);
                        setShowContactUs(false);
                        setSelectedCruiseId(cruiseId);
                        setSelectedCruiseLine(cruiseLine);
                        setSelectedCruiseShip(cruiseShip);
                        setSelectedSailDate(sailDate);
                        setSelectedNights(nights);
                        setSelectedProduct(product);
                        setSelectedCurrentPriceAdv(currentPriceAdv);
                        setSelectedCurrentPercentAdv(currentPercentAdv);
                        setSelectedGroupRate(groupRate);
                      }}
                      onInquireClick={(
                        cruiseId,
                        cruiseLine,
                        cruiseShip,
                        sailDate,
                        nights,
                        product,
                        currentPriceAdv,
                        currentPercentAdv,
                        groupRate
                      ) => {
                        setShowContactUs(true);
                        setShowDetails(false);
                        setSelectedCruiseId(cruiseId);
                        setSelectedCruiseLine(cruiseLine);
                        setSelectedCruiseShip(cruiseShip);
                        setSelectedSailDate(sailDate);
                        setSelectedNights(nights);
                        setSelectedProduct(product);
                        setSelectedCurrentPriceAdv(currentPriceAdv);
                        setSelectedCurrentPercentAdv(currentPercentAdv);
                        setSelectedGroupRate(groupRate);
                      }}
                    />
                  )}

                  {showDetails && (
                    <CruiseDetailModal
                      cruiseId={selectedCruiseId}
                      cruiseLine={selectedCruiseLine}
                      cruiseShip={selectedCruiseShip}
                      sailDate={selectedSailDate}
                      nights={selectedNights}
                      product={selectedProduct}
                      currentPriceAdv={selectedCurrentPriceAdv}
                      currentPercentAdv={selectedCurrentPercentAdv}
                      groupRate={selectedGroupRate}
                      onClose={() => setShowDetails(false)}
                    />
                  )}

                  {showContactUs && (
                    <ContactUs
                      cruiseId={selectedCruiseId}
                      cruiseLine={selectedCruiseLine}
                      cruiseShip={selectedCruiseShip}
                      sailDate={selectedSailDate}
                      nights={selectedNights}
                      product={selectedProduct}
                      currentPriceAdv={selectedCurrentPriceAdv}
                      currentPercentAdv={selectedCurrentPercentAdv}
                      groupRate={selectedGroupRate}
                      onClose={() => setShowContactUs(false)}
                    />
                  )}

                  {/* Render the ContactUs component within a modal-like container */}
                  {/* {showContactUs && (
                    <div className="fixed inset-0 z-10 overflow-y-auto bg-gray-500 bg-opacity-75 flex justify-center items-center">
                      <div className="relative bg-white rounded-lg w-full md:w-3/4 lg:w-1/2 xl:w-1/3 p-8">
                        <ContactUs onClose={closeContactUsModal} />
                        <button
                          onClick={closeContactUsModal}
                          className="absolute top-0 right-0 m-4 p-2 rounded-full bg-gray-200 hover:bg-gray-300 focus:outline-none">
                          Close
                        </button>
                      </div>
                    </div>
                  )} */}
                </>
              )}

              {selectedMenu.includes("Favourites") && (
                <>
                  <GridCruiseList
                    selectedMenu={selectedMenu}
                    onDetailsClick={(
                      cruiseId,
                      cruiseLine,
                      cruiseShip,
                      sailDate,
                      nights,
                      product,
                      currentPriceAdv,
                      currentPercentAdv,
                      groupRate
                    ) => {
                      setShowDetails(true);
                      setShowContactUs(false);
                      setSelectedCruiseId(cruiseId);
                      setSelectedCruiseLine(cruiseLine);
                      setSelectedCruiseShip(cruiseShip);
                      setSelectedSailDate(sailDate);
                      setSelectedNights(nights);
                      setSelectedProduct(product);
                      setSelectedCurrentPriceAdv(currentPriceAdv);
                      setSelectedCurrentPercentAdv(currentPercentAdv);
                      setSelectedGroupRate(groupRate);
                    }}
                    onInquireClick={(
                      cruiseId,
                      cruiseLine,
                      cruiseShip,
                      sailDate,
                      nights,
                      product,
                      currentPriceAdv,
                      currentPercentAdv,
                      groupRate
                    ) => {
                      setShowContactUs(true);
                      setShowDetails(false);
                      setSelectedCruiseId(cruiseId);
                      setSelectedCruiseLine(cruiseLine);
                      setSelectedCruiseShip(cruiseShip);
                      setSelectedSailDate(sailDate);
                      setSelectedNights(nights);
                      setSelectedProduct(product);
                      setSelectedCurrentPriceAdv(currentPriceAdv);
                      setSelectedCurrentPercentAdv(currentPercentAdv);
                      setSelectedGroupRate(groupRate);
                    }}
                  />

                  {showDetails && (
                    <CruiseDetailModal
                      cruiseId={selectedCruiseId}
                      cruiseLine={selectedCruiseLine}
                      cruiseShip={selectedCruiseShip}
                      sailDate={selectedSailDate}
                      nights={selectedNights}
                      product={selectedProduct}
                      currentPriceAdv={selectedCurrentPriceAdv}
                      currentPercentAdv={selectedCurrentPercentAdv}
                      groupRate={selectedGroupRate}
                      onClose={() => setShowDetails(false)}
                    />
                  )}

                  {showContactUs && (
                    <ContactUs
                      cruiseId={selectedCruiseId}
                      cruiseLine={selectedCruiseLine}
                      cruiseShip={selectedCruiseShip}
                      sailDate={selectedSailDate}
                      nights={selectedNights}
                      product={selectedProduct}
                      currentPriceAdv={selectedCurrentPriceAdv}
                      currentPercentAdv={selectedCurrentPercentAdv}
                      groupRate={selectedGroupRate}
                      onClose={() => setShowContactUs(false)}
                    />
                  )}
                </>
              )}

              {selectedMenu.includes("Quotes") && (
                <GridQuoteList
                  quotes={userQuotes.quotes.quote}
                  onDetailsClick={handleDetailsClick}
                  onInquireClick={handleInquireClick}
                />
              )}

              {selectedMenu === "Cruise Calendar" && <CruiseCalendar />}

              {selectedMenu === "Your Profile" && <Profile />}
            </div>
          </main>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default Dashboard;
