import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const fetchCruiseLines = createAsyncThunk("cruiseLines/fetch", async () => {
    const config = {
        headers: {
            "Content-Type": "application/json",
            // Add other headers as needed
        },
    };

    const appId = process.env.REACT_APP_CRUISE_API_KEY;
    const appToken = process.env.REACT_APP_CRUISE_TOKEN;

    let url = `https://www.widgety.co.uk/api/operators.json?app_id=${appId}&token=${appToken}`;

    const response = axios.get(url, config) 
    return response.data
});

export { fetchCruiseLines };
