import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL

const buildQueryString = (params) => {
  const {
    sort,
    sortOrder,
    page,
    pageSize,
    cruiseLine,
    ship,
    // excludeSoldOut,
    minSailDateObj,
    maxSailDateObj,
    nights,
    category,
    priceAdv,
    percAdv,
    region,
    selectedPorts,
    sellingAgent,
  } = params;

  // const soldOutParam = excludeSoldOut ? "&excludeSoldOut=true" : "";
  const agentParam = sellingAgent
    ? `&sellingAgent=${encodeURIComponent(sellingAgent)}`
    : "";
  const portsParam = selectedPorts
    ? `&selectedPorts=${encodeURIComponent(selectedPorts)}`
    : "";

  // return `?sort=${sort}&sortOrder=${sortOrder}&page=${page}&pageSize=${pageSize}&cruiseLine=${cruiseLine}&ship=${ship}&excludeSoldOut=${soldOutParam}&minSailDateObj=${minSailDateObj}&maxSailDateObj=${maxSailDateObj}&nights=${nights}&category=${category}&priceAdv=${priceAdv}&percAdv=${percAdv}&region=${region}${portsParam}${agentParam}`; // Add portsParam here
  return `?sort=${sort}&sortOrder=${sortOrder}&page=${page}&pageSize=${pageSize}&cruiseLine=${cruiseLine}&ship=${ship}&minSailDateObj=${minSailDateObj}&maxSailDateObj=${maxSailDateObj}&nights=${nights}&category=${category}&priceAdv=${priceAdv}&percAdv=${percAdv}&region=${region}${portsParam}${agentParam}`; // Add portsParam here
};

const fetchCruises = createAsyncThunk(
  "cruises/fetch",
  async ({ selectedPorts, ...params }, { getState }) => {
    const state = getState();
    const { token, _id, agentEmail } =
      state.activeUser?.data?.user || state.activeAgent?.data?.user;

    const queryString = buildQueryString({
      ...params,
      sellingAgent: agentEmail,
      selectedPorts: selectedPorts,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-api-token": token,
        "x-user-id": _id,
      },
    };

    try {
      const response = await axios.get(
        `https://${apiBaseUrl}/cruises${queryString}`,
        config
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export { fetchCruises };
