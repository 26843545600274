import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRouteAdmin = ({ children }) => {
  const userType = useSelector(
    (state) => state.activeUser?.data?.user?.user_type
  );
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is an admin
    if (userType !== "admin") {
      navigate("/not-found"); // Redirect to the not found page if not an admin
    }
  }, [userType, navigate]);

  return userType === "admin" ? children : null;
};

export default ProtectedRouteAdmin;
