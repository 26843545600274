import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchActiveAgent } from "../../store/thunks/fetchActiveAgent";
import { fetchAgentMetrics } from "../../store/thunks/agent/fetchAgentMetrics";
import { Link } from "react-router-dom";

import NoSubscriptionWarning from "../../components/agent/NoSubscriptionWarning";
import MetricsDetails from "../../components/admin/MetricsDetails";

import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  ArrowDownCircleIcon,
  ArrowPathIcon,
  ArrowUpCircleIcon,
  Bars3Icon,
  EllipsisHorizontalIcon,
  PlusSmallIcon,
} from "@heroicons/react/20/solid";
import { BellIcon, XMarkIcon } from "@heroicons/react/24/outline";

const timeFrames = [
  { name: "Last 7 days", href: "#", current: true },
  { name: "Last 30 days", href: "#", current: false },
  { name: "All-time", href: "#", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Metrics() {
  const dispatch = useDispatch();
  const activeAgent = useSelector((state) => state.activeAgent.data.agent);
  const [dateFilter, setDateFilter] = useState("Last 7 days");
  const agentMetrics = useSelector((state) => state.agentMetrics); // Assuming this is how you access agentMetrics from the Redux store

  const [selectedTimeFrame, setSelectedTimeFrame] = useState(0); // Default to the first option
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    dispatch(fetchAgentMetrics());
    dispatch(fetchActiveAgent());
  }, [dispatch, dateFilter]);

  const handleRefresh = () => {
    dispatch(fetchAgentMetrics()); // Dispatch fetchAgentMetrics thunk action
  };

  // useEffect to determine agent subscription status
  useEffect(() => {
    if (activeAgent.subscription == null || activeAgent.subscription === "") {
      setIsSubscribed(false);
      console.log("no sub");
    } else {
      setIsSubscribed(true);
    }
  }, [activeAgent]);

  const handleNavigationSelect = (index) => {
    setSelectedTimeFrame(index); // Update selected navigation state

    // Log out the name of the selected time frame
    console.log("Selected time frame:", timeFrames[index].name);
  };

  // Dynamic stats based on selected navigation
  let selectedStats = [];
  switch (selectedTimeFrame) {
    case 0: // Last 7 days
      selectedStats = agentMetrics.data["Last 7 days"];
      break;
    case 1: // Last 30 days
      selectedStats = agentMetrics.data["Last 30 days"];
      break;
    case 2: // All-time
      selectedStats = agentMetrics.data["All-time"];
      break;
    default:
      break;
  }

  // console.log("selected stats", selectedStats);
  // console.log(agentMetrics);

  return (
    <>
      {/* Subscription warning */}
      {isSubscribed ? null : <NoSubscriptionWarning />}

      <div className={`relative ${!isSubscribed && "pointer-events-none"}`}>
        {!isSubscribed && (
          <div className="absolute inset-0 bg-opacity-50 backdrop-blur-sm z-10"></div>
        )}
        <main>
          <div className="relative isolate overflow-hidden">
            {/* Time navigation */}
            <header className="pb-4 pt-6 sm:pb-6">
              <div className="mx-auto flex max-w-7xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
                <h1 className="text-base font-semibold leading-7 text-gray-900">
                  Agent Metrics
                </h1>
                <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
                  <>
                    <button
                      className={`${
                        selectedTimeFrame === 0
                          ? "text-indigo-600"
                          : "text-gray-700"
                      }`}
                      onClick={() => handleNavigationSelect(0)}
                      style={{
                        color: selectedTimeFrame === 0 ? "#7C3AED" : "", // Purple color if selected
                      }}>
                      Last 7 days
                    </button>

                    <button
                      className={`${
                        selectedTimeFrame === 1
                          ? "text-indigo-600"
                          : "text-gray-700"
                      }`}
                      onClick={() => handleNavigationSelect(1)}
                      style={{
                        color: selectedTimeFrame === 1 ? "#7C3AED" : "", // Purple color if selected
                      }}>
                      Last 30 days
                    </button>

                    <button
                      className={`${
                        selectedTimeFrame === 2
                          ? "text-indigo-600"
                          : "text-gray-700"
                      }`}
                      onClick={() => handleNavigationSelect(2)}
                      style={{
                        color: selectedTimeFrame === 2 ? "#7C3AED" : "", // Purple color if selected
                      }}>
                      All-time
                    </button>
                  </>
                </div>
                <button
                  onClick={handleRefresh}
                  className="ml-auto flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50">
                  Refresh
                </button>
              </div>
            </header>

            {/* Stats */}
            <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
              <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 lg:px-2 xl:px-0">
                {selectedStats &&
                  Object.values(selectedStats).map((stat, statIdx) => {
                    // Determine the path based on the stat name
                    let path = "#";
                    if (stat.name === "Active Users") path = "/agent/clients";
                    if (stat.name === "Inquiries") path = "/agent/inquiries";

                    return (
                      <Link
                        to={path}
                        key={stat.name}
                        className={classNames(
                          statIdx % 2 === 1
                            ? "sm:border-l"
                            : statIdx === 2
                            ? "lg:border-l"
                            : "",
                          "group",
                          "flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8"
                        )}>
                        <dt className="text-sm font-medium leading-6 text-gray-500 group-hover:underline">
                          {stat.name}
                        </dt>
                        <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                          {stat.value}
                        </dd>
                        {/* <dd
                          className={classNames(
                            stat.changeType === "negative"
                              ? "text-rose-600"
                              : "text-green-600",
                            "text-xs font-medium"
                          )}>
                          {stat.changeType === "negative"
                            ? "Decreased"
                            : "Increased"}
                        </dd> */}
                      </Link>
                    );
                  })}
              </dl>
            </div>
            <div
              className="absolute left-0 top-full -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
              aria-hidden="true">
              <div
                className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#6EE7B7] to-[#88FCA3]"
                style={{
                  clipPath:
                    "polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)",
                }}
              />
            </div>
          </div>

          <>
            <div className="space-y-16 py-16 xl:space-y-20">
              {/* Recent activity table
            {/* <div className="relative">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <h2 className="mx-auto max-w-2xl text-base font-semibold leading-6 text-gray-900 lg:mx-0 lg:max-w-none">
                  Recent activity
                </h2>
              </div>
              <div className="mt-6 overflow-hidden border-t border-gray-100">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                  <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                    <table className="w-full text-left">
                      <thead className="sr-only">
                        <tr>
                          <th>Amount</th>
                          <th className="hidden sm:table-cell">Client</th>
                          <th>More details</th>
                        </tr>
                      </thead>
                      <tbody>
                        {days.map((day) => (
                          <Fragment key={day.dateTime}>
                            <tr className="text-sm leading-6 text-gray-900">
                              <th
                                scope="colgroup"
                                colSpan={3}
                                className="relative isolate py-2 font-semibold">
                                <time dateTime={day.dateTime}>{day.date}</time>
                                <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                                <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                              </th>
                            </tr>
                            {day.transactions.map((transaction) => (
                              <tr key={transaction.id}>
                                <td className="relative py-5 pr-6">
                                  <div className="flex gap-x-6">
                                    <transaction.icon
                                      className="hidden h-6 w-5 flex-none text-gray-400 sm:block"
                                      aria-hidden="true"
                                    />
                                    <div className="flex-auto">
                                      <div className="flex items-start gap-x-3">
                                        <div className="text-sm font-medium leading-6 text-gray-900">
                                          {transaction.amount}
                                        </div>
                                        <div
                                          className={classNames(
                                            statuses[transaction.status],
                                            "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset"
                                          )}>
                                          {transaction.status}
                                        </div>
                                      </div>
                                      {transaction.tax ? (
                                        <div className="mt-1 text-xs leading-5 text-gray-500">
                                          {transaction.tax} tax
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                                  <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                                </td>
                                <td className="hidden py-5 pr-6 sm:table-cell">
                                  <div className="text-sm leading-6 text-gray-900">
                                    {transaction.client}
                                  </div>
                                  <div className="mt-1 text-xs leading-5 text-gray-500">
                                    {transaction.description}
                                  </div>
                                </td>
                                <td className="py-5 text-right">
                                  <div className="flex justify-end">
                                    <a
                                      href={transaction.href}
                                      className="text-sm font-medium leading-6 text-indigo-600 hover:text-indigo-500">
                                      View
                                      <span className="hidden sm:inline">
                                        {" "}
                                        transaction
                                      </span>
                                      <span className="sr-only">
                                        , invoice #{transaction.invoiceNumber},{" "}
                                        {transaction.client}
                                      </span>
                                    </a>
                                  </div>
                                  <div className="mt-1 text-xs leading-5 text-gray-500">
                                    Invoice{" "}
                                    <span className="text-gray-900">
                                      #{transaction.invoiceNumber}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div> */}

              {/* Recent client list*/}
              {/* <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                <div className="flex items-center justify-between">
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Recent clients
                  </h2>
                  <a
                    href="#"
                    className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                    View all<span className="sr-only">, clients</span>
                  </a>
                </div>
                <ul
                  role="list"
                  className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                  {clients.map((client) => (
                    <li
                      key={client.id}
                      className="overflow-hidden rounded-xl border border-gray-200">
                      <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                        <img
                          src={client.imageUrl}
                          alt={client.name}
                          className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                        />
                        <div className="text-sm font-medium leading-6 text-gray-900">
                          {client.name}
                        </div>
                        <Menu as="div" className="relative ml-auto">
                          <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                            <span className="sr-only">Open options</span>
                            <EllipsisHorizontalIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95">
                            <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active ? "bg-gray-50" : "",
                                      "block px-3 py-1 text-sm leading-6 text-gray-900"
                                    )}>
                                    View
                                    <span className="sr-only">
                                      , {client.name}
                                    </span>
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active ? "bg-gray-50" : "",
                                      "block px-3 py-1 text-sm leading-6 text-gray-900"
                                    )}>
                                    Edit
                                    <span className="sr-only">
                                      , {client.name}
                                    </span>
                                  </a>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Last invoice</dt>
                          <dd className="text-gray-700">
                            <time dateTime={client.lastInvoice.dateTime}>
                              {client.lastInvoice.date}
                            </time>
                          </dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Amount</dt>
                          <dd className="flex items-start gap-x-2">
                            <div className="font-medium text-gray-900">
                              {client.lastInvoice.amount}
                            </div>
                            <div
                              className={classNames(
                                statuses[client.lastInvoice.status],
                                "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset"
                              )}>
                              {client.lastInvoice.status}
                            </div>
                          </dd>
                        </div>
                      </dl>
                    </li>
                  ))}
                </ul>
              </div>
            </div> */}
            </div>
          </>
        </main>
      </div>
    </>
  );
}
