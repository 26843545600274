import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  //   fetchUsersList,
  editActiveUser,
  updateUserThunk,
} from "../../store/thunks/usersThunk";
import {
  fetchAgentsList,
  editActiveAgent,
  updateAgentThunk,
} from "../../store/thunks/agentsThunk";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Clients.css";
import EditConfirmationModal from "../../components/admin/EditConfirmationModal";
import {
  BarsArrowUpIcon,
  BarsArrowDownIcon,
  Bars3BottomLeftIcon,
} from "@heroicons/react/20/solid";
import { ClipLoader } from "react-spinners";

const Agents = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.users.activeUser);
  const currentAgent = useSelector((state) => state.users.activeAgent);
  const editUserStatus = useSelector((state) => state.editUser.isLoading);
  // const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null); // true for ascending, false for descending
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [feedbackColor, setFeedbackColor] = useState("");
  const [showEditModal, setShowEditModal] = useState({
    first_name: "",
    last_name: "",
    email: "",
    // user_type: "",
  });
  const [editFormData, setEditFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    // user_type: "",
  });
  const [editMode, setEditMode] = useState(null);
  const [userToSaveIndex, setUserToSaveIndex] = useState(null);
  const [showEditConfirmModal, setShowEditConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Assuming you have a users state in your Redux store and it looks like { users: [], error: null, isLoading: false }
  const usersList = useSelector((state) => state.users.usersList);
  const agentsList = useSelector((state) => state.agents.agentsList);
  const status = useSelector((state) => state.users.status);
  const error = useSelector((state) => state.users.error);

  // New state for controlling the spinner's display
  const [shouldDisplayLoader, setShouldDisplayLoader] = useState(false);

  // REDUX USEEFFECT
  useEffect(() => {
    console.log("trying to dispatch");
    dispatch(fetchAgentsList());
    console.log("dispatched");
  }, [dispatch]);

  const handleSort = (field) => {
    if (sortField === field) {
      if (sortDirection === false) {
        // if the current field is already being sorted in descending, reset sort
        resetSort();
      } else {
        // if the current field is already being sorted, change direction
        setSortDirection(!sortDirection);
      }
    } else {
      // if a new field is selected, sort in ascending order by default
      setSortField(field);
      setSortDirection(true);
    }
  };

  const resetSort = () => {
    setSortField(null);
    setSortDirection(null);
  };

  const getSortIcon = (field) => {
    if (sortField === field) {
      if (sortDirection === true) {
        return <BarsArrowUpIcon className="h-4 w-4 inline ml-2" />;
      } else if (sortDirection === false) {
        return <BarsArrowDownIcon className="h-4 w-4 inline ml-2" />;
      }
    }
    return <Bars3BottomLeftIcon className="h-3.5 w-3.5 inline ml-2" />;
  };

  const filteredAgents = agentsList
    .filter((agent) =>
      `${agent.first_name} ${agent.last_name} ${agent.email}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (!sortField) return 0;
      if (a[sortField] < b[sortField]) return sortDirection ? -1 : 1;
      if (a[sortField] > b[sortField]) return sortDirection ? 1 : -1;
      return 0;
    });

  const displayedAgents = filteredAgents.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  //   const filteredUsers = usersList
  //     .filter((user) =>
  //       `${user.first_name} ${user.last_name} ${user.email}`
  //         .toLowerCase()
  //         .includes(searchTerm.toLowerCase())
  //     )
  //     .sort((a, b) => {
  //       if (!sortField) return 0;
  //       if (a[sortField] < b[sortField]) return sortDirection ? -1 : 1;
  //       if (a[sortField] > b[sortField]) return sortDirection ? 1 : -1;
  //       return 0;
  //     });

  //   const displayedUsers = filteredUsers.slice(
  //     currentPage * itemsPerPage,
  //     (currentPage + 1) * itemsPerPage
  //   );

  // REDUX REFRESH DATA
  const refreshData = () => {
    setIsLoading(true);

    const minimumDisplayTime = 1000;
    const startTime = Date.now();

    async function fetchData() {
      try {
        console.log("dispatching thunk");
        dispatch(fetchAgentsList());
        console.log("thunk dispatched");

        const elapsed = Date.now() - startTime;
        const delayTime = Math.max(0, minimumDisplayTime - elapsed);

        setTimeout(() => {
          setIsLoading(false);
          // console.log("Clients refreshed");
          setFeedback("Refresh successful");
          setFeedbackColor("green");
          setTimeout(() => {
            setFeedback("");
          }, 1750);
        }, delayTime);
      } catch (error) {
        const elapsed = Date.now() - startTime;
        const delayTime = Math.max(0, minimumDisplayTime - elapsed);

        setTimeout(() => {
          setIsLoading(false);
          // console.log("Clients refresh failed");
          // console.error("Error:", error);
          setFeedback("Refresh failed");
          setFeedbackColor("red");
          setTimeout(() => {
            setFeedback("");
          }, 1750);
        }, delayTime);
      }
    }
    fetchData();
  };

  const totalPage = Math.ceil(agentsList.length / itemsPerPage);

  console.log("this is the agentsList", agentsList);

  function handleInputChange(e, field) {
    setEditFormData({
      ...editFormData,
      [field]: e.target.value,
    });
  }
  const updateAgent = (_id) => {
    const user = filteredAgents.find((u) => u._id === _id);

    if (!user) {
      console.error("User with _id not found:", _id);
      setFeedback("User update failed");
      setFeedbackColor("red");
      setTimeout(() => {
        setFeedback("");
      }, 1750);
      setEditMode(null);
      return;
    }

    const updatedUser = { ...user, ...editFormData };
    delete updatedUser._id;

    // Use the thunk to update the user
    dispatch(updateUserThunk({ userId: user._id, updatedUser })).then(
      (action) => {
        if (updateUserThunk.fulfilled.match(action)) {
          // Successful update
          setFeedback("User updated");
          setFeedbackColor("green");
          setTimeout(() => {
            setFeedback("");
          }, 1750);
          setEditMode(null);
          refreshData(); // Refresh data after updating user
        } else {
          // Update failed
          setFeedback("User update failed");
          setFeedbackColor("red");
          setTimeout(() => {
            setFeedback("");
          }, 1750);
        }
      }
    );
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(e.target.value);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  function highlightTerm(text, term) {
    const splitText = String(text).split(new RegExp(`(${term})`, "gi"));

    return splitText.map((part, i) =>
      part.toLowerCase() === term.toLowerCase() ? (
        <span key={i} className="bg-yellow-300">
          {part}
        </span>
      ) : (
        part
      )
    );
  }

  function handleResendVerification() {
    console.log("Button clicked");
    // Logic for resending the email verification
    // This could involve making a request to your backend, which then sends the verification email.
  }

  return (
    <div className="pt-4 ml-0 sm:px-6 lg:pr-10 lg:pl-0">
      {/* Header row */}
      <div className="sm:flex sm:items-center justify-between">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Agents
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all agents registered with CruiseCompare.ca.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <div className="flex items-center">
            {/* This is the new flex container */}
            <p
              className="text-base font-semibold leading-6 mr-2.5"
              style={{ color: feedbackColor }}>
              {feedback}
            </p>
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={refreshData}>
              Refresh Agents
            </button>
          </div>
        </div>
      </div>

      {/* Pagination and search stuff */}
      <div className="mt-4 pb-2 flex justify-between">
        <div className="w-1/2 sm:w-1/4">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search by name or email"
            className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="inline-flex items-center border rounded-md">
          <button
            className="px-3 py-2 border-r text-sm font-medium rounded-l-md text-gray-500 bg-white hover:text-gray-700 hover:bg-gray-50"
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 0}>
            &laquo;
          </button>

          <span className="px-3 py-2 text-sm font-medium text-gray-500 bg-white">
            Page {currentPage + 1} of {totalPage}
          </span>

          <button
            className="px-3 py-2 border-l text-sm font-medium rounded-r-md text-gray-500 bg-white hover:text-gray-700 hover:bg-gray-50"
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPage - 1}>
            &raquo;
          </button>

          <div className="px-3 py-2">
            <label htmlFor="itemsPerPage" className="sr-only">
              Items per page
            </label>
            <select
              id="itemsPerPage"
              className="border-gray-300 rounded-md shadow-sm"
              onChange={handleItemsPerPageChange}
              value={itemsPerPage}>
              <option value={5}>5 per page</option>
              <option value={10}>10 per page</option>
              <option value={20}>20 per page</option>
              <option value={50}>50 per page</option>
            </select>
          </div>
        </div>
      </div>

      {/* Table */}
      <div className="-mx-4 -my-2 pb-20 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            {/* Table Header */}
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3 cursor-pointer"></th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3 cursor-pointer"
                  onClick={() => handleSort("name")}>
                  <div className="flex items-center">
                    Name {getSortIcon("name")}
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                  onClick={() => handleSort("email")}>
                  <div className="flex items-center">
                    Email {getSortIcon("email")}
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                  onClick={() => handleSort("created_at")}>
                  <div className="flex items-center">
                    Created at {getSortIcon("created_at")}
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Company Name
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Company Subdomain
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Company Number
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Subscription Class
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Virgin Voyages Link
                </th>
              </tr>
            </thead>

            {/* TABLE BODY WITH REDUX */}
            <tbody className="divide-gray-200 divide-y bg-white">
              {/* Loading State */}
              {status === "loading" && (
                <tr>
                  <td colSpan="5" className="text-center py-5">
                    <ClipLoader color="#4535be" size={75} />
                  </td>
                </tr>
              )}

              {/* Error State */}
              {status === "failed" && (
                <tr>
                  <td colSpan="5" className="text-center py-5 text-red-500">
                    Error: {error}
                  </td>
                </tr>
              )}

              {/* No Users State */}
              {status === "succeeded" && usersList.length === 0 && (
                <tr>
                  <td colSpan="5" className="text-center py-5">
                    No users found.
                  </td>
                </tr>
              )}

              {status === "succeeded" &&
                displayedAgents.map((user, index) => (
                  <tr key={user._id} className="even:bg-gray-50">
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {currentPage * itemsPerPage + index + 1}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {highlightTerm(
                        `${user.first_name} ${user.last_name}`,
                        searchTerm
                      )}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {highlightTerm(user.email, searchTerm)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {highlightTerm(user.created_at, searchTerm)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {highlightTerm(user.company_name, searchTerm)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {highlightTerm(user.company_subdomain, searchTerm)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {highlightTerm(user.company_number, searchTerm)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {user.subscription &&
                      user.subscription.productData &&
                      user.subscription.productData.name
                        ? highlightTerm(
                            user.subscription.productData.name,
                            searchTerm
                          )
                        : "N/A"}
                    </td>

                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {user.virgin_voyages_link && (
                        <a
                          href={user.virgin_voyages_link}
                          target="_blank"
                          rel="noopener noreferrer">
                          {user.virgin_voyages_link}
                        </a>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      <EditConfirmationModal
        open={showEditConfirmModal}
        onClose={() => setShowEditConfirmModal(false)}
        onSave={() => {
          updateAgent(userToSaveIndex);
          setUserToSaveIndex(null); // Reset the index after updating
        }}
        onCancel={() => {
          setShowEditConfirmModal(false); // closes the modal
          setEditMode(null); // sets the editMode to null
          setFeedback("User not updated");
          setFeedbackColor("orange");
          setTimeout(() => {
            setFeedback("");
          }, 1500);
        }}
      />
    </div>
  );
};

export default Agents;
