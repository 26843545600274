// This below works to change the primary-color but doesn't send the call
// This is good - the color picker shouldn't send the call. Just pick the color
import React, { useState } from "react";
import { ChromePicker } from "react-color";

const ColorPicker = ({ selectedColor, onColorChange }) => {
  const [color, setColor] = useState(selectedColor);

  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
    onColorChange(newColor.hex);
  };

  return (
    <div>
      <ChromePicker color={color} onChange={handleColorChange} />
    </div>
  );
};

export default ColorPicker;
