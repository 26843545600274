import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../../config"; // Import the dynamic API base URL

const fetchAgentMetrics = createAsyncThunk(
  "agent/fetchMetrics",
  async (_, { getState }) => {
    const state = getState();
    const { token, _id, email } = state.activeAgent.data.agent; // Extract email from activeAgent

    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-agent-api-token": token,
        "x-agent-id": _id,
      },
    };

    try {
      // Make an API call to fetch the agent metrics data with the agent's email
      const response = await axios.get(
        `https://${apiBaseUrl}/agent/metrics?email=${email}`,
        config
      );

      return response.data;
    } catch (error) {
      // You can handle errors here or re-throw them as needed
      throw error;
    }
  }
);

export { fetchAgentMetrics };
