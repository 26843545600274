import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isDev } from "../../config";
import ReactGA from "react-ga4";
import shipList from "./shipList";
import roomList from "./roomList";
import GridTileCarousel from "./GridTileCarousel";
import {
  addFavouriteCruiseThunk,
  removeFavouriteCruiseThunk,
} from "../../store/thunks/usersThunk";
import { fetchActiveUser } from "../../store/thunks/fetchActiveUser";

import CruiseDetailModal from "../cruiseDetail/CruiseDetailModal";
import SoldOutModal from "./SoldOutModal";
import ShipDetailModal from "../cruiseDetail/ShipDetailModal";
import CruisePerksModal from "../cruiseDetail/CruisePerksModal";
import vvCruisesOBC from "./vvCruisesOBC";

import { US, CA } from "country-flag-icons/react/3x2";
import { SparklesIcon, MoonIcon } from "@heroicons/react/24/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShip, faDoorOpen, faHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";

import "../../App.css";
import "./CruiseGridTile.css";

const CruiseGridTile = ({ cruise, cruises, onInquireClick, isFavourite }) => {
  const dispatch = useDispatch();
  // const activeUser = useSelector((state) => state.activeUser.data.user);

  const [soldOutModalOpen, setSoldOutModalOpen] = useState(false);
  const [selectedSoldOutCruiseLine, setselectedSoldOutCruiseLine] =
    useState(null);
  const [cruisePerksModalOpen, setCruisePerksModalOpen] = useState(false);
  const [currentCruiseShip, setCurrentCruiseShip] = useState(null);
  const [currentOperator, setCurrentOperator] = useState(null);
  const [sailDateForPerks, setSailDateForPerks] = useState(null);
  const [sailDateForCruiseDetail, setSailDateForCruiseDetail] = useState();
  const [shipNameForCruiseDetail, setShipNameForCruiseDetail] = useState();
  const [shipDetailModalOpen, setShipDetailModalOpen] = useState(false);
  const [cruiseDetailModalOpen, setCruiseDetailModalOpen] = useState(false);
  const [cruiseUnavailableModalOpen, setCruiseUnavailableModalOpen] =
    useState(false);

  // const [showFavouritesMessage, setShowFavouritesMessage] = useState(false);
  // const [favouritesMessageText, setFavouritesMessageText] = useState("");
  const [favouriteCruisesArray, setFavouriteCruisesArray] = useState([]);
  const [heartIcon, setHeartIcon] = useState(
    isFavourite ? solidHeart : regularHeart
  );

  // Update heart icon when isFavourite changes
  useEffect(() => {
    setHeartIcon(isFavourite ? solidHeart : regularHeart);
  }, [isFavourite]);

  const addToFavourites = (cruise) => {
    dispatch(
      addFavouriteCruiseThunk({
        userId: activeUser._id,
        favouriteCruises: [...favouriteCruisesArray, cruise],
      })
    ).then(() => {
      dispatch(fetchActiveUser());
    });
  };

  const removeFromFavourites = (cruiseId) => {
    dispatch(
      removeFavouriteCruiseThunk({
        userId: activeUser._id,
        cruiseId: cruiseId,
      })
    ).then(() => {
      dispatch(fetchActiveUser());
    });
  };

  const handleFavouritesClick = () => {
    if (isFavourite) {
      removeFromFavourites(cruise._id); // Call removeFromFavourites if already favorited
      if (!isDev) {
        ReactGA.event({
          event: "click",
          category: "click",
          action: "Removed Cruise from Favourites",
        });
      }
    } else {
      addToFavourites(cruise); // Call addToFavourites if not favorited
      if (!isDev) {
        ReactGA.event({
          event: "click",
          category: "click",
          action: "Added Cruise To Favourites",
        });
      }
    }
  };

  const activeUser = useSelector((state) => state.activeUser.data.user);
  const usersAgent = useSelector((state) => state.usersAgent.agentData);

  function toggleShipDetailModal(show) {
    if (!isDev) {
      ReactGA.event({
        event: "cruise_search",
        category: "Search",
        action: "Clicked Ship Detail",
      });
    }
    setShipDetailModalOpen(show);
  }

  function toggleCruiseDetailModal(show) {
    console.log("toggleCruiseDetailModal called with:", show);
    if (!isDev) {
      ReactGA.event({
        event: "cruise_search",
        category: "Search",
        action: "Clicked Cruise Detail",
      });
    }
    setCruiseDetailModalOpen(show);
  }

  function toggleCruisePerksModal(show) {
    console.log("toggleCruisePerksModal called with:", show);
    if (!isDev) {
      ReactGA.event({
        event: "cruise_search",
        category: "Search",
        action: "Clicked Cruise Perks",
      });
    }
    setCruisePerksModalOpen(show);
  }

  function toggleCruiseUnavailableModal(show) {
    setCruiseUnavailableModalOpen(show);
  }

  function handleFullCruiseDetailsClick(cruise) {
    try {
      if (typeof window !== "undefined" && window !== null) {
        // Create the URL with query parameters
        const url = `/cruise_detail?shipName=${encodeURIComponent(
          cruise.Ship
        )}&sailDate=${encodeURIComponent(
          cruise.SailDate
        )}&cruiseId=${encodeURIComponent(cruise._id)}`;

        // Open the URL in a new tab
        window.open(url, "_blank", "noopener noreferrer");
      } else {
        console.error("Window is not available.");
      }
    } catch (error) {
      console.error("Error opening new tab:", error);
    }
  }

  function getShipImageURL(shipName) {
    const matchingShip = shipList.find(
      (shipItem) => shipItem.ship === shipName
    );
    return matchingShip ? matchingShip.compressedImageURI500x : null;
  }

  const determinePerks = (cruiseLine, shipName, sailDate) => {
    console.log(sailDate);
    const year = new Date(sailDate).getFullYear();
    let perks = [];

    switch (cruiseLine) {
      case "Celebrity":
        if (year === 2024) {
          perks = [
            "$50 USD Onboard Credit",
            "Free Gratuities",
            "Free WiFi",
            "Open Bar",
          ];
        } else if (year === 2025 || year === 2026) {
          perks = ["$50 USD Onboard Credit", "Open Bar", "Free WiFi"];
        }
        break;
      case "Virgin Voyages":
        perks = ["Free Gratuities", "Free WiFi"]; // Default perks
        if (
          vvCruisesOBC[shipName] &&
          vvCruisesOBC[shipName].includes(sailDate)
        ) {
          perks.unshift("$100 USD Sailor Loot");
        }
        break;
      case "Royal Caribbean":
        perks = ["$50 USD Onboard Credit"];
        break;
      default:
        // Handle other cruise lines here if needed
        break;
    }

    return perks.join(", ");
  };

  const getUpdatedVirginUrl = (cruise, usersAgent) => {
    // Check if usersAgent is defined
    if (!usersAgent || usersAgent.virgin_voyages_link === "") {
      return cruise.Url;
    } else {
      const agentUrl = new URL(usersAgent.virgin_voyages_link);
      const agentId = agentUrl.searchParams.get("agentId");
      const agencyId = agentUrl.searchParams.get("agencyId");
  
      // Parse the cruise URL to replace the agentId and agencyId
      const cruiseUrl = new URL(cruise.Url);
      cruiseUrl.searchParams.set("agentId", agentId);
      cruiseUrl.searchParams.set("agencyId", agencyId);
  
      // Return the updated cruise URL
      return cruiseUrl.toString();
    }
  };
  
  const getCategoryDetails = (cruise) => {
    let room;

    if (cruise.CruiseLine === "Celebrity") {
      room = roomList.find((room) => room.categoryCode === cruise.Category);
    } else if (cruise.CruiseLine === "Royal Caribbean") {
      room = roomList.find(
        (room) => room.categoryCode === cruise.CategoryClass
      );
    } else {
      return { code: "", name: "" }; // fallback
    }

    if (room && room.categoryName) {
      // Check for "w/" and "(" as potential break points
      const breakpoints = [" w/ ", " ("];
      let splitName;

      for (const breakpoint of breakpoints) {
        splitName = room.categoryName.split(breakpoint);
        if (splitName.length > 1) {
          const separator = breakpoint === " w/ " ? " w/ " : " (";
          return {
            code:
              cruise.CruiseLine === "Celebrity"
                ? cruise.Category
                : cruise.CategoryClass,
            name: `${splitName[0]} ${separator} ${splitName[1]}`,
          };
        }
      }

      return {
        code:
          cruise.CruiseLine === "Celebrity"
            ? cruise.Category
            : cruise.CategoryClass,
        name: room.categoryName,
      };
    }

    return {
      code:
        cruise.CruiseLine === "Celebrity"
          ? cruise.Category
          : cruise.CategoryClass,
      name: "",
    };
  };

  return (
    <div className="grid-tile">
      <div style={{ margin: 10, marginRight: 10, marginLeft: 10 }}>
        {/* Top half of tile */}
        <div style={{ display: "block", width: "100%" }}>
          {/* Product and Nights block */}
          <div
            style={{
              width: "100%",
              padding: "10px",
              backgroundColor: "#3949ab",
              color: "white",
              borderTopLeftRadius: "6px",
              borderTopRightRadius: "6px",
            }}>
            <p className="text-md font-semibold">
              {cruise.name ? cruise.name : cruise.Product}
            </p>
            <p className="text-sm">{cruise.CruiseLine}</p>
          </div>

          {/* Ship image and other stuff */}
          <div
            style={{
              position: "relative",
              display: "block",
              width: "100%",
            }}>
            {/* Heart icon */}
            {activeUser && (
              <div
                style={{
                  position: "absolute",
                  bottom: "-30px",
                  right: "6px",
                  zIndex: 1,
                  cursor: "pointer",
                }}>
                <FontAwesomeIcon
                  icon={heartIcon}
                  style={{
                    color: "#3949ab",
                    fontSize: "18px",
                  }}
                  onClick={handleFavouritesClick}
                />
              </div>
            )}

            {/* All photos carousel */}
            <GridTileCarousel
              images={cruise.port_images}
              shipImage={getShipImageURL(cruise.Ship)}
            />
          </div>
        </div>

        {/* Bottom half of tile */}
        <>
          <div className="pl-1 pt-2">
            {/* Ship */}
            <div className="flex items-center" style={{ paddingBottom: "4px" }}>
              <FontAwesomeIcon
                icon={faShip}
                width={20}
                height={20}
                style={{ marginRight: "8px" }}
              />
              <p>{cruise.Ship}</p>
            </div>

            {/* Category */}
            <div className="flex items-center" style={{ paddingBottom: "4px" }}>
              <FontAwesomeIcon
                icon={faDoorOpen}
                width={20}
                height={20}
                style={{ marginRight: "8px" }}
              />
              {cruise.CategoryName ? (
                <p>
                  {cruise.Category} - {cruise.CategoryName}
                </p>
              ) : (
                <>
                  <p>
                    {getCategoryDetails(cruise).code}
                    {cruise.CruiseLine !== "Virgin Voyages" && " - "}
                    {getCategoryDetails(cruise).name}
                  </p>
                  {cruise.CruiseLine === "Virgin Voyages" && (
                    <div>
                      <p>
                        {cruise.CategoryClass} - {cruise.Category}
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>

            {/* Starting */}
            <div className="flex items-center" style={{ paddingBottom: "4px" }}>
              <MoonIcon
                height={18}
                width={18}
                style={{ marginRight: "10px" }}
              />
              <p>
                {cruise.Nights} nights,{" "}
                {new Date(cruise.SailDate).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </p>
            </div>

            {/* Perks */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                position: "relative",
              }}>
              <SparklesIcon
                height={22}
                width={22}
                style={{
                  height: "22px",
                  width: "22px",
                  marginRight: "6px",
                }}
              />

              {cruise.Perks ? (
                <p>{cruise.Perks}</p>
              ) : (
                <p>
                  {determinePerks(
                    cruise.CruiseLine,
                    cruise.Ship,
                    cruise.SailDate
                  )}
                </p>
              )}
            </div>
          </div>

          {/* Cost / Savings */}
          <div className="pt-3 pb-6 flex justify-center">
            {cruise.CruiseLine === "Virgin Voyages" ? (
              <div className="flex flex-col items-center">
                <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 mr-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                  {/* Render flag based on currency */}
                  {cruise.Currency === "CAD" ? (
                    <CA title="Canada" className="w-4" />
                  ) : (
                    <US title="United States" className="w-4" />
                  )}
                  {/* Render GroupRate with two decimal places and add "pp" */}
                  <p className="font-bold text-xl ml-1 mr-1">
                    $
                    {parseFloat(cruise.GroupRate).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                  <p> / person*</p>
                </span>
              </div>
            ) : (
              <div className="flex items-center">
                <div className="flex flex-col items-center">
                  <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 mr-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                    {cruise.Currency === "CAD" ? (
                      <CA title="Canada" className="w-4" />
                    ) : (
                      <US title="United States" className="w-4" />
                    )}
                    <p className="font-bold text-xl ml-1 mr-1">
                      ${parseFloat(cruise.GroupRate).toFixed(2)}
                    </p>
                    <p>/ person *</p>
                  </span>

                  {cruise.CurrentPriceAdv !== "SOLD OUT" &&
                    cruise.CurrentGroupRate !== "SOLD OUT" && (
                      <div
                        className="flex flex-row items-center pt-2"
                        style={{ marginLeft: "-4px" }}>
                        <span className="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-sm font-medium text-gray-500 ring-1 ring-inset ring-gray-600/20">
                          <span
                            style={{
                              textDecoration: "line-through",
                              color: "gray",
                            }}>
                            $
                            {isNaN(parseFloat(cruise.CurrentGroupRate))
                              ? "0.00"
                              : parseFloat(
                                  cruise.CurrentGroupRate
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </span>

                          <p className="pl-1 text-sm font-medium text-gray-500">
                            (
                            {isNaN(parseFloat(cruise.CurrentPercentAdv))
                              ? "0"
                              : cruise.CurrentPercentAdv}
                            % OFF)
                          </p>
                        </span>
                      </div>
                    )}
                </div>
              </div>
            )}
          </div>

          {/* 4 buttons */}
          <div className="buttons-container">
            <button
              type="button"
              className="rounded-md bg-white px-1 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={(event) => {
                event.stopPropagation();
                setShipNameForCruiseDetail(cruise.Ship);
                setSailDateForCruiseDetail(cruise.SailDate);
                toggleCruiseDetailModal(true);
              }}>
              Itinerary
            </button>
            <button
              type="button"
              className="rounded-md bg-white px-1 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => handleFullCruiseDetailsClick(cruise)}>
              Cruise Details
            </button>
            <button
              type="button"
              className="rounded-md bg-white px-1 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={(event) => {
                event.stopPropagation(); // stop the click event from propagating up to the document
                setCurrentCruiseShip(cruise.Ship); // Set current cruise ship here
                setCurrentOperator(cruise.CruiseLine); // Set the proper operator name to pass to thunk
                toggleShipDetailModal(true);
              }}>
              Ship Details
            </button>

            <button
              type="button"
              className="rounded-md bg-white px-1 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => {
                if (cruise.CruiseLine === "Virgin Voyages") {
                  const updatedVirginUrl = getUpdatedVirginUrl(cruise);
                  // Open a URL in a new window for Virgin Voyages
                  window.open(updatedVirginUrl, "_blank");
                } else {
                  // Perform the original action (onInquireClick) for other CruiseLines
                  onInquireClick(
                    cruise._id,
                    cruise.CruiseLine,
                    cruise.Ship,
                    cruise.SailDate,
                    cruise.Nights,
                    cruise.Product,
                    cruise.CurrentPriceAdv,
                    cruise.CurrentPercentAdv,
                    cruise.GroupRate
                  );
                  console.log(
                    "Inquire button clicked. Here's the data being passed through",
                    "id:",
                    cruise._id,
                    "cruiseline",
                    cruise.CruiseLine,
                    "ship:",
                    cruise.Ship,
                    "saildate",
                    cruise.SailDate,
                    "nights",
                    cruise.Nights,
                    "product",
                    cruise.Product,
                    "currpriceadv",
                    cruise.CurrentPriceAdv,
                    "currgroupadv",
                    cruise.CurrentPercentAdv,
                    "grouprate",
                    cruise.GroupRate
                  );
                }
                // For now, scroll to the bottom for inquire
                window.scrollTo(0, document.body.scrollHeight);
              }}>
              {cruise.CruiseLine === "Virgin Voyages" ? "Book" : "Inquire"}
              <span className="sr-only">, {cruise._id}</span>
            </button>
          </div>

          {/* Disclaimer information */}
          <p className="pb-0.5 pt-2.5 text-xs text-gray-500">
            * Starting price. Taxes and fees not included.
          </p>
        </>

        {/* Modals */}
        <>
          {soldOutModalOpen && (
            <SoldOutModal
              cruiseLine={selectedSoldOutCruiseLine}
              closeModal={() => {
                setSoldOutModalOpen(false);
                setselectedSoldOutCruiseLine(null);
              }}
            />
          )}
          {shipDetailModalOpen && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0,0,0,0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflowY: "auto",
                zIndex: 5,
              }}>
              <ShipDetailModal
                isOpen={shipDetailModalOpen}
                onClose={() => toggleShipDetailModal(false)}
                shipName={currentCruiseShip} // Passed ship name here
                operator={currentOperator} // Pass operator name
              />
            </div>
          )}
          {cruiseDetailModalOpen && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0,0,0,0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflowY: "auto",
                zIndex: 10,
              }}>
              <CruiseDetailModal
                isOpen={cruiseDetailModalOpen}
                onClose={() => {
                  toggleCruiseDetailModal(false);
                  toggleCruiseUnavailableModal(false);
                }}
                toggleCruiseDetailModal={toggleCruiseDetailModal} // Pass it here
                sailDate={sailDateForCruiseDetail}
                shipName={shipNameForCruiseDetail}
              />
            </div>
          )}
          {cruisePerksModalOpen && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0,0,0,0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflowY: "auto", // added overflowY here
              }}>
              <CruisePerksModal
                isOpen={cruisePerksModalOpen}
                onClose={() => {
                  toggleCruisePerksModal(false);
                }}
                toggleCruisePerksModal={toggleCruisePerksModal} // Pass it here
                cruiseLine={currentOperator}
                shipName={currentCruiseShip}
                sailDate={sailDateForPerks}
              />
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default CruiseGridTile;
