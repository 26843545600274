import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL

const fetchUsersAgent = createAsyncThunk("users/agent", async (_, { getState }) => {
  const state = getState();
  const { token, _id, agentEmail } = state.activeUser.data.user; // Assuming you have activeAgent in your state

  console.log(_id, agentEmail);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-agent-api-token": token,
      "x-agent-id": _id, // Adjust the header name as per your backend API requirements
      // Add other headers as needed
    },
  };

  const response = await axios.get(`https://${apiBaseUrl}/agents/${agentEmail}`, config);
  return response.data;

});

export { fetchUsersAgent };
