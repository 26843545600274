// this is a combination of usersSlice and editActiveUsersSlice

import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAgentsList,
  fetchAgentsClientsList,
  fetchAgentsInquiriesList,
  editActiveAgent,
  updateAgentThunk,
  updateAgentColorThunk,
  updateSubdomainThunk,
  updateSubscriptionPlanThunk,
} from "../thunks/agentsThunk";

const agentsSlice = createSlice({
  name: "agents",
  initialState: {
    agentsList: [],
    agent: null,
    status: "idle",
    isLoading: false,
    error: null,
  },
  reducers: {
    setActiveAgent: (state, action) => {
      state.activeAgent = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgentsList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAgentsList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.agentsList = action.payload;
      })
      .addCase(fetchAgentsList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchAgentsClientsList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAgentsClientsList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.agentsList = action.payload;
      })
      .addCase(fetchAgentsClientsList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchAgentsInquiriesList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAgentsInquiriesList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.agentsList = action.payload;
      })
      .addCase(fetchAgentsInquiriesList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(editActiveAgent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(editActiveAgent.fulfilled, (state, action) => {
        state.agent = action.payload;
        state.isLoading = false;
      })
      .addCase(editActiveAgent.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(updateAgentThunk.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateAgentThunk.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateAgentThunk.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateAgentColorThunk.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateAgentColorThunk.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateAgentColorThunk.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateSubdomainThunk.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateSubdomainThunk.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateSubdomainThunk.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateSubscriptionPlanThunk.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateSubscriptionPlanThunk.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateSubscriptionPlanThunk.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setActiveAgent } = agentsSlice.actions;
export default agentsSlice.reducer;
