import React from "react";

const PrivacyPolicy = () => {
    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
            <p className="mb-6">Effective Date: August 2023</p>

            <h2 className="text-xl font-semibold mb-2">
                Information We Collect
            </h2>
            <ul className="list-disc pl-6 mb-6">
                <li>
                    Personal Information: We may collect the following personal
                    information when you interact with our website or subscribe
                    to our services:
                    <ul className="list-disc pl-6">
                        <li>First Name</li>
                        <li>Last Name</li>
                        <li>Email</li>
                        <li>Phone Number</li>
                    </ul>
                    This information is collected when you submit an inquiry
                    about a cruise or subscribe to our services. It is shared
                    with our trusted travel agent partners to provide you with
                    tailored cruise options and a seamless booking experience.
                    By submitting your information, you consent to the sharing
                    of this data as described in our Privacy Policy.
                </li>
                <li>
                    Subscription via Stripe: If you choose to subscribe to our
                    services, your subscription payment information will be
                    collected and processed by Stripe. To understand how Stripe
                    handles your payment and subscription information, please
                    review{" "}
                    <a
                        href="https://stripe.com/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Stripe's Privacy Policy
                    </a>
                    .
                </li>
                <li>
                    Analytics Data: We use Google Analytics and Facebook Pixel
                    to collect analytics data. This data helps us understand how
                    users interact with our website and improve our services.
                    The analytics data may include anonymized information about
                    your browsing behavior and actions on our site. For more
                    information about how Google Analytics and Facebook Pixel
                    handle your data, please review{" "}
                    <a
                        href="https://policies.google.com/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Google's Privacy Policy
                    </a>{" "}
                    and{" "}
                    <a
                        href="https://www.facebook.com/privacy/explanation"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Facebook's Data Policy
                    </a>
                    .
                </li>
                <li>
                    Usage Data: We may also collect usage data, which includes
                    information about how you use our website, such as your
                    browsing history, pages visited, interactions, and other
                    similar data. This data helps us improve our website's
                    functionality and tailor our content to better suit your
                    preferences. This usage data is collected using cookies and
                    similar tracking technologies. You can learn more about how
                    we use cookies and your options in our{" "}
                    <a href="/cookie-policy">Cookie Policy</a>.
                </li>
            </ul>

            {/* Add more sections as needed */}

            <h2 className="text-xl font-semibold mb-2">
                How We Use Your Information
            </h2>
            <p className="mb-6">
                We may use the information we collect for the following
                purposes:
            </p>
            <ul className="list-disc pl-6 mb-6">
                <li>
                    Personalization and Communication: The personal information,
                    including your First Name, Last Name, Email, and Phone
                    Number, that you provide when inquiring about a cruise or
                    subscribing to our services is used to tailor our
                    communication and services to your preferences. We may send
                    you relevant information, offers, and updates related to
                    your cruise inquiries and bookings.
                </li>
                <li>
                    Fulfillment of Services: Your personal information is shared
                    with our trusted travel agent partners to assist in
                    fulfilling your cruise inquiries and bookings. This sharing
                    enables us to provide you with the best possible cruise
                    options and a seamless booking experience.
                </li>
                <li>
                    Subscription Management: If you subscribe to our services
                    through Stripe, your subscription payment information is
                    processed to manage and maintain your subscription. Stripe
                    handles the secure processing of your payment information.
                    To understand how Stripe manages your payment and
                    subscription data, please review{" "}
                    <a
                        href="https://stripe.com/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Stripe's Privacy Policy
                    </a>
                    .
                </li>
                <li>
                    Website Improvement: We use analytics data from Google
                    Analytics and Facebook Pixel to understand how users
                    interact with our website. This helps us optimize our
                    website's performance, user experience, and content to
                    better meet your needs and preferences.
                </li>
                <li>
                    Usage Analysis: We collect usage data, including information
                    about your browsing behavior, pages visited, interactions,
                    and similar data. This data is used to analyze and improve
                    the functionality and content of our website. Cookies and
                    similar tracking technologies are used for this purpose.
                    Learn more in our <a href="/cookie-policy">Cookie Policy</a>
                    .
                </li>
            </ul>

            {/* Add more content */}

            <h2 className="text-xl font-semibold mb-2">
                Sharing Your Information
            </h2>
            <p className="mb-6">
                We do not share your personal information with third parties,
                except as described in this Privacy Policy.
            </p>

            {/* Additional content */}
            <p className="mb-6">
                Upon submitting a request to inquire about a cruise, your
                personal information will be shared with our trusted travel
                agent partners who assist us in providing you with the best
                cruise options and information tailored to your preferences.
                This sharing is necessary to fulfill your request and provide
                you with a seamless booking experience.
            </p>

            <p className="mb-6">
                Rest assured that we only share the minimum necessary
                information for the travel agent to assist you effectively. Your
                privacy remains a top priority, and our travel agent partners
                are contractually obligated to handle your information in
                compliance with applicable data protection laws and our strict
                confidentiality standards.
            </p>

            <p className="mb-6">
                By submitting an inquiry about a cruise, you consent to the
                sharing of your personal information with our travel agent
                partners for the purpose of assisting you with your cruise
                inquiries and bookings.
            </p>

            <p className="mb-6">
                For processing credit card payments, we use Stripe's secure
                payment gateway. Your credit card information is encrypted and
                securely transmitted to Stripe for payment processing. To
                understand how Stripe handles your payment information, please
                review{" "}
                <a
                    href="https://stripe.com/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Stripe's Privacy Policy
                </a>
                .
            </p>

            <h2 className="text-xl font-semibold mb-2">Your Rights</h2>
            <ul className="list-disc pl-6 mb-6">
                <li>Access, update, or delete your personal information.</li>
                <li>Opt out of receiving promotional materials.</li>
            </ul>

            <h2 className="text-xl font-semibold mb-2">
                Cross-Border Data Transfers
            </h2>
            <p className="mb-6">
                Our services may involve the transfer of your personal
                information to servers located in the United States. This is
                particularly relevant if you choose to make payments through
                Stripe, our payment processing partner. Stripe is based in the
                United States and processes payments on our behalf. By using our
                services, you consent to the transfer of your personal
                information to the United States, where the data protection laws
                may differ from those in your country.
            </p>
            <p className="mb-6">
                When transferring your personal information to the United
                States, we take appropriate measures to ensure the security and
                confidentiality of your data. Stripe, as our payment processor,
                is committed to safeguarding your information and complies with
                industry standards for data protection and security.
            </p>
            <p className="mb-6">
                Rest assured that we take steps to ensure that any cross-border
                data transfers comply with applicable privacy laws and
                regulations. If you have any concerns about the transfer of your
                personal information, please contact us at [contact email or
                phone number] for more information.
            </p>

            {/* Add more content */}

            <h2 className="text-xl font-semibold mb-2">Data Security</h2>
            <p className="mb-6">
                We take comprehensive measures to ensure the security of your
                data and protect it from unauthorized access, loss, alteration,
                or disclosure. Your personal information, including your First
                Name, Last Name, Email, and Phone Number, is handled with utmost
                care and in compliance with relevant data protection laws.
            </p>
            <ul className="list-disc pl-6 mb-6">
                <li>
                    Secure Transmission: When you submit personal information
                    through our website, we use encryption technologies such as
                    Secure Sockets Layer (SSL) to ensure the secure transmission
                    of your data. This helps prevent unauthorized interception
                    of your information during its transmission.
                </li>
                <li>
                    Trusted Partners: In cases where we share your personal
                    information with our travel agent partners for cruise
                    inquiries and bookings, we only collaborate with trusted
                    partners who are contractually obligated to treat your data
                    with confidentiality and in compliance with applicable data
                    protection regulations.
                </li>
                <li>
                    Payment Security: For subscription payments processed
                    through Stripe, your payment information is securely handled
                    by Stripe's advanced security features. Stripe is a trusted
                    and certified payment service provider known for its
                    stringent security standards. You can learn more about
                    Stripe's security practices in their{" "}
                    <a
                        href="https://stripe.com/docs/security/stripe"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Security Guide
                    </a>
                    .
                </li>
                <li>
                    Analytics Protection: Analytics data collected through
                    Google Analytics and Facebook Pixel is anonymized and
                    aggregated to protect your privacy. This information is used
                    solely for the purpose of understanding user behavior and
                    improving our website's performance.
                </li>
                <li>
                    Usage Data Protection: We use cookies and similar tracking
                    technologies for collecting usage data. You can manage your
                    cookie preferences by reviewing our{" "}
                    <a href="/cookie-policy">Cookie Policy</a>.
                </li>
            </ul>
            <p className="mb-6">
                Despite our rigorous efforts to protect your data, no method of
                data transmission over the internet or electronic storage is
                completely secure. Therefore, while we strive to safeguard your
                personal information, we cannot guarantee its absolute security.
            </p>

            <h2 className="text-xl font-semibold mb-2">
                Data Breach Notification
            </h2>
            <p className="mb-6">
                We take data security seriously and have implemented measures to
                protect your personal information from unauthorized access,
                loss, alteration, or disclosure. However, in the event of a data
                breach that compromises your personal information, we will take
                immediate steps to mitigate the impact and notify you as
                required by applicable laws.
            </p>
            <p className="mb-6">
                If we determine that a data breach has occurred and that it
                poses a risk of significant harm to you, we will notify you and
                relevant authorities as soon as possible. The notification will
                include details about the nature of the breach, the type of
                information that was affected, the steps we are taking to
                address the breach, and recommendations for actions you can take
                to protect yourself.
            </p>
            <p className="mb-6">
                We will communicate with you via email (if available) or other
                appropriate means. It's important to keep your contact
                information up to date so that we can reach you in the event of
                a data breach. If you believe that your personal information has
                been compromised, please contact us immediately at [contact
                email or phone number].
            </p>
            <p className="mb-6">
                Please note that while we take every precaution to secure your
                data, no method of transmission over the internet or electronic
                storage is entirely secure. We will continue to enhance our
                security measures to protect your personal information and
                prevent future breaches.
            </p>

            {/* Add more content */}

            <h2 className="text-xl font-semibold mb-2">
                Changes to this Privacy Policy
            </h2>
            <p className="mb-6">
                We may update this privacy policy from time to time to reflect
                changes in our practices or for legal, operational, or
                regulatory reasons. Any changes made to this policy will be
                effective when posted on this page.
            </p>
            <p className="mb-6">
                We will notify you of significant changes by sending an email to
                the email address you have provided to us or by placing a
                prominent notice on our website. We encourage you to review this
                privacy policy periodically to stay informed about how we are
                protecting your information and enhancing your user experience.
            </p>
            <p className="mb-6">
                By continuing to use our services after any changes to this
                privacy policy, you acknowledge and agree to the updated terms
                and practices. If you disagree with any of the changes, you
                should discontinue using our website and services.
            </p>

            {/* Add more content */}

            <h2 className="text-xl font-semibold mb-2">Contact Us</h2>
            <p>
                If you have any questions or concerns about this privacy
                policy, email us at admin@cruisecompare.ca.
            </p>

            {/* Add more content */}
        </div>
    );
};

export default PrivacyPolicy;
