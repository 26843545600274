import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiBaseUrl } from "../../config";

export const sendClientQuote = createAsyncThunk(
  "quotes/sendClientQuote",
  async (quote, { dispatch, getState }) => {
    try {
      const state = getState();
      const { token, _id } = state.activeAgent.data.agent;

      const config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-agent-api-token": token,
          "x-agent-id": _id,
        },
        body: JSON.stringify(quote),
      };

      const response = await fetch(
        `https://${apiBaseUrl}/sendClientQuote`,
        config
      );
      const data = await response.json();

      if (!response.ok) {
        throw new Error(
          data.error || "An error occurred while sending the email."
        );
      }

      return data;
    } catch (error) {
      console.error("Error sending quote:", error.message);
      throw error;
    }
  }
);
