import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const fetchCruiseDetails = createAsyncThunk(
  "cruiseDetails/fetch",
  async (
    { start_date_range_beginning, start_date_range_end, ship_name },
    { rejectWithValue }
  ) => {
    try {
      const appId = process.env.REACT_APP_CRUISE_API_KEY;
      const appToken = process.env.REACT_APP_CRUISE_TOKEN;

      const queryString = new URLSearchParams({
        start_date_range_beginning,
        start_date_range_end,
        ship_name,
        app_id: appId,
        token: appToken,
      }).toString();

      const apiUrl = `https://www.widgety.co.uk/api/cruises.json?${queryString}`;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json;api_version=2",
        },
      };

      const response = await axios.get(apiUrl, config);
      const cruiseData = response.data;

      // Checking if the ref exists
      if (
        cruiseData &&
        cruiseData.cruises &&
        cruiseData.cruises[0] &&
        cruiseData.cruises[0].ref
      ) {
        const ref = cruiseData.cruises[0].ref;
        const portVisitsQueryString = new URLSearchParams({
          app_id: appId,
          token: appToken,
        }).toString();
        const portVisitsUrl = `https://www.widgety.co.uk/api/cruises/${ref}/port_visits.json?${portVisitsQueryString}`;

        try {
          const portVisitsResponse = await axios.get(portVisitsUrl, config);
          const portVisitsData = portVisitsResponse.data;

          if (portVisitsData && Array.isArray(portVisitsData)) {
            // Fetch port images for each port visit
            for (const portVisit of portVisitsData) {
              if (portVisit && portVisit.port && portVisit.port.unlocode) {
                const portImageUnlocode = portVisit.port.unlocode;

                // Additional queryparams for image geometry and JPEG recompression
                const additionalParams = {
                  images_geometry: "1000x1000",
                  jpegrecompress: "allow_lossy:true",
                };

                // Use Object.assign to merge the previous and new query parameters
                const mergedParams = Object.assign(
                  {
                    app_id: appId,
                    token: appToken,
                  },
                  additionalParams
                );

                // const portImageQueryString = new URLSearchParams({
                //   app_id: appId,
                //   token: appToken,
                // }).toString();

                const portImageQueryString = new URLSearchParams(
                  mergedParams
                ).toString();

                const portImageUrl = `https://www.widgety.co.uk/api/ports/${portImageUnlocode}.json?${portImageQueryString}`;

                try {
                  const portImageResponse = await axios.get(
                    portImageUrl,
                    config
                  );
                  portVisit.portImages = portImageResponse.data; // Add port images to port visit object
                } catch (error) {
                  console.error("Error fetching port images data", error);
                }
              } else {
                console.error("Port visit data is missing or incomplete");
              }
            }

            cruiseData.portVisits = portVisitsData;
          } else {
            console.error("Port visits data is missing or not an array");
          }
        } catch (error) {
          console.error("Error fetching port visits data", error);
        }
      } else {
        console.error("Ref does not exist in cruise data response");
      }

      return cruiseData;
    } catch (error) {
      console.error("Error fetching cruise details: ", error.message);
      return rejectWithValue(error.message);
    }
  }
);

export { fetchCruiseDetails };
