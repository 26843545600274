import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL

const fetchCruisePricingWithoutCruiseId = createAsyncThunk(
  "cruises/fetchCruisePricingWithoutCruiseId",
  async (data, { getState }) => {
    const { CruiseLine, Ship, TextSailDate } = data;
    const systemUserToken = process.env.SYSTEM_USER_TOKEN;
    const systemUserID = process.env.SYSTEM_USER_ID;

    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-api-token": systemUserToken,
        "x-user-id": systemUserID,
      },
    };

    try {
      const response = await axios.post(
        `https://${apiBaseUrl}/cruises/public/pricing`,
        { CruiseLine, Ship, TextSailDate },
        config
      );
      return response.data;
    } catch (error) {
      // Handle error as needed
      throw error;
    }
  }
);

export { fetchCruisePricingWithoutCruiseId };
