import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { registerUser } from "../store/thunks/registerUser";
import { registerAgent } from "../store/thunks/registerAgent";
import Logo from "../components/Logo";
import ReactGA from "react-ga4";
import Cookies from "js-cookie";
import { isDev } from "../config";
import {
  ArrowUpOnSquareIcon,
  InformationCircleIcon,
  XMarkIcon,
  EyeIcon,
} from "@heroicons/react/24/outline";
import RegisterModal from "../components/agent/RegisterModal";
// const { uploadLogo } = require("../../server/middlewares/upload");

function AgentRegister() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyNumber, setCompanyNumber] = useState("");
  const [subdomain, setSubdomain] = useState("");
  const [companyLogo, setCompanyLogo] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isSubdomainModalOpen, setIsSubdomainModalOpen] = useState(false);
  const [isCompanyLogoModalOpen, setIsCompanyLogoModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordComplex, setPasswordComplex] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [isVerified, setIsVerified] = useState(false);

  const [error, setError] = useState(null);
  const [clientUserAgent, setClientUserAgent] = useState(""); // Client User Agent
  const [fbc, setFbc] = useState(""); // Click ID (fbc) Cookie
  const theme_color = "#4f46e5";

  useEffect(() => {
    setClientUserAgent(navigator.userAgent);
    // Fetch the fbc cookie value
    const fbcCookieValue =
      document.cookie
        .split("; ")
        .find((row) => row.startsWith("_fbc="))
        ?.split("=")[1] || "";

    setFbc(fbcCookieValue);
  }, []);

  let formData = {
    user_type: "agent",
    first_name: firstName,
    last_name: lastName,
    company_name: companyName,
    company_number: companyNumber,
    company_subdomain: subdomain,
    // company_logo: companyLogo?.photo,
    email: email,
    password: password,
    username: email,
    client_user_agent: clientUserAgent, // Add client_user_agent to formData
    fbc: fbc, // Add fbc to formData
    theme_color: theme_color,
  };

  const isValidEmail = (email) => {
    // Simple regex pattern for email validation
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !firstName ||
      !lastName ||
      !companyName ||
      !companyNumber ||
      !email ||
      !password ||
      !confirmPassword
    ) {
      setError("All fields are required.");
      return;
    }

    // Email validation
    if (!isValidEmail(formData.email)) {
      setError("Invalid email format. Please check your email.");
      return; // Return early
    }

    // Password matching check
    if (password !== confirmPassword) {
      setError("Passwords do not match. Please re-enter your password.");
      return; // Return early
    }

    setError(null);
    setClientUserAgent(navigator.userAgent);

    try {
      const response = await dispatch(registerAgent(formData));
      console.log("response.payload is:", response.payload);
      if (response.payload && response.payload._id) {
        console.log("Registration successful");
        if (!isDev) {
          ReactGA.event({
            category: "Agent",
            action: "Created an Account",
          });
        }
        navigate("/agent/login");
      } else {
        console.log(
          "Registration failed. Unexpected payload:",
          response.payload
        );
        setError("Registration failed. Please ensure no empty fields.");
      }
    } catch (error) {
      setError(error.message);
      console.log("Error:", error);
      alert(error.message);
    }
  };

  const openSubdomainModal = () => {
    setIsSubdomainModalOpen(true);
    console.log("subdomain modal OPEN");
  };

  const closeSubdomainModal = () => {
    setIsSubdomainModalOpen(false);
    console.log("subdomain modal CLOSED");
  };

  const openCompanyLogoModal = () => {
    setIsCompanyLogoModalOpen(true);
    console.log("logo modal OPEN");
  };

  const closeCompanyLogoModal = () => {
    setIsCompanyLogoModalOpen(false);
    console.log("logo modal CLOSED");
  };

  const handleNumberChange = (e) => {
    const input = e.target.value;
    // Remove non-numeric characters and store in state
    setCompanyNumber(input.replace(/\D/g, ""));
    console.log("companynumber is", companyNumber);
  };

  // For uploading logo
  const handleLogoChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  // For uploading logo
  const handleUpload = async () => {
    try {
      if (!selectedFile) {
        alert("Please select a file.");
        return;
      }

      const formData = new FormData();
      formData.append("image", selectedFile);

      await axios.post("/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      alert("File uploaded successfully.");
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const previewCompanyLogo = () => {
    if (companyLogo) {
      window.open(URL.createObjectURL(companyLogo));
    }
  };

  // const handleCompanyLogoChange = (file) => {
  //   if (file) {
  //     setSelectedFile(file);
  //   }
  // };

  const handleCompanyLogoChange = (e) => {
    setCompanyLogo(e.target.files[0]);
  };

  // const handleCompanyLogoChange = (e) => {
  //   setCompanyLogo({ ...companyLogo, photo: e.target.files[0] });
  //   console.log(companyLogo?.photo);
  // };

  const removeCompanyLogo = () => {
    setSelectedFile(null);
  };

  function formatPhoneNumber(input) {
    // Apply formatting
    const formatted = input.replace(
      /(\d{3})(\d{0,3})(\d{0,4})/,
      function (_, p1, p2, p3) {
        let result = "";
        if (p1) {
          result += `(${p1}`;
          if (p2) result += `) ${p2}`;
          if (p3) result += `-${p3}`;
        }
        return result;
      }
    );

    return formatted;
  }

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    // Check if passwords match
    setPasswordsMatch(newPassword === confirmPassword);

    // Check password complexity when it changes
    setPasswordComplex(isPasswordComplex(newPassword));
    console.log("Password complexity:", passwordComplex);
  };

  function isPasswordComplex(password) {
    // Password complexity criteria
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const isLongEnough = password.length >= 8; // Minimum 8 characters

    return hasUpperCase && hasLowerCase && hasNumber && isLongEnough;
  }

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);

    // Check if passwords match
    setPasswordsMatch(password === newConfirmPassword);
  };

  const handlePasswordBlur = () => {
    // Check if passwords match
    setPasswordsMatch(password === confirmPassword);
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="flex justify-center">
            <Logo width={300} height={300} />
          </div>
          <div className="flex-col justify-center items-center">
            <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Create an Agent Account
            </h2>
          </div>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <p className="text-center text-sm text-gray-500">
              All fields are required.
            </p>

            {/* Form */}
            <div className="mt-10 grid grid-cols-2 gap-6">
              {/* FName */}
              <div className="col-span-1">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  First Name
                </label>
                <div className="mt-2">
                  <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    autoComplete="given-name"
                    required
                    className="w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
              </div>

              {/* LName */}
              <div className="col-span-1">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Last Name
                </label>
                <div className="mt-2">
                  <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    autoComplete="family-name"
                    required
                    className="w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>

              {/* Company Name */}
              <div className="col-span-1">
                <label
                  htmlFor="companyName"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Company Name
                </label>
                <div className="mt-2">
                  <input
                    id="companyName"
                    name="companyName"
                    type="text"
                    placeholder="Your Company"
                    autoComplete="organization"
                    required
                    className="w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder-text-gray-400 italic focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </div>
              </div>

              {/* Company Phone Number */}
              <div className="col-span-1">
                <label
                  htmlFor="companyNumber"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Company Phone Number
                </label>
                <div className="mt-2">
                  <input
                    id="companyNumber"
                    name="companyNumber"
                    type="text"
                    placeholder="(XXX) XXX-XXXX"
                    autoComplete="off"
                    required
                    className="w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder-text-gray-400 italic focus:ring-2 focus-ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={formatPhoneNumber(companyNumber)}
                    onChange={handleNumberChange}
                  />
                  {/* {companyNumber} */}
                </div>
              </div>

              {/* Thick gray border lol */}
              <div className="col-span-2 border-t-2 border-gray-300 my-4"></div>

              {/* Email (login) */}
              <div className="col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              {/* Password */}
              <div className="col-span-2">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  {passwordComplex ? (
                    <p className="text-sm text-green-500 pt-4">
                      Password is complex.
                    </p>
                  ) : (
                    <p className="text-sm text-red-500 pt-4">
                      Passwords must be at least 8 digits, and contain 1 capital
                      letter, 1 lowercase letter, 1 number.
                    </p>
                  )}
                </div>
              </div>

              {/* Confirm Password */}
              <div className="col-span-2">
                <label
                  htmlFor="confirmPassword"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Confirm Password
                </label>
                <div className="mt-2">
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                  />
                </div>
              </div>

              {/* Passwords Match Message */}
              {passwordsMatch && (
                <p className="mt-2 text-sm text-green-500">Passwords match.</p>
              )}

              {/* Passwords Do Not Match Message */}
              {!passwordsMatch && (
                <p className="mt-2 text-sm text-red-500">
                  Passwords do not match.
                </p>
              )}
            </div>

            {/* Verification button */}
            <div className="relative flex items-center pt-4">
              <div className="flex items-center">
                <input
                  id="verifyInformation"
                  name="verifyInformation"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  onChange={(e) => setIsVerified(e.target.checked)}
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label
                  htmlFor="verifyInformation"
                  className="font-medium text-gray-900">
                  I verify the above information is correct
                </label>
              </div>
            </div>

            {error && (
              <div className="mt-4 flex items-center">
                <p className="text-red-500 mr-2">{error}</p>
                <button
                  className="text-red-500 font-semibold"
                  onClick={() => setError("")}>
                  Dismiss
                </button>
              </div>
            )}

            {/* Create Account button */}
            <div className="pt-10">
              <button
                type="button" // Use type="button" to prevent form submission
                onClick={handleSubmit}
                className={`flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                  passwordsMatch && isVerified
                    ? "focus-visible:outline-indigo-600"
                    : "cursor-not-allowed opacity-50"
                }`}
                disabled={!passwordsMatch || !isVerified}>
                Create Account
              </button>
            </div>

            <p className="mt-10 text-center text-sm text-gray-500">
              After creation, check your email for a verification link.
            </p>

            <p className="mt-10 text-center text-sm text-gray-500">
              Already an agent?{" "}
              <Link
                to="/agent/login"
                className="font-semibold text-indigo-600 hover:text-indigo-500">
                Log In Now
              </Link>
            </p>

            {/* Modals */}
            <>
              <RegisterModal
                isOpen={isSubdomainModalOpen}
                onClose={closeSubdomainModal}
                type="subdomain"
              />

              <RegisterModal
                isOpen={isCompanyLogoModalOpen}
                onClose={closeCompanyLogoModal}
                type="logo"
              />
            </>
          </div>
        </div>
      </div>
    </>
  );
}

export default AgentRegister;
