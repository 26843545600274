import { createSlice } from "@reduxjs/toolkit";
import { sendGa4Event } from "../../thunks/google/sendGa4Event";

const initialState = {
  cruiseData: [], // Initialize with an empty array
  loading: false,
  error: null,
};

const sendGa4EventSlice = createSlice({
  name: "sendGa4Event",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendGa4Event.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendGa4Event.fulfilled, (state, action) => {
        state.loading = false;
        state.cruiseData = action.payload;
      })
      .addCase(sendGa4Event.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default sendGa4EventSlice.reducer;
