import { Routes, Route, Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import ProtectedRoute from "./util/ProtectedRoute";
import ProtectedRouteAgent from "./util/ProtectedRouteAgent";
import ProtectedRouteAdmin from "./util/ProtectedRouteAdmin";
import LandingPage from "./pages/LandingPage";
import NewLandingPage from "./pages/NewLandingPage";
import VVLandingPage from "./pages/VVLandingPage";
import AgentLandingPage from "./pages/AgentLandingPage";
import Register from "./auth/Register";
import AgentRegister from "./auth/AgentRegister";
import Login from "./auth/Login";
import AgentLogin from "./auth/AgentLogin";
import Logout from "./auth/Logout";
import AgentLogout from "./auth/AgentLogout";
import Dashboard from "./pages/dashboard/Dashboard";
import AgentPage from "./pages/AgentPage";
import AdminPage from "./pages/AdminPage";
import PrivacyPolicy from "./pages/legal/privacyPolicy";
import UsageTerms from "./pages/legal/usageTerms";
import EmailVerification from "./components/email/emailVerification";
import AgentEmailVerification from "./components/email/agentEmailVerification";
import ResetPassword from "./auth/ResetPassword";
import NotFoundPage from "./pages/NotFoundPage";
import CruiseDetailPage from "./pages/CruiseDetailPage";
import QuoteDetailPage from "./pages/QuoteDetailPage";
import FAQ from "./pages/faq/Faq";
import ReactGA from "react-ga4";

import { isDev } from "./config";
import CruiseLine from "./pages/cruises/cruiseLine";
import "./App.css";
import "./colors.css";

function App() {
  ReactGA.initialize("G-NJKY607G7Y", {
    debug: true,
    titleCase: false,
  });

  // Determine the root component based on the hostname
  const hostname = window.location.hostname;
  let rootComponent;
  if (hostname === "virginvoyages.cruisecompare.ca") {
    rootComponent = <VVLandingPage />;
  } else if (hostname === "agents.cruisecompare.ca") {
    rootComponent = <AgentLandingPage />;
  } else {
    rootComponent = <NewLandingPage />;
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes>
          <Route path="/" element={rootComponent} />
          <Route path="/register" element={<Register />} />
          <Route path="/agent/register" element={<AgentRegister />} />
          <Route path="/reset_password" element={<ResetPassword />} />
          <Route path="/login" element={<Login />} />
          <Route path="/agent/login" element={<AgentLogin />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/agent/logout" element={<AgentLogout />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/faq/:faq" element={<FAQ />} />
          <Route
            path="/:cruiseLine/:cruiseShip/:cruiseDate"
            element={<CruiseDetailPage />}
          />
          <Route path="/quote_detail" element={<QuoteDetailPage />} />
          <Route path="/quote_detail/:quoteId" element={<QuoteDetailPage />} />
          <Route path="/cruise_detail" element={<CruiseDetailPage />} />
          <Route path="/usage_terms" element={<UsageTerms />} />
          <Route
            path="/confirm-email/:userId/:hash"
            element={<EmailVerification />}
          />
          <Route
            path="/agent-confirm-email/:userId/:hash"
            element={<AgentEmailVerification />}
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <ProtectedRouteAdmin>
                <Navigate to="/admin/metrics" replace />
              </ProtectedRouteAdmin>
            }
          />
          <Route
            path="/admin/*"
            element={
              <ProtectedRouteAdmin>
                <AdminPage />
              </ProtectedRouteAdmin>
            }
          />
          <Route
            path="/agent/*"
            element={
              <ProtectedRouteAgent>
                <AgentPage />
              </ProtectedRouteAgent>
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </PersistGate>
    </Provider>
  );
}

export default App;
