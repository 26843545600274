import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL

function UploadFile() {
  const [file, setFile] = useState(null);
  // const state = useSelector((state) => state.activeUser.data.user);
  const state = useSelector((state) => state.activeAgent.data.agent);

  const { token, _id } = state;

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const onFileUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);

    // Adjust the URL as per your configuration
    await axios.post(`https://${apiBaseUrl}/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-token": token,
        "x-user-id": _id,
      },
    });
  };

  return (
    <div className="py-10">
      <header>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
            Upload file page
          </h1>
        </div>
      </header>
      <main>
        <form onSubmit={onFileUpload}>
          {/* Update accept attribute to ".csv" for accepting CSV files */}
          <input type="file" accept=".csv" onChange={onFileChange} />
          <button type="submit">Upload</button>
        </form>
      </main>
    </div>
  );
}

export default UploadFile;
