import { useState } from "react";
import { PaperClipIcon } from "@heroicons/react/20/solid";

export default function InquiryModal({ inquiry, closeModal }) {
  if (!inquiry) return null;

  console.log("inside InquiryModal, data is:", inquiry);

  return (
    <div
      className="max-w-screen-md overflow-hidden bg-white shadow sm:rounded-lg"
      style={{
        maxHeight: "80vh", // Adjust the value as needed
        height: "auto",
        resize: "vertical",
        overflow: "auto",
      }}>
      {/* <div className="max-w-screen-md overflow-hidden bg-white shadow sm:rounded-lg"> */}
      <div className="px-4 py-6 sm:px-6">
        <h3 className="text-lg font-semibold leading-7 text-gray-900">
          Cruise Inquiry
        </h3>
      </div>
      <div className="mx-4 mb-4">
        {/* Client Information */}
        <div className="border border-gray-200 p-4 rounded-lg bg-gray-50 px-6 sm:px-8">
          <div className="border-b border-gray-200 mb-4">
            <h4 className="text-md font-medium text-gray-900 mb-3">
              Client Information
            </h4>
          </div>
          <div className="sm:grid sm:grid-cols-3 gap-4">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-900">Name</dt>
              <dd className="text-sm leading-6 text-gray-700 mt-1">
                {inquiry.contact.firstName} {inquiry.contact.lastName}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-900">Email</dt>
              <dd className="text-sm leading-6 text-gray-700 mt-1">
                <a
                  href={`mailto:${inquiry.contact.email}`}
                  className="text-blue-600 hover:underline">
                  {inquiry.contact.email}
                </a>
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-900">Phone</dt>
              <dd className="text-sm leading-6 text-gray-700 mt-1">
                {inquiry.contact.phone ? inquiry.contact.phone : "N/A"}
              </dd>
            </div>
          </div>

          <div className=" mt-4">
            <dt className="text-sm font-medium text-gray-900">Message</dt>
            <dd className="flex text-sm leading-6 text-gray-700 sm:col-span-2">
              {inquiry.contact.message}
            </dd>
          </div>
        </div>
        {/* Cruise Information */}
        <div className=" border border-gray-300 p-4 mt-4 rounded-lg bg-gray-50 px-6 sm:px-8 py-4">
          <div className="border-b border-gray-200 mb-4">
            <h4 className="text-md font-medium text-gray-900 mb-3">
              Cruise Information
            </h4>
          </div>
          {inquiry.cruiseData ? (
            <div className="sm:grid sm:grid-cols-1 gap-4 mt-4">
              <div className="sm:col-span-1 overflow-y-auto">
                <dt className="text-sm font-medium text-gray-900">
                  Cruise Data
                </dt>
                <dd className="text-sm leading-6 text-gray-700 mt-1 overflow-y-auto">
                  <pre className="max-h-48">
                    {JSON.stringify(inquiry.cruiseData, null, 2)}
                  </pre>
                </dd>
              </div>
            </div>
          ) : (
            <div className="sm:grid sm:grid-cols-3 gap-4">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-900">
                  Cruise name
                </dt>
                <dd className="text-sm leading-6 text-gray-700 mt-1">
                  {inquiry.name}, {inquiry.vacation_days} vacation days
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-900">
                  Cruise ship
                </dt>
                <dd className="text-sm leading-6 text-gray-700 mt-1">
                  {inquiry.ship_title}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-900">
                  Start info
                </dt>
                <dd className="text-sm leading-6 text-gray-700 mt-1">
                  {inquiry.starts_at}, {inquiry.starts_on}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-900">
                  Group Rate ($)
                </dt>
                <dd className="text-sm leading-6 text-gray-700 mt-1">
                  {inquiry.GroupRate}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-900">
                  Current Group Rate ($)
                </dt>
                <dd className="text-sm leading-6 text-gray-700 mt-1">
                  {inquiry.CurrentGroupRate}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-900">
                  Savings ($ / %)
                </dt>
                <dd className="text-sm leading-6 text-gray-700 mt-1">
                  ${inquiry.CurrentPriceAdv} / {inquiry.CurrentPercentAdv}%
                </dd>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
