import React from "react";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";

const VVPagination = ({ currentPage, totalPages, onPageChange }) => {
  const handleClick = (page) => {
    onPageChange(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    // Function to render ellipsis
    const renderEllipsis = (key) => {
      return (
        <span
          key={key}
          className="border-transparent text-gray-500 px-4 pt-4 text-sm font-medium">
          ...
        </span>
      );
    };

    // Render first page number
    pageNumbers.push(
      <button
        key={1}
        onClick={() => handleClick(1)}
        className={`inline-flex items-center border-t-2 ${
          currentPage === 1
            ? "border-indigo-500 text-indigo-600"
            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
        } px-4 pt-4 text-sm font-medium`}>
        1
      </button>
    );

    // Render ellipsis if required
    if (startPage > 2) {
      pageNumbers.push(renderEllipsis("ellipsisStart"));
    }

    // Render page numbers between startPage and endPage
    for (let i = startPage; i <= endPage; i++) {
      if (i !== 1 && i !== totalPages) {
        // Exclude first and last page numbers
        pageNumbers.push(
          <button
            key={i}
            onClick={() => handleClick(i)}
            className={`inline-flex items-center border-t-2 ${
              currentPage === i
                ? "border-indigo-500 text-indigo-600"
                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
            } px-4 pt-4 text-sm font-medium`}>
            {i}
          </button>
        );
      }
    }

    // Render ellipsis if required
    if (endPage < totalPages - 1) {
      pageNumbers.push(renderEllipsis("ellipsisEnd"));
    }

    // Render last page number
    if (totalPages > 1) {
      pageNumbers.push(
        <button
          key={totalPages}
          onClick={() => handleClick(totalPages)}
          className={`inline-flex items-center border-t-2 ${
            currentPage === totalPages
              ? "border-indigo-500 text-indigo-600"
              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
          } px-4 pt-4 text-sm font-medium`}>
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        <button
          disabled={currentPage === 1}
          onClick={() => handleClick(currentPage - 1)}
          className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
          <ArrowLongLeftIcon
            className={`${
              currentPage === 1 ? "text-gray-400" : "text-gray-500"
            } mr-3 h-5 w-5`}
            aria-hidden="true"
          />
          Previous
        </button>
      </div>
      <div className="hidden md:-mt-px md:flex">{renderPageNumbers()}</div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <button
          disabled={currentPage === totalPages}
          onClick={() => handleClick(currentPage + 1)}
          className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
          Next
          <ArrowLongRightIcon
            className={`${
              currentPage === totalPages ? "text-gray-400" : "text-gray-500"
            } ml-3 h-5 w-5`}
            aria-hidden="true"
          />
        </button>
      </div>
    </nav>
  );
};

export default VVPagination;
