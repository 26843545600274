import { createSlice } from "@reduxjs/toolkit";
// import { fetchAgentQuotes } from "./thunks/fetchAgentQuotes";
import { fetchAgentQuotes } from "../thunks/fetchAgentQuotes";

const initialState = {
  quotes: [],
  status: "idle",
  error: null,
};

const quotesSlice = createSlice({
  name: "quotes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgentQuotes.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchAgentQuotes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.quotes = action.payload;
      })
      .addCase(fetchAgentQuotes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default quotesSlice.reducer;
