import React from "react";
import { useRoutes } from "react-router-dom";
import Metrics from "./admin/Metrics";
import Database from "./admin/Database";
import Clients from "./admin/Clients";
import Agents from "./admin/Agents";
import UploadFile from "./admin/UploadFile";
import AdminHeader from "../components/admin/AdminHeader";
import AdminSidebar from "../components/admin/AdminSidebar";

const AdminPage = () => {
  let routes = useRoutes([
    {
      path: "metrics",
      element: <Metrics />,
    },
    {
      path: "clients",
      element: <Clients />,
    },
    {
      path: "agents",
      element: <Agents />,
    },
    {
      path: "database",
      element: <Database />,
    },
    {
      path: "uploadFile",
      element: <UploadFile />,
    },
  ]);

  return (
    <div className="flex flex-col h-screen">
      <div className="flex flex-col flex-grow">
        <AdminHeader />
        <div className="flex flex-col lg:flex-row">
          <AdminSidebar />
          <div className="flex-grow overflow-auto">{routes}</div>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
