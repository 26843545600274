import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL

const fetchActiveUser = createAsyncThunk("users/fetch", async (_, { getState }) => {
  const state = getState();
  const { token, _id } = state.activeUser.data.user;

  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-api-token": token,
      "x-user-id": _id,
      // Add other headers as needed
    },
  };

  const response = await axios.get(`https://${apiBaseUrl}/users/${_id}`, config);

  return response.data;
});

export { fetchActiveUser };
