import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL

// March 27 - Functional add cruise function, with working ISO date as well
export const addCruise = createAsyncThunk(
  "cruises/addCruise",
  async (cruiseData, { getState }) => {
    const state = getState();
    const { token, _id } = state.activeAgent.data.agent;

    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-agent-api-token": token,
        "x-agent-id": _id,
      },
      body: JSON.stringify(cruiseData),
    };

    const response = await fetch(
      `https://${apiBaseUrl}/cruises/insert`,
      config
    );
    const data = await response.json();
    console.log("Response from addCruise:", data);

    if (!response.ok) {
      throw new Error(data.message || "Failed to add cruise");
    }

    return data.insertedId;
  }
);

// March 27 - Functional update cruise funciton, with working ISO date as well
export const updateCruise = createAsyncThunk(
  "cruises/updateCruise",
  async ({ id, updatedCruise }, { getState }) => {
    const state = getState();
    const { token, _id } = state.activeAgent.data.agent;

    const config = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "x-agent-api-token": token,
        "x-agent-id": _id,
      },
      body: JSON.stringify(updatedCruise),
    };

    const response = await fetch(`https://${apiBaseUrl}/cruises/${id}`, config);
    const data = await response.json();
    console.log("Response from updateCruise:", data);

    if (!response.ok) {
      throw new Error(data.message || "Failed to update cruise");
    }

    return data;
  }
);

// Thunk to delete a cruise by its ID
export const deleteCruise = createAsyncThunk(
  "cruises/deleteCruise",
  async ({ id }, { getState }) => {
    console.log("receieved this id in thunk", id);
    try {
      const state = getState();
      const { token, _id } = state.activeAgent.data.agent;

      const config = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-agent-api-token": token,
          "x-agent-id": _id,
        },
      };

      console.log("before the delete call");
      // Make a DELETE request to your API endpoint with the provided ID and config
      const response = await fetch(
        `https://${apiBaseUrl}/cruises/delete/${id}`,
        config
      );

      console.log("after the delete call");
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete cruise");
      }

      // Return the ID of the deleted cruise
      return id;
    } catch (error) {
      // If an error occurs, throw it to be handled by Redux Toolkit
      throw error;
    }
  }
);
