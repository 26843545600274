import CompleteProfileModal from "../../components/agent/CompleteProfileModal";

// This page is purely for prompting the agent to complete their profile
// It's part of the agent registration flow

const CompleteProfile = () => {
  return (
    <div className="bg-white pb-24 mx-auto max-w-7xl px-6 lg:px-8">
      <div className="flow-root">
        <CompleteProfileModal />
      </div>
    </div>
  );
};

export default CompleteProfile;
