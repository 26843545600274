import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchShipDetails } from "../../store/thunks/widgety/fetchShipDetails";
import {
  Tab,
  RadioGroup,
  Disclosure,
  Dialog,
  Transition,
} from "@headlessui/react";
import { TailSpin } from "react-loader-spinner";
import { Plus, Minus, XOutline } from "heroicons-react";
import NestedTabMenu from "./NestedTabMenu";
import ShipUnavailableModal from "../cruiseList/ShipUnavailableModal";
import shipList from "../cruiseList/shipList";

export default function ShipDetailModal({
  shipName,
  operator,
  isOpen,
  onClose,
}) {
  const dispatch = useDispatch();
  const [openTabs, setOpenTabs] = useState([]);
  const [isShipUnavailableModalOpen, setShipUnavailableModalOpen] =
    useState(false);

  // Define the tab names as constants for better management
  const TAB_FEATURES = "Features";
  const TAB_ACCOMMODATIONS = "Accommodations";
  const TAB_DINING = "Dining";
  const TAB_ENTERTAINMENT = "Entertainment";
  const TAB_GRATUITIES = "Gratuities";

  // This maps the user-friendly names to the ones accepted by the API
  const operatorMapping = {
    Celebrity: "celebrity-cruises",
    "Royal Caribbean": "royal-caribbean-international",
  };

  // Map the user-friendly operator name to the name accepted by the API
  const apiOperatorName = operatorMapping[operator] || operator;

  useEffect(() => {
    if (isOpen) {
      console.log("Searching for", shipName);
      // Pass the correct operator name along with the shipName to the thunk
      dispatch(fetchShipDetails({ shipName, operator: apiOperatorName }))
        .then((result) => {
          console.log("Thunk resolved with: ", result);
        })
        .catch((error) => {
          console.error("Error from Thunk: ", error);
        });
    }

    // Just logging for testing purposes
    console.log("shipData:", shipData);
    console.log("loading:", loading);
    console.log("error:", error);

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose(); // Call the onClose prop when escape key is pressed
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown); // Cleanup the event listener
  }, [isOpen, dispatch, shipName, apiOperatorName]); // Add apiOperatorName to the dependency array

  const { shipData, loading, error } = useSelector((state) => state.ship);

  useEffect(() => {
    if (error) {
      setShipUnavailableModalOpen(true);
    }
  }, [error]);

  const handleTabClick = (tabName) => {
    setOpenTabs((prevOpenTabs) =>
      prevOpenTabs.includes(tabName)
        ? prevOpenTabs.filter((name) => name !== tabName)
        : [...prevOpenTabs, tabName]
    );
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  if (loading) {
    return (
      <div className="flex items-center">
        <TailSpin
          color={"var(--primary-color)"}
          height={35}
          width={35}
          radius={2}
        />
        <p className="font-medium px-4" style={{ color: "whitesmoke" }}>
          Getting ship details...
        </p>
      </div>
    );
  }

  if (error && isShipUnavailableModalOpen) {
    return (
      <ShipUnavailableModal
        isOpen={isShipUnavailableModalOpen}
        onClose={() => {
          setShipUnavailableModalOpen(false);
          onClose();
        }}
      />
    );
  }

  if (!shipData) {
    return <div>No data available!</div>;
  }

  const getCompressedImage500x = (shipName) => {
    const ship = shipList.find((s) => s.ship === shipName);
    return ship ? ship.compressedImageURI500x : null;
  };

  return (
    <div className="bg-white rounded-lg max-h-screen overflow-auto">
      {loading ? (
        <TailSpin />
      ) : error ? (
        <div>Error loading ship details! {error.message}</div>
      ) : (
        <div className="mx-auto max-w-xl px-3 py-3 sm:px-4 lg:px-4">
          {" "}
          {/* Adjusted padding on y-axis */}
          <div className="lg:grid lg:grid-cols-1 lg:items-start lg:gap-x-8">
            {/* Render a single image */}
            <div className="mx-auto mb-4 w-full max-w-xl sm:block lg:max-w-none">
              {" "}
              {/* Adjusted margin on y-axis */}
              {/* <img
                src={shipData.cover_image_href}
                alt="Ship Cover"
                className="h-auto w-full object-contain sm:rounded-lg mx-auto" // Ensure image aspect ratio is maintained and fits within the container
              /> */}
              {/* fetch image from shipList */}
              <img
                src={getCompressedImage500x(shipData.title)}
                alt="Ship Cover"
                className="h-auto w-full object-contain sm:rounded-lg mx-auto"
              />
            </div>

            <div className="mt-6 px-4 sm:mt-16 sm:px-0 lg:mt-0">
              <div className="flex justify-between items-center">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                  {shipData.title}
                </h1>

                <button
                  onClick={onClose}
                  className="p-2"
                  aria-label="Close modal" // Added for accessibility
                >
                  <XOutline className="w-6 h-6" />
                </button>
              </div>
              <div className="mt-3">
                <h2 className="sr-only">Cruise operator</h2>
                <p className="text-xl text-gray-900">
                  {shipData.operator.name}
                </p>
              </div>

              {/* Ship teaser */}
              <div className="mt-6">
                <h3 className="sr-only">Ship teaser</h3>
                <div
                  className="space-y-4 text tracking-normal text-gray-700"
                  dangerouslySetInnerHTML={{ __html: shipData.teaser }}
                />
              </div>

              <section aria-labelledby="details-heading" className="mt-12">
                <h2 id="details-heading" className="sr-only">
                  Additional details
                </h2>

                <div className="divide-y divide-gray-200 border-t">
                  {[
                    TAB_FEATURES,
                    TAB_ACCOMMODATIONS,
                    TAB_DINING,
                    TAB_ENTERTAINMENT,
                    ...(shipData.gratuities !== "<p><br></p>"
                      ? [TAB_GRATUITIES]
                      : []),
                  ].map((tabName) => (
                    <Disclosure
                      as="div"
                      key={tabName}
                      onClick={() => handleTabClick(tabName)}>
                      {({ open }) => (
                        <>
                          <h3>
                            <Disclosure.Button className="group relative flex w-full items-center justify-between py-6 text-left">
                              <span
                                className={classNames(
                                  open ? "text-primary-color" : "text-gray-900",
                                  "text-sm font-medium"
                                )}>
                                {tabName}
                              </span>
                              <span className="ml-6 flex items-center">
                                {open ? (
                                  <Minus
                                    className="block h-6 w-6 text-primary-color group-hover:text-indigo-500"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <Plus
                                    className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel
                            as="div"
                            style={{ maxHeight: "70vh", overflowY: "auto" }}
                            className="prose prose-sm pb-6">
                            {open && (
                              <>
                                {/* Changed this line to decide the content to render based on the tabName instead of activeTab */}
                                {tabName === TAB_FEATURES && (
                                  <div>
                                    <ul className="list-disc pl-5">
                                      {[
                                        {
                                          title: "Launch year:",
                                          key: "launch_year",
                                        },
                                        {
                                          title: "Gross tonnage:",
                                          key: "gross_tonnage",
                                          suffix: " tonnes",
                                        },
                                        {
                                          title: "Length:",
                                          key: "length",
                                          suffix: " m",
                                        },
                                        {
                                          title: "Capacity:",
                                          key: "capacity",
                                          suffix: " guests",
                                        },
                                        {
                                          title: "Deck count:",
                                          key: "deck_count",
                                        },
                                        {
                                          title: "Cabin count:",
                                          key: "cabin_count",
                                        },
                                      ].map(
                                        ({ title, key, suffix = "" }) =>
                                          shipData.ship_facts[key] && (
                                            <li
                                              className="mb-1 text-gray-700"
                                              key={key}>
                                              <span className="font-medium">
                                                {title}
                                              </span>{" "}
                                              {`${shipData.ship_facts[key]}${suffix}`}
                                            </li>
                                          )
                                      )}
                                    </ul>
                                    <br />
                                    {shipData.unique_feature &&
                                      shipData.unique_feature !==
                                        "<p><br></p>" && (
                                        <div>
                                          <h4 className="text-gray-700 font-medium">
                                            Unique to {shipData.title}:
                                          </h4>
                                          <br />
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: shipData.unique_feature,
                                            }}
                                          />
                                        </div>
                                      )}
                                  </div>
                                )}

                                {tabName === TAB_ACCOMMODATIONS && (
                                  <div className="prose prose-sm">
                                    <div
                                      className="mb-4"
                                      dangerouslySetInnerHTML={{
                                        __html: shipData.accommodation.intro,
                                      }}
                                    />
                                    {shipData.accomodation_types && (
                                      <NestedTabMenu
                                        items={shipData.accomodation_types}
                                      />
                                    )}
                                  </div>
                                )}

                                {tabName === TAB_DINING && (
                                  <div className="prose prose-sm">
                                    <div
                                      className="mb-4"
                                      dangerouslySetInnerHTML={{
                                        __html: shipData.dining.intro,
                                      }}
                                    />
                                    {shipData.dining_options && (
                                      <NestedTabMenu
                                        items={shipData.dining_options}
                                        isDining={true}
                                      />
                                    )}
                                  </div>
                                )}

                                {tabName === TAB_ENTERTAINMENT && (
                                  <div className="prose prose-sm">
                                    <div
                                      className="mb-4"
                                      dangerouslySetInnerHTML={{
                                        __html: shipData.entertainment.intro,
                                      }}
                                    />
                                    {shipData.entertainment_types && (
                                      <NestedTabMenu
                                        items={shipData.entertainment_types}
                                      />
                                    )}
                                  </div>
                                )}

                                {tabName === TAB_GRATUITIES &&
                                  shipData.gratuities !== "<p><br></p>" && (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: shipData.gratuities,
                                      }}
                                    />
                                  )}
                              </>
                            )}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </div>
              </section>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
