// This is taken from usersThunk

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL

export const fetchAgentsList = createAsyncThunk(
  "agents/fetchAgentsList", // <-- Adjust the action name here for clarity

  async (_, { getState }) => {
    const state = getState();
    const { token, _id } = state.activeUser.data.user; // Update this according to your user info structure

    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-api-token": token,
        "x-user-id": _id,
      },
    };

    const response = await fetch(`https://${apiBaseUrl}/agents`, config);
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Could not fetch agents"); // Adjusted the error message for clarity
    }

    console.log("in the end of the func");
    return data;
  }
);

// Pass agent email through
export const fetchAgentsClientsList = createAsyncThunk(
  "agents/fetchAgentsClientsList", // <-- Adjust the action name here for clarity

  async (activeAgentEmail, { getState }) => {
    const state = getState();
    const { token, _id } = state.activeAgent.data.agent; // Update this according to your user info structure

    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-api-token": token,
        "x-user-id": _id,
        // Add other headers as needed
      },
    };
    const response = await fetch(
      `https://${apiBaseUrl}/agentclients?agentEmail=${activeAgentEmail}`,
      config
    );
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Could not fetch agents clients"); // Adjusted the error message for clarity
    }

    return data;
  }
);

export const fetchAgentsInquiriesList = createAsyncThunk(
  "agents/fetchAgentsInquiriesList",

  async (activeAgentEmail, { getState }) => {
    const state = getState();
    const { token, _id } = state.activeAgent.data.agent;

    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-api-token": token,
        "x-user-id": _id,
      },
    };

    const response = await fetch(
      `https://${apiBaseUrl}/agentinquiries?agentEmail=${activeAgentEmail}`,
      config
    );

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Could not fetch agents inquiries");
    }

    return data;
  }
);

export const editActiveAgent = createAsyncThunk(
  "agent/edit",
  async ({ userId, userData, getState }) => {
    const state = getState();
    const { token, _id } = state.activeAgent.data.agent; // Update this according to your user info structure

    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-api-token": token,
        "x-user-id": _id,
        // Add other headers as needed
      },
    };
    const response = await axios.put(
      `https://${apiBaseUrl}/agents/${userId}`,
      userData,
      config
    );

    if (!response.data) {
      throw new Error("Failed to edit agent data");
    }

    return response.data;
  }
);

export const updateAgentThunk = createAsyncThunk(
  "agents/updateAgent",
  async (agentUpdateData, { getState }) => {
    // Note the second argument here, destructuring the thunkAPI
    const { userId, updatedAgent } = agentUpdateData;
    const state = getState();
    const { token, _id } = state.activeAgent.data.agent;

    console.log("the id from agentsThunk:", userId);
    console.log("the data from agentsThunk", agentUpdateData);

    try {
      const response = await axios.put(
        `https://${apiBaseUrl}/agents/${userId}`,
        updatedAgent,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-token": token,
            "x-user-id": _id,
            // Add other headers as needed
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error("Network response was not ok");
    }
  }
);

export const updateAgentColorThunk = createAsyncThunk(
  "agents/updateAgentColor",
  async ({ userId, themeColor, getState }) => {
    const state = getState();
    const { token, _id } = state.activeAgent.data.agent; // Update this according to your user info structure

    console.log("inside the thunk, this is the color", themeColor);

    try {
      const response = await axios.post(
        `https://${apiBaseUrl}/api/agent/changeColor`,
        { userId, themeColor },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-token": token,
            "x-user-id": _id,
            // Add other headers as needed
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error("Network response was not ok");
    }
  }
);

export const updateSubdomainThunk = createAsyncThunk(
  "agents/updateSubdomain",
  async ({ userId, updatedAgent, subdomain }, { getState }) => {
    const state = getState();
    const { token, _id } = state.activeAgent.data.agent;

    try {
      // First, update the subdomain in your backend
      const updateResponse = await axios.put(
        `https://${apiBaseUrl}/agents/${userId}/subdomain`,
        updatedAgent,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-token": token,
            "x-user-id": _id,
            // Add other headers as needed
          },
        }
      );

      return updateResponse.data;
    } catch (error) {
      // Check if the error indicates a duplicate subdomain
      if (error.response && error.response.status === 409) {
        throw new Error("Duplicate subdomain found");
      } else {
        // If it's not a duplicate subdomain error, rethrow the original error
        throw error;
      }
    }
  }
);

// Subscription Plan Thunk
export const updateSubscriptionPlanThunk = createAsyncThunk(
  "agents/updateSubscriptionPlan",
  async ({ userId, updatedAgent, subscriptionPlan }, { getState }) => {
    const state = getState();
    const { token, _id } = state.activeAgent.data.agent;

    try {
      // Update the subscription plan in your backend
      const updateResponse = await axios.put(
        `https://${apiBaseUrl}/agents/${userId}/subscription-plan`,
        updatedAgent,
        // { subscription_plan: subscriptionPlan }
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-token": token,
            "x-user-id": _id,
            // Add other headers as needed
          },
        }
      );

      return updateResponse.data;
    } catch (error) {
      throw new Error("Network response was not ok");
    }
  }
);
