import { createSlice } from "@reduxjs/toolkit";
import { editActiveUser } from "../thunks/editActiveUser";

const editActiveUserSlice = createSlice({
  name: "editActiveUserSlice",
  initialState: {
    user: null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(editActiveUser.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(editActiveUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isLoading = false;
    });
    builder.addCase(editActiveUser.rejected, (state, action) => {
      state.error = action.error.message;
      state.isLoading = false;
    });
  },
});

export default editActiveUserSlice.reducer;
