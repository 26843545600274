import { useState, useEffect } from "react";
import axios from "axios";
import Text from "./Text";
import { TopDestinationTexts } from "./DataLists";
import { Carousel } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Button from "./Button";
import "./TopDestination.css";
import CruiseGridTile from "../cruiseList/CruiseGridTile";
import ContactUs from "../contact/ContactUs";

const TopDestination = () => {
  const [specialCruises, setSpecialCruises] = useState([]);
  const [shipImages, setShipImages] = useState({});
  const [refreshData, setRefreshData] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [numVisibleItems, setNumVisibleItems] = useState(1);

  const [showDetails, setShowDetails] = useState(false);
  const [showContactUs, setShowContactUs] = useState(false);
  const [selectedCruiseId, setSelectedCruiseId] = useState(null);
  const [selectedCruiseLine, setSelectedCruiseLine] = useState(null);
  const [selectedCruiseShip, setSelectedCruiseShip] = useState(null);
  const [selectedSailDate, setSelectedSailDate] = useState(null);
  const [selectedNights, setSelectedNights] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCurrentPriceAdv, setSelectedCurrentPriceAdv] = useState(null);
  const [selectedCurrentPercentAdv, setSelectedCurrentPercentAdv] =
    useState(null);
  const [selectedGroupRate, setSelectedGroupRate] = useState(null);

  // Fetch special cruises from backend
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://www.cruisecompare.ca:6010/api/specialCruises"
      );
      setSpecialCruises(response.data);

      // Fetch ship images once specialCruises data is available
      loadShipImages(response.data);
    } catch (error) {
      console.error("Error fetching cruises in component:", error);
    }
  };

  useEffect(() => {
    fetchData();
    // Add an event listener to update isMobile when the window is resized
    window.addEventListener("resize", updateIsMobile);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("resize", updateIsMobile);
    };
  }, [refreshData]);

  useEffect(() => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1201) {
      setNumVisibleItems(3);
    } else if (screenWidth >= 768) {
      setNumVisibleItems(2);
    } else {
      setNumVisibleItems(1);
    }

    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 1201) {
        setNumVisibleItems(3);
      } else if (screenWidth >= 768) {
        setNumVisibleItems(2);
      } else {
        setNumVisibleItems(1);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const updateIsMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const normalizeString = (str) => {
    return str.toLowerCase().replace(/[^a-z0-9]/gi, "");
  };

  const loadShipImages = async (cruises) => {
    const shipImagesData = {};

    // Extract unique cruise lines from the cruises
    const uniqueCruiseLines = [
      ...new Set(cruises.map((cruise) => cruise.CruiseLine)),
    ];

    // Get ships for each unique cruise line
    const cruiseLineShips = {};

    for (const cruiseLine of uniqueCruiseLines) {
      const operator = mapToApiOperator(cruiseLine);
      const ships = await getShipsForOperator(operator);
      cruiseLineShips[operator] = ships;
    }

    // Map ships to their images
    for (const cruise of cruises) {
      const shipName = normalizeString(cruise.Ship);
      const operator = mapToApiOperator(cruise.CruiseLine);

      const ships = cruiseLineShips[operator];
      const ship = ships.find(
        (ship) => normalizeString(ship.title) === shipName
      );

      shipImagesData[cruise.Ship] = ship ? ship.cover_image_href : null;
    }

    setShipImages(shipImagesData);
  };

  // const loadShipImages = async (cruises) => {
  //   const shipImagesData = {};

  //   // Extract unique cruise lines from the cruises
  //   const uniqueCruiseLines = [
  //     ...new Set(cruises.map((cruise) => cruise.CruiseLine)),
  //   ];

  //   // Get ships for each unique cruise line
  //   const cruiseLineShips = {};

  //   for (const cruiseLine of uniqueCruiseLines) {
  //     const operator = mapToApiOperator(cruiseLine);
  //     const ships = await getShipsForOperator(operator);
  //     cruiseLineShips[operator] = ships;
  //   }

  //   // Map ships to their images
  //   for (const cruise of cruises) {
  //     const shipName = cruise.Ship;
  //     const operator = mapToApiOperator(cruise.CruiseLine);

  //     const ships = cruiseLineShips[operator];
  //     const ship = ships.find((ship) => ship.title === shipName);

  //     shipImagesData[shipName] = ship ? ship.cover_image_href : null;
  //   }

  //   setShipImages(shipImagesData);
  // };

  const getShipsForOperator = async (operator) => {
    try {
      const appId = process.env.REACT_APP_CRUISE_API_KEY;
      const appToken = process.env.REACT_APP_CRUISE_TOKEN;

      const page1Url = `https://www.widgety.co.uk/api/ships.json?operator=${operator}&include_cruises=true&page=1&app_id=${appId}&token=${appToken}`;
      const page2Url = `https://www.widgety.co.uk/api/ships.json?operator=${operator}&include_cruises=true&page=2&app_id=${appId}&token=${appToken}`;

      // Make both requests simultaneously using axios.all
      const responses = await axios.all([
        axios.get(page1Url),
        axios.get(page2Url),
      ]);

      // Combine the ships array from both responses
      const shipsPage1 = responses[0].data.ships || [];
      const shipsPage2 = responses[1].data.ships || [];
      const allShips = shipsPage1.concat(shipsPage2);

      return allShips;
    } catch (error) {
      console.error("Error fetching ship data:", error);
      return [];
    }
  };

  const mapToApiOperator = (operator) => {
    const operatorMapping = {
      Celebrity: "celebrity-cruises",
      "Royal Caribbean": "royal-caribbean-international",
    };
    return operatorMapping[operator] || operator;
  };

  const renderCarouselItems = () => {
    const carouselItems = [];
    for (let i = 0; i < specialCruises.length; i += numVisibleItems) {
      const itemsChunk = specialCruises.slice(i, i + numVisibleItems);
      carouselItems.push(
        <Carousel.Item key={i}>
          <div className="d-flex">
            {itemsChunk.map((cruise, index) => (
              <div key={index} className="grid-tile-wrapper">
                <CruiseGridTile
                  cruise={cruise}
                  onInquireClick={(
                    cruiseId,
                    cruiseLine,
                    cruiseShip,
                    sailDate,
                    nights,
                    product,
                    currentPriceAdv,
                    currentPercentAdv,
                    groupRate
                  ) => {
                    setShowContactUs(true);
                    setShowDetails(false);
                    setSelectedCruiseId(cruiseId);
                    setSelectedCruiseLine(cruiseLine);
                    setSelectedCruiseShip(cruiseShip);
                    setSelectedSailDate(sailDate);
                    setSelectedNights(nights);
                    setSelectedProduct(product);
                    setSelectedCurrentPriceAdv(currentPriceAdv);
                    setSelectedCurrentPercentAdv(currentPercentAdv);
                    setSelectedGroupRate(groupRate);
                  }}
                />
              </div>
            ))}
          </div>
        </Carousel.Item>
      );
    }
    return carouselItems;
  };

  return (
    <div className="w-full h-auto flex flex-col items-center justify-center relative lg:px-24 md:px-20 px-6 my-20">
      <div className="w-full mt-12 flex justify-between items-center md:px-6 px-3">
        <div className="flex-grow">
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {TopDestinationTexts.secondText}
          </p>
        </div>
        <Button
          className="outline-none border-none lg:px-3 px-3 py-3 bg-color2 text-white font-semibold rounded-lg transform transition-all duration-300 hover:bg-purple-600 hover:scale-105 hover:shadow-lg"
          onClick={() => fetchData()}>
          Generate more
        </Button>
      </div>
      <br />
      <div className="flex justify-start">
        <Text
          as="h4"
          className="lg:text-xl md:text-lg text-lg text-indigo-700 font-semibold">
          {TopDestinationTexts.thirdText}
        </Text>
      </div>

      <br />

      <Carousel interval={7500} indicators={true} controls={true} touch={true}>
        {renderCarouselItems()}
      </Carousel>

      {showContactUs && (
        <ContactUs
          cruiseId={selectedCruiseId}
          cruiseLine={selectedCruiseLine}
          cruiseShip={selectedCruiseShip}
          sailDate={selectedSailDate}
          nights={selectedNights}
          product={selectedProduct}
          currentPriceAdv={selectedCurrentPriceAdv}
          currentPercentAdv={selectedCurrentPercentAdv}
          groupRate={selectedGroupRate}
          onClose={() => setShowContactUs(false)}
        />
      )}
    </div>
  );
};

export default TopDestination;
