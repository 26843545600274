import React from "react";

export const NavLinks = [
  {
    name: "Destination",
    url: "/",
  },
  {
    name: "Hotels",
    url: "/",
  },
  {
    name: "Flights",
    url: "/",
  },
  {
    name: "Bookings",
    url: "/",
  },
  {
    name: "Destination",
    url: "/",
  },
  {
    name: "Hotels",
    url: "/",
  },
  {
    name: "Flights",
    url: "/",
  },
  {
    name: "Bookings",
    url: "/",
  },
];

export const NavButtons = [
  {
    name: "Login",
    url: "login/",
  },
  {
    name: "Signup",
    url: "/register",
  },
];

export const HeroTexts = {
  firstText: "Find hidden fares with Virgin Voyages",
  secondText:
    "Sail beyond your expectations with exclusive cruise deals, only at CruiseCompare.ca.",
  thirdText:
    "Built Wicket longer admire do barton vanity itself do in it. Preferred to sportsmen it engrossed listening. Park gate sell they west hard for the.",
  firstButton: "Sign Up Now For Free",
  secondButton: "Play Demo",
};

// Dive deeper into cruise mkt
export const PlatformOverview = {
  firstText: "Explore hidden treasure in the cruise market",
  secondText: "CruiseCompare.ca was built by travel agents, for travel agents.",
  thirdText:
    "With our innovative software, you can find unmatched value with the ultimate tool for uncovering hidden cruise deals.",
  fourthText:
    "Apply filters, sort by values, and view all cruise deals with our simple and intuitive interface.",
};

// Your ticket to exclusive savings
export const BookingStepsTexts = {
  firstText: "Easy and Fast",
  secondText: "Your Ticket to Exclusive Savings!",
  thirdText: "Follow our 3-step process to unlock the full potential",
  fourthText: "Don't miss out on our exclusive offers - start saving today",
  listOfSteps: [
    {
      text: "Join the CruiseCompare.ca community today by signing up for free.\n\nGet exclusive access to our handpicked selection of hidden cruise fares and embark on unforgettable voyages at unbeatable prices.",
    },
    {
      text: "Discover our Top 10 cruise deals curated just for you. These deals are handpicked gems that offer incredible value and remarkable experiences. \n\nExplore, compare, and choose the perfect cruise to suit your desires.",
    },
    {
      text: "Utilize our advanced filtering and sorting options to access over 20,000 hidden cruise fares.\n\n View all details about ships and cruises, and use our Inquire feature to get in touch with us.",
    },
  ],
  cardOne: {
    name: "Cruise To Norway",
    date: "July 5th, 2024  |  on Jewel Of The Seas",
    people: "10 Cabins Left at 72% Off",
  },
  cardTwo: {
    status: "Ongoing",
    destination: "Trip to Rome",
    completion: "40% Completed",
  },
};

// Why choose CruiseCompare.ca?
export const ServiceTexts = {
  firstText: "Why Choose CruiseCompare?",
  secondText:
    "Our mission is simple - to uncover the best hidden cruise fares for you. Imagine setting sail on your dream cruise, all while enjoying massive savings on your voyage. Only our experienced team at CruiseCompare.ca can give this to you.",
  cards: [
    {
      firstText: "Exclusive Access",
      secondText:
        "Gain entry to a treasure trove of hidden cruise fares not found elsewhere",
    },
    {
      firstText: "Unbeatable Savings",
      secondText:
        "Save up to 76% on luxury cruises, as well as free value added perks such as Onboard Credit",
    },
    {
      firstText: "Transparent Pricing",
      secondText:
        "No hidden fees or surprises - we believe in upfront and honest pricing",
    },
    {
      firstText: "Expert Support",
      secondText:
        "Our partnerships with dedicated travel agents ensure someone is there to assist you every step of the way",
    },
  ],
};

// Hot deals
export const TopDestinationTexts = {
  firstText: "Top selling",
  secondText: "Hot Deals",
  thirdText: "Take a sneak peek at some deals",
  cards: [
    {
      ship: "Harmony Of The Seas",
      price: "76% off",
      duration: "7 Night Caribbean",
    },
    {
      ship: "Voyageur of the Seas",
      price: "84% off",
      duration: "4 Night Short Caribbean",
    },
    {
      ship: "Icon Of The Seas",
      price: "71% off",
      duration: "7 Night Caribbean",
    },
    {
      ship: "Odyssy Of The Seas",
      price: "70% off",
      duration: "12 Night Caribbean",
    },
    {
      ship: "Adventure Of The Seas",
      price: "68% off",
      duration: "8 Night Caribbean",
    },
    {
      ship: "Jewel Of The Seas",
      price: "68% off",
      duration: "12 Night Europe",
    },
  ],
};

export const FeatureList = {
  firstText: "Everything you need",
  secondText: "All-in-one platform",
  thirdText:
    "Discover unbeatable deals and a seamless booking experience with CruiseCompare.ca, your gateway to the perfect cruise vacation.",
  fourthText:
    "With our clear interface and advanced filters, effortlessly navigate through a wide range of cruise options to find your dream getaway.",
  fifthText:
    "Enjoy peace of mind with dedicated support always ready to assist you.",
  features: [
    {
      name: "Exclusive Access",
      description:
        "Gain entry to a treasure trove of hidden cruise fares not found elsewhere. We offer unbeatable deals at competitive price points.",
    },
    {
      name: "Unbeatable Savings",
      description:
        "Save up to 76% on luxury cruises while unlocking exclusive perks such as Free Wifi, Open Bar, and Onboard Credit.",
    },
    {
      name: "Transparent Pricing",
      description:
        "No hidden fees or surprises - we believe in upfront and honest pricing.",
    },
    {
      name: "Dedicated Customer Support",
      description:
        "Our partnerships with dedicated travel agents ensure someone is there to assist you every step of the way. Whether you have general questions or are interested in booking, our team will always be available to assist.",
    },
    // {
    //   name: "Easy Interface",
    //   description:
    //     "Our platform is designed for ease of use, ensuring that you can quickly identify and select the best option without getting lost in the details.",
    // },

    // {
    //   name: "Dedicated Customer Support",
    //   description:
    //     "You're never alone on this journey. Our dedicated agent support ensures that you have assistance whenever you need, helping you make the most out of our platform.",
    // },
  ],
};

export const TestimonialTexts = {
  firstText: "TESTIMONIALS",
  secondText: "What people say about Us.",
  feedBacks: [
    {
      text: "You did a fabulous job. There were no hitches. Thank you again for all your work planning this trip.",
      person: "Mike Taylor",
      location: "Lahore, Pakistan",
    },
    {
      text: "Jadoo was great with the entire process from planning to updates during the trip. We had 11 people and everything was perfectly executed. We appreciate all of her hard work. It was truly the trip of a lifetime. Thank you!",
      person: "Gina",
      location: "Owerri, Nigeria",
    },
    {
      text: "Booking through you was very easy and made our lives so much easier. I have nothing bad to say! Thank you for giving us tips and guidance before we left on what to bring and such, that was very helpful!",
      person: "Emma",
      location: "Vancouver, Canada",
    },
    {
      text: "Thank you for your recommendation and putting the trip together. The resort was absolutely beautiful. The infinity pools, the palm trees in the main pool, the infinity pool in our preferred area overlooking the gulf and the golf course were exceptional....",
      person: "Loveth",
      location: "Lagos, Nigeria",
    },
  ],
};

export const NewsletterTexts = {
  firstText:
    "Subscribe to get information, latest news and other interesting offers about Jadoo.",
  placeholderText: "Your email",
  buttonText: "Subscribe",
};

export const GetStarted = {
  firstText: "Embark on an unforgettable voyage with CruiseCompare.ca",
  secondText:
    "Unlock a treasure trove of exclusive cruise deals and start saving now.",
  thirdText:
    "Seize the moment to revolutionize your cruising experience. Come aboard and set sail towards your dream getaway.",
};

export const FooterTexts = {
  underLogoText: "Book your trip in minutes, get full control for much longer.",
  quickLinks: {
    caption: "Quick Links",
    links: [
      {
        name: "Destination",
        url: "/",
      },
      {
        name: "Hotels",
        url: "/",
      },
      {
        name: "Flights",
        url: "/",
      },
      {
        name: "Bookings",
        url: "/",
      },
      {
        name: "Login",
        url: "/login",
      },
      {
        name: "Signup",
        url: "/register",
      },
    ],
  },
  contacts: {
    caption: "Contacts",
    links: [
      {
        name: "FAQs",
        url: "/",
      },
      {
        name: "Help",
        url: "/",
      },
      {
        name: "Policies",
        url: "/",
      },
      {
        name: "Terms & Conditions",
        url: "/",
      },
    ],
  },
  more: {
    caption: "More",
    links: [
      {
        name: "Career",
        url: "/",
      },
      {
        name: "Airlines",
        url: "/",
      },
      {
        name: "Airline Fees",
        url: "/",
      },
      {
        name: "Low Fare Tips",
        url: "/",
      },
    ],
  },
};
