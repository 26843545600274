import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiBaseUrl } from '../../config'; // Import the dynamic API base URL

// Fetch all FAQs
const fetchFaqs = createAsyncThunk('faqs/fetchAll', async () => {
  const response = await axios.get(`https://${apiBaseUrl}/faqs`);
  return response.data; // Return the list of FAQs
});

// Fetch a single FAQ by slug
const fetchFaqBySlug = createAsyncThunk('faqs/fetchBySlug', async (slug) => {
  const response = await axios.get(`https://${apiBaseUrl}/faqs/${slug}`);
  return response.data; // Return the specific FAQ
});

// Add a new FAQ
const addFaq = createAsyncThunk('faqs/add', async (faqData) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      // Additional headers can be added here if needed
    },
  };

  const response = await axios.post(
    `https://${apiBaseUrl}/faqs`,
    faqData,
    config
  );
  return response.data; // Return the newly added FAQ data
});

// Update an existing FAQ by slug
const updateFaq = createAsyncThunk('faqs/update', async ({ slug, updateData }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      // Additional headers can be added here if needed
    },
  };

  const response = await axios.patch(
    `https://${apiBaseUrl}/faqs/${slug}`,
    updateData,
    config
  );
  return response.data; // Return the updated FAQ data
});

// Delete an FAQ by slug
const deleteFaq = createAsyncThunk('faqs/delete', async (slug) => {
  const response = await axios.delete(`https://${apiBaseUrl}/faqs/${slug}`);
  return response.data; // Return some indication of deletion, if needed
});

export { fetchFaqs, fetchFaqBySlug, addFaq, updateFaq, deleteFaq };
