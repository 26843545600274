import { Disclosure } from "@headlessui/react";
import { ChevronDownOutline } from "heroicons-react";
// import NestedTabMenu from "./NestedTabMenu.css";
import "./NestedTabMenu.css";

const NestedTabMenu = ({ items, isDining = false }) => {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div>
      {items.map((item) => (
        <Disclosure as="div" className="my-2" key={item.name}>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex justify-between items-center w-full text-left py-2 px-4 
                        bg-gray-200 hover:bg-gray-300 focus:outline-none 
                        ${
                          open ? "rounded-tl-lg rounded-tr-lg" : "rounded-lg"
                        }`}>
                <span className="font-medium">
                  {item.name}
                  {isDining && (
                    <>
                      <span className="block text-sm">
                        Experience: {capitalizeFirstLetter(item.experience)}
                      </span>
                      <span className="block text-sm">
                        Food: {capitalizeFirstLetter(item.food)}
                      </span>
                    </>
                  )}
                </span>
                <ChevronDownOutline
                  className={`${open ? "transform rotate-180" : ""} w-5 h-5`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 py-2 text-gray-500 bg-gray-100 rounded-bl-lg rounded-br-lg">
                <div className="custom-list-style">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.description,
                    }}
                  />
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  );
};

export default NestedTabMenu;
