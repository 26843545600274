import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Dialog, Transition, Menu } from "@headlessui/react";
import {
  Bars3Icon,
  HomeIcon,
  UsersIcon,
  ArrowUpOnSquareIcon,
  PlusCircleIcon,
  XMarkIcon,
  CircleStackIcon,
  ChartBarSquareIcon,
  QueueListIcon,
  RectangleStackIcon,
  RectangleGroupIcon,
  Cog6ToothIcon,
  TicketIcon,
  DocumentPlusIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";
import ReturnHomeModal from "./ReturnHomeModal";

// To navigate to the subpages within Agent Panel
const navigation = [
  // {
  //   name: "Go Home",
  //   href: "/dashboard",
  //   icon: HomeIcon,
  //   current: false,
  //   uniqueID: "dashboard", // Added uniqueID here
  // },
  {
    name: "Metrics",
    href: "/agent/metrics",
    icon: ChartBarSquareIcon,
    current: false,
  },

  { name: "Clients", href: "/agent/clients", icon: UsersIcon, current: false },
  {
    name: "Inquiries",
    href: "/agent/inquiries",
    icon: RectangleGroupIcon,
    current: false,
  },
  // {
  //   name: "Database",
  //   href: "/agent/database",
  //   icon: CircleStackIcon,
  //   current: false,
  // },
  {
    name: "Your Cruises",
    href: "/agent/your-cruises",
    icon: CircleStackIcon,
    current: false,
  },
  {
    name: "Add Cruise",
    href: "/agent/add-cruise",
    icon: PlusCircleIcon,
    current: false,
  },
  {
    name: "Your Quotes",
    href: "/agent/your-quotes",
    icon: DocumentTextIcon,
    current: false,
  },
  {
    name: "Add Quote",
    href: "/agent/add-quote",
    icon: DocumentPlusIcon,
    current: false,
  },
  // {
  //   name: "Upload file",
  //   href: "/agent/uploadFile",
  //   icon: ArrowUpOnSquareIcon,
  //   current: false,
  // },
  {
    name: "Settings",
    href: "/agent/settings",
    icon: Cog6ToothIcon,
    current: false,
  },
  {
    name: "Subscription",
    href: "/agent/subscriptions",
    icon: TicketIcon,
    current: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const AgentSidebar = () => {
  const activeAgent = useSelector((state) => state.activeAgent.data.agent);
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <ReturnHomeModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={() => {
          navigate("/dashboard");
          setShowModal(false);
        }}
      />

      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full">
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                    <div className="flex h-16 shrink-0 items-center">
                      {/* <img
                        className="h-8 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                        alt="Your Company"
                      /> */}
                    </div>

                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    location.pathname === item.href
                                      ? "bg-gray-50 text-indigo-600"
                                      : item.uniqueID === "dashboard" // Here is where we check for the uniqueID
                                      ? "bg-gray-900 text-white"
                                      : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}>
                                  <item.icon
                                    className={classNames(
                                      location.pathname === item.href
                                        ? "text-indigo-600"
                                        : "text-gray-400 group-hover:text-indigo-600",
                                      "h-6 w-6 shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                        {/* <li>
                          <div className="text-xs font-semibold leading-6 text-gray-400">
                            Your teams
                          </div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            {teams.map((team) => (
                              <li key={team.name}>
                                <a
                                  href={team.href}
                                  className={classNames(
                                    team.current
                                      ? "bg-gray-50 text-indigo-600"
                                      : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}>
                                  <span
                                    className={classNames(
                                      team.current
                                        ? "text-indigo-600 border-indigo-600"
                                        : "text-gray-400 border-gray-200 group-hover:border-indigo-600 group-hover:text-indigo-600",
                                      "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white"
                                    )}>
                                    {team.initial}
                                  </span>
                                  <span className="truncate">{team.name}</span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li> */}
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-50 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
            <div className="flex h-16 shrink-0 items-center -mx-6 py-3 px-6">
              <div className="flex items-center gap-x-4">
                <span className="text-xl font-semibold leading-6 text-gray-900">
                  Agent Panel
                </span>
              </div>
            </div>

            <nav className="flex flex-1 flex-col">
              <ul
                role="list"
                className="flex flex-1 flex-col gap-y-7 border: thick solid #0000ff">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        {item.uniqueID === "dashboard" ? (
                          // If the item is the dashboard, show the special description
                          <div>
                            <button
                              onClick={() => setShowModal(true)} // Open the modal
                              className={classNames(
                                location.pathname === item.href
                                  ? "bg-gray-50 text-indigo-600 w-full h-full"
                                  : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50 w-full h-full",
                                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                              )}>
                              <item.icon
                                className={classNames(
                                  location.pathname === item.href
                                    ? "text-indigo-600"
                                    : "text-gray-400 group-hover:text-indigo-600",
                                  "h-6 w-6 shrink-0"
                                )}
                                aria-hidden="true"
                              />
                              {item.name}
                            </button>
                          </div>
                        ) : (
                          // If the item isn't the dashboard, show it as normal
                          <Link
                            to={item.href}
                            className={classNames(
                              location.pathname === item.href
                                ? "bg-gray-50 text-indigo-600"
                                : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                              "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                            )}>
                            <item.icon
                              className={classNames(
                                location.pathname === item.href
                                  ? "text-indigo-600"
                                  : "text-gray-400 group-hover:text-indigo-600",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </li>
                {/* Bottom menu */}
                <li className="-mx-6 mt-auto">
                  <Menu>
                    <div>
                      <a className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50">
                        <Menu.Button className="flex max-w-xs items-center rounded-full bg-color2 text-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                          <span className="sr-only">Open user menu</span>
                          <div className="h-8 w-8 rounded-full flex items-center justify-center">
                            {activeAgent?.first_name[0].toUpperCase()}
                            {activeAgent?.last_name[0].toUpperCase()}
                          </div>
                        </Menu.Button>
                        <span aria-hidden="true">
                          {activeAgent?.first_name} {activeAgent?.last_name}
                        </span>
                      </a>
                    </div>
                  </Menu>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        {/* the lil horizontal header */}
        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">
            Agent Panel
          </div>
          {/* tim cook photo lol */}
          {/* <a href="#">
            <span className="sr-only">Your profile</span>
            <img
              className="h-8 w-8 rounded-full bg-gray-50"
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            />
          </a> */}
        </div>

        <main className="py-10 lg:pl-44">
          <div className="px-4 sm:px-6 lg:px-8">{/* Your content */}</div>
        </main>
      </div>
    </>
  );
};

export default AgentSidebar;
