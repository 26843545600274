import { useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid"; // Import XIcon for the clear button
import { Combobox } from "@headlessui/react";
import PortList from "../cruiseList/portList"; // Import PortList from the specified path

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PortDropdown({ selectedPorts, setSelectedPorts }) {
  const [query, setQuery] = useState("");

  const filteredPorts =
    query === ""
      ? PortList
      : PortList.filter((port) => {
          const searchTerm = query.toLowerCase();
          return (
            port.name.toLowerCase().includes(searchTerm) ||
            port.unlocode.toLowerCase().includes(searchTerm) ||
            port.country.toLowerCase().includes(searchTerm)
          );
        });

  const handleSelection = (port) => {
    const index = selectedPorts.findIndex((p) => p.code === port.code);
    if (index === -1) {
      setSelectedPorts([...selectedPorts, port]);
    } else {
      const updatedSelectedPorts = [...selectedPorts];
      updatedSelectedPorts.splice(index, 1);
      setSelectedPorts(updatedSelectedPorts);
    }
  };

  return (
    <Combobox
      as="div"
      value={selectedPorts}
      onChange={setSelectedPorts}
      multiple
      className="w-[300px]">
      <Combobox.Label className="block text-base font-semibold leading-6 text-gray-900">
        Port<span className="ml-2 text-xs text-gray-500">Select many</span>
      </Combobox.Label>
      <div className="relative mt-2 mb-4">
        <Combobox.Input
          className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          onChange={(event) => setQuery(event.target.value)}
          value={query}
          placeholder="Search port..."
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredPorts.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredPorts.map((port) => (
              <Combobox.Option
                key={port.unlocode}
                value={port}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
                onSelect={() => handleSelection(port)}>
                {({ active, selected }) => (
                  <>
                    <div className="flex">
                      <span
                        className={classNames(
                          "truncate",
                          selected && "font-semibold"
                        )}>
                        {port.unlocode} - {port.name}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600"
                        )}>
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
