import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  InformationCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
  XMarkIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import AddCruiseModal from "../../components/agent/AddCruiseModal";
import { addCruise } from "../../store/thunks/cruisesThunk";
import NoSubscriptionWarning from "../../components/agent/NoSubscriptionWarning";
import shipList from "../../components/cruiseList/shipList";
import { fetchCruiseDetails } from "../../store/thunks/widgety/fetchCruiseDetails";

export default function AddCruise() {
  const dispatch = useDispatch();
  const activeAgent = useSelector((state) => state.activeAgent.data.agent);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [isFormComplete, setIsFormComplete] = useState(true);
  const [showIncompleteForm, setShowIncompleteForm] = useState(false);
  const [incompleteFields, setIncompleteFields] = useState([]);

  const [cruiseOptions, setCruiseOptions] = useState([]); // State to hold fetched cruise options

  const [isCategoryClassModalOpen, setIsCateogryClassModalOpen] =
    useState(false);
  const [isCategoryModalOpen, setIsCateogryModalOpen] = useState(false);
  const [isCategoryNameModalOpen, setIsCateogryNameModalOpen] = useState(false);
  const [isGroupRateModalOpen, setIsGroupRateModalOpen] = useState(false);
  const [isMarketRateModalOpen, setIsMarketRateModalOpen] = useState(false);
  const [
    isCurrentPriceAdvantageModalOpen,
    setIsCurrentPriceAdvantageModalOpen,
  ] = useState(false);
  const [
    isCurrentPercentAdvantageModalOpen,
    setIsCurrentPercentAdvantageModalOpen,
  ] = useState(false);
  const [isPerksModalOpen, setIsPerksModalOpen] = useState(false);

  // useEffect to determine agent subscription status
  useEffect(() => {
    if (activeAgent.subscription == null || activeAgent.subscription === "") {
      setIsSubscribed(false);
      console.log("no sub");
    } else {
      setIsSubscribed(true);
    }
  }, [activeAgent]);

  const initialFormData = {
    CruiseLine: "",
    GroupName: "",
    GroupId: "",
    Ship: "",
    SailDate: "",
    Product: "",
    Nights: "",
    Currency: "",
    CategoryClass: "",
    Category: "",
    CategoryName: "",
    RoomsAvailable: "",
    GroupRate: "",
    MarketRate: "",
    CurrentPriceAdv: "",
    CurrentPercentAdv: "",
    Region: "",
    name: "",
    Perks: "",
    sellingAgent: activeAgent.email,
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "SailDate") {
      // Append the desired time component to the selected date
      const dateWithTime = `${value}T04:00:00.000Z`;
      console.log(dateWithTime);
      setFormData({
        ...formData,
        [name]: dateWithTime,
      });
    } else if (
      name === "Nights" ||
      name === "RoomsAvailable" ||
      name === "GroupRate" ||
      name === "MarketRate" ||
      name === "CurrentPriceAdv" ||
      name === "CurrentPercentAdv"
    ) {
      // Check if the value is empty or not a number and update state accordingly
      const numericValue =
        value === ""
          ? ""
          : !isNaN(parseFloat(value))
          ? parseFloat(value)
          : formData[name];
      setFormData({
        ...formData,
        [name]: numericValue,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // This useEffect will now also watch for changes to formData.Ship and formData.SailDate to fetch cruise details
  useEffect(() => {
    if (formData.Ship && formData.SailDate && isSubscribed) {
      // Format SailDate as needed for your API call
      const formattedSailDate = formData.SailDate.split("T")[0];

      // Dummy endDate calculation (adjust as needed)
      const endDate = new Date(formattedSailDate);
      endDate.setDate(endDate.getDate() + 1); // Just a placeholder for whatever logic you need
      const formattedEndDate = endDate.toISOString().split("T")[0];

      dispatch(
        fetchCruiseDetails({
          ship_name: formData.Ship,
          start_date_range_beginning: formattedSailDate,
          start_date_range_end: formattedEndDate,
        })
      )
        .unwrap()
        .then((response) => {
          if (response.cruises && response.cruises.length > 0) {
            // Update cruiseOptions with the first cruise's name
            setCruiseOptions([{ name: response.cruises[0].name }]);

            // Automatically set the Nights field based on the first cruise's cruise_nights value
            setFormData((prevFormData) => ({
              ...prevFormData,
              Nights: response.cruises[0].cruise_nights,
            }));
          }
        })
        .catch((error) => {
          console.error("Error fetching cruise details:", error);
          setCruiseOptions([]); // Reset or handle as needed
        });
    }
  }, [dispatch, formData.Ship, formData.SailDate, isSubscribed]); // Add dependencies as needed

  const handleSubmit = (e) => {
    e.preventDefault();

    const newIncompleteFields = []; // Array to store incomplete fields

    // Check if any field is empty
    if (formData.CruiseLine === "") newIncompleteFields.push("Cruise Line");
    if (formData.GroupName === "") newIncompleteFields.push("Group Name");
    if (formData.GroupId === "") newIncompleteFields.push("Group Id");
    if (formData.Ship === "") newIncompleteFields.push("Ship");
    if (formData.SailDate === "") newIncompleteFields.push("Sail Date");
    if (formData.Product === "") newIncompleteFields.push("Product");
    if (formData.Nights === "") newIncompleteFields.push("Nights");
    if (formData.CategoryClass === "")
      newIncompleteFields.push("CategoryClass");
    if (formData.Category === "") newIncompleteFields.push("Category");
    if (formData.CategoryName === "") newIncompleteFields.push("Category Name");
    if (formData.GroupRate === "") newIncompleteFields.push("Group Rate");
    if (formData.CurrentGroupRate === "")
      newIncompleteFields.push("Market Rate");
    if (formData.CurrentPriceAdv === "")
      newIncompleteFields.push("Current Price Advantage");
    if (formData.CurrentPercentAdv === "")
      newIncompleteFields.push("Current Percent Advantage");
    if (formData.Currency === "") newIncompleteFields.push("Currency");
    if (formData.RoomsAvailable === "")
      newIncompleteFields.push("Rooms Available");
    if (formData.Region === "") newIncompleteFields.push("Region");
    if (formData.name === "") newIncompleteFields.push("Cruise Name");

    if (newIncompleteFields.length > 0) {
      setIsFormComplete(false);
      setShowIncompleteForm(true);
      setIncompleteFields(newIncompleteFields); // Update incompleteFields state
      setTimeout(() => setShowIncompleteForm(false), 3000);
      return;
    }

    // Convert SailDate to a Date object before submitting
    const sailDate = new Date(formData.SailDate);

    // Update formData with the converted SailDate
    const formDataWithDate = {
      ...formData,
      SailDate: sailDate,
    };

    console.log("form data with modified date", formDataWithDate);
    console.log("modified saildate from handlesubmit", sailDate);

    dispatch(addCruise(formDataWithDate))
      .unwrap()
      .then((response) => {
        console.log("Cruise added successfully. Response:", response);
        setFormData(initialFormData);
        setFeedback(<SuccessFeedback onClose={() => setFeedback(null)} />);
        setTimeout(() => setFeedback(null), 3000);
        setIsFormComplete(true); // Set form complete state to true after successful submission
      })
      .catch((error) => {
        console.error("Failed to add cruise:", error);
        setFeedback(
          <ErrorFeedback
            onClose={() => setFeedback(null)}
            incompleteFields={newIncompleteFields}
          />
        );
        setTimeout(() => setFeedback(null), 3000);
      });
  };

  // Reset the showIncompleteForm state after 4 seconds
  useEffect(() => {
    const timer = setTimeout(() => setShowIncompleteForm(false), 4000);
    return () => clearTimeout(timer);
  }, [showIncompleteForm]);

  const handleCancel = () => {
    setFormData(initialFormData);
  };

  // Derive unique cruise lines
  const uniqueCruiseLines = [
    ...new Set(shipList.map((ship) => ship.cruiseLine)),
  ];

  function SuccessFeedback({ onClose }) {
    return (
      <div className="rounded-md bg-green-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-green-800">
              Successfully uploaded
            </p>
            <div className="mt-2 text-sm text-green-700">
              <p>Your clients will now be able to see your cruise deal.</p>
            </div>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                onClick={onClose}
                className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50">
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function ErrorFeedback({ onClose, incompleteFields }) {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">
              There was an error with your submission
            </h3>
            <div className="mt-2 text-sm text-red-700">
              <p>Please check your inputs and try again.</p>
              {incompleteFields.length > 0 && (
                <div>
                  <p>Fields with missing data:</p>
                  <ul>
                    {incompleteFields.map((field, index) => (
                      <li key={index}>{field}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                onClick={onClose}
                className="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50">
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const openCategoryClassModal = () => {
    setIsCateogryClassModalOpen(true);
  };

  const closeCategoryClassModal = () => {
    setIsCateogryClassModalOpen(false);
  };

  const openCategoryModal = () => {
    setIsCateogryModalOpen(true);
  };

  const closeCategoryModal = () => {
    setIsCateogryModalOpen(false);
  };

  const openCategoryNameModal = () => {
    setIsCateogryNameModalOpen(true);
  };

  const closeCategoryNameModal = () => {
    setIsCateogryNameModalOpen(false);
  };

  const openGroupRateModal = () => {
    setIsGroupRateModalOpen(true);
  };

  const closeGroupRateModal = () => {
    setIsGroupRateModalOpen(false);
  };

  const openMarketRateModal = () => {
    setIsMarketRateModalOpen(true);
  };

  const closeMarketRateModal = () => {
    setIsMarketRateModalOpen(false);
  };

  const openCurrentPriceAdvantageModal = () => {
    setIsCurrentPriceAdvantageModalOpen(true);
  };

  const closeCurrentPriceAdvantageModal = () => {
    setIsCurrentPriceAdvantageModalOpen(false);
  };

  const openCurrentPercentAdvantageModal = () => {
    setIsCurrentPercentAdvantageModalOpen(true);
  };

  const closeCurrentPercentAdvantageModal = () => {
    setIsCurrentPercentAdvantageModalOpen(false);
  };

  const openPerksModal = () => {
    setIsPerksModalOpen(true);
  };

  const closePerksModal = () => {
    setIsPerksModalOpen(false);
  };

  return (
    <div className="pt-4 pb-4 ml-6 mr-6 sm:px-6 lg:pr-10 lg:pl-0">
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="space-y-6">
          {/* Top text */}
          <div className="border-b border-gray-900/10 pb-8">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Add Cruise
            </h2>
            <p className="mt-2 text-sm leading-6 text-gray-600">
              Use this form to add a cruise that only your clients will be able
              to see, on top of the pre-existing Cruise List.
            </p>
            <p className="mt-2 text-sm leading-6 text-gray-600">
              If you wish to add many cruises, consider contacting us directly
              at <i>admin@cruisecompare.ca.</i>
            </p>
          </div>

          {/* Subscription warning */}
          {isSubscribed ? null : <NoSubscriptionWarning />}

          {/* Entire form */}
          <div className={`relative ${!isSubscribed && "pointer-events-none"}`}>
            {!isSubscribed && (
              <div className="absolute inset-0 bg-opacity-50 backdrop-blur-sm z-10"></div>
            )}
            <div className=" border-gray-900/10 pb-12">
              <div className="mt-10 grid grid-cols-1 sm:grid-cols-9 gap-x-6 gap-y-8">
                <>
                  {/* Group Id */}
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="group-id"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      Group ID
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="GroupId"
                        id="group-id"
                        value={formData.GroupId}
                        onChange={handleInputChange}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  {/* Group Name */}
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="group-name"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      Group Name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="GroupName"
                        id="group-name"
                        value={formData.GroupName}
                        onChange={handleInputChange}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  {/* Cruise Line */}
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="cruise-line"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      Cruise Line
                    </label>
                    <div className="mt-2">
                      <select
                        name="CruiseLine"
                        id="cruise-line"
                        value={formData.CruiseLine}
                        onChange={(e) => {
                          const selectedCruiseLine = e.target.value;
                          setFormData({
                            ...formData,
                            CruiseLine: selectedCruiseLine,
                            Ship: "", // Reset Ship when Cruise Line changes
                          });
                        }}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <option value="">Select cruise line</option>
                        {uniqueCruiseLines.map((cruiseLine) => (
                          <option key={cruiseLine} value={cruiseLine}>
                            {cruiseLine}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {/* Cruise Ship */}
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="ship"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      Cruise Ship
                    </label>
                    <div className="mt-2">
                      <select
                        name="Ship"
                        id="ship"
                        value={formData.Ship}
                        onChange={handleInputChange}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <option value="">Select ship</option>
                        {formData.CruiseLine &&
                          shipList
                            .filter(
                              (ship) => ship.cruiseLine === formData.CruiseLine
                            )
                            .map((ship) => (
                              <option key={ship.ship} value={ship.ship}>
                                {ship.ship}
                              </option>
                            ))}
                      </select>
                    </div>
                  </div>

                  {/* Sail Date */}
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="sail-date"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      Sail Date
                    </label>
                    <div className="mt-2">
                      <input
                        type="date"
                        name="SailDate"
                        id="sail-date"
                        value={
                          formData.SailDate
                            ? formData.SailDate.split("T")[0]
                            : ""
                        } // Extracting only the date part
                        onChange={handleInputChange}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  {/* Cruise Name - from Widgety */}
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      Cruise Name
                    </label>
                    <div className="mt-2">
                      <select
                        name="name"
                        id="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <option value="">Select Cruise Name</option>
                        {cruiseOptions.map((option, index) => (
                          <option key={index} value={option.ref}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {/* Product */}
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="product"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      Product
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="Product"
                        id="product"
                        value={formData.Product}
                        onChange={handleInputChange}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  {/* Region */}
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="region"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      Region
                    </label>
                    <div className="mt-2">
                      <select
                        id="region"
                        name="Region"
                        value={formData.Region}
                        onChange={handleInputChange}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <option value="">Select a region</option>
                        <option value="Alaska">Alaska</option>
                        <option value="Asia">Asia</option>
                        <option value="Australia/New Zealand/South Pacific">
                          Australia/New Zealand/South Pacific
                        </option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Canada">Canada</option>
                        <option value="Canals">Canals</option>
                        <option value="Caribbean">Caribbean</option>
                        <option value="Europe">Europe</option>
                        <option value="Hawaii">Hawaii</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Repositioning/Transatlantic">
                          Repositioning/Transatlantic
                        </option>
                        <option value="West Coast Short">
                          West Coast Short
                        </option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>

                  {/* Nights */}
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="nights"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      Nights
                    </label>
                    <div className="mt-2">
                      <input
                        type="number"
                        name="Nights"
                        id="nights"
                        value={formData.Nights}
                        onChange={handleInputChange}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  {/* Cruise Name */}
                  {/* <div className="sm:col-span-3">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      Cruise Name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div> */}

                  {/* Category Class */}
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="category-class"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      <span className="flex items-center">
                        Category Class
                        <button
                          type="button"
                          onClick={openCategoryClassModal}
                          className="ml-1 text-indigo-600 focus:outline-none hover:text-indigo-500">
                          <InformationCircleIcon width={20} height={20} />
                        </button>
                      </span>
                    </label>

                    <div className="mt-2">
                      <select
                        name="CategoryClass"
                        id="category-class"
                        value={formData.CategoryClass}
                        onChange={handleInputChange}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <option value="">Select Category</option>
                        <option value="I">I - Inside</option>
                        <option value="O">O - Oceanview</option>
                        <option value="B">B - Balcony</option>
                        <option value="N">N - Neighbourhood</option>
                        <option value="S">S - Suite</option>
                      </select>
                    </div>
                  </div>

                  {/* Category */}
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="category"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      <span className="flex items-center">
                        Category Code
                        <button
                          type="button"
                          onClick={openCategoryModal}
                          className="ml-1 text-indigo-600 focus:outline-none hover:text-indigo-500">
                          <InformationCircleIcon width={20} height={20} />
                        </button>
                      </span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="Category"
                        id="category"
                        value={formData.Category}
                        onChange={handleInputChange}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  {/* Category Name */}
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="category-name"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      <span className="flex items-center">
                        Category Name
                        <button
                          type="button"
                          onClick={openCategoryNameModal}
                          className="ml-1 text-indigo-600 focus:outline-none hover:text-indigo-500">
                          <InformationCircleIcon width={20} height={20} />
                        </button>
                      </span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="CategoryName"
                        id="categoryname"
                        value={formData.CategoryName}
                        onChange={handleInputChange}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  {/* Currency */}
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="currency"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      Currency
                    </label>
                    <div className="mt-2">
                      <select
                        id="currency"
                        name="Currency"
                        value={formData.Currency}
                        onChange={handleInputChange}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                        <option value="">Select currency</option>
                        <option value="CAD">CAD</option>
                        <option value="USD">USD</option>
                      </select>
                    </div>
                  </div>

                  {/* Group Rate */}
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="group-rate"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      <span className="flex items-center">
                        Group Rate
                        <button
                          type="button"
                          onClick={openGroupRateModal}
                          className="ml-1 text-indigo-600 focus:outline-none hover:text-indigo-500">
                          <InformationCircleIcon width={20} height={20} />
                        </button>
                      </span>
                    </label>
                    <div className="mt-2 relative">
                      <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
                        $
                      </span>
                      <input
                        type="text"
                        name="GroupRate"
                        id="group-rate"
                        value={formData.GroupRate}
                        onChange={handleInputChange}
                        placeholder="Enter group rate"
                        pattern="[0-9]*" // Accepts only numbers
                        className="block w-full pl-10 pr-3 py-1.5 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  {/* Market Rate */}
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="current-group-rate"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      <span className="flex items-center">
                        Market Rate
                        <button
                          type="button"
                          onClick={openMarketRateModal}
                          className="ml-1 text-indigo-600 focus:outline-none hover:text-indigo-500">
                          <InformationCircleIcon width={20} height={20} />
                        </button>
                      </span>{" "}
                    </label>
                    <div className="mt-2 relative">
                      <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
                        $
                      </span>
                      <input
                        type="text"
                        name="MarketRate"
                        id="market-rate"
                        value={formData.MarketRate}
                        onChange={handleInputChange}
                        placeholder="Enter market rate"
                        pattern="[0-9]*" // Accepts only numbers
                        className="block w-full pl-10 pr-3 py-1.5 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  {/* Price Advantage */}
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="current-price-adv"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      <span className="flex items-center">
                        Current Price Advantage
                        <button
                          type="button"
                          onClick={openCurrentPriceAdvantageModal}
                          className="ml-1 text-indigo-600 focus:outline-none hover:text-indigo-500">
                          <InformationCircleIcon width={20} height={20} />
                        </button>
                      </span>{" "}
                    </label>
                    <div className="mt-2 relative">
                      <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
                        $
                      </span>
                      <input
                        type="text"
                        name="CurrentPriceAdv"
                        id="current-price-adv"
                        value={formData.CurrentPriceAdv}
                        onChange={handleInputChange}
                        placeholder="Enter price saved"
                        pattern="[0-9]*" // Accepts only numbers
                        className="block w-full pl-10 pr-3 py-1.5 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  {/* Percent Advantage */}
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="current-percent-adv"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      <span className="flex items-center">
                        Current Percent Advantage
                        <button
                          type="button"
                          onClick={openCurrentPercentAdvantageModal}
                          className="ml-1 text-indigo-600 focus:outline-none hover:text-indigo-500">
                          <InformationCircleIcon width={20} height={20} />
                        </button>
                      </span>
                    </label>
                    <div className="mt-2 relative">
                      <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
                        %
                      </span>
                      <input
                        type="number"
                        name="CurrentPercentAdv"
                        id="current-percent-adv"
                        value={formData.CurrentPercentAdv}
                        placeholder="Enter percent saved"
                        onChange={handleInputChange}
                        min="0" // Minimum value allowed
                        max="100" // Maximum value allowed
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-10" // Added pl-10 to give space for the symbol
                      />
                    </div>
                  </div>

                  {/* Rooms Available */}
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="rooms-available"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      Rooms Available
                    </label>
                    <div className="mt-2">
                      <input
                        type="number"
                        name="RoomsAvailable"
                        id="rooms-available"
                        value={formData.RoomsAvailable}
                        onChange={handleInputChange}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  {/* Perks */}
                  <div className="col-span-full">
                    <label
                      htmlFor="perks"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      <span className="flex items-center">
                        Perks
                        <button
                          type="button"
                          onClick={openPerksModal}
                          className="ml-1 text-indigo-600 focus:outline-none hover:text-indigo-500">
                          <InformationCircleIcon width={20} height={20} />
                        </button>
                      </span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="Perks"
                        id="perks"
                        placeholder="Leave empty if no perks available"
                        value={formData.Perks}
                        onChange={handleInputChange}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>

          {feedback && feedback}

          {/* Render incomplete form if the form is not considered complete and the timer is active */}
          {!isFormComplete && showIncompleteForm && (
            <div className="rounded-md bg-yellow-50 p-4 mt-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon
                    className="h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-yellow-800">
                    Unable to add - All fields must be complete
                  </h3>
                  <div className="mt-2 text-sm text-yellow-700">
                    {/* Display incomplete fields feedback */}
                    {incompleteFields.length > 0 && (
                      <div>
                        <p>Fields with missing data:</p>
                        <ul>
                          {incompleteFields.map((field, index) => (
                            <li key={index}>{field}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Buttons */}
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              onClick={handleCancel}
              className="text-sm font-semibold leading-6 text-gray-900">
              Clear
            </button>
            <button
              type="submit"
              onClick={handleSubmit}
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Add
            </button>
          </div>
        </div>
      </form>

      <>
        <AddCruiseModal
          isOpen={isCategoryClassModalOpen}
          onClose={closeCategoryClassModal}
          type="categoryClass"
        />

        <AddCruiseModal
          isOpen={isCategoryModalOpen}
          onClose={closeCategoryModal}
          type="category"
        />

        <AddCruiseModal
          isOpen={isCategoryNameModalOpen}
          onClose={closeCategoryNameModal}
          type="categoryName"
        />

        <AddCruiseModal
          isOpen={isGroupRateModalOpen}
          onClose={closeGroupRateModal}
          type="groupRate"
        />

        <AddCruiseModal
          isOpen={isMarketRateModalOpen}
          onClose={closeMarketRateModal}
          type="marketRate"
        />

        <AddCruiseModal
          isOpen={isCurrentPriceAdvantageModalOpen}
          onClose={closeCurrentPriceAdvantageModal}
          type="currentPriceAdvantage"
        />

        <AddCruiseModal
          isOpen={isCurrentPercentAdvantageModalOpen}
          onClose={closeCurrentPercentAdvantageModal}
          type="currentPercentAdvantage"
        />

        <AddCruiseModal
          isOpen={isPerksModalOpen}
          onClose={closePerksModal}
          type="perks"
        />
      </>
    </div>
  );
}
