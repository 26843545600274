import { createSlice } from "@reduxjs/toolkit";
// import { sendClientQuote } from "./thunkFile"; // Assuming the thunk is exported from 'thunkFile.js'
import { sendClientQuote } from "../thunks/sendClientQuoteThunk";

const quoteSlice = createSlice({
  name: "quotes",
  initialState: {
    isLoading: false,
    quoteSent: null,
    error: null,
  },
  reducers: {
    // Define other reducers that might be needed for other actions
    resetQuoteState(state) {
      state.isLoading = false;
      state.quoteSent = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendClientQuote.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(sendClientQuote.fulfilled, (state, action) => {
        state.isLoading = false;
        state.quoteSent = action.payload;
        state.error = null;
      })
      .addCase(sendClientQuote.rejected, (state, action) => {
        state.isLoading = false;
        state.quoteSent = null;
        state.error = action.error.message;
      });
  },
});

export const { resetQuoteState } = quoteSlice.actions;
export default quoteSlice.reducer;
