import React from "react";

const Logo = ({ width, height, marginLeft, marginTop, marginRight, marginBottom }) => {
  return (
    <img
      src={process.env.PUBLIC_URL + "/logo/brandmark-design.png"}
      alt="Logo"
      style={{ 
        width: width, 
        height: height, 
        marginLeft: marginLeft, 
        marginTop: marginTop, 
        marginRight: marginRight, 
        marginBottom: marginBottom,
        objectFit: 'contain',
      }}
    />
  );
};

export default Logo;
