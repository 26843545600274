import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

function CruiseImageGallery({ shipData, cruiseData }) {
  // Assuming shipData and cruiseData contain the necessary image data

  const images = [];

  // Add ship image as the first item
  if (shipData && shipData.cover_image_href) {
    images.push({
      original: shipData.cover_image_href,
      thumbnail: shipData.cover_image_href,
      // description: shipData.title,
      originalAlt: "Cruise Ship",
      thumbnailAlt: "Cruise Ship",
    });
  }

  // Add port visit images
  if (cruiseData && cruiseData.portVisits) {
    cruiseData.portVisits.forEach((portVisit, portVisitIndex) => {
      if (portVisit.portImages && portVisit.portImages.images.length > 0) {
        portVisit.portImages.images.forEach((image, imageIndex) => {
          images.push({
            original: image.href,
            thumbnail: image.href,
            // description: image.credit, // Set image name as description
            originalAlt: image.name,
            thumbnailAlt: image.name,
          });
        });
      }
    });
  }

  return (
    <ImageGallery
      items={images}
      showThumbnails={true}
      showFullscreenButton={true}
      autoPlay={true}
      thumbnailPosition="bottom"
      slideDuration={2000}
      showIndex={true}
    />
  );
}

export default CruiseImageGallery;
