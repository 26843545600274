import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { InformationCircleIcon } from "@heroicons/react/24/solid";

export default function RegisterModal({ isOpen, onClose, type }) {
  const modalContent = {
    subdomain: {
      title: "Subdomain Information",
      message1:
        "Your subdomain is what your clients will see in the address bar when they use our platform. It must be a single word with no spaces.",
      message2: "A subdomain will look like:",
      message3: "yourcompany.cruisecompare.ca",
      message4:
        "We suggest using your company or agency name as the subdomain.",
    },
    logo: {
      title: "Company Logo Information",
      message1:
        "It is highly recommended that you upload high-resolution PNG or SVG files only.",
    },
    color: {
      title: "Theme Color Information",
      message1: "Ensure this color is a HEX code. (e.g: #ABC123)",
      message2:
        "Your clients will see your chosen color throughout the platform.",
    },
    subscription_business: {
      title: "Business Class Plan - C$19.95/month + HST",
      message1: "The general membership, which includes:",
      message2:
        "Full platform access, unique agent database, upload custom deals, unlimitied inquiries, website personalization, customer support.",
    },
    subscription_first_class: {
      title: "First Class Tier - C$29.95/month + HST",
      message1: "All the benefits of business class, plus:",
      message2:
        "Automated marketing emails, in depth analytics, automated quote generation, priority customer support.",
    },
    vv_link: {
      title: "Virgin Voyages Information",
      message1: "Attach your LetsGoBook link here.",
    },
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onClose}>
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          <div className="relative z-10 w-full max-w-md p-6 my-8 bg-white shadow-xl rounded-lg">
            <div className="mt-3 text-center sm:mt-5">
              <Dialog.Title
                as="h3"
                className="text-lg font-semibold leading-6 text-gray-900">
                {modalContent[type].title}
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {modalContent[type].message1}
                </p>

                <p className="text-sm text-gray-500 pt-4">
                  {modalContent[type].message4}
                </p>

                {(type === "subdomain" ||
                  type === "color" ||
                  type === "subscription_business" ||
                  type === "subscription_first_class") && (
                  <p className="text-sm text-gray-500 pt-4">
                    {modalContent[type].message2}{" "}
                    <i>{modalContent[type].message3}</i>
                  </p>
                )}
              </div>
            </div>

            <div className="mt-4 sm:mt-4">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={onClose}>
                Close
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
