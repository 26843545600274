import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    fetchUsersList,
    editActiveUser,
    updateUserThunk,
} from "../../store/thunks/usersThunk";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Clients.css";
import EditConfirmationModal from "../../components/admin/EditConfirmationModal";
import {
    BarsArrowUpIcon,
    BarsArrowDownIcon,
    Bars3BottomLeftIcon,
} from "@heroicons/react/20/solid";
import { ClipLoader } from "react-spinners";
import { fetchAgentsList } from "../../store/thunks/agentsThunk";

const Clients = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.users.activeUser);
    const editUserStatus = useSelector((state) => state.editUser.isLoading);
    const agents = useSelector((state) => state.agents.agentsList);

    console.log(agents);

    // const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortField, setSortField] = useState(null);
    const [sortDirection, setSortDirection] = useState(null); // true for ascending, false for descending
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(0);
    const [feedback, setFeedback] = useState("");
    const [feedbackColor, setFeedbackColor] = useState("");
    const [showEditModal, setShowEditModal] = useState({
        first_name: "",
        last_name: "",
        email: "",
        // user_type: "",
    });
    const [editFormData, setEditFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        // user_type: "",
    });
    const [editMode, setEditMode] = useState(null);
    const [userToSaveIndex, setUserToSaveIndex] = useState(null);
    const [showEditConfirmModal, setShowEditConfirmModal] = useState(false);
    const [matchedAgent, setMatchedAgent] = useState();
    const [isLoading, setIsLoading] = useState(false);

    // Assuming you have a users state in your Redux store and it looks like { users: [], error: null, isLoading: false }
    const usersList = useSelector((state) => state.users.usersList);
    const status = useSelector((state) => state.users.status);
    const error = useSelector((state) => state.users.error);

    // New state for controlling the spinner's display
    const [shouldDisplayLoader, setShouldDisplayLoader] = useState(false);

    // REDUX USEEFFECT
    useEffect(() => {
        dispatch(fetchUsersList());
        dispatch(fetchAgentsList());
    }, [dispatch]);

    const handleSort = (field) => {
        if (sortField === field) {
            if (sortDirection === false) {
                // if the current field is already being sorted in descending, reset sort
                resetSort();
            } else {
                // if the current field is already being sorted, change direction
                setSortDirection(!sortDirection);
            }
        } else {
            // if a new field is selected, sort in ascending order by default
            setSortField(field);
            setSortDirection(true);
        }
    };

    const resetSort = () => {
        setSortField(null);
        setSortDirection(null);
    };

    const getSortIcon = (field) => {
        if (sortField === field) {
            if (sortDirection === true) {
                return <BarsArrowUpIcon className="h-4 w-4 inline ml-2" />;
            } else if (sortDirection === false) {
                return <BarsArrowDownIcon className="h-4 w-4 inline ml-2" />;
            }
        }
        return <Bars3BottomLeftIcon className="h-3.5 w-3.5 inline ml-2" />;
    };

    const filteredUsers = usersList
        .filter((user) =>
            `${user.first_name} ${user.last_name} ${user.email}`
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => {
            if (!sortField) return 0;
            if (a[sortField] < b[sortField]) return sortDirection ? -1 : 1;
            if (a[sortField] > b[sortField]) return sortDirection ? 1 : -1;
            return 0;
        });

    const displayedUsers = filteredUsers.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
    );

    // REDUX REFRESH DATA
    const refreshData = () => {
        setIsLoading(true);

        const minimumDisplayTime = 1000;
        const startTime = Date.now();

        async function fetchData() {
            try {
                dispatch(fetchUsersList());

                const elapsed = Date.now() - startTime;
                const delayTime = Math.max(0, minimumDisplayTime - elapsed);

                setTimeout(() => {
                    setIsLoading(false);
                    // console.log("Clients refreshed");
                    setFeedback("Refresh successful");
                    setFeedbackColor("green");
                    setTimeout(() => {
                        setFeedback("");
                    }, 1750);
                }, delayTime);
            } catch (error) {
                const elapsed = Date.now() - startTime;
                const delayTime = Math.max(0, minimumDisplayTime - elapsed);

                setTimeout(() => {
                    setIsLoading(false);
                    // console.log("Clients refresh failed");
                    // console.error("Error:", error);
                    setFeedback("Refresh failed");
                    setFeedbackColor("red");
                    setTimeout(() => {
                        setFeedback("");
                    }, 1750);
                }, delayTime);
            }
        }
        fetchData();
    };

    const totalPage = Math.ceil(usersList.length / itemsPerPage);

    function handleInputChange(e, field) {
        if (field === "agent") {
            const selectedAgentId = e.target.value;
            const selectedAgent = agents.find(
                (agent) => agent._id === selectedAgentId
            );

            if (selectedAgent) {
                setEditFormData({
                    ...editFormData,
                    agentId: selectedAgent._id, // Temporary internal use
                    agent: `${selectedAgent.first_name} ${selectedAgent.last_name}`, // Keep the actual agent name for backend compatibility
                });
            }
        } else {
            setEditFormData({
                ...editFormData,
                [field]: e.target.value,
            });
        }
    }

    const updateUser = (_id) => {
        const user = filteredUsers.find((u) => u._id === _id);

        if (!user) {
            console.error("User with _id not found:", _id);
            setFeedback("User update failed");
            setFeedbackColor("red");
            setTimeout(() => {
                setFeedback("");
            }, 1750);
            setEditMode(null);
            return;
        }

        const updatedUser = { ...user, ...editFormData };
        delete updatedUser._id;

        // Use the thunk to update the user
        dispatch(updateUserThunk({ userId: user._id, updatedUser })).then(
            (action) => {
                if (updateUserThunk.fulfilled.match(action)) {
                    // Successful update
                    setFeedback("User updated");
                    setFeedbackColor("green");
                    setTimeout(() => {
                        setFeedback("");
                    }, 1750);
                    setEditMode(null);
                    refreshData(); // Refresh data after updating user
                } else {
                    // Update failed
                    setFeedback("User update failed");
                    setFeedbackColor("red");
                    setTimeout(() => {
                        setFeedback("");
                    }, 1750);
                }
            }
        );
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(e.target.value);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    function highlightTerm(text, term) {
        const splitText = String(text).split(new RegExp(`(${term})`, "gi"));

        return splitText.map((part, i) =>
            part.toLowerCase() === term.toLowerCase() ? (
                <span key={i} className="bg-yellow-300">
                    {part}
                </span>
            ) : (
                part
            )
        );
    }

    function handleEditClick(user) {
        // Find the agent whose full name matches the user's agent name
        const match = agents.find(
            (agent) => `${agent.first_name} ${agent.last_name}` === user.agent
        );

        if (match) {
            setEditFormData({
                ...user, // Assuming you want to copy all user data into the form data
                agent: match._id, // Set agent as _id for internal form management
            });
        } else {
            // Fallback if no matching agent found; consider your own handling here
            setEditFormData({
                ...user,
                agent: "", // Reset or use a placeholder
            });
        }

        // Activate edit mode for the selected user
        setEditMode(user);
    }

    function handleResendVerification() {
        console.log("Button clicked");
        // Logic for resending the email verification
        // This could involve making a request to your backend, which then sends the verification email.
    }

    //   <div>
    //     {usersList && usersList.length > 0 ? (
    //       <table>
    //         <thead>
    //           <tr>
    //             <th>First Name</th>
    //             <th>Last Name</th>
    //             <th>Email</th>
    //             <th>User Type</th>
    //           </tr>
    //         </thead>
    //         <tbody>
    //           {usersList?.map((user) => (
    //             <tr key={user._id}>
    //               <td>{user.first_name}</td>
    //               <td>{user.last_name}</td>
    //               <td>{user.email}</td>
    //               <td>{user.user_type}</td>
    //             </tr>
    //           ))}
    //         </tbody>
    //       </table>
    //     ) : (
    //       <p>No users available.</p>
    //     )}
    //   </div>
    // );

    return (
        <div className="pt-4 ml-0 sm:px-6 lg:pr-10 lg:pl-0">
            {/* Header row */}
            <div className="sm:flex sm:items-center justify-between">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">
                        Clients
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                        A list of all clients including their name, title, email
                        and role.
                    </p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <div className="flex items-center">
                        {/* This is the new flex container */}
                        <p
                            className="text-base font-semibold leading-6 mr-2.5"
                            style={{ color: feedbackColor }}
                        >
                            {feedback}
                        </p>
                        <button
                            type="button"
                            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={refreshData}
                        >
                            Refresh Clients
                        </button>
                    </div>
                </div>
            </div>

            {/* Pagination and search stuff */}
            <div className="mt-4 pb-2 flex justify-between">
                <div className="w-1/2 sm:w-1/4">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearch}
                        placeholder="Search by name or email"
                        className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <div className="inline-flex items-center border rounded-md">
                    <button
                        className="px-3 py-2 border-r text-sm font-medium rounded-l-md text-gray-500 bg-white hover:text-gray-700 hover:bg-gray-50"
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 0}
                    >
                        &laquo;
                    </button>

                    <span className="px-3 py-2 text-sm font-medium text-gray-500 bg-white">
                        Page {currentPage + 1} of {totalPage}
                    </span>

                    <button
                        className="px-3 py-2 border-l text-sm font-medium rounded-r-md text-gray-500 bg-white hover:text-gray-700 hover:bg-gray-50"
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === totalPage - 1}
                    >
                        &raquo;
                    </button>

                    <div className="px-3 py-2">
                        <label htmlFor="itemsPerPage" className="sr-only">
                            Items per page
                        </label>
                        <select
                            id="itemsPerPage"
                            className="border-gray-300 rounded-md shadow-sm"
                            onChange={handleItemsPerPageChange}
                            value={itemsPerPage}
                        >
                            <option value={5}>5 per page</option>
                            <option value={10}>10 per page</option>
                            <option value={20}>20 per page</option>
                            <option value={50}>50 per page</option>
                        </select>
                    </div>
                </div>
            </div>

            {/* Table */}
            <div className="-mx-4 -my-2 pb-20 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                        {/* Table Header */}
                        <thead>
                            <tr>
                                <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3 cursor-pointer"
                                ></th>
                                <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3 cursor-pointer"
                                    onClick={() => handleSort("first_name")}
                                >
                                    <div className="flex items-center">
                                        First Name {getSortIcon("first_name")}
                                    </div>
                                </th>
                                <th
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                                    onClick={() => handleSort("last_name")}
                                >
                                    <div className="flex items-center">
                                        Last Name {getSortIcon("last_name")}
                                    </div>
                                </th>
                                <th
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                                    onClick={() => handleSort("email")}
                                >
                                    <div className="flex items-center">
                                        Email {getSortIcon("email")}
                                    </div>
                                </th>
                                <th
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                    Is Email Verified
                                </th>
                                <th
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                    Agent
                                </th>
                                {/* Hiding the ability to show user type */}
                                {/* <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                  onClick={() => handleSort("user_type")}>
                  <div className="flex items-center">
                    User Type {getSortIcon("user_type")}
                  </div>
                </th> */}
                            </tr>
                        </thead>

                        {/* TABLE BODY WITH REDUX */}
                        <tbody className="divide-gray-200 divide-y bg-white">
                            {/* Loading State */}
                            {status === "loading" && (
                                <tr>
                                    <td
                                        colSpan="5"
                                        className="text-center py-5"
                                    >
                                        <ClipLoader color="#4535be" size={75} />
                                    </td>
                                </tr>
                            )}

                            {/* Error State */}
                            {status === "failed" && (
                                <tr>
                                    <td
                                        colSpan="5"
                                        className="text-center py-5 text-red-500"
                                    >
                                        Error: {error}
                                    </td>
                                </tr>
                            )}

                            {/* No Users State */}
                            {status === "succeeded" &&
                                usersList.length === 0 && (
                                    <tr>
                                        <td
                                            colSpan="5"
                                            className="text-center py-5"
                                        >
                                            No users found.
                                        </td>
                                    </tr>
                                )}

                            {/* Display Users */}
                            {status === "succeeded" &&
                                displayedUsers.map((user, index) => (
                                    <tr
                                        key={user._id}
                                        className="even:bg-gray-50"
                                    >
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {currentPage * itemsPerPage +
                                                index +
                                                1}
                                        </td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                            {editMode === user ? (
                                                <input
                                                    type="text"
                                                    value={
                                                        editFormData.first_name
                                                    }
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            e,
                                                            "first_name"
                                                        )
                                                    }
                                                />
                                            ) : (
                                                highlightTerm(
                                                    user.first_name,
                                                    searchTerm
                                                )
                                            )}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {editMode === user ? (
                                                <input
                                                    type="text"
                                                    value={
                                                        editFormData.last_name
                                                    }
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            e,
                                                            "last_name"
                                                        )
                                                    }
                                                />
                                            ) : (
                                                highlightTerm(
                                                    user.last_name,
                                                    searchTerm
                                                )
                                            )}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {editMode === user ? (
                                                <input
                                                    type="text"
                                                    value={editFormData.email}
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            e,
                                                            "email"
                                                        )
                                                    }
                                                />
                                            ) : (
                                                highlightTerm(
                                                    user.email,
                                                    searchTerm
                                                )
                                            )}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {editMode === user ? (
                                                <div>
                                                    <input
                                                        type="text"
                                                        value={
                                                            editFormData.email
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                "email"
                                                            )
                                                        }
                                                    />
                                                    {!user.isEmailVerified && (
                                                        <button
                                                            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                            onClick={
                                                                handleResendVerification
                                                            }
                                                        >
                                                            Resend Verification
                                                            Email
                                                        </button>
                                                    )}
                                                </div>
                                            ) : (
                                                highlightTerm(
                                                    user.isEmailVerified,
                                                    searchTerm
                                                )
                                            )}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {editMode === user ? (
                                                <div>
                                                    <select
                                                        value={
                                                            editFormData.agent
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                "agent"
                                                            )
                                                        }
                                                        className="block w-full px-2 py-1 text-sm text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                                                    >
                                                        {agents.map((agent) => (
                                                            <option
                                                                key={agent._id}
                                                                value={
                                                                    agent._id
                                                                }
                                                            >
                                                                {
                                                                    agent.first_name
                                                                }{" "}
                                                                {
                                                                    agent.last_name
                                                                }
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            ) : (
                                                highlightTerm(
                                                    user.agent,
                                                    searchTerm
                                                )
                                            )}
                                        </td>

                                        {/* Hiding the ability to edit the user */}
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {editMode === user ? (
                                                <select
                                                    name="user_type"
                                                    value={
                                                        editFormData.user_type
                                                    }
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            e,
                                                            "user_type"
                                                        )
                                                    }
                                                    className="border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                                >
                                                    <option value="user">
                                                        User
                                                    </option>
                                                    <option value="admin">
                                                        Admin
                                                    </option>
                                                </select>
                                            ) : (
                                                user.user_type
                                            )}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {editMode === user ? (
                                                <button
                                                    className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    onClick={() => {
                                                        setUserToSaveIndex(
                                                            user._id
                                                        );
                                                        setShowEditConfirmModal(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    Save/Cancel
                                                </button>
                                            ) : (
                                                <button
                                                    className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    onClick={() => {
                                                        handleEditClick(user); // This will set all necessary edit form data
                                                        setShowEditModal(true); // Assuming you need to control modal visibility
                                                    }}
                                                >
                                                    Edit
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <EditConfirmationModal
                open={showEditConfirmModal}
                onClose={() => setShowEditConfirmModal(false)}
                onSave={() => {
                    updateUser(userToSaveIndex);
                    setUserToSaveIndex(null); // Reset the index after updating
                }}
                onCancel={() => {
                    setShowEditConfirmModal(false); // closes the modal
                    setEditMode(null); // sets the editMode to null
                    setFeedback("User not updated");
                    setFeedbackColor("orange");
                    setTimeout(() => {
                        setFeedback("");
                    }, 1500);
                }}
            />
        </div>
    );
};

export default Clients;
