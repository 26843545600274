import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiBaseUrl } from "../../config";
import axios from "axios";

// Thunk to fetch all quotes for a specific user
export const fetchUserQuote = createAsyncThunk(
  "quotes/fetchUserQuote",
  async (_, { getState }) => {
    try {
      const state = getState();
      const { token, _id } = state.activeUser.data.user;

      const config = {
        headers: {
          "Content-Type": "application/json",
          "x-api-token": token,
          "x-user-id": _id,
        },
      };

      // Make a GET request to your API endpoint to fetch quotes for the client
      const response = await axios.get(
        `https://${apiBaseUrl}/quotes/user/${_id}`,
        config
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
