import { createSlice } from "@reduxjs/toolkit";
import { fetchCruises, fetchAgentCruises } from "../thunks/fetchCruises";
import { addCruise, updateCruise } from "../thunks/cruisesThunk";

const cruisesSlice = createSlice({
  name: "cruises",
  initialState: {
    cruisesList: [],
    status: "idle",
    error: null,
    insertedId: null,
    updatedCruiseId: null, // Add this if you need to track the ID of the updated cruise
  },
  reducers: {
    clearCruisesError: (state) => {
      state.error = null;
    },
    // Any other synchronous actions related to cruises can go here
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCruises.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchCruises.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.cruisesList = action.payload;
      })
      .addCase(fetchCruises.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // .addCase(fetchAgentCruises.pending, (state) => {
      //   state.status = "loading";
      //   state.error = null;
      // })
      // .addCase(fetchAgentCruises.fulfilled, (state, action) => {
      //   state.status = "succeeded";
      //   state.cruisesList = action.payload;
      // })
      // .addCase(fetchAgentCruises.rejected, (state, action) => {
      //   state.status = "failed";
      //   state.error = action.error.message;
      // })
      .addCase(addCruise.pending, (state) => {
        state.status = "loading";
        state.error = null;
        state.insertedId = null;
      })
      .addCase(addCruise.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.insertedId = action.payload;
      })
      .addCase(addCruise.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Add the following case for updateCruise thunk
      .addCase(updateCruise.pending, (state) => {
        state.status = "loading";
        state.error = null;
        state.updatedCruiseId = null;
      })
      .addCase(updateCruise.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Assuming the payload contains the ID of the updated cruise
        state.updatedCruiseId = action.payload.id;
      })
      .addCase(updateCruise.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// Exporting the actions
export const { clearCruisesError } = cruisesSlice.actions;

export default cruisesSlice.reducer;
