import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import CruiseFilter from "../filter/CruiseFilter";
import celebrityLogo from "../../assets/celebrityLogo.png";
import royalLogo from "../../assets/royalLogo.png";
import virginLogo from "../../assets/virginLogo.png";
import { isDev } from "../../config";
import CruiseGridTile from "./CruiseGridTile";
import { fetchActiveUser } from "../../store/thunks/fetchActiveUser"; // Import the action to fetch active user

import "../../App.css";
import "./GridCruiseList.css";

function GridCruiseList({ onInquireClick, onDetailsClick, selectedMenu }) {
  const [sort, setSort] = useState("CurrentPriceAdv");
  const [sortOrder, setSortOrder] = useState("neutral");
  const [currentPage, setCurrentPage] = useState(1);
  const [overlayTop, setOverlayTop] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: "GroupRate",
    direction: "default", // can be 'asc', 'desc', or 'default'
  });
  const [soldOutModalOpen, setSoldOutModalOpen] = useState(false);
  const [selectedSoldOutCruiseLine, setselectedSoldOutCruiseLine] =
    useState(null);

  const [cruiseDetailModalOpen, setCruiseDetailModalOpen] = useState(false);
  const [cruiseUnavailableModalOpen, setCruiseUnavailableModalOpen] =
    useState(false);
  useState(false);
  const [shipDetailModalOpen, setShipDetailModalOpen] = useState(false);
  const [cruisePerksModalOpen, setCruisePerksModalOpen] = useState(false);
  const [currentCruiseShip, setCurrentCruiseShip] = useState(null);
  const [currentOperator, setCurrentOperator] = useState(null);
  const [sailDateForPerks, setSailDateForPerks] = useState(null);

  const activeUser = useSelector((state) => state.activeUser.data.user);
  const activeAgent = useSelector((state) => state.activeAgent.data.agent);

  const dispatch = useDispatch();
  const cruises = useSelector((state) => state.cruises.cruisesList.cruises);
  const favouriteCruises = activeUser.favouriteCruises;

  const subscriptionActive = useSelector((state) => {
    const subscriptionStatus = "active";
    const userIsAdmin = state.activeUser?.data?.user?.user_type === "admin";
    const userIsAgent = state.activeAgent?.data?.agent?.user_type === "agent";

    return subscriptionStatus || userIsAdmin || userIsAgent;
  });

  const [sailDateForCruiseDetail, setSailDateForCruiseDetail] = useState();
  const [shipNameForCruiseDetail, setShipNameForCruiseDetail] = useState();

  const rowRefs = useMemo(() => {
    return cruises ? cruises.map(() => React.createRef()) : [];
  }, [cruises]);

  // console.log(cruises);
  // console.log("Favs", favouriteCruises);
  console.log("In", selectedMenu, "menu");

  // Fetch the active user when the component mounts
  useEffect(() => {
    dispatch(fetchActiveUser());
  }, [dispatch]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (shipDetailModalOpen && !event.target.closest(".popup")) {
        toggleShipDetailModal(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [sort, sortOrder, setShipDetailModalOpen]);

  const toggleSortOrder = (currentOrder) => {
    switch (currentOrder) {
      case "neutral":
        return "asc";
      case "asc":
        return "desc";
      case "desc":
        return "neutral";
      default:
        return "neutral";
    }
  };

  // Render logo for each cruise line (just Celeb and Royal atm)
  function getLogoByCruiseLine(cruiseLine) {
    switch (cruiseLine) {
      case "Celebrity":
        return celebrityLogo;
      case "Royal Caribbean":
        return royalLogo;
      case "Virgin Voyages":
        return virginLogo;
      default:
        return null; // or some default logo if needed
    }
  }

  // This function is triggered for information icon on sold out cruises
  function showSoldOutModal(cruiseLine) {
    setSoldOutModalOpen(true);
    setselectedSoldOutCruiseLine(cruiseLine);
  }

  function toggleShipDetailModal(show) {
    if (!isDev) {
      ReactGA.event({
        event: "cruise_search",
        category: "Search",
        action: "Clicked Ship Detail",
      });
    }
    setShipDetailModalOpen(show);
  }

  function toggleCruiseUnavailableModal(show) {
    setCruiseUnavailableModalOpen(show);
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {/* Header bit */}
      <>
        {selectedMenu === "Dashboard" && (
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-">
                Cruise Deals
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                View our exclusive list of cruise deals. Prices are per person,
                taxes not included.
              </p>
              <p className="mt-2 text-sm text-gray-700">
                Available while quantities last. Prices subject to change
                without notice.
              </p>
            </div>
          </div>
        )}

        {selectedMenu.includes("Favourites") && (
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-">
                Favourites
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                View your list of favourite cruises.
              </p>
              <p className="mt-2 text-sm text-gray-700">
                Available while quantities last. Prices subject to change
                without notice.
              </p>
            </div>
          </div>
        )}

        {selectedMenu.includes("Quotes") && (
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-">
                Quotes
              </h1>
              <p className="mt-2 text-sm text-gray-700">View your quotes.</p>
              <p className="mt-2 text-sm text-gray-700">
                Available while quantities last. Prices subject to change
                without notice.
              </p>
            </div>
          </div>
        )}
      </>

      <br />

      {/* CruiseFilter */}
      {selectedMenu === "Dashboard" && (
        <CruiseFilter
          onPageChange={handlePageChange}
          subscriptionActive={subscriptionActive}
          sortOrder={sortOrder}
          sort={sort}
        />
      )}

      <div className="flex justify-center items-center pr-2 sm:hidden mt-4 mb-4">
        <div className="pl-2 pr-4">
          <label
            className="block text-sm font-semibold text-gray-900"
            htmlFor="sort">
            Sort:
          </label>
        </div>
        {/* Mobile sorting options */}
        <select
          id="sort"
          name="sort"
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          onChange={(e) => {
            const value = e.target.value;
            if (value !== sort) {
              setSort(value);
              setSortOrder("asc");
            } else {
              setSortOrder(toggleSortOrder(sortOrder));
            }
          }}>
          <option value="SailDate">Duration & Sail Date</option>
          <option value="CurrentGroupRate">Market Price</option>
          <option value="GroupRate">Our Price</option>
          <option value="CurrentPriceAdv">Savings</option>
        </select>
      </div>

      {/* New grid stuff */}
      <div className="mt-8 cruise-grid">
        {selectedMenu === "Dashboard" ? (
          // Keep the current behavior for 'Dashboard'
          cruises && cruises.length ? (
            cruises.map((cruise, index) => {
              const isFav =
                favouriteCruises &&
                favouriteCruises.some(
                  (favCruise) => favCruise._id === cruise._id
                );
              return (
                <CruiseGridTile
                  key={cruise.CombinedKey}
                  cruise={cruise}
                  onInquireClick={onInquireClick}
                  isFavourite={isFav}
                  className={index % 2 === 0 ? "even-row" : ""}
                />
              );
            })
          ) : (
            <div>
              <p className="mt-2 text-sm text-gray-700">
                Error - no matching cruises.
              </p>
              <p className="mt-2 text-sm text-gray-700">
                Go to page 1 if available, or Refresh.
              </p>
            </div>
          )
        ) : selectedMenu.includes("Favourites") && favouriteCruises ? (
          // Render based on favouriteCruises array if it's defined
          favouriteCruises.length ? (
            favouriteCruises.map((cruise, index) => {
              return (
                <CruiseGridTile
                  key={cruise._id} // Assuming _id is the unique identifier for cruises
                  cruise={cruise}
                  onInquireClick={onInquireClick}
                  isFavourite={true} // Set isFavourite to true for favourite cruises
                  className={index % 2 === 0 ? "even-row" : ""}
                />
              );
            })
          ) : (
            <h1 className="text-base font-semibold leading-6 text-">
              No Favourite Cruises Available.
            </h1>
          )
        ) : (
          <h1 className="text-base font-semibold leading-6 text-">
            No Favourite Cruises Available.
          </h1>
        )}
      </div>
    </div>
  );
}

export default React.memo(GridCruiseList);
