import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiBaseUrl } from "../../config";
import axios from "axios";

// Thunk to add a quote
export const addQuote = createAsyncThunk(
  "quotes/addQuote",
  async (quoteData, { getState }) => {
    const state = getState();
    const { token, _id } = state.activeAgent.data.agent;

    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-agent-api-token": token,
        "x-agent-id": _id,
      },
      body: JSON.stringify(quoteData),
    };

    const response = await fetch(`https://${apiBaseUrl}/quotes/insert`, config);
    const data = await response.json();
    console.log("Response from addQuote thunk call:", data);

    if (!response.ok) {
      throw new Error(data.message || "Failed to add quote");
    }

    return data.insertedId;
  }
);

// Thunk to update a quote by its ID
export const updateQuote = createAsyncThunk(
  "quotes/updateQuote",
  async ({ id, updatedQuote }, { getState }) => {
    const state = getState();
    const { token, _id } = state.activeAgent.data.agent;

    const config = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "x-agent-api-token": token,
        "x-agent-id": _id,
      },
      body: JSON.stringify(updatedQuote),
    };

    const response = await fetch(`https://${apiBaseUrl}/quotes/${id}`, config);
    const data = await response.json();
    console.log("Response from updateQuote thunk call:", data);

    if (!response.ok) {
      throw new Error(data.message || "Failed to update quote");
    }

    return data;
  }
);

// Thunk to delete a quote by its ID
export const deleteQuote = createAsyncThunk(
  "quotes/deleteQuote",
  async ({ id }, { getState }) => {
    console.log("Received this id in quote delete thunk", id);
    try {
      const state = getState();
      const { token, _id } = state.activeAgent.data.agent;

      const config = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-agent-api-token": token,
          "x-agent-id": _id,
        },
      };

      console.log("Before quote deletion thunk call");
      // Make a DELETE request to your API endpoint with the provided ID and config
      const response = await fetch(
        `https://${apiBaseUrl}/quotes/delete/${id}`,
        config
      );

      console.log("After quote deletion thunk call");
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete quote");
      }

      // Return the ID of the deleted quote
      return id;
    } catch (error) {
      // If an error occurs, throw it to be handled by Redux Toolkit
      throw error;
    }
  }
);

// Thunk to fetch data for a specific quote
export const fetchQuoteById = createAsyncThunk(
  "quotes/fetchQuoteById",
  async (quoteId, { getState }) => {
    const state = getState();
    const systemUserToken = process.env.SYSTEM_USER_TOKEN;
    const systemUserID = process.env.SYSTEM_USER_ID;

    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-api-token": systemUserToken,
        "x-user-id": systemUserID,
      },
    };

    try {
      const response = await axios.get(
        `https://${apiBaseUrl}/quote_detail/${quoteId}`,
        config
      );
      return response.data;
    } catch (error) {
      // Handle error as needed
      throw error;
    }
  }
);

// // Thunk to fetch quotes for a specific agent
// export const fetchQuotes = createAsyncThunk(
//   "quotes/fetch",
//   async (_, { getState }) => {
//     const state = getState();
//     const { token, _id, agentEmail } =
//       state.activeUser?.data?.user || state.activeAgent?.data?.agent;

//     const config = {
//       headers: {
//         "Content-Type": "application/json",
//         "x-api-token": token,
//         "x-user-id": _id,
//       },
//     };

//     try {
//       const response = await axios.get(
//         `https://${apiBaseUrl}/quotes/${encodeURIComponent(agentEmail)}`,
//         config
//       );

//       return response.data;
//     } catch (error) {
//       throw error;
//     }
//   }
// );
