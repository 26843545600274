import { createSlice } from "@reduxjs/toolkit";
import { fetchCruiseLines } from '../thunks/widgety/fetchCruiseLines'

const fetchCruiseLinesSlice = createSlice({
  name: "cruiseLines",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  extraReducers(builder) {
    builder.addCase(fetchCruiseLines.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCruiseLines.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchCruiseLines.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  }
});

export const { setCruiseLinesStatistics } = fetchCruiseLinesSlice.actions;

export default fetchCruiseLinesSlice.reducer;