import React from "react";

function GridQuoteList({ quotes }) {
  const openQuoteDetails = (quoteId) => {
    window.open(`/quote_detail/${quoteId}`, "_blank");
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center pb-8">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6">Quotes</h1>
          <p className="mt-2 text-sm text-gray-700">
            View the quotes your agent has provided from your inquiries.
          </p>
          <p className="mt-2 text-sm text-gray-700">
            Available while quantities last. Prices subject to change without
            notice.
          </p>
        </div>
      </div>

      {/* Conditionally render quotes list or a message if no quotes */}
      {Array.isArray(quotes) && quotes.length > 0 ? (
        <ul className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
          {quotes.map((quote, index) => (
            <li
              key={index}
              className="overflow-hidden rounded-xl border border-gray-200">
              <div className="flex items-start flex-col gap-y-2 border-b border-gray-900/5 bg-gray-50 px-6 py-3">
                <div className="text-left">
                  <h3 className="text-sm font-medium leading-6 text-gray-900">
                    {quote.cruiseInformation.name}
                  </h3>
                </div>
                <div className="text-left">
                  <h3 className="text-sm font-medium leading-6 text-gray-500">
                    {quote.cruiseInformation.Ship}
                  </h3>
                </div>
              </div>

              <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                <div className="flex justify-between gap-x-4 py-3">
                  <dt className="text-gray-500 font-normal">Quote created</dt>
                  <dd className="text-gray-500 font-semibold">
                    <time dateTime={quote.createdAt}>
                      {new Date(quote.createdAt).toLocaleDateString("en-US", {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                      })}
                    </time>
                  </dd>
                </div>
                <div className="flex justify-between gap-x-4 py-3">
                  <dt className="text-gray-500 font-normal">Sail Date</dt>
                  <dd className="text-gray-500 font-semibold">
                    <time dateTime={quote.cruiseInformation.SailDate}>
                      {new Date(
                        quote.cruiseInformation.SailDate
                      ).toLocaleDateString("en-US", {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                        timeZone: "UTC",
                      })}
                    </time>
                  </dd>
                </div>

                <div className="flex justify-between gap-x-4 py-3 border-t border-b border-gray-900">
                  <dt className="text-gray-500 font-normal">
                    {quote.quoteInformation.roomPrices.length} prices available
                  </dt>
                </div>
              </dl>

              <div className="flex justify-between gap-x-4 py-3 px-6 border-t border-gray-900/5 bg-gray-50 ">
                <button
                  className="text-sm font-medium leading-6 text-gray-900"
                  onClick={() => openQuoteDetails(quote._id)}>
                  View Quote
                </button>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-700 text-sm px-6 py-4">
          Your agent has not created any quotes yet.
        </p>
      )}
    </div>
  );
}

export default GridQuoteList;
