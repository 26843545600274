import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function AddCruiseModal({ isOpen, onClose, type }) {
  const modalContent = {
    categoryClass: {
      title: "Category Class Information",
      message1: "This is used in the Cruise Filter. Choose between:",
      message2:
        "I - Inside, O - Oceanview, B - Balcony, N - Neighbourhood, S - Suite",
    },
    category: {
      title: "Category Information",
      message1: "The codename for the room category, for example 4N or B2.",
    },
    categoryName: {
      title: "Category Name Information",
      message1:
        "The name of the room, for example 'Mega Rock Star Suite' or 'Concierge Class'.",
    },
    groupRate: {
      title: "Group Rate Information",
      message1: "The price that you can offer.",
    },
    marketRate: {
      title: "Market Rate Information",
      message1:
        "The price that the cruise is currently offered for on the general market.",
    },
    currentPriceAdvantage: {
      title: "Current Price Advantage Information",
      message1: "The amount saved.",
    },
    currentPercentAdvantage: {
      title: "Current Percent Advantage Information",
      message1: "The percent saved.",
    },
    perks: {
      title: "Perks Information",
      message1:
        "Please input any perks asssociated with this cruise. Specify currency if OBC is available.",
      message2: "For example: $100 OBC USD, Open Bar, Free Wifi",
    },
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onClose}>
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          <div className="relative z-10 w-full max-w-md p-6 my-8 bg-white shadow-xl rounded-lg">
            <div className="mt-3 text-center sm:mt-5">
              <Dialog.Title
                as="h3"
                className="text-lg font-semibold leading-6 text-gray-900">
                {modalContent[type].title}
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {modalContent[type].message1}
                </p>

                {(type === "categoryClass" || type === "perks") && (
                  <p className="text-sm text-gray-500 pt-4">
                    {modalContent[type].message2}
                  </p>
                )}
              </div>
            </div>

            <div className="mt-4 sm:mt-4">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={onClose}>
                Close
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
