import { createSlice } from "@reduxjs/toolkit";
import { fetchCruiseDetails } from "../../thunks/widgety/fetchCruiseDetails"; // Make sure to import the fetchCruiseDetails thunk

const initialState = {
  cruiseData: [], // Initialize with an empty array
  loading: false,
  error: null,
};

const fetchCruiseDetailsSlice = createSlice({
  name: "cruiseDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCruiseDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCruiseDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.cruiseData = action.payload;
      })
      .addCase(fetchCruiseDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default fetchCruiseDetailsSlice.reducer;
