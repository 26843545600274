import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateSubdomainThunk } from "../../store/thunks/agentsThunk";
import { fetchActiveAgent } from "../../store/thunks/fetchActiveAgent";
import FeedbackMessage from "./FeedbackMessage";
import { useNavigate } from "react-router-dom";
import { InformationCircleIcon, EyeIcon } from "@heroicons/react/24/outline";
import RegisterModal from "./RegisterModal";

export default function CompleteProfileModal({ onSave, onSkip }) {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Using useNavigate instead of useHistory
  const activeAgent = useSelector((state) => state.activeAgent.data.agent);

  const [editedAgent, setEditedAgent] = useState({ ...activeAgent });
  const [editModeCompanySubdomain, setEditModeCompanySubdomain] =
    useState(false);

  const [isFeedbackVisible, setIsFeedbackVisible] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [feedbackType, setFeedbackType] = useState("");
  const [isSubdomainModalOpen, setIsSubdomainModalOpen] = useState(false);
  const [newSubdomain, setNewSubdomain] = useState("");

  useEffect(() => {
    setEditedAgent({ ...activeAgent });
  }, [activeAgent]);

  const toggleEditModeCompanySubdomain = () => {
    setEditModeCompanySubdomain(!editModeCompanySubdomain);
  };

  const handleSubdomainChange = (e) => {
    setEditedAgent({ ...editedAgent, company_subdomain: e.target.value });
  };

  const handleSubdomainSave = async () => {
    try {
      console.log("New Subdomain:", newSubdomain);
      console.log("Edited agent", editedAgent);
      const response = await dispatch(
        updateSubdomainThunk({
          userId: activeAgent._id,
          updatedAgent: editedAgent,
          subdomain: newSubdomain,
        })
      );

      console.log("Update response", response);
      console.log("Update response payload message:", response.payload.message);
      setFeedbackMessage(response.payload.message);

      if (
        response &&
        response.meta &&
        response.meta.requestStatus === "fulfilled" &&
        response.payload &&
        response.payload.message
      ) {
        showFeedback(response.payload.message);
        if (activeAgent.company_subdomain !== "") {
          // If the company_subdomain is not empty, navigate to subscriptions
          navigate("/agent/subscriptions");
        }
      } else {
        throw new Error("Update failed");
      }

      dispatch(fetchActiveAgent());
    } catch (error) {
      console.error("An error occurred during subdomain save:", error);
      // Check if the error is due to a duplicate subdomain
      if (error.message === "Duplicate subdomain found") {
        setFeedbackMessage(
          "Subdomain already exists. Please choose a different one."
        );
      } else {
        // For other errors, provide a generic error message
        setFeedbackMessage(
          "An error occurred during subdomain save. Please try again later."
        );
      }
      setFeedbackType("error");
      showFeedback("Update failed");
    } finally {
      if (editModeCompanySubdomain) {
        toggleEditModeCompanySubdomain();
      }
    }
  };

  const showFeedback = (message) => {
    setFeedbackMessage(message);

    if (message.toLowerCase().includes("successfully")) {
      setFeedbackType("success");
    } else {
      setFeedbackType("error");
    }

    setIsFeedbackVisible(true);

    setTimeout(() => {
      setIsFeedbackVisible(false);
      setFeedbackMessage("");
    }, 3000);
  };

  const handleSkip = () => {
    navigate("/agent/subscriptions"); // Using navigate to redirect
  };

  const openSubdomainModal = () => {
    setIsSubdomainModalOpen(true);
    console.log("subdomain modal OPEN");
  };

  const closeSubdomainModal = () => {
    setIsSubdomainModalOpen(false);
    console.log("subdomain modal CLOSED");
  };

  return (
    <div
      className="z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true">
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-white rounded-lg p-8 w-full max-w-md mx-auto">
          <div className="text-center flex flex-col items-center">
            <h3 className="text-lg font-medium text-gray-900" id="modal-title">
              Complete Your Profile
            </h3>
            <div className="flex items-center mt-2">
              <p className="text-sm text-gray-500 mr-2">
                Create your subdomain to continue.
              </p>
            </div>
            <p className="text-sm text-gray-500 mt-2">
              The suffix .cruisecompare.ca will be added automatically.
            </p>
          </div>

          <form className="mt-6">
            <div className="flex items-center pb-2">
              <label
                htmlFor="subdomain"
                className="block text-sm font-medium text-gray-700 mr-1">
                Subdomain
              </label>
              <button
                type="button"
                onClick={openSubdomainModal}
                className="text-indigo-600 focus:outline-none hover:text-indigo-500">
                <InformationCircleIcon width={20} height={20} />
              </button>
            </div>
            <div className="flex items-center mt-1">
              <input
                type="text"
                name="subdomain"
                id="subdomain"
                className="block w-full rounded-l-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Enter your subdomain without .cruisecompare.ca"
                value={editedAgent.company_subdomain}
                onChange={handleSubdomainChange}
              />
              <span className="inline-block rounded-r-md border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm px-3 py-2 text-gray-500 bg-white border-l-0">
                .cruisecompare.ca
              </span>
            </div>

            <div className="mt-6 flex justify-between">
              <button
                type="button"
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={handleSubdomainSave}>
                {activeAgent.company_subdomain ? "Continue" : "Save"}
              </button>
              {!activeAgent.company_subdomain && ( // Render the "Skip for now" button only if company_subdomain doesn't exist
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  onClick={handleSkip}>
                  Skip for now
                </button>
              )}
            </div>

            <div>
              {isFeedbackVisible && (
                <div
                  className={`feedback-message mt-1 text-sm leading-6 ${
                    feedbackType === "success"
                      ? "text-green-500"
                      : "text-red-500"
                  }`}>
                  {feedbackType === "success" ? (
                    <FeedbackMessage message={feedbackMessage} success />
                  ) : (
                    <FeedbackMessage success={false}>
                      <ul>
                        <li>There was an error saving your updates.</li>
                        <li>Please check the criteria and try again.</li>
                      </ul>
                    </FeedbackMessage>
                  )}
                </div>
              )}
            </div>
          </form>
        </div>
      </div>

      <div>
        <RegisterModal
          isOpen={isSubdomainModalOpen}
          onClose={closeSubdomainModal}
          type="subdomain"
        />
      </div>
    </div>
  );
}
