import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL

const logoutAgent = createAsyncThunk(
  "agent/logout",
  async (_, { getState }) => {
    const state = getState();
    const { token, _id } = state.activeAgent.data.agent;

    console.log(state.activeAgent.data.agent);
    console.log(token);
    console.log(_id);

    const headers = {
      "Content-Type": "application/json",
      "x-api-token": token,
      "x-user-id": _id,
    };

    const response = await axios.post(
      `https://${apiBaseUrl}/agent/logout`,
      { userId: _id },
      { headers }
    );

    return response.data;
  }
);

export { logoutAgent };
