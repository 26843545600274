import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAgentsInquiriesList } from "../../store/thunks/agentsThunk";
import { fetchActiveAgent } from "../../store/thunks/fetchActiveAgent";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Inquiries.css";
import { XOutline } from "heroicons-react";
import { ClipLoader } from "react-spinners";
import InquiryModal from "../../components/agent/InquiryModal";
import NoSubscriptionWarning from "../../components/agent/NoSubscriptionWarning";
import {
  CheckCircleIcon,
  XMarkIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";

const Inquiries = () => {
  const dispatch = useDispatch();
  const activeAgent = useSelector((state) => state.activeAgent.data.agent);

  const [isSubscribed, setIsSubscribed] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null); // true for ascending, false for descending
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [feedbackColor, setFeedbackColor] = useState("");
  const [error, setError] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInquiry, setSelectedInquiry] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [activeCard, setActiveCard] = useState(null);

  // Assuming you have a users state in your Redux store and it looks like { users: [], error: null, isLoading: false }

  const [agentsInquiries, setAgentsInquiries] = useState([]);

  const inquiriesList = useSelector((state) => state.inquiries); // Update with the correct selector path
  const status = useSelector((state) => state.inquiries.status);
  // const error = useSelector((state) => state.inquiries.error);

  // REDUX USEEFFECT TO GET INQUIRIES - NEW ONE USING REDUX AGENT EMAIL
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(
          fetchAgentsInquiriesList(activeAgent.email)
        );
        const agentsInquiriesData = response.payload;
        setAgentsInquiries(agentsInquiriesData);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [dispatch, activeAgent.email]);

  // Function to handle refresh data
  const refreshData = async () => {
    setIsLoading(true);

    const minimumDisplayTime = 1000;
    const startTime = Date.now();

    try {
      await dispatch(fetchAgentsInquiriesList(activeAgent.email));

      const elapsed = Date.now() - startTime;
      const delayTime = Math.max(0, minimumDisplayTime - elapsed);

      setTimeout(() => {
        setIsLoading(false);
        setFeedback({ message: "Refreshed", color: "bg-green-50" });
        setTimeout(() => {
          setFeedback({ message: "", color: "" });
        }, 1750);
      }, delayTime);
    } catch (error) {
      const elapsed = Date.now() - startTime;
      const delayTime = Math.max(0, minimumDisplayTime - elapsed);

      setTimeout(() => {
        setIsLoading(false);
        setError("Error: There were issues with your refresh");
      }, delayTime);
    }

    dispatch(fetchActiveAgent());
  };
  // useEffect to determine agent subscription status
  useEffect(() => {
    if (activeAgent.subscription == null || activeAgent.subscription === "") {
      setIsSubscribed(false);
      console.log("no sub");
    } else {
      setIsSubscribed(true);
    }
  }, [activeAgent]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  function highlightTerm(text, term) {
    const splitText = String(text).split(new RegExp(`(${term})`, "gi"));

    return splitText.map((part, i) =>
      part.toLowerCase() === term.toLowerCase() ? (
        <span key={i} className="bg-yellow-300">
          {part}
        </span>
      ) : (
        part
      )
    );
  }

  // const totalPage = Math.ceil(usersList.length / itemsPerPage);
  const totalPage = Math.ceil(agentsInquiries.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(0); // Reset to the first page when changing items per page
  };

  const handleInquiryClick = (inquiry) => {
    setSelectedInquiry(inquiry);
  };

  const openModal = (inquiry) => {
    setSelectedInquiry(inquiry);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedInquiry(null);
    setIsModalOpen(false);
  };

  // Function to handle escape key press
  const handleEscapeKey = (e) => {
    if (e.key === "Escape") {
      closeModal();
    }
  };

  useEffect(() => {
    // Add event listener for the escape key
    window.addEventListener("keydown", handleEscapeKey);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleEscapeKey);
    };
  }, []); // Empty dependency array means this effect runs once on mount
  // console.log("this is agentsInquiries", agentsInquiries);

  function formatInquiryDate(dateString) {
    // If dateString is not provided or is empty, return 'N/A'
    if (!dateString || dateString.trim() === "") {
      return "N/A";
    }

    // Convert the inquiry_date string to a Date object
    const inquiryDate = new Date(dateString);

    // Check if the conversion resulted in an invalid date
    if (isNaN(inquiryDate.getTime())) {
      return "N/A";
    }

    // Format the date in Canadian timekeeping format
    const formattedDate = inquiryDate.toLocaleString("en-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // Use 12-hour clock
    });

    return formattedDate;
  }

  // const filteredInquiries = agentsInquiries.filter((inquiry) => {
  //   const lowerSearchTerm = searchTerm.toLowerCase();
  //   const fullName =
  //     `${inquiry.contact.firstName} ${inquiry.contact.lastName}`.toLowerCase();
  //   const email = inquiry.contact.email.toLowerCase();
  //   return (
  //     fullName.includes(lowerSearchTerm) || email.includes(lowerSearchTerm)
  //   );
  // });

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedInquiries = agentsInquiries.slice(startIndex, endIndex);

  // Function to close feedback
  const closeFeedback = () => {
    setFeedback({ message: "", color: "" });
    setError("");
  };

  return (
    <div className="pt-4 ml-6 mr-6 sm:px-6 lg:pr-10 lg:pl-0">
      {/* Header row */}
      <div className="sm:flex sm:items-center justify-between">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Agent Inquiries
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            View all inquiries belonging to {activeAgent.email}.
          </p>
          <p className="mt-2 text-sm text-gray-700">
            You will be notified, via email, for every new inquiry. Be sure to
            check your spam folder.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <div className="flex items-center">
            {/* This is the new flex container */}
            {/* <p
              className="text-base font-semibold leading-6 mr-2.5"
              style={{ color: feedbackColor }}>
              {feedback}
            </p> */}
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={refreshData}>
              Refresh Inquiries
            </button>
          </div>
        </div>
      </div>

      {/* Conditional rendering of feedback */}
      {feedback.message && (
        <div className={`rounded-md ${feedback.color} p-4`}>
          <div className="flex">
            <div className="ml-3">
              <p className="text-sm font-medium text-green-800">
                {feedback.message}
              </p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  type="button"
                  className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                  onClick={closeFeedback} // Close button functionality
                >
                  <span className="sr-only">Dismiss</span>
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Conditional rendering of error */}
      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">{error}</h3>
            </div>
          </div>
        </div>
      )}

      {/* Pagination and search stuff */}
      <div className="mt-4 pb-2 flex justify-between">
        <div className="w-1/2 sm:w-1/4">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search by name or email"
            className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="inline-flex items-center border rounded-md">
          {/* <button
            className="px-3 py-2 border-r text-sm font-medium rounded-l-md text-gray-500 bg-white hover:text-gray-700 hover:bg-gray-50"
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 0}>
            &laquo;
          </button>

          <span className="px-3 py-2 text-sm font-medium text-gray-500 bg-white">
            Page {currentPage + 1} of {totalPage}
          </span>

          <button
            className="px-3 py-2 border-l text-sm font-medium rounded-r-md text-gray-500 bg-white hover:text-gray-700 hover:bg-gray-50"
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPage - 1}>
            &raquo;
          </button> */}

          <button
            className="px-3 py-2 border-r text-sm font-medium rounded-l-md text-gray-500 bg-white hover:text-gray-700 hover:bg-gray-50"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 0}>
            &laquo;
          </button>

          <span className="px-3 py-2 text-sm font-medium text-gray-500 bg-white">
            Page {currentPage + 1} of {totalPage}
          </span>

          <button
            className="px-3 py-2 border-l text-sm font-medium rounded-r-md text-gray-500 bg-white hover:text-gray-700 hover:bg-gray-50"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPage - 1}>
            &raquo;
          </button>

          {/* Temporarily comment this out */}
          <div className="px-3 py-2">
            <label htmlFor="itemsPerPage" className="sr-only">
              Items per page
            </label>
            <select
              id="itemsPerPage"
              className="border-gray-300 rounded-md shadow-sm"
              onChange={handleItemsPerPageChange}
              value={itemsPerPage}>
              <option value={5}>5 per page</option>
              <option value={10}>10 per page</option>
              <option value={20}>20 per page</option>
              <option value={50}>50 per page</option>
            </select>
          </div>
        </div>
      </div>

      {/* Subscription warning */}
      {isSubscribed ? null : <NoSubscriptionWarning />}

      {/* Table */}
      <div>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div className={`relative ${!isSubscribed && "pointer-events-none"}`}>
            {!isSubscribed && (
              <div className="absolute inset-0 bg-opacity-50 backdrop-blur-sm z-10"></div>
            )}
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8">
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Inquiry Date
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Message
                  </th>
                </tr>
              </thead>
              <tbody
                className={`divide-y divide-gray-200 bg-white ${
                  !isSubscribed && "opacity-50"
                }`}>
                {Array.isArray(agentsInquiries) &&
                agentsInquiries.length > 0 ? (
                  agentsInquiries.map((agent) =>
                    agent.inquiries.map((inquiry, index) => (
                      <tr
                        key={index}
                        onClick={() => openModal(inquiry)} // Handle click event
                        style={{ cursor: "pointer" }} // Add a pointer cursor to indicate clickability
                      >
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                          {inquiry.contact.firstName} {inquiry.contact.lastName}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {inquiry.contact.email}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {formatInquiryDate(inquiry.inquiry_date)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {inquiry.contact.message}
                        </td>
                      </tr>
                    ))
                  )
                ) : (
                  <tr>
                    <td colSpan="2">
                      <p className="mt-2 text-sm text-gray-700">
                        You have no inquires available.
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}

        {/* Render InquiryModal if isModalOpen is true */}
        {isModalOpen && selectedInquiry && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="absolute inset-0 bg-black opacity-40"></div>
            <div className="relative z-10">
              <button
                onClick={closeModal}
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
                <XOutline className="w-6 h-6" />
              </button>
              <InquiryModal inquiry={selectedInquiry} />
            </div>
          </div>
        )}
      </div>

      {/* Inquiries list */}
      <div className="-mx-4 -my-2 pb-20 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <ul
            role="list"
            className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {status === "loading" && (
              <tr>
                <td colSpan="5" className="text-center py-5">
                  <ClipLoader color="#4535be" size={75} />
                </td>
              </tr>
            )}
            {status === "failed" && (
              <tr>
                <td colSpan="5" className="text-center py-5 text-red-500">
                  Error: {error}
                </td>
              </tr>
            )}

            {status === "succeeded"
              ? agentsInquiries.inquiries.map((agent) =>
                  agent.inquiries.map((user, index) => (
                    <li
                      key={user._id}
                      className="col-span-1 divide-y divide-gray-200 rounded-lg bg-gray-100 shadow">
                      <div className="p-6 space-y-2">
                        <div className="flex items-center justify-between space-x-6">
                          <div className="flex-1 truncate">
                            <div className="flex items-center space-x-3">
                              <h3 className="truncate text-sm font-medium text-gray-900">
                                {user.first_name} {user.last_name}
                              </h3>
                            </div>
                            <p className="mt-1 truncate text-sm text-gray-500">
                              <span className="inline-flex flex-shrink-0 items-center rounded-full bg-blue-50 px-1.5 py-0.5 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">
                                {user.email}
                              </span>
                            </p>
                          </div>
                          <img
                            className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
                            src={user.imageUrl}
                            alt=""
                          />
                        </div>
                        <div className="overflow-x-auto overflow-y-auto">
                          <div className="min-w-full whitespace-nowrap">
                            {user.inquiries.map((inquiry, inquiryIndex) => (
                              <div
                                key={inquiryIndex}
                                className="relative bg-white border border-gray-200 rounded-lg shadow p-4 my-2 max-w-full card"
                                onClick={() => openModal(inquiry)}>
                                <div className="text-sm text-gray-500">
                                  <div className="flex max-w-full custom-wrap">
                                    <div className="w-1/4">
                                      <b>Message</b>
                                    </div>
                                    <div
                                      className="w-3/4"
                                      style={{ maxWidth: "80%" }}>
                                      {inquiry.contact.message}
                                    </div>
                                  </div>
                                  {inquiry.cruiseData && (
                                    <div className="pt-3 flex max-w-full custom-wrap">
                                      <div className="w-1/4">
                                        <b>Data</b>
                                      </div>
                                      <div className="w-3/4">
                                        <pre>
                                          Product: {inquiry.cruiseData.Product}
                                          {"\n"}
                                          Ship: {inquiry.cruiseData.Ship}
                                          {"\n"}
                                          SailDate:{" "}
                                          {inquiry.cruiseData.SailDate}
                                        </pre>
                                      </div>
                                    </div>
                                  )}
                                  {inquiry.name && (
                                    <div className="pt-3 flex max-w-full custom-wrap">
                                      <div className="w-1/4">
                                        <b>Name</b>
                                      </div>
                                      <div className="w-3/4">
                                        {inquiry.name}
                                      </div>
                                    </div>
                                  )}
                                  {inquiry.ship_title && (
                                    <div className="flex max-w-full custom-wrap">
                                      <div className="w-1/4">
                                        <b>Ship</b>
                                      </div>
                                      <div className="w-3/4">
                                        {inquiry.ship_title}
                                      </div>
                                    </div>
                                  )}
                                  {inquiry.starts_on && (
                                    <div className="flex max-w-full custom-wrap">
                                      <div className="w-1/4">
                                        <b>Starts</b>
                                      </div>
                                      <div className="w-3/4">
                                        {inquiry.starts_on}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      {isModalOpen && selectedInquiry && (
                        <div className="fixed inset-0 flex items-center justify-center z-50">
                          <div className="absolute inset-0 bg-black opacity-40"></div>
                          <div className="relative z-10">
                            <button
                              onClick={closeModal}
                              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
                              <XOutline className="w-6 h-6" />
                            </button>
                            <InquiryModal inquiry={selectedInquiry} />
                          </div>
                        </div>
                      )}
                    </li>
                  ))
                )
              : null}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Inquiries;
