import axios from "axios";
import { apiBaseUrl } from "../../../config"; // Import the dynamic API base URL
import { createAsyncThunk } from "@reduxjs/toolkit";

const sendGa4Event = createAsyncThunk(
    "ga/sendGaEvent",
    async (eventData, { getState }) => {
        const state = getState();
        const { token, _id } = state.activeUser.data.user;

        const config = {
            headers: {
                "Content-Type": "application/json",
                "x-api-token": token,
                "x-user-id": _id,
                // Add other headers as needed
            },
        };

        let clientId = "";

        const cookies = document.cookie.split(";");
        for (const cookie of cookies) {
            const parts = cookie.trim().split("=");
            if (parts[0] === "_ga") {
                clientId = parts[1].split(".").slice(-2).join(".");
                break;
            }
        }

        if (!clientId) {
            console.error("GA Client ID not found.");
            return;
        }

        const data = {
            client_id: clientId,
            events: eventData,
        };

        try {
            const response = await axios.post(
                `https://${apiBaseUrl}/gaEvent`, // Use the apiBaseUrl here
                data,
                config
            );
            return response;
            // Optionally dispatch a success action here if needed.
            // e.g., dispatch({ type: 'GA_EVENT_SENT', payload: eventData });
        } catch (error) {
            console.error("Error sending event:", error);
            // Optionally dispatch an error action here if needed.
            // e.g., dispatch({ type: 'GA_EVENT_ERROR', payload: error });
        }
    }
);

export { sendGa4Event };
