import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL

// Define the resetPassword thunk
const resetPassword = createAsyncThunk(
    "user/resetPassword",
    async ({ email, newPassword, token }) => {
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    // Add other headers as needed
                },
            };

            // Create a request body object with email and newPassword
            const requestBody = {
                email,
                newPassword,
                token
            };

            console.log(requestBody);

            // Make a POST request to the reset password endpoint with the user's email and newPassword
            const response = await axios.post(
                `https://${apiBaseUrl}/reset_password`,
                requestBody,
                config
            );

            // Return the response data on success
            return response.data;
        } catch (error) {
            // Handle any errors that occurred during the request
            throw error;
        }
    }
);

const verifyResetToken = createAsyncThunk(
    "user/verifyResetToken",
    async (resetToken) => {
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    // Add other headers as needed
                },
            };

            // Make a POST request to the verfiy token endpoint with the user's email
            const response = await axios.post(
                `https://${apiBaseUrl}/verify_reset_token`,
                { resetToken },
                config
            );

            // Return the response data on success
            return response.data;
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.error
            ) {
                throw new Error(error.response.data.error);
            } else {
                throw error; // if the error structure is not as expected, throw the whole error.
            }
        }
    }
);

export { resetPassword, verifyResetToken };
