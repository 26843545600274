import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { apiBaseUrl } from "../../config";

import NoSubscriptionWarning from "../../components/agent/NoSubscriptionWarning";
import EditQuoteModal from "../../components/agent/EditQuoteModal";

import { fetchAgentQuotes } from "../../store/thunks/fetchAgentQuotes";
import { fetchActiveAgent } from "../../store/thunks/fetchActiveAgent";
import { sendClientQuote } from "../../store/thunks/sendClientQuoteThunk";
import { ClipLoader } from "react-spinners";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

const YourQuotes = () => {
  // Extract quotes data and associated state from Redux store
  const dispatch = useDispatch();
  const activeAgent = useSelector((state) => state.activeAgent.data.agent);

  const quotes = useSelector((state) => state.quotes.quotes.quotes);
  const error = useSelector((state) => state.quotes.error);
  const status = useSelector((state) => state.quotes.status);

  const [selectedQuote, setSelectedQuote] = useState(null);

  const subscriptionActive = useSelector((state) => {
    const subscriptionStatus =
      state.subscriptionStatus.data.status === "active";
    // const userIsAdmin = state.activeUser.data.user.user_type === "admin";
    const userIsAgent = state.activeAgent.data.agent.user_type === "agent";

    return subscriptionStatus || userIsAgent;
  });

  const [isSubscribed, setIsSubscribed] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null); // true for ascending, false for descending
  const [itemsPerPage, setItemsPerPage] = useState(5);
  // const [totalPages, setTotalPages] = useState(1); // Set initial value after fetching the first time
  const [feedback, setFeedback] = useState("");
  const [feedbackColor, setFeedbackColor] = useState("");
  const [editFormData, setEditFormData] = useState({
    GroupId: "",
    GroupName: "",
    Ship: "",
    SailDate: "",
    Product: "",
    Nights: null,
    Currency: "",
    CategoryClass: "",
    Category: "",
    CategoryName: "",
    RoomsAvailable: null,
    GroupRate: null,
    CurrentGroupRate: null,
    CurrentPriceAdv: null,
    CurrentPercentAdv: null,
    CombinedKey: "",
  });
  const [editMode, setEditMode] = useState(null);
  const [showEditConfirmModal, setShowEditConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [filters, setFilters] = useState(initialFilters);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const [showSuccessFeedback, setShowSuccessFeedback] = useState(false);
  const [showDeleteFeedback, setShowDeleteFeedback] = useState(false);

  const handleEditSuccess = () => {
    setShowSuccessFeedback(true);
    setTimeout(() => {
      setShowSuccessFeedback(false);
    }, 3000); // Set timeout to hide feedback after 3 seconds
  };

  const handleDeleteSuccess = () => {
    setShowDeleteFeedback(true);
    setTimeout(() => {
      setShowDeleteFeedback(false);
    }, 3000); // Set timeout to hide feedback after 3 seconds
  };

  // useEffect to dispatch using selling agent on every component mount
  useEffect(() => {
    if (activeAgent?.email) {
      dispatch(fetchAgentQuotes({ agentEmail: activeAgent.email }));
    }
  }, [dispatch, activeAgent]);

  // Refresh handler
  const refreshData = () => {
    dispatch(fetchAgentQuotes({ agentEmail: activeAgent.email }));
    dispatch(fetchActiveAgent());
  };

  // useEffect to determine agent subscription status
  useEffect(() => {
    if (activeAgent.subscription == null || activeAgent.subscription === "") {
      setIsSubscribed(false);
      console.log("no sub");
    } else {
      setIsSubscribed(true);
    }
  }, [activeAgent]);

  if (status === "loading") return <p>Loading...</p>;
  if (error === "failed") return <p>Error: {error}</p>;

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  let displayedQuotes = []; // Define displayedQuotes variable outside the conditional block

  if (quotes && quotes.length > 0 && searchTerm) {
    displayedQuotes = quotes.filter((quote) => {
      return Object.values(quote).some((val) =>
        String(val).toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    // Use displayedQuotes in your rendering logic
  } else {
    // Handle the case where quotes or searchTerm is not defined
  }

  const handleSendEmail = async (quote) => {
    try {
      if (isSendingEmail) return; // Prevent multiple clicks
      setIsSendingEmail(true); // Set the button to disabled state

      const action = dispatch(sendClientQuote(quote)); // Dispatch the thunk and obtain the action
      const response = await action; // Wait for the action to complete

      console.log(
        "Response from sendClientQuote thunk call:",
        response.payload
      );

      if (response.payload && response.payload.message) {
        console.log("Email sent successfully:", response.payload.message);
        alert("Email sent successfully!");
      } else {
        console.error("Failed to send email");
        console.error("Error data:", response.payload);
        alert("Failed to send email. Please try again later.");
      }

      // Enable the button after 5 seconds
      setTimeout(() => {
        setIsSendingEmail(false);
      }, 3000);
    } catch (error) {
      console.error("Error sending email:", error.message);
      console.error("Detailed error:", error);
      alert(
        "An error occurred while sending the email. Please try again later."
      );
      setIsSendingEmail(false); // Reset sending status in case of error
    }
  };

  return (
    <div className="pt-4 ml-6 mr-6 sm:px-6 lg:pr-10 lg:pl-0">
      {/* Header and Refresh DB button */}
      <div className="sm:flex sm:items-center justify-between">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Your Quotes
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            These are the quotes you have created for your clients.
          </p>
          <p className="mt-2 text-sm text-gray-700">
            Upon creation of a quote, use the 'Send Email' button to notify your
            client that the quote is ready.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <div className="flex items-center">
            <p
              className="text-base font-semibold leading-6 mr-2.5"
              style={{ color: feedbackColor }}>
              {feedback}
            </p>
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={refreshData}>
              Refresh Your Quotes
            </button>
          </div>
        </div>
      </div>

      {/* Search and Pagination */}
      <div className="my-4 flex justify-between">
        <div className="w-1/2 sm:w-1/4">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search by any field"
            className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        {/* <div className="inline-flex items-center rounded-md">
          <div className="px-3 py-2">
            <label htmlFor="itemsPerPage" className="sr-only">
              Items per page
            </label>
            <select
              id="itemsPerPage"
              className="border-gray-300 rounded-md shadow-sm"
              onChange={handleItemsPerPageChange}
              value={itemsPerPage}>
              <option value={5}>5 per page</option>
              <option value={10}>10 per page</option>
              <option value={20}>20 per page</option>
              <option value={50}>50 per page</option>
            </select>
          </div>
        </div> */}
      </div>

      {/* Success feedback */}
      {showSuccessFeedback && (
        <div className="rounded-md bg-green-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-green-800">
                Successfully updated
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Delete feedback */}
      {showDeleteFeedback && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                Quote deleted
              </h3>
            </div>
          </div>
        </div>
      )}

      {/* Subscription warning */}
      {isSubscribed ? null : <NoSubscriptionWarning />}

      {/* Table */}
      <div className="mt-8 flow-root pb-20">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 relative">
            {status === "loading" ? (
              <tr>
                <td colSpan="5" className="text-center py-5">
                  <ClipLoader color="#4535be" size={75} />
                </td>
              </tr>
            ) : error ? (
              <div className="error-container">
                <p>Error fetching your quotes: {error}</p>
                {/* <button onClick={handleClearError}>Clear Error</button> */}
              </div>
            ) : (
              <div
                className={`relative ${
                  !isSubscribed && "pointer-events-none"
                }`}>
                {!isSubscribed && (
                  <div className="absolute inset-0 bg-opacity-50 backdrop-blur-sm z-10"></div>
                )}
                <table className="min-w-full divide-y divide-gray-300 pr-8">
                  {/* Table Header */}
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-400 sm:pl-0 cursor-pointer">
                        {"    "}
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 cursor-pointer">
                        Client Name
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer">
                        Client Email
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer">
                        Cruise Line
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer">
                        Ship
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer">
                        Sail Date
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer">
                        Cruise Name
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer">
                        Created At
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer">
                        Updated At
                      </th>
                    </tr>
                  </thead>

                  {/* Table Body */}
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {/* Displaying the quotes data */}
                    {quotes && quotes.length > 0 ? (
                      quotes.map((quote) => (
                        <tr key={quote._id} className="even:bg-gray-100">
                          <td>
                            <div className="flex space-x-2">
                              <button
                                className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                onClick={() => {
                                  setSelectedQuote(quote);
                                  setShowEditModal(true);
                                  setIsModalOpen(true);
                                }}>
                                Edit
                              </button>
                              <button
                                className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                onClick={() => {
                                  window.open(
                                    `/quote_detail/${quote._id}`,
                                    "_blank"
                                  );
                                }}>
                                View
                              </button>
                            </div>

                            <button
                              className="rounded bg-indigo-600 px-1 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-2"
                              onClick={() => handleSendEmail(quote)}
                              disabled={isSendingEmail} // Disable the button if sending email
                            >
                              {isSendingEmail ? "Sending..." : "Send Email"}
                            </button>
                          </td>

                          <td className="whitespace-nowrap px-2 py-4 text-sm text-gray-500">
                            {quote.clientInformation.ClientFirstName}{" "}
                            {quote.clientInformation.ClientLastName}
                          </td>
                          <td className="whitespace-nowrap px-2 py-4 text-sm text-gray-500">
                            {quote.clientInformation.ClientEmail}
                          </td>
                          <td className="whitespace-nowrap px-2 py-4 text-sm text-gray-500">
                            {quote.cruiseInformation.CruiseLine}
                          </td>
                          <td className="whitespace-nowrap px-2 py-4 text-sm text-gray-500">
                            {quote.cruiseInformation.Ship}
                          </td>
                          <td className="whitespace-nowrap px-2 py-4 text-sm text-gray-500">
                            {new Date(
                              quote.cruiseInformation.SailDate
                            ).toLocaleDateString("en-US", {
                              timeZone: "UTC",
                              month: "long",
                              day: "numeric",
                              year: "numeric",
                            })}
                          </td>

                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {quote.cruiseInformation.name}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {/* Render additional information here */}
                            {quote.createdAt && (
                              <span>
                                {new Date(quote.createdAt).toLocaleDateString(
                                  "en-US",
                                  {
                                    month: "long",
                                    day: "numeric",
                                    year: "numeric",
                                  }
                                )}
                                {", "}
                                {new Date(quote.createdAt).toLocaleTimeString(
                                  "en-US",
                                  {
                                    hour: "numeric",
                                    minute: "numeric",
                                  }
                                )}
                              </span>
                            )}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {/* Render additional information here */}
                            {quote.updatedAt && (
                              <span>
                                {new Date(quote.updatedAt).toLocaleDateString(
                                  "en-US",
                                  {
                                    month: "long",
                                    day: "numeric",
                                    year: "numeric",
                                  }
                                )}
                                {", "}
                                {new Date(quote.updatedAt).toLocaleTimeString(
                                  "en-US",
                                  {
                                    hour: "numeric",
                                    minute: "numeric",
                                  }
                                )}
                              </span>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="10">
                          <p className="mt-2 text-sm text-gray-700">
                            You have no quotes available.
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>

      {showEditModal && selectedQuote && (
        <EditQuoteModal
          key={selectedQuote?._id}
          isOpen={showEditModal}
          onClose={() => setShowEditModal(false)}
          quote={selectedQuote}
          onEditSuccess={handleEditSuccess}
          onDeleteSuccess={handleDeleteSuccess}
        />
      )}
    </div>
  );
};

export default YourQuotes;
