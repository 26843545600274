import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchActiveAgent } from "../../store/thunks/fetchActiveAgent";
import { CheckIcon } from "@heroicons/react/20/solid";
import { AgentPricingTexts } from "../../components/agentLandingPage/DataLists";
import FeedbackMessage from "../../components/agent/FeedbackMessage";
import NoSubscriptionWarning from "../../components/agent/NoSubscriptionWarning";
import SubscriptionModal from "../../components/modals/SubscriptionModal";
import { addToCart } from "../../store/thunks/facebook/addToCart";

const Subscriptions = () => {
  const dispatch = useDispatch();
  const activeAgent = useSelector((state) => state.activeAgent.data.agent);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const [isBusinessModalOpen, setIsBusinessModalOpen] = useState(false);
  const [isFirstClassModalOpen, setIsFirstClassModalOpen] = useState(false);

  const [editModeSubscriptionPlan, setEditModeSubscriptionPlan] =
    useState(false);
  const toggleEditModeSubscriptionPlan = () => {
    setEditModeSubscriptionPlan(!editModeSubscriptionPlan);
  };

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [feedbackType, setFeedbackType] = useState(""); // You can use this for different types of feedback (e.g., success, error)
  const [isFeedbackVisible, setIsFeedbackVisible] = useState(false);

  useEffect(() => {
    dispatch(fetchActiveAgent);
  });

  // useEffect to determine agent subscription status
  useEffect(() => {
    if (activeAgent.subscription == null || activeAgent.subscription === "") {
      setIsSubscribed(false);
      console.log("no sub");
    } else {
      setIsSubscribed(true);
    }
  }, [activeAgent]);

  // Function to show the Business Class subscription modal
  const openBusinessModal = () => {
    setIsBusinessModalOpen(true);
  };

  // Function to show the First Class subscription modal
  const openFirstClassModal = () => {
    setIsFirstClassModalOpen(true);
  };

  const tiers = [
    {
      name: AgentPricingTexts.tiers[0].title,
      id: "tier-business",
      href: "#",
      priceMonthly: AgentPricingTexts.tiers[0].price,
      description: AgentPricingTexts.tiers[0].description,
      features: AgentPricingTexts.tiers[0].perks,
      buttonText: "Subscribe to Business Class",
      onClick: () => setIsBusinessModalOpen(true), // Function to open Business Class modal
    },
    {
      name: AgentPricingTexts.tiers[1].title,
      id: "tier-first-class",
      href: "#",
      priceMonthly: AgentPricingTexts.tiers[1].price,
      description: AgentPricingTexts.tiers[1].description,
      features: AgentPricingTexts.tiers[1].perks,
      buttonText: "Subscribe to First Class",
      onClick: () => setIsFirstClassModalOpen(true), // Function to open First Class modal
    },
  ];

  const handleRefresh = () => {
    dispatch(fetchActiveAgent());
  };

  const handleViewReceipt = () => {
    // Check if chargesData is available and has the receipt_url property
    if (
      activeAgent.subscription &&
      activeAgent.subscription.chargesData &&
      activeAgent.subscription.chargesData.data[0] &&
      activeAgent.subscription.chargesData.data[0].receipt_url
    ) {
      // Open the receipt URL in a new tab
      window.open(
        activeAgent.subscription.chargesData.data[0].receipt_url,
        "_blank"
      );
    } else {
      console.error("Receipt URL not found in chargesData.");
    }
  };

  return (
    <>
      <div className="pt-4 ml-6 mr-6 sm:px-6 lg:pr-10 lg:pl-0">
        {/* Main profile for all users */}
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Subscription Plans
          </h2>

          <p className="mt-1 text-md leading-6 text-gray-500">
            {isSubscribed &&
            activeAgent.subscription &&
            activeAgent.subscription.productData
              ? `Your current plan is: ${activeAgent.subscription.productData.name}`
              : "You are unsubscribed"}
          </p>

          <div className="mt-2">
            <button
              onClick={handleRefresh}
              className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Refresh
            </button>
          </div>

          <div className="mt-2">
            <button
              onClick={handleViewReceipt}
              className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              View Receipt
            </button>
          </div>

          {/* Render the feedback message */}
          <div>
            {isFeedbackVisible && (
              <div
                className={`feedback-message mt-1 text-sm leading-6 ${
                  feedbackType === "success" ? "text-green-500" : "text-red-500"
                }`}>
                {feedbackType === "success" ? (
                  <FeedbackMessage message={feedbackMessage} success />
                ) : (
                  <FeedbackMessage success={false}>
                    {/* Render the error message when the call fails */}
                    <ul>
                      <li>There was an error saving your updates.</li>
                      <li>Please check the criteria and try again.</li>
                    </ul>
                  </FeedbackMessage>
                )}
              </div>
            )}
          </div>

          {/* Subscription warning */}
          {isSubscribed ? null : <NoSubscriptionWarning />}

          <div className="bg-white pb-24 sm:pb-32">
            <div className="mx-auto max-w-full px-6 lg:px-8">
              {/* Adjusted max-width to full */}
              <div className="mt-20 flow-root">
                <div className="isolate -mt-16 grid max-w-full grid-cols-1 gap-y-16 divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-3 lg:divide-x lg:divide-y-0 xl:-mx-4">
                  {/* Adjusted max-width to full */}
                  {tiers.map((tier) => (
                    <div
                      key={tier.id}
                      className="pt-16 lg:px-8 lg:pt-0 xl:px-14">
                      <h3
                        id={tier.id}
                        className="text-base font-semibold leading-7 text-gray-900">
                        {tier.name}
                      </h3>
                      <p className="mt-6 flex items-baseline gap-x-1">
                        <span className="text-5xl font-bold tracking-tight text-gray-900">
                          {tier.priceMonthly}
                        </span>
                        <span className="text-sm font-semibold leading-6 text-gray-600">
                          /month
                        </span>
                      </p>

                      <a
                        href={tier.href}
                        aria-describedby={tier.id}
                        className="mt-10 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => {
                          console.log("Clicked tier.id:", tier.id);

                          if (tier.id === "tier-business") {
                            openBusinessModal();
                          } else {
                            openFirstClassModal();
                          }
                        }}>
                        {tier.buttonText}
                      </a>

                      <p className="mt-10 text-sm font-semibold leading-6 text-gray-900">
                        {tier.description}
                      </p>
                      <ul
                        role="list"
                        className="mt-6 space-y-3 text-sm leading-6 text-gray-600">
                        {tier.features.map((feature) => (
                          <li key={feature} className="flex gap-x-3">
                            <CheckIcon
                              className="h-6 w-5 flex-none text-indigo-600"
                              aria-hidden="true"
                            />
                            {feature}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>

                {/* Subscription modal for business class */}
                <SubscriptionModal
                  isOpen={isBusinessModalOpen}
                  onClose={() => setIsBusinessModalOpen(false)}
                  onConfirm={() => {
                    dispatch(addToCart(activeAgent));

                    window.open(
                      `https://buy.stripe.com/test_9AQcNJ0Bg5UXfFmcMO?client_reference_id=${activeAgent._id}`,
                      "_blank"
                    );
                  }}
                />

                {/* Subscription modal for first class */}
                <SubscriptionModal
                  isOpen={isFirstClassModalOpen}
                  onClose={() => setIsFirstClassModalOpen(false)}
                  onConfirm={() => {
                    dispatch(addToCart(activeAgent));
                    window.open(
                      `https://buy.stripe.com/test_7sI8xt6ZEcjlal2cMP?client_reference_id=${activeAgent._id}`,
                      "_blank"
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscriptions;
