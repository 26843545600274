import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL

const fetchAgentQuotes = createAsyncThunk(
  "quotes/fetchAgentQuotes",
  async (_, { getState }) => {
    const state = getState();
    const { token, _id, email } = state.activeAgent?.data?.agent;

    if (!token || !_id || !email) {
      throw new Error("No active agent found");
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-agent-api-token": token,
        "x-agent-id": _id,
      },
      params: {
        agentEmail: email, // Pass email as a query parameter in the params object
      },
    };

    try {
      const response = await axios.get(
        `https://${apiBaseUrl}/agentquotes`,
        config
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export { fetchAgentQuotes };
