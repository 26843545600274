import React from "react";

const UsageTerms = () => {
    return (
        <div className="terms-container">
            <h1 className="text-2xl font-semibold mb-4">
                Terms and Conditions of Use
            </h1>
            <p className="mb-6">
                These terms and conditions ("Terms") govern your use of our
                software and services ("Software"). By using the Software, you
                agree to comply with and be bound by these Terms. Please read
                them carefully before using the Software.
            </p>
            <h2 className="text-xl font-semibold mb-2">
                1. Acceptance of Terms
            </h2>
            <p className="mb-6">
                By accessing or using the Software, you acknowledge that you
                have read, understood, and agree to be bound by these Terms. If
                you do not agree to these Terms, please do not use the Software.
            </p>
            <h2 className="text-xl font-semibold mb-2">2. Use of Software</h2>
            <p className="mb-6">
                You may use the Software solely for its intended purpose and in
                compliance with all applicable laws and regulations. You agree
                not to use the Software for any illegal, unauthorized, or
                unethical purposes.
            </p>
            <h2 className="text-xl font-semibold mb-2">
                3. Intellectual Property
            </h2>
            <p className="mb-6">
                All intellectual property rights in the Software and its content
                are owned by us or our licensors. You may not reproduce, modify,
                distribute, or create derivative works based on the Software
                without our prior written consent.
            </p>
            <h2 className="text-xl font-semibold mb-2">4. Privacy</h2>
            <p className="mb-6">
                We collect, use, and protect your personal information in
                accordance with our Privacy Policy. By using the Software, you
                consent to the collection and use of your information as
                described in the Privacy Policy.
            </p>
            <h2 className="text-xl font-semibold mb-2">
                5. Disclaimer of Warranty
            </h2>
            <p className="mb-6">
                The Software is provided "as is" and without warranties of any
                kind. We do not warrant that the Software will be error-free,
                secure, or free from interruptions. Please be aware that cruise
                details provided through the Software, including but not limited
                to pricing, cruise availability, cabin availability, special
                promotions, and other information, may be out of date or subject
                to change. We make no representations or warranties regarding
                the accuracy, completeness, or timeliness of such information.
            </p>
            <h2 className="text-xl font-semibold mb-2">
                6. Limitation of Liability
            </h2>
            <p className="mb-6">
                We shall not be liable for any indirect, consequential,
                incidental, or punitive damages arising out of or in connection
                with your use of the Software.
            </p>
            <h2 className="text-xl font-semibold mb-2">
                7. Modifications to Terms
            </h2>
            <p className="mb-6">
                We reserve the right to modify these Terms at any time. Changes
                will be effective when posted on this page. Your continued use
                of the Software after changes to the Terms indicates your
                acceptance of the modified Terms.
            </p>
            <h2 className="text-xl font-semibold mb-2">8. Governing Law</h2>
            <p className="mb-6">
                These Terms are governed by the laws of Ontario. Any disputes
                arising under or in connection with these Terms shall be subject
                to the exclusive jurisdiction of the courts in Ontario.
            </p>
            <h2 className="text-xl font-semibold mb-2">9. Travel Agents</h2>
            <p className="mb-6">
                Our Software may facilitate interactions with third-party travel
                agents who are independent entities. We do not control the
                actions, services, or practices of these travel agents. Your
                interactions and transactions with travel agents are solely
                between you and the travel agents. We are not responsible for
                any disputes, issues, or actions arising from your interactions
                with travel agents facilitated through our Software.
            </p>
            <h2 className="text-xl font-semibold mb-2">
                10. We Are Not a Seller of Travel
            </h2>
            <p className="mb-6">
                We want to make it clear that we are solely a software platform
                that aggregates and provides information about travel deals from
                various sources. We do not operate as a seller of travel, and we
                do not offer or sell travel services or packages directly to
                consumers. Any travel bookings or transactions you make are
                directly with the respective travel agents or providers, and we
                are not a party to those transactions.
            </p>
            <h2 className="text-xl font-semibold mb-2">
                11. Safeguards for Personal Information
            </h2>
            <p className="mb-6">
                We take data security seriously and have implemented safeguards
                to protect your personal information. Our databases and
                infrastructure are protected by industry-standard encryption and
                security measures. We use firewalls, secure socket layer (SSL)
                technology, and other security protocols to prevent unauthorized
                access, loss, alteration, or disclosure of your data.
            </p>
            <p className="mb-6">
                Additionally, when processing payments through our Software, we
                partner with Stripe, a trusted payment gateway that is compliant
                with all applicable credit card rules and security standards.
                Stripe is certified as a Payment Card Industry Data Security
                Standard (PCI DSS) Level 1 Service Provider. This is the highest
                level of certification available in the payment industry,
                ensuring that your payment information is handled with the
                utmost security.
            </p>
            <p className="mb-6">
                It's important to note that at no time does CruiseCompare.ca
                ever have possession of your credit card information. All
                payment processing, including the entry and storage of credit
                card details, is handled directly by Stripe.
            </p>
            <h2 className="text-xl font-semibold mb-2">12. User Conduct</h2>
            <p className="mb-6">
                The information and services provided by our Software are
                intended solely for their intended purpose – to help you gather
                and access travel deals and related information. Any usage of
                the Software outside of its intended purpose is strictly
                prohibited and may result in immediate account revocation
                without refund or warning.
            </p>
            <p className="mb-6">
                You agree not to misuse, abuse, interfere with, or disrupt the
                functionality of the Software, including any interactions with
                travel agents or providers facilitated through the Software. Any
                form of abuse, harassment, or behavior that negatively impacts
                other users, travel agents, or our platform will not be
                tolerated and may result in account termination.
            </p>
            <p className="mb-6">
                Furthermore, you agree not to engage in any activities that
                violate applicable laws, regulations, or the rights of others.
                You shall not upload, distribute, or transmit any harmful or
                malicious content, including viruses, malware, or any content
                that infringes upon intellectual property rights.
            </p>
            <p className="mb-6">
                We reserve the right to investigate and take appropriate
                actions, including terminating your account, if we have
                reasonable grounds to believe that you are violating these user
                conduct guidelines or engaging in any behavior that threatens
                the integrity of our platform, the experience of other users, or
                the reputation of our partners.
            </p>
            <h2 className="text-xl font-semibold mb-2">
                13. Subscription Terms
            </h2>
            <p className="mb-6">
                Certain features and services of our Software may be available
                through a paid annual subscription ("Subscription"). By
                subscribing to our Service, you agree to the following terms:
            </p>
            <ul className="list-disc pl-6 mb-6">
                <li>
                    The Subscription is valid for one year from the date of
                    purchase and will automatically renew at the end of each
                    subscription period unless you cancel your subscription.
                </li>
                <li>
                    The subscription fee will be automatically charged to your
                    chosen payment method on the renewal date. You authorize us
                    to charge your payment method for the renewal fee.
                </li>
                <li>
                    You can cancel your subscription at any time by accessing
                    your user profile and clicking the "Cancel Subscription"
                    button. Upon cancellation, your subscription will expire at
                    the end of the current subscription period, and you will not
                    be charged for the next renewal.
                </li>
                <li>
                    Please note that cancellations must be processed through
                    your user profile and that we do not offer refunds for
                    unused subscription periods.
                </li>
            </ul>
            <p className="mb-6">
                It is your responsibility to ensure that your payment
                information is accurate and up to date to prevent any disruption
                to your subscription service. Failure to renew your subscription
                due to outdated payment information will result in the loss of
                access to subscription features.
            </p>
            <h2 className="text-xl font-semibold mb-2">14. Indemnification</h2>
            <p className="mb-6">
                You agree to indemnify, defend, and hold harmless
                CruiseCompare.ca and its affiliates, officers, directors,
                employees, agents, and partners from and against any claims,
                liabilities, damages, losses, costs, and expenses, including
                reasonable attorneys' fees, arising out of or in connection with
                your use of the Software, your violation of these Terms, or your
                infringement of any third-party rights. This obligation will
                survive the termination of your access to the Software.
            </p>
            <h2 className="text-xl font-semibold mb-2">
                15. User Age and Consent
            </h2>
            <p className="mb-6">
                The use of our Software is intended for individuals who have
                reached the age of consent in their respective location, but in
                all cases, individuals must be at least 18 years old. By using
                the Software, you represent that you have reached the required
                age. If you are under the required age, you must obtain parental
                or guardian consent to use the Software.
            </p>
            <p className="mb-6">
                We do not knowingly collect personal information from
                individuals under the required age without appropriate consent.
                If we become aware that we have collected personal information
                from a user under the required age without parental or guardian
                consent, we will take steps to remove such information from our
                records.
            </p>
            <h2 className="text-xl font-semibold mb-2">16. Severability</h2>
            <p className="mb-6">
                If any provision of these Terms is found to be unenforceable or
                invalid under any applicable law, such unenforceability or
                invalidity shall not render these Terms unenforceable or invalid
                as a whole. Instead, the unenforceable or invalid provision
                shall be modified to the extent necessary to make it enforceable
                and valid while preserving its intent. The rest of the
                provisions shall continue to be fully enforceable and valid.
            </p>
            <p className="mt-8">Last updated: August 2023</p>
        </div>
    );
};

export default UsageTerms;
