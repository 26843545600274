import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, SparklesIcon } from "@heroicons/react/24/outline";
import vvCruisesOBC from "../cruiseList/vvCruisesOBC";

export default function CruisePerksModal({
  cruiseLine,
  shipName,
  sailDate,
  isOpen,
  onClose,
}) {
  const [open, setOpen] = useState(true);

  // Extract the year from sailDate
  const year = new Date(sailDate).getFullYear();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [isOpen]);

  console.log("this is the selected saildate", sailDate);
  console.log("this is the selected shipname", shipName);

  // Define default perks
  let perks = [];

  switch (cruiseLine) {
    case "Celebrity":
      if (year === 2024) {
        perks = [
          "$50 USD Onboard Credit",
          "Free Gratuities",
          "Free WiFi",
          "Open Bar",
        ];
      } else if (year === 2025) {
        perks = ["$50 USD Onboard Credit", "Open Bar", "Free WiFi"];
      }
      break;
    case "Virgin Voyages":
      perks = ["Free Gratuities", "Free WiFi"]; // Default perks
      if (vvCruisesOBC[shipName] && vvCruisesOBC[shipName].includes(sailDate)) {
        perks.unshift("$100 USD Onboard Credit (on this specific cruise)");
      }
      break;
    case "Royal Caribbean":
      perks = ["$50 USD Onboard Credit"];
      break;
    default:
      // Handle other cruise lines here if needed
      break;
  }

  const handleCloseModal = () => {
    onClose();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleCloseModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6"
                style={{ width: "600px" }}>
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <SparklesIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900">
                      Included Perks
                    </Dialog.Title>
                    <div className="mt-2 text-sm text-gray-500">
                      With CruiseCompare.ca, these bonuses come with all our{" "}
                      {cruiseLine} cruises:
                    </div>
                    <ul className="list-disc list-inside text-sm text-gray-500 mt-2">
                      {perks.map((perk, index) => (
                        <li key={index}>{perk}</li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={handleCloseModal}>
                    Good to know!
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
