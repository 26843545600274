import { useState, useEffect } from "react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";

export default function FeedbackMessage({ message, success }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (message) {
      setIsVisible(true);

      setTimeout(() => {
        setIsVisible(false);
      }, 3000);
    }
  }, [message]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={`rounded-md ${success ? "bg-green-50" : "bg-red-50"} p-4 mt-4`}>
      <div className="flex">
        <div className="flex-shrink-0">
          {success ? (
            <CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          ) : (
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          )}
        </div>
        <div className="ml-3">
          <h3
            className={`text-sm font-medium ${
              success ? "text-green-800" : "text-red-800"
            }`}>
            {success
              ? "Subdomain created."
              : "There were errors with your submission"}
          </h3>
          {success ? null : (
            <div className="mt-2 text-sm text-red-700">
              <ul role="list" className="list-disc space-y-1 pl-5">
                {/* Add error messages here */}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
