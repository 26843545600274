import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAgentsClientsList } from "../../store/thunks/agentsThunk";
import { fetchActiveAgent } from "../../store/thunks/fetchActiveAgent";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Clients.css";
import {
  BarsArrowUpIcon,
  BarsArrowDownIcon,
  Bars3BottomLeftIcon,
} from "@heroicons/react/20/solid";
import NoSubscriptionWarning from "../../components/agent/NoSubscriptionWarning";
import {
  CheckCircleIcon,
  XMarkIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";

const Clients = () => {
  const dispatch = useDispatch();
  const activeAgent = useSelector((state) => state.activeAgent.data.agent);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const currentUser = useSelector((state) => state.users.activeUser);
  const editUserStatus = useSelector((state) => state.editUser.isLoading);
  // const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchMatches, setSearchMatches] = useState([]); // Track search results
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null); // true for ascending, false for descending
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [feedbackColor, setFeedbackColor] = useState("");
  const [error, setError] = useState("");

  const [showEditModal, setShowEditModal] = useState({
    first_name: "",
    last_name: "",
    email: "",
    // user_type: "",
  });
  const [editFormData, setEditFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    // user_type: "",
  });
  const [editMode, setEditMode] = useState(null);
  const [userToSaveIndex, setUserToSaveIndex] = useState(null);
  const [showEditConfirmModal, setShowEditConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // ... (your existing state variables)

  // Assuming you have a users state in your Redux store and it looks like { users: [], error: null, isLoading: false }
  const usersList = useSelector((state) => state.users.usersList);
  const status = useSelector((state) => state.users.status);
  // const error = useSelector((state) => state.users.error);

  const [agentsClients, setAgentsClients] = useState([]);

  const [demoList, setDemoList] = useState([]);
  // New state for controlling the spinner's display
  const [shouldDisplayLoader, setShouldDisplayLoader] = useState(false);

  // REDUX USEEFFECT TO GET CLIENTS
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(
          fetchAgentsClientsList(activeAgent.email)
        );

        // You can access the response data here directly
        const agentsClientsData = response.payload;
        setAgentsClients(agentsClientsData);
        console.log("These are the clients", agentsClients);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();

    dispatch(fetchActiveAgent());
  }, [dispatch]);

  // Update the search logic to populate searchMatches
  useEffect(() => {
    if (!agentsClients) {
      return; // Exit early if agentsClients is undefined
    }

    const lowerSearchTerm = searchTerm.toLowerCase();
    const matches = agentsClients.filter((client) =>
      `${client.first_name} ${client.last_name} ${client.email}`
        .toLowerCase()
        .includes(lowerSearchTerm)
    );

    // Console log the search matches for debugging
    // console.log("Search Matches:", matches);

    setSearchMatches(matches);
  }, [searchTerm, agentsClients]);

  // REDUX REFRESH DATA
  const refreshData = async () => {
    setIsLoading(true);

    const minimumDisplayTime = 1000;
    const startTime = Date.now();

    try {
      await dispatch(fetchAgentsClientsList(activeAgent.email));

      const elapsed = Date.now() - startTime;
      const delayTime = Math.max(0, minimumDisplayTime - elapsed);

      setTimeout(() => {
        setIsLoading(false);
        setFeedback({ message: "Refreshed", color: "bg-green-50" });
        setTimeout(() => {
          setFeedback("");
        }, 1750);
      }, delayTime);
    } catch (error) {
      const elapsed = Date.now() - startTime;
      const delayTime = Math.max(0, minimumDisplayTime - elapsed);

      setTimeout(() => {
        setIsLoading(false);
        setFeedback("Refresh failed");
        setError("Error: There were issues with your refresh");
        setTimeout(() => {
          setFeedback("");
        }, 1750);
      }, delayTime);
    }

    dispatch(fetchActiveAgent());
  };

  // useEffect to determine agent subscription status
  useEffect(() => {
    if (activeAgent.subscription == null || activeAgent.subscription === "") {
      setIsSubscribed(false);
      console.log("no sub");
    } else {
      setIsSubscribed(true);
    }
  }, [activeAgent]);

  const handleSort = (field) => {
    if (sortField === field) {
      if (sortDirection === false) {
        // if the current field is already being sorted in descending, reset sort
        resetSort();
      } else {
        // if the current field is already being sorted, change direction
        setSortDirection(!sortDirection);
      }
    } else {
      // if a new field is selected, sort in ascending order by default
      setSortField(field);
      setSortDirection(true);
    }
  };

  const resetSort = () => {
    setSortField(null);
    setSortDirection(null);
  };

  const getSortIcon = (field) => {
    if (sortField === field) {
      if (sortDirection === true) {
        return <BarsArrowUpIcon className="h-4 w-4 inline ml-2" />;
      } else if (sortDirection === false) {
        return <BarsArrowDownIcon className="h-4 w-4 inline ml-2" />;
      }
    }
    return <Bars3BottomLeftIcon className="h-3.5 w-3.5 inline ml-2" />;
  };

  function formatDate(isoDate) {
    const date = new Date(isoDate);

    const options = {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
    };

    return date.toLocaleString("en-US", options);
  }

  // const filteredClients = agentsClients.filter((client) => {
  //   const lowerSearchTerm = searchTerm.toLowerCase();
  //   return (
  //     client.first_name.toLowerCase().includes(lowerSearchTerm) ||
  //     client.last_name.toLowerCase().includes(lowerSearchTerm) ||
  //     client.email.toLowerCase().includes(lowerSearchTerm)
  //   );
  // });

  const filteredUsers = agentsClients
    ? agentsClients
        .filter((user) =>
          `${user.first_name} ${user.last_name} ${user.email}`
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => {
          if (!sortField) return 0;
          if (a[sortField] < b[sortField]) return sortDirection ? -1 : 1;
          if (a[sortField] > b[sortField]) return sortDirection ? 1 : -1;
          return 0;
        })
    : [];

  const displayedUsers = filteredUsers.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const totalPage = agentsClients
    ? Math.ceil(agentsClients.length / itemsPerPage)
    : 0;

  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage < totalPage) {
      setCurrentPage(newPage);
    }
  };

  // Add a function to handle the change in items per page
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(0); // Reset to the first page when changing items per page
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  function highlightText(text, term) {
    const splitText = String(text).split(new RegExp(`(${term})`, "gi"));

    return splitText.map((part, i) =>
      part.toLowerCase() === term.toLowerCase() ? (
        <span key={i} className="bg-yellow-300">
          {part}
        </span>
      ) : (
        part
      )
    );
  }

  // Calculate the start and end indexes for the current page for searchMatches
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the clients to display on the current page from searchMatches
  const clientsToDisplay = searchMatches.slice(startIndex, endIndex);

  // Define the Example component
  const SuccessFeedback = ({ feedback, feedbackColor, onClose }) => (
    <div className={`rounded-md ${feedbackColor} p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-green-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-green-800">{feedback}</p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
              onClick={onClose} // Close button functionality
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  // Define the Example component for errors
  const ErrorFeedback = () => (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            There were errors with your submission
          </h3>
          <div className="mt-2 text-sm text-red-700">
            <ul role="list" className="list-disc space-y-1 pl-5">
              <li>Your password must be at least 8 characters</li>
              <li>
                Your password must include at least one pro wrestling finishing
                move
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );

  // Function to close feedback
  const closeFeedback = () => {
    setFeedback({ message: "", color: "" });
    setError("");
  };

  return (
    <div className="pt-4 ml-6 mr-6 sm:px-6 lg:pr-10 lg:pl-0">
      {/* Header row */}
      <div className="sm:flex sm:items-center justify-between">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Agent Clients
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            View the information of your clients.
          </p>
          <p className="mt-2 text-sm text-gray-700">
            You will be notified, via email, for every new client. Be sure to
            check your spam folder.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <div className="flex items-center">
            {/* This is the new flex container */}
            {/* <p
              className="text-base font-semibold leading-6 mr-2.5"
              style={{ color: feedbackColor }}>
              {feedback}
            </p> */}
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={refreshData}>
              Refresh Clients
            </button>
          </div>
        </div>
      </div>

      {/* Conditional rendering of feedback */}
      {feedback.message && (
        <div className={`rounded-md ${feedback.color} p-4`}>
          <div className="flex">
            <div className="ml-3">
              <p className="text-sm font-medium text-green-800">
                {feedback.message}
              </p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  type="button"
                  className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                  onClick={closeFeedback} // Close button functionality
                >
                  <span className="sr-only">Dismiss</span>
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Conditional rendering of error */}
      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">{error}</h3>
            </div>
          </div>
        </div>
      )}

      {/* Pagination and search stuff */}
      <div className="mt-4 pb-2 flex justify-between">
        <div className="w-1/2 sm:w-1/4">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search by name or email"
            className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div className="inline-flex items-center border rounded-md">
          <button
            className="px-3 py-2 border-r text-sm font-medium rounded-l-md text-gray-500 bg-white hover:text-gray-700 hover:bg-gray-50"
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 0}>
            &laquo;
          </button>

          <span className="px-3 py-2 text-sm font-medium text-gray-500 bg-white">
            Page {currentPage + 1} of {totalPage}
          </span>

          <button
            className="px-3 py-2 border-l text-sm font-medium rounded-r-md text-gray-500 bg-white hover:text-gray-700 hover:bg-gray-50"
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPage - 1}>
            &raquo;
          </button>

          {/* Temporarily comment this out */}
          <div className="px-3 py-2">
            <label htmlFor="itemsPerPage" className="sr-only">
              Items per page
            </label>
            <select
              id="itemsPerPage"
              className="border-gray-300 rounded-md shadow-sm"
              onChange={handleItemsPerPageChange}
              value={itemsPerPage}>
              <option value={5}>5 per page</option>
              <option value={10}>10 per page</option>
              <option value={20}>20 per page</option>
              <option value={50}>50 per page</option>
            </select>
          </div>
        </div>
      </div>

      {/* Subscription warning */}
      {isSubscribed ? null : <NoSubscriptionWarning />}

      {/* New working table */}
      <div className="-mx-4 -my-2 pb-20 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className={`relative ${!isSubscribed && "pointer-events-none"}`}>
            {!isSubscribed && (
              <div className="absolute inset-0 bg-opacity-50 backdrop-blur-sm z-10"></div>
            )}
            <table className="min-w-full divide-y divide-gray-300">
              {/* Table Header */}
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                    First Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                    Last Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                    Inquiries
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                    Created On
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                    Last Active On
                  </th>
                </tr>
              </thead>

              {/* Table Body */}
              <tbody className="divide-gray-200 divide-y bg-white">
                {/* Loading State */}
                {isLoading && (
                  <tr>
                    <td colSpan="2" className="text-center py-5">
                      Loading...
                    </td>
                  </tr>
                )}

                {/* No Clients State */}
                {(!Array.isArray(agentsClients) ||
                  agentsClients.length === 0) && (
                  <tr>
                    <td colSpan="3">
                      <p className="mt-2 text-sm text-gray-700">
                        No clients available.
                      </p>
                    </td>
                  </tr>
                )}

                {/* Display Clients */}
                {Array.isArray(agentsClients) &&
                  clientsToDisplay.length > 0 &&
                  clientsToDisplay.map((client) => {
                    const {
                      first_name,
                      last_name,
                      email,
                      inquiries,
                      created_at,
                      last_active,
                    } = client;

                    const lowerSearchTerm = searchTerm.toLowerCase();

                    const highlight = (text) => {
                      const splitText = String(text).split(
                        new RegExp(`(${lowerSearchTerm})`, "gi")
                      );

                      return splitText.map((part, i) =>
                        part.toLowerCase() === lowerSearchTerm ? (
                          <span key={i} className="bg-yellow-300">
                            {part}
                          </span>
                        ) : (
                          part
                        )
                      );
                    };

                    const isMatch =
                      first_name.toLowerCase().includes(lowerSearchTerm) ||
                      last_name.toLowerCase().includes(lowerSearchTerm) ||
                      email.toLowerCase().includes(lowerSearchTerm);

                    return isMatch ? (
                      <tr key={client._id} className="even:bg-gray-50">
                        <td className="px-3 py-4 text-sm text-gray-900">
                          {highlight(first_name)}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500">
                          {highlight(last_name)}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500">
                          {highlight(email)}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500">
                          {inquiries ? inquiries.length : 0}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500">
                          {created_at ? formatDate(created_at) : "N/A"}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500">
                          {last_active ? formatDate(last_active) : "N/A"}
                        </td>
                      </tr>
                    ) : null;
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
