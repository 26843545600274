// vvCruisesWithOBC.js
// These are all the VV cruises that are applicable for the $100 OBC

const vvCruisesOBC = {
  "Resilient Lady": [
    "2024-05-26T04:00:00.000Z",
    "2024-06-30T04:00:00.000Z",
    "2024-07-07T04:00:00.000Z",
  ],
  "Scarlet Lady": [
    "2024-01-06T04:00:00.000Z",
    "2024-01-19T04:00:00.000Z",
    "2024-01-28T04:00:00.000Z",
    "2024-02-11T04:00:00.000Z",
    "2024-02-25T04:00:00.000Z",
    "2024-03-06T04:00:00.000Z",
    "2024-03-15T04:00:00.000Z",
    "2024-04-07T04:00:00.000Z",
    "2024-05-05T04:00:00.000Z",
    "2024-05-12T04:00:00.000Z",
    "2024-05-19T04:00:00.000Z",
    "2024-05-26T04:00:00.000Z",
    "2024-06-09T04:00:00.000Z",
    "2024-06-16T04:00:00.000Z",
    "2024-06-23T04:00:00.000Z",
    "2024-06-30T04:00:00.000Z",
    "2024-07-07T04:00:00.000Z",
    "2024-07-14T04:00:00.000Z",
    "2024-07-21T04:00:00.000Z",
    "2024-07-28T04:00:00.000Z",
    "2024-08-04T04:00:00.000Z",
    "2024-08-11T04:00:00.000Z",
    "2024-08-18T04:00:00.000Z",
    "2024-08-25T04:00:00.000Z",
    "2024-09-08T04:00:00.000Z",
    "2024-12-22T04:00:00.000Z",
    "2025-01-05T04:00:00.000Z",
    "2025-01-25T04:00:00.000Z",
    "2025-03-02T04:00:00.000Z",
    "2025-03-16T04:00:00.000Z",
    "2025-04-13T04:00:00.000Z",
  ],
  "Valiant Lady": [
    "2024-01-27T04:00:00.000Z",
    "2024-02-24T04:00:00.000Z",
    "2024-04-26T04:00:00.000Z",
    "2024-05-29T04:00:00.000Z",
    "2024-06-16T04:00:00.000Z",
    "2024-07-14T04:00:00.000Z",
    "2024-08-02T04:00:00.000Z",
    "2024-08-07T04:00:00.000Z",
    "2024-08-11T04:00:00.000Z",
    "2024-08-16T04:00:00.000Z",
    "2024-08-21T04:00:00.000Z",
    "2024-08-25T04:00:00.000Z",
    "2024-08-30T04:00:00.000Z",
    "2024-09-22T04:00:00.000Z",
    "2024-10-25T04:00:00.000Z",
    "2024-12-15T04:00:00.000Z",
    "2025-01-03T04:00:00.000Z",
    "2025-02-23T04:00:00.000Z",
    "2025-03-14T04:00:00.000Z",
  ],
  // Add more cruise ships and their respective sail dates if needed
};

export default vvCruisesOBC;
