import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginAgent } from "../store/thunks/loginAgent";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../components/Logo";
import FlowRider from "../assets/FlowRider.webp";

export default function AgentLogin({ onLogin, onRegister }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeAgent = useSelector((state) => state.activeAgent.data.agent);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [emailToVerify, setEmailToVerify] = useState(null);

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const errorType = queryParams.get("error");

    if (errorType === "sessionExpired") {
      setError("Your session has expired. Please log in again.");
    }
  }, [location]);

  // Functional previous handle submit
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const loginData = { email: username, password: password };

  //   try {
  //     const action = await dispatch(loginAgent(loginData));
  //     // Check if the action was successful
  //     if (action.payload) {
  //       if (action.payload.emailNotVerified) {
  //         setEmailToVerify(username);
  //       } else {
  //         if (action.payload.user.token) {
  //           navigate("/dashboard");
  //         } else {
  //           setError("Unexpected response from server.");
  //         }
  //       }
  //     } else {
  //       setError("Invalid credentials. inside AgentLogin line 47");
  //     }
  //   } catch (error) {
  //     // Handle the email not verified error specifically
  //     setError("An error occurred.");
  //   }
  // };

  // New hanle submit from gpt
  const handleSubmit = async (e) => {
    e.preventDefault();
    const loginData = { email: username, password: password };

    try {
      console.log("Logging in with data:", loginData); // Log login data
      const action = await dispatch(loginAgent(loginData));
      console.log("Server response:", action); // Log server response

      if (action.payload) {
        if (action.payload.emailNotVerified) {
          setEmailToVerify(username);
        } else {
          const agent = action.payload.agent;
          if (agent.token) {
            // Check profile completion
            const isProfileComplete = checkProfileCompletion(agent);
            if (isProfileComplete) {
              navigate("/agent/metrics");
            } else {
              navigate("/agent/complete-profile");
            }
          } else {
            setError("Unexpected response from server.");
            console.error("Unexpected response from server.", action.payload);
          }
        }
      } else {
        setError("Invalid credentials.");
        console.error("Invalid credentials. Server response:", action.payload);
      }
    } catch (error) {
      console.error("An error occurred:", error); // Log the error

      // Handle the email not verified error specifically
      if (error.response && error.response.status === 401) {
        setError("Invalid username or password.");
        console.error(
          "Invalid username or password. Server response:",
          error.response.data
        );
      } else {
        setError("An error occurred.");
      }
    }
  };

  // Function to check if profile completion
  const checkProfileCompletion = (activeAgent) => {
    return (
      activeAgent.first_name &&
      activeAgent.last_name &&
      activeAgent.email &&
      activeAgent.company_name &&
      activeAgent.company_number &&
      activeAgent.company_subdomain
    );
  };

  const handleResendVerificationEmail = () => {
    // TODO: Implement the logic to resend verification email
    alert(`Verification email resent to ${emailToVerify}`);
  };

  return (
    <>
      {/* NEW TAILWINDS ONE */}
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="flex justify-center">
            <Logo width={300} height={300} />
          </div>
          {/* <img
            className="mx-auto h-10 w-auto"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            alt="Your Company"
          /> */}
          <h2 className=" text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your agent account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            {error && (
              <div className="mt-4 flex items-center">
                <p className="text-red-500 mr-2">{error}</p>
                <button
                  className="text-red-500 font-semibold"
                  onClick={() => setError("")}>
                  Dismiss
                </button>
              </div>
            )}

            <div className="mt-10">
              <div>
                {emailToVerify ? (
                  <div>
                    <p>Your email {emailToVerify} is not verified.</p>
                    <button
                      onClick={handleResendVerificationEmail}
                      className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                      Resend Verification Email
                    </button>
                  </div>
                ) : (
                  <form onSubmit={handleSubmit} className="space-y-6">
                    {/* <form className="space-y-6"> */}
                    {/* New one */}
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900">
                        Email address
                      </label>
                      <div className="mt-2">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium leading-6 text-gray-900">
                        Password
                      </label>
                      <div className="mt-2">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          required
                          className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <input
                          id="remember-me"
                          name="remember-me"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <label
                          htmlFor="remember-me"
                          className="ml-3 block text-sm leading-6 text-gray-700">
                          Remember me
                        </label>
                      </div>
                      <div className="text-sm leading-6">
                        <Link
                          to="/reset_password"
                          className="font-semibold text-indigo-600 hover:text-indigo-500">
                          Forgot password?
                        </Link>
                      </div>
                    </div>
                    
                    <div>
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Sign in
                      </button>
                    </div>

                    {/* <div>
                      <div className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Sign in is temporarily unavailable for updates.
                      </div>
                    </div> */}
                  </form>
                )}
              </div>
            </div>
            <p className="mt-10 text-center text-sm text-gray-500">
              Not an agent?{" "}
              <Link
                to="/agent/register"
                className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                Sign Up Now
              </Link>
            </p>

            <p className="mt-2 text-center text-sm text-gray-500">
              Are you a client?{" "}
              <Link
                to="/login"
                className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                User Sign In
              </Link>
            </p>

            {/* <p className="mt-10 text-center text-sm text-gray-500">
              Not an agent?{" "}
              <a
                href="#"
                className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                <Link
                  to="/agent/register"
                  className="font-semibold text-indigo-600 hover:text-indigo-500">
                  Sign Up Now
                </Link>
              </a>
            </p> */}
          </div>
        </div>
      </div>
    </>
  );
}
