import { Link } from "react-router-dom";
import Button from "./Button";
import { isDev } from "../../config";
import ReactGA from "react-ga4";

const SignUpButton = ({ props }) => {
    const handleClick = () => {
        if (!isDev) {
            ReactGA.event({
                event_category: "Registration",
                event_action: "Clicked Register Button",
                event_label: "Landing Page",
            });
        }
    };

    return (
        <div className="w-full flex justify-start items-center lg:gap-12 md:gap-6 gap-0">
            <Link to="/register">
                <Button
                    type="button"
                    onClick={handleClick}
                    className="outline-none border-none lg:px-3 px-3 py-3 bg-color2 text-white font-semibold rounded-lg transform transition-all duration-300 hover:bg-purple-600 hover:scale-105 hover:shadow-lg"
                >
                    {props}
                </Button>
            </Link>
        </div>
    );
};

export default SignUpButton;
