import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL

const fetchCruisePricing = createAsyncThunk(
  "cruises/fetchCruisePricing",
  async (id, { getState }) => {
    const systemUserToken = process.env.SYSTEM_USER_TOKEN;
    const systemUserID = process.env.SYSTEM_USER_ID;
  
    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-agent-api-token": systemUserToken,
        "x-agent-id": systemUserID,
      },
    };

    try {
      const response = await axios.get(
        `https://${apiBaseUrl}/cruises/pricing/${id}`,
        config
      );
      return response.data;
    } catch (error) {
      // Handle error as needed
      throw error;
    }
  }
);

export { fetchCruisePricing };
