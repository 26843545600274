import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Cookies from "js-cookie";
import CCNavBar from "../components/vvLandingPage/CCNavBar";
import FoldImage from "../components/vvLandingPage/FoldImage";
import NewHeroSection from "../components/vvLandingPage/NewHeroSection";
import SaleBanner from "../components/vvLandingPage/SaleBanner";
import NewBookingSteps from "../components/vvLandingPage/NewBookingSteps";
import NewPlatformOverview from "../components/vvLandingPage/NewPlatformOverview";
import NewFeatureList from "../components/vvLandingPage/NewFeatureList";
import VVTopDestination from "../components/vvLandingPage/VVTopDestination";
import NewGetStarted from "../components/vvLandingPage/NewGetStarted";
import Footer from "../components/vvLandingPage/Footer";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./LandingPage.css";

function VVLandingPage() {
  const location = useLocation();
  console.log(process.env.NODE_ENV);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const referrer = urlParams.get("referrer");

    if (referrer) {
      Cookies.set("referrer", referrer, { expires: 180 }); // 180 days expiration
    }
  }, [location]);

  return (
    <>
      <CCNavBar />
      <SaleBanner />
      <VVTopDestination />
      <Footer />
    </>
  );
}

export default VVLandingPage;
