import React, { useState, useEffect } from "react";
import axios from "axios";
import Text from "./Text";
import { TopDestinationTexts } from "./DataLists";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Card from "./Card";
import Button from "./Button";
import SignUpButton from "./SignUpButton";
import "./TopDestination.css";
import bookingImage2 from "../../assets/bookingImage2.jpeg";
import VVPagination from "./VVPagination";
import SortDropdown from "./SortDropdown";
import CruiseGridTile from "../cruiseList/CruiseGridTile";

const VVTopDestination = () => {
    const [specialCruises, setSpecialCruises] = useState([]);
    const [shipImages, setShipImages] = useState({});
    const [refreshData, setRefreshData] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    // const [selectedDate, setSelectedDate] = useState("");
    const [currentPage, setCurrentPage] = useState(1); // Current page number
    const [cruisesPerPage] = useState(12); // Number of cruises per page
    const [totalCruises, setTotalCruises] = useState(0); // Add state for total cruises
    const [sortBy, setSortBy] = useState(""); // Sort by sail date or price
    const [selectedRegion, setSelectedRegion] = useState(""); // State for selected region

    const [sortByPrice, setSortByPrice] = useState("");
    const [sortBySailDate, setSortBySailDate] = useState("");
    const [sortByRegion, setSortByRegion] = useState("");
    const [sortByRoomType, setSortByRoomType] = useState("");
    const [sortByCruise, setSortByCruise] = useState("");
    const [sortByShip, setSortByShip] = useState("");
    const [selectedDate, setSelectedDate] = useState("");

    const priceSortOptions = [
        { id: 0, label: "None", value: "" },
        { id: 1, label: "Lowest to Highest", value: "asc" },
        { id: 2, label: "Highest to Lowest", value: "desc" },
    ];

    const sailDateSortOptions = [
        { id: 0, label: "None", value: "" },
        { id: 1, label: "Earliest to Latest", value: "asc" },
        { id: 2, label: "Latest to Earliest", value: "desc" },
    ];

    const shipSortOptions = [
        { id: 0, label: "None", value: "" },
        { id: 1, label: "Scarlet Lady", value: "Scarlet Lady" },
        { id: 2, label: "Valiant Lady", value: "Valiant Lady" },
        { id: 3, label: "Resilient Lady", value: "Resilient Lady" },
        { id: 4, label: "Brilliant Lady", value: "Brilliant Lady" },
    ];

    const regionSortOptions = [
        { id: 0, label: "None", value: "" },
        {
            id: 1,
            label: "Australia/New Zealand/South Pacific",
            value: "Australia/New Zealand/South Pacific",
        },
        { id: 2, label: "Caribbean", value: "Caribbean" },
        { id: 3, label: "Europe", value: "Europe" },
        { id: 4, label: "Mexico", value: "Mexico" },
        {
            id: 5,
            label: "Repositioning/Transatlantic",
            value: "Repositioning/Transatlantic",
        },
    ];

    const roomSortOptions = [
        { id: 0, label: "None", value: "" },
        { id: 1, label: "Inside", value: "I" },
        { id: 1, label: "Ocean View", value: "O" },
        { id: 1, label: "Balcony", value: "B" },
        { id: 1, label: "Suite", value: "S" },
    ];

    const cruiseSortOptions = [
        { id: 0, label: "None", value: "" },
        { id: 1, label: "A Virgin Celebration Voyage", value: "A Virgin Celebration Voyage" },
        { id: 2, label: "Adriatic Sea & Greek Gems", value: "Adriatic Sea & Greek Gems" },
        { id: 3, label: "Ancient Aegean to The Modern Med", value: "Ancient Aegean to The Modern Med" },
        { id: 4, label: "Barcelona to Cannes, Rome & More", value: "Barcelona to Cannes, Rome & More" },
        { id: 5, label: "Barcelona to Cannes, Rome & Tuscany", value: "Barcelona to Cannes, Rome & Tuscany" },
        { id: 6, label: "Barcelona to Civitavecchia", value: "Barcelona to Civitavecchia" },
        { id: 7, label: "Barcelona to France, Italy & Greece", value: "Barcelona to France, Italy & Greece" },
        { id: 8, label: "Barcelona to Malta & Greece", value: "Barcelona to Malta & Greece" },
        { id: 9, label: "Barcelona to Palma, Ibiza & More", value: "Barcelona to Palma, Ibiza & More" },
        { id: 10, label: "Barcelona to Rome & More", value: "Barcelona to Rome & More" },
        { id: 11, label: "Barcelona, Palma, Rome & (Many) More", value: "Barcelona, Palma, Rome & (Many) More" },
        { id: 12, label: "Colombia & Caribbean Islands", value: "Colombia & Caribbean Islands" },
        { id: 13, label: "Corfu, Dubrovnik & More", value: "Corfu, Dubrovnik & More" },
        { id: 14, label: "Costa Maya & Cozumel Charm", value: "Costa Maya & Cozumel Charm" },
        { id: 15, label: "Dominican Daze", value: "Dominican Daze" },
        { id: 16, label: "Dubrovnik Days & Mykonos Nights", value: "Dubrovnik Days & Mykonos Nights" },
        { id: 17, label: "Eastern Caribbean Antilles", value: "Eastern Caribbean Antilles" },
        { id: 18, label: "Elevate Voyage", value: "Elevate Voyage" },
        { id: 19, label: "England to Iceland, Norway", value: "England to Iceland, Norway" },
        { id: 20, label: "English Sights & European Delights", value: "English Sights & European Delights" },
        { id: 21, label: "Fire and Sunset Soirées", value: "Fire and Sunset Soirées" },
        { id: 22, label: "Florence, Rome, Palma & More", value: "Florence, Rome, Palma & More" },
        { id: 23, label: "French Daze & Ibiza Nights", value: "French Daze & Ibiza Nights" },
        { id: 24, label: "From England to Amsterdam (& back)", value: "From England to Amsterdam (& back)" },
        { id: 25, label: "From Rome to Miami", value: "From Rome to Miami" },
        { id: 26, label: "From the UK to Spain and Morocco", value: "From the UK to Spain and Morocco" },
        { id: 27, label: "Glorious Greek Isles to Spanish Sunsets", value: "Glorious Greek Isles to Spanish Sunsets" },
        { id: 28, label: "Grand Cayman, Costa Maya & More", value: "Grand Cayman, Costa Maya & More" },
        { id: 29, label: "Grand Turk, Puerto Plata & Bimini", value: "Grand Turk, Puerto Plata & Bimini" },
        { id: 30, label: "Grand Turk, Puerto Plata & more", value: "Grand Turk, Puerto Plata & more" },
        { id: 31, label: "Greek Island Glow", value: "Greek Island Glow" },
        { id: 32, label: "Idyllic Isles of the Caribbean & Colombia", value: "Idyllic Isles of the Caribbean & Colombia" },
        { id: 33, label: "Irresistible Med & Rome", value: "Irresistible Med & Rome" },
        { id: 34, label: "Istanbul, Santorini & More", value: "Istanbul, Santorini & More" },
        { id: 35, label: "Italy to France & Spain", value: "Italy to France & Spain" },
        { id: 36, label: "Mayan Sol", value: "Mayan Sol" },
        { id: 37, label: "Miami to Casablanca & Barcelona", value: "Miami to Casablanca & Barcelona" },
        { id: 38, label: "Miami, Grand Turk (& back)", value: "Miami, Grand Turk (& back)" },
        { id: 39, label: "One-Way San Juan Sojourn", value: "One-Way San Juan Sojourn" },
        { id: 40, label: "Portsmouth to Amsterdam & Bordeaux", value: "Portsmouth to Amsterdam & Bordeaux" },
        { id: 41, label: "Portsmouth to Ireland, Scotland & More", value: "Portsmouth to Ireland, Scotland & More" },
        { id: 42, label: "Portsmouth to Lisbon, Casablanca & Spain", value: "Portsmouth to Lisbon, Casablanca & Spain" },
        { id: 43, label: "Portsmouth to Zeebrugge (& back)", value: "Portsmouth to Zeebrugge (& back)" },
        { id: 44, label: "Pre-Holiday Caribbean Refresh", value: "Pre-Holiday Caribbean Refresh" },
        { id: 45, label: "Puerto Rican Daze & Caribbean Nights", value: "Puerto Rican Daze & Caribbean Nights" },
        { id: 46, label: "Resilient Caribbean Holidays", value: "Resilient Caribbean Holidays" },
        { id: 47, label: "Resilient New Year's Ahoy", value: "Resilient New Year's Ahoy" },
        { id: 48, label: "Reykjavik to Amsterdam & Portsmouth", value: "Reykjavik to Amsterdam & Portsmouth" },
        { id: 49, label: "Riviera Maya", value: "Riviera Maya" },
        { id: 50, label: "Saint (Isles) & Seas of the Caribbean", value: "Saint (Isles) & Seas of the Caribbean" },
        { id: 51, label: "San Juan to Spanish Island Nights", value: "San Juan to Spanish Island Nights" },
        { id: 52, label: "San Juan, Barbados & Beyond", value: "San Juan, Barbados & Beyond" },
        { id: 53, label: "San Juan, St. Vincent, Barbados & More", value: "San Juan, St. Vincent, Barbados & More" },
        { id: 54, label: "Southern Spain & Casablanca", value: "Southern Spain & Casablanca" },
        { id: 55, label: "Spain & Morocco to Miami", value: "Spain & Morocco to Miami" },
        { id: 56, label: "Spanish & Italian Hot Spots", value: "Spanish & Italian Hot Spots" },
        { id: 57, label: "Starry Nights in Greece & Croatia", value: "Starry Nights in Greece & Croatia" },
        { id: 58, label: "Sunny Daze in Puerto Plata & Nassau", value: "Sunny Daze in Puerto Plata & Nassau" },
        { id: 59, label: "Sunsets in the Lesser Antilles", value: "Sunsets in the Lesser Antilles" },
        { id: 60, label: "The Irresistible Med", value: "The Irresistible Med" },
        { id: 61, label: "The Modern Med to Ancient Aegean", value: "The Modern Med to Ancient Aegean" },
        { id: 62, label: "The UK to Zeebrugge & Amsterdam", value: "The UK to Zeebrugge & Amsterdam" },
        { id: 63, label: "Valiant Caribbean Holiday", value: "Valiant Caribbean Holiday" },
        { id: 64, label: "Western Caribbean Charm", value: "Western Caribbean Charm" }
    ];
    

    const handleSortByPriceChange = (value) => {
        setSortByPrice(value);
    };

    const handleSortBySailDateChange = (value) => {
        setSortBySailDate(value);
    };

    const handleSortByRegionChange = (value) => {
        setSortByRegion(value);
    };

    const handleSortByRoomType = (value) => {
        setSortByRoomType(value);
    };

    const handleSortByCruise = (value) => {
        setSortByCruise(value);
    };

    const handleSortByShip = (value) => {
        setSortByShip(value);
    };

    const handleDateChange = (event) => {
        const inputValue = event.target.value;
        if (inputValue) {
            const selectedDate = new Date(inputValue);
            if (!isNaN(selectedDate.getTime())) {
                // Check if selectedDate is a valid date
                const isoDateTime = selectedDate.toISOString();
                const isoDate = isoDateTime.split("T")[0]; // Extract only the date part
                setSelectedDate(isoDate);
            } else {
                console.error("Invalid date input:", inputValue);
            }
        } else {
            // Handle the case when input value is empty
            setSelectedDate(""); // or any default value you prefer
        }
    };

    useEffect(() => {
        console.log(selectedDate);
    }, [selectedDate]);

    function getCurrentDate() {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        let day = today.getDate();

        month = month < 10 ? `0${month}` : month;
        day = day < 10 ? `0${day}` : day;

        return `${year}-${month}-${day}`;
    }

    useEffect(() => {
        fetchData();
        window.addEventListener("resize", updateIsMobile);

        return () => {
            window.removeEventListener("resize", updateIsMobile);
        };
    }, [refreshData]);

    // Fetch VV cruises using new params and sort by
    const fetchData = async () => {
        try {
            const response = await axios.get(
                `https://www.cruisecompare.ca:6010/api/specialVirginCruises?sailDate=${selectedDate}&sortByPrice=${sortByPrice}&sortBySailDate=${sortBySailDate}&sortByRegion=${sortByRegion}&sortByRoomType=${sortByRoomType}&sortByCruise=${sortByCruise}&sortByShip=${sortByShip}`
            );
            setSpecialCruises(response.data);
            // Fetch ship images once specialCruises data is available
            loadShipImages(response.data);

            // Update totalCruises after fetching data
            setTotalCruises(response.data.length);
            console.log("total cruises", totalCruises);
        } catch (error) {
            console.error("Error fetching cruises in component:", error);
        }
    };

    useEffect(() => {
        fetchData();
        // Add other useEffect dependencies...
    }, [
        selectedDate,
        sortByPrice,
        sortBySailDate,
        sortByRegion,
        sortByRoomType,
        sortByCruise,
        sortByShip,
    ]);

    const updateIsMobile = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    const normalizeString = (str) => {
        return str.toLowerCase().replace(/[^a-z0-9]/gi, "");
    };

    const loadShipImages = async (cruises) => {
        const shipImagesData = {};

        // Extract unique cruise lines from the cruises
        const uniqueCruiseLines = [
            ...new Set(cruises.map((cruise) => cruise.CruiseLine)),
        ];

        // Get ships for each unique cruise line
        const cruiseLineShips = {};

        for (const cruiseLine of uniqueCruiseLines) {
            const operator = mapToApiOperator(cruiseLine);
            const ships = await getShipsForOperator(operator);
            cruiseLineShips[operator] = ships;
        }

        // Map ships to their images
        for (const cruise of cruises) {
            const shipName = normalizeString(cruise.Ship);
            const operator = mapToApiOperator(cruise.CruiseLine);

            const ships = cruiseLineShips[operator];
            const ship = ships.find(
                (ship) => normalizeString(ship.title) === shipName
            );

            shipImagesData[cruise.Ship] = ship ? ship.cover_image_href : null;
        }

        setShipImages(shipImagesData);
    };

    const getShipsForOperator = async (operator) => {
        try {
            const appId = process.env.REACT_APP_CRUISE_API_KEY;
            const appToken = process.env.REACT_APP_CRUISE_TOKEN;

            const page1Url = `https://www.widgety.co.uk/api/ships.json?operator=${operator}&include_cruises=true&page=1&app_id=${appId}&token=${appToken}`;
            const page2Url = `https://www.widgety.co.uk/api/ships.json?operator=${operator}&include_cruises=true&page=2&app_id=${appId}&token=${appToken}`;

            // Make both requests simultaneously using axios.all
            const responses = await axios.all([
                axios.get(page1Url),
                axios.get(page2Url),
            ]);

            // Combine the ships array from both responses
            const shipsPage1 = responses[0].data.ships || [];
            const shipsPage2 = responses[1].data.ships || [];
            const allShips = shipsPage1.concat(shipsPage2);

            return allShips;
        } catch (error) {
            console.error("Error fetching ship data:", error);
            return [];
        }
    };

    const mapToApiOperator = (operator) => {
        const operatorMapping = {
            Celebrity: "celebrity-cruises",
            "Royal Caribbean": "royal-caribbean-international",
            // Add more mappings as needed
        };
        return operatorMapping[operator] || operator;
    };

    const sortCruises = (cruises) => {
        if (sortBy === "priceAsc") {
            return cruises.slice().sort((a, b) => a.GroupRate - b.GroupRate);
        } else if (sortBy === "priceDesc") {
            return cruises.slice().sort((a, b) => b.GroupRate - a.GroupRate);
        } else if (sortBy === "dateAsc") {
            return cruises
                .slice()
                .sort((a, b) => new Date(a.SailDate) - new Date(b.SailDate));
        } else if (sortBy === "dateDesc") {
            return cruises
                .slice()
                .sort((a, b) => new Date(b.SailDate) - new Date(a.SailDate));
        } else {
            return cruises;
        }
    };

    // Function to actually render the cruises on the grid
    const renderCruises = () => {
        const sortedCruises = sortCruises(specialCruises);
        const indexOfLastCruise = currentPage * cruisesPerPage;
        const indexOfFirstCruise = indexOfLastCruise - cruisesPerPage;
        const currentCruises = sortedCruises.slice(
            indexOfFirstCruise,
            indexOfLastCruise
        );

        if (currentCruises.length === 0) {
            return <div>No cruises available.</div>;
        }

        return currentCruises.map((cruise, index) => (
            <div key={index}>
                <CruiseGridTile key={index} cruise={cruise} />
            </div>
        ));
    };

    const renderCruiseCount = () => {
        return (
            <div>
                <p className="text-sm text-gray-700">
                    Showing <span className="font-medium">{totalCruises}</span>{" "}
                    results
                </p>
            </div>
        );
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <section className=" w-full h-auto flex flex-col items-center justify-center relative lg:px-24 md:px-20 px-6 my-20">
            {/* Top text */}
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-">
                        Virgin Voyages Cruise Deals
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                        View our exclusive list of deals with Virgin Voyages.
                        Prices are per person, taxes not included.
                    </p>

                    <p className="mt-2 text-sm text-gray-700">
                        For further information or general questions, use our{" "}
                        <em>
                            <strong>Contact Us</strong>
                        </em>{" "}
                        feature in Cruise Details.
                    </p>
                </div>
            </div>

            {/* Sort By's */}
            <div className="flex flex-col sm:flex-row items-center justify-between mt-8 p-4 mb-4 rounded-md border-2 border-indigo-600">
                <div className="mr-2">
                    <SortDropdown
                        title={"Sort By Price"}
                        value={sortByPrice}
                        onChange={handleSortByPriceChange}
                        options={priceSortOptions}
                    />
                </div>

                <div className="mr-2">
                    <SortDropdown
                        title={"Sort By Departure"}
                        value={sortBySailDate}
                        onChange={handleSortBySailDateChange}
                        options={sailDateSortOptions}
                    />
                </div>

                <div className="mr-2">
                    <SortDropdown
                        title={"Filter By Region"}
                        value={sortByRegion}
                        onChange={handleSortByRegionChange}
                        options={regionSortOptions}
                    />
                </div>

                <div className="mr-2">
                    <SortDropdown
                        title={"Filter By Ship"}
                        value={sortByShip}
                        onChange={handleSortByShip}
                        options={shipSortOptions}
                    />
                </div>

                <div className="mr-2">
                    <SortDropdown
                        title={"Filter By Room Type"}
                        value={sortByRoomType}
                        onChange={handleSortByRoomType}
                        options={roomSortOptions}
                    />
                </div>

                <div className="mr-2">
                    <SortDropdown
                        title={"Filter By Itinerary"}
                        value={sortByCruise}
                        onChange={handleSortByCruise}
                        options={cruiseSortOptions}
                    />
                </div>

                <div className="mr-2">
                    <div>
                        <label
                            htmlFor="sort"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            Departure Date
                        </label>
                        <input
                            type="date"
                            id="datePicker"
                            value={selectedDate || getCurrentDate()}
                            min={getCurrentDate()}
                            onChange={handleDateChange}
                            className="block rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
            </div>

            {renderCruiseCount()}

            {/* Grid */}
            <div className="bg-white w-full">
                <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-6 lg:max-w-7xl lg:px-8">
                    <VVPagination
                        currentPage={currentPage}
                        totalPages={Math.ceil(totalCruises / cruisesPerPage)}
                        onPageChange={paginate}
                        totalCruises={totalCruises}
                    />

                    <div className="mt-8 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-2 lg:grid-cols-4 xl:gap-x-2">
                        {renderCruises()}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default VVTopDestination;
