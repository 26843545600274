import { createSlice } from "@reduxjs/toolkit";
import { fetchSubscriptionStatus } from '../thunks/fetchSubscriptionStatus'

const fetchSubscriptionStatusSlice = createSlice({
  name: "subscriptionStatus",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  extraReducers(builder) {
    builder.addCase(fetchSubscriptionStatus.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchSubscriptionStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchSubscriptionStatus.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  }
});

export const { setFetchSubscriptionStatusStatistics } = fetchSubscriptionStatusSlice.actions;

export default fetchSubscriptionStatusSlice.reducer;