import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../../config"; // Import the dynamic API base URL

// Replace 'YOUR_API_ENDPOINT' with the actual endpoint URL for canceling a subscription.

export const cancelSubscription = createAsyncThunk(
    "subscription/cancel",
    async ({subscriptionId}, { getState} ) => {
        const state = getState();
        const { token, _id } = state.activeUser.data.user;

        const config = {
            headers: {
                "Content-Type": "application/json",
                "x-api-token": token,
                "x-user-id": _id,
                // Add other headers as needed
              },
        };

        const response = await axios.post(
            `https://${apiBaseUrl}/subscription/cancel/${subscriptionId}`,
            config
        );
        return response.data;
    }
);
