import { createSlice } from "@reduxjs/toolkit";
import { fetchFaqs, fetchFaqBySlug, addFaq, updateFaq, deleteFaq } from '../thunks/faqsThunk';

const initialState = {
  faqs: [],
  currentFaq: null,
  isLoading: false,
  error: null,
};

const faqsSlice = createSlice({
  name: "faqs",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchFaqs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFaqs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.faqs = action.payload;
        state.error = null;
      })
      .addCase(fetchFaqs.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchFaqBySlug.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFaqBySlug.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentFaq = action.payload;
        state.error = null;
      })
      .addCase(fetchFaqBySlug.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(addFaq.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addFaq.fulfilled, (state, action) => {
        state.isLoading = false;
        state.faqs.push(action.payload); // Assuming the backend returns the newly added FAQ
        state.error = null;
      })
      .addCase(addFaq.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateFaq.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateFaq.fulfilled, (state, action) => {
        state.isLoading = false;
        // Update the specific FAQ in the state
        const index = state.faqs.findIndex(faq => faq.slug === action.meta.arg.slug);
        if (index !== -1) {
          state.faqs[index] = action.payload; // Assuming the backend returns the updated FAQ
        }
        state.error = null;
      })
      .addCase(updateFaq.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(deleteFaq.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteFaq.fulfilled, (state, action) => {
        state.isLoading = false;
        // Remove the FAQ from the state
        state.faqs = state.faqs.filter(faq => faq.slug !== action.meta.arg);
        state.error = null;
      })
      .addCase(deleteFaq.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  }
});

export default faqsSlice.reducer;
