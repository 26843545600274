import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  updateAgentThunk,
  updateSubdomainThunk,
  updateSubscriptionPlanThunk,
} from "../../store/thunks/agentsThunk";
import { fetchActiveAgent } from "../../store/thunks/fetchActiveAgent";
import ColorPicker from "../../components/ColorPicker";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import RegisterModal from "../../components/agent/RegisterModal";
import SubdomainAlert from "../../components/agent/SubdomainAlert";
import FeedbackMessage from "../../components/agent/FeedbackMessage";
import NoSubscriptionWarning from "../../components/agent/NoSubscriptionWarning";

const Settings = () => {
  const dispatch = useDispatch();
  const activeAgent = useSelector((state) => state.activeAgent.data.agent);

  const [isValidSubdomain, setIsValidSubdomain] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const [companyLogo, setCompanyLogo] = useState(null);
  const [isCompanyLogoModalOpen, setIsCompanyLogoModalOpen] = useState(false);
  const [isSubdomainModalOpen, setIsSubdomainModalOpen] = useState(false);
  const [isColorModalOpen, setIsColorModalOpen] = useState(false);
  const [isVVLinkModalOpen, setIsVVLinkModalOpen] = useState(false);
  useState(false);

  const [showVVCruises, setShowVVCruises] = useState(true);
  const [VVLink, setVVLink] = useState("");

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [feedbackType, setFeedbackType] = useState(""); // You can use this for different types of feedback (e.g., success, error)

  // Define a state variable for controlling the visibility of the feedback message
  const [isFeedbackVisible, setIsFeedbackVisible] = useState(false);

  const [primaryColor, setPrimaryColor] = useState("#4f46e5"); // Default color
  const [themeColor, setThemeColor] = useState(activeAgent.theme_color);

  const [editMode, setEditMode] = useState(false);

  // Separate editMode states for each field
  const [editModeCompanyName, setEditModeCompanyName] = useState(false);
  const [editModeCompanyNumber, setEditModeCompanyNumber] = useState(false);
  const [editModeCompanySubdomain, setEditModeCompanySubdomain] =
    useState(false);
  const [editModeThemeColor, setEditModeThemeColor] = useState(false);
  const [editModeVVLink, setEditModeVVLink] = useState(false);
  const [editModeSubscriptionPlan, setEditModeSubscriptionPlan] =
    useState(false);

  const [editedAgent, setEditedAgent] = useState({ ...activeAgent });

  const toggleEditModeCompanyNumber = () => {
    setEditModeCompanyNumber(!editModeCompanyNumber);
  };

  const toggleEditModeCompanySubdomain = () => {
    setEditModeCompanySubdomain(!editModeCompanySubdomain);
  };

  const toggleEditModeThemeColor = () => {
    setEditModeThemeColor(!editModeThemeColor);
  };

  const toggleEditModeVVLink = () => {
    setEditModeVVLink(!editModeVVLink);
  };

  const toggleEditModeSubscriptionPlan = () => {
    setEditModeSubscriptionPlan(!editModeSubscriptionPlan);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Check if the field name is "company_subdomain"
    if (name === "company_subdomain") {
      const subdomainPattern = /^[a-zA-Z0-9.]+$/; // Include '.' as a valid character

      // Check if the input value matches the pattern
      if (subdomainPattern.test(value)) {
        // If it matches, set isValidSubdomain to true
        setIsValidSubdomain(true);
      } else {
        // If it doesn't match, set isValidSubdomain to false
        setIsValidSubdomain(false);
      }
    }

    if (name === "virgin_voyages_link") {
      // Check if the field name is "virgin_voyages_link"
      if (!showVVCruises) {
        // If showVVCruises is false, set vvlink to an empty string
        setVVLink("");
      } else {
        // Otherwise, update vvlink with the input value
        setVVLink(value);
      }
    }

    // Update editedAgent with other fields
    setEditedAgent({
      ...editedAgent,
      [name]: value,
    });
  };

  // Function to show the feedback message
  const showFeedback = (message) => {
    setFeedbackMessage(message);

    // Check if the message contains "successfully" to determine success or error
    if (message.toLowerCase().includes("successfully")) {
      setFeedbackType("success");
    } else {
      setFeedbackType("error");
    }

    setIsFeedbackVisible(true);

    // Automatically hide the feedback message after 3 seconds
    setTimeout(() => {
      setIsFeedbackVisible(false);
      setFeedbackMessage("");
    }, 3000);
  };

  // useEffect to determine agent subscription status
  useEffect(() => {
    if (activeAgent.subscription == null || activeAgent.subscription === "") {
      setIsSubscribed(false);
      console.log("no sub");
    } else {
      setIsSubscribed(true);
    }
  }, [activeAgent]);

  const handleRefresh = () => {
    dispatch(fetchActiveAgent());
  };

  const handleSave = async () => {
    console.log("New Company Subdomain:", editedAgent.company_subdomain);
    console.log("New company number", editedAgent.company_number);
    console.log("New company theme color", editedAgent.theme_color);
    console.log("New VVLink", editedAgent.virgin_voyages_link);

    try {
      // Dispatch the updateAgentThunk action with the necessary data
      const response = await dispatch(
        updateAgentThunk({ userId: activeAgent._id, updatedAgent: editedAgent })
      );

      // Log the response to the console
      console.log("Update response", response);
      console.log("Update response payload message:", response.payload.message);
      setFeedbackMessage(response.payload.message);

      // Check if the update was successful based on your response structure
      if (response && response.payload && response.payload.message) {
        showFeedback(response.payload.message);
      } else {
        showFeedback("Update failed");
        setFeedbackType("error");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setFeedbackMessage("An error occurred");
      setFeedbackType("error");
    } finally {
      // Toggle off the edit mode for both fields
      if (editModeCompanyNumber) {
        toggleEditModeCompanyNumber();
      }
      if (editModeCompanySubdomain) {
        toggleEditModeCompanySubdomain();
      }
      if (editModeThemeColor) {
        toggleEditModeThemeColor();
      }
      if (editModeVVLink) {
        toggleEditModeVVLink();
      }
    }

    // Fetch data from db
    dispatch(fetchActiveAgent());
  };

  const handleSubdomainSave = async () => {
    try {
      // Assuming you have updated the `editedAgent` with the new subdomain value
      const response = await dispatch(
        updateSubdomainThunk({
          userId: activeAgent._id,
          updatedAgent: editedAgent,
          subdomain: editedAgent.company_subdomain, // Pass the new subdomain
        })
      );

      // Log the response to the console
      console.log("Update response", response);
      console.log("Update response payload message:", response.payload.message);
      setFeedbackMessage(response.payload.message);

      // Check if the update was successful based on your response structure
      if (response && response.payload && response.payload.message) {
        showFeedback(response.payload.message);
      } else {
        throw new Error("Update failed");
      }

      // Fetch data from db
      dispatch(fetchActiveAgent());

      // Trigger any other actions or update UI as needed
    } catch (error) {
      console.error("An error occurred:", error);
      setFeedbackMessage("An error occurred");
      setFeedbackType("error"); // Handle error gracefully
      showFeedback("Update failed"); // Show the unsuccessful FeedbackMessage
    } finally {
      // Toggle off the edit mode for subdomain fields
      if (editModeCompanySubdomain) {
        toggleEditModeCompanySubdomain();
      }
    }
  };

  const handleSubscriptionSave = async () => {
    try {
      // Assuming you have updated the `editedAgent` with the new subdomain value
      const response = await dispatch(
        updateSubscriptionPlanThunk({
          userId: activeAgent._id,
          updatedAgent: editedAgent,
          subscription_plan: editedAgent.subscription_plan, // Pass the new subdomain
        })
      );

      // Log the response to the console
      console.log("Update response", response);
      console.log("Update response payload message:", response.payload.message);
      setFeedbackMessage(response.payload.message);

      // Check if the update was successful based on your response structure
      if (response && response.payload && response.payload.message) {
        showFeedback(response.payload.message);
      } else {
        throw new Error("Update failed");
      }

      // Fetch data from db
      dispatch(fetchActiveAgent());

      // Trigger any other actions or update UI as needed
    } catch (error) {
      console.error("An error occurred:", error);
      setFeedbackMessage("An error occurred");
      setFeedbackType("error"); // Handle error gracefully
      showFeedback("Update failed"); // Show the unsuccessful FeedbackMessage
    } finally {
      // Toggle off the edit mode for subdomain fields
      if (editModeSubscriptionPlan) {
        toggleEditModeSubscriptionPlan();
      }
    }
  };

  const handleColorChange = (primaryColor) => {
    setPrimaryColor(primaryColor); // Update the local state with the new color
    localStorage.setItem("primaryColor", primaryColor); // Save to local storage
    document.documentElement.style.setProperty("--primary-color", primaryColor); // Update the CSS variable

    console.log("the primary color set is now", primaryColor);
    setThemeColor(primaryColor);

    // Update the editedAgent with the new theme color
    setEditedAgent((prevEditedAgent) => ({
      ...prevEditedAgent,
      theme_color: primaryColor,
    }));
  };

  const handleCompanyLogoChange = (e) => {
    setCompanyLogo(e.target.files[0]);
  };

  const openCompanyLogoModal = () => {
    setIsCompanyLogoModalOpen(true);
    console.log("logo modal OPEN");
  };

  const closeCompanyLogoModal = () => {
    setIsCompanyLogoModalOpen(false);
    console.log("logo modal CLOSED");
  };

  const openSubdomainModal = () => {
    setIsSubdomainModalOpen(true);
    console.log("subdomain modal OPEN");
  };

  const closeSubdomainModal = () => {
    setIsSubdomainModalOpen(false);
    console.log("subdomain modal CLOSED");
  };

  const openColorModal = () => {
    setIsColorModalOpen(true);
    console.log("color modal OPEN");
  };

  const closeColorModal = () => {
    setIsColorModalOpen(false);
    console.log("color modal CLOSED");
  };

  const openVVLinkModal = () => {
    setIsVVLinkModalOpen(true);
    console.log("VVLink modal OPEN");
  };

  const closeVVLinkModal = () => {
    setIsVVLinkModalOpen(false);
    console.log("VVLink modal CLOSED");
  };

  return (
    <>
      <div className="pt-4 ml-6 mr-6 sm:px-6 lg:pr-10 lg:pl-0">
        {/* Main profile for all users */}
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            General Settings
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            This is your general information.
          </p>

          <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                First Name
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">{activeAgent.first_name}</div>
              </dd>
            </div>
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Last Name
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">{activeAgent.last_name}</div>
              </dd>
            </div>
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Email address
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">{activeAgent.email}</div>
              </dd>
            </div>
          </dl>
        </div>

        {/* Agent information */}

        <div className="pt-10 pb-20">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Agent Settings
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-500">
                This information relates to your agent profile.
              </p>
            </div>
            <button
              onClick={handleRefresh}
              className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Refresh
            </button>
          </div>

          {/* Render the feedback message */}
          <div>
            {isFeedbackVisible && (
              <div
                className={`feedback-message mt-1 text-sm leading-6 ${
                  feedbackType === "success" ? "text-green-500" : "text-red-500"
                }`}>
                {feedbackType === "success" ? (
                  <FeedbackMessage message={feedbackMessage} success />
                ) : (
                  <FeedbackMessage success={false}>
                    {/* Render the error message when the call fails */}
                    <ul>
                      <li>There was an error saving your updates.</li>
                      <li>Please check the criteria and try again.</li>
                    </ul>
                  </FeedbackMessage>
                )}
              </div>
            )}
          </div>

          {/* Subscription warning */}
          {isSubscribed ? null : <NoSubscriptionWarning />}
          <div className={`relative ${!isSubscribed && "pointer-events-none"}`}>
            {!isSubscribed && (
              <div className="absolute inset-0 bg-opacity-50 backdrop-blur-sm z-10"></div>
            )}
            <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
              {/* Company Name */}
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                  Company Name{" "}
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  {editModeCompanyName ? (
                    <input
                      type="text"
                      className="text-gray-900"
                      name="company_name"
                      value={editedAgent.company_name}
                      onChange={handleInputChange}
                      disabled={!isSubscribed}
                    />
                  ) : (
                    <div className="text-gray-900">
                      {activeAgent.company_name}
                    </div>
                  )}
                </dd>
              </div>

              {/* Company Number */}
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                  Company Number
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  {editModeCompanyNumber ? (
                    <input
                      type="text"
                      className="text-gray-900"
                      name="company_number"
                      value={editedAgent.company_number}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <div className="text-gray-900">
                      {activeAgent.company_number}
                    </div>
                  )}
                  {editModeCompanyNumber ? (
                    <button
                      type="button"
                      className="font-semibold text-primary-color hover:text-indigo-500"
                      onClick={handleSave}
                      disabled={!isSubscribed}>
                      Save Phone Number
                    </button>
                  ) : (
                    <button
                      type="button"
                      className={`font-semibold ${
                        !isSubscribed ? "text-gray-400" : "text-primary-color"
                      } hover:text-indigo-500`}
                      onClick={toggleEditModeCompanyNumber}
                      disabled={!isSubscribed}>
                      Edit
                    </button>
                  )}
                </dd>
              </div>

              {/* Company Subdomain */}
              <div>
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex items-center">
                    Company Subdomain
                    <button
                      type="button"
                      onClick={openSubdomainModal}
                      className="ml-2 text-indigo-600 focus:outline-none hover:text-indigo-500">
                      <InformationCircleIcon width={20} height={20} />
                    </button>
                  </dt>

                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    {editModeCompanySubdomain ? (
                      <input
                        type="text"
                        className="text-gray-900"
                        name="company_subdomain"
                        value={editedAgent.company_subdomain}
                        onChange={handleInputChange}
                      />
                    ) : (
                      <div className="text-gray-900">
                        {activeAgent.company_subdomain}
                      </div>
                    )}
                    {activeAgent.company_subdomain &&
                    !editModeCompanySubdomain ? null : (
                      <button
                        type="button"
                        className={`font-semibold ${
                          !isSubscribed ? "text-gray-400" : "text-primary-color"
                        } hover:text-indigo-500`}
                        onClick={
                          editModeCompanySubdomain
                            ? handleSubdomainSave
                            : toggleEditModeCompanySubdomain
                        }
                        disabled={!isSubscribed}>
                        {editModeCompanySubdomain ? "Save Subdomain" : "Edit"}
                      </button>
                    )}
                  </dd>
                </div>
                {!isValidSubdomain && <SubdomainAlert />}
              </div>

              {/* Company Logo */}
              {/* <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex items-center">
                Company Logo
                <button
                  type="button"
                  onClick={openCompanyLogoModal}
                  className="ml-2 text-indigo-600 focus:outline-none hover:text-indigo-500">
                  <InformationCircleIcon width={20} height={20} />
                </button>
              </dt>

              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">{activeAgent.company_logo}</div>
                <div className="flex items-center"></div>
                <div className="mt-2 flex items-center gap-x-3">
                  <label
                    htmlFor="company-logo-upload"
                    className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500 pb-2">
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg, .svg"
                      name="company-logo"
                      onChange={handleCompanyLogoChange}
                      disabled={!isSubscribed}
                    />
                  </label>
                  {companyLogo && (
                    <div className="h-12 w-12 text-gray-300 relative">
                      <img
                        src={URL.createObjectURL(companyLogo)}
                        alt="Company Logo"
                        className="w-full h-full object-contain cursor-pointer"
                        onClick={() =>
                          window.open(URL.createObjectURL(companyLogo?.photo))
                        }
                      />
                      <button
                        type="button"
                        onClick={() =>
                          window.open(URL.createObjectURL(companyLogo))
                        }
                        className="absolute top-0 right-0 p-2 bg-white rounded-full text-gray-500 hover:bg-gray-100"
                        disabled={!isSubscribed}>
                        <EyeIcon className="w-6 h-6" />
                      </button>
                    </div>
                  )}
                </div>
                <button
                  type="button"
                  className={`font-semibold ${
                    !isSubscribed ? "text-gray-400" : "text-primary-color"
                  } hover:text-indigo-500`}
                  disabled={!isSubscribed}>
                  Edit
                </button>
              </dd>
            </div> */}

              {/* Virgin Voyages */}
              <div>
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex items-center">
                    Virgin Voyages LetsGoBook
                    <button
                      type="button"
                      onClick={openVVLinkModal}
                      className="ml-2 text-indigo-600 focus:outline-none hover:text-indigo-500">
                      <InformationCircleIcon width={20} height={20} />
                    </button>
                  </dt>

                  {/* Conditionally render the content below based on showVVCruises */}
                  {showVVCruises && (
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      {editModeVVLink ? (
                        <input
                          type="text"
                          className="text-gray-900"
                          name="virgin_voyages_link"
                          value={editedAgent.virgin_voyages_link}
                          onChange={handleInputChange}
                        />
                      ) : (
                        <div className="text-gray-900">
                          {activeAgent.virgin_voyages_link}
                        </div>
                      )}
                      {editModeVVLink ? (
                        <button
                          type="button"
                          className="font-semibold text-primary-color hover:text-indigo-500"
                          onClick={handleSave}
                          disabled={!isSubscribed}>
                          Save LetsGoBook Link
                        </button>
                      ) : (
                        <button
                          type="button"
                          className={`font-semibold ${
                            !isSubscribed
                              ? "text-gray-400"
                              : "text-primary-color"
                          } hover:text-indigo-500`}
                          onClick={toggleEditModeVVLink}
                          disabled={!isSubscribed}>
                          Edit
                        </button>
                      )}
                    </dd>
                  )}
                </div>
              </div>

              {/* Theme Color */}
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 flex items-center">
                  Theme Color{" "}
                  <button
                    type="button"
                    onClick={openColorModal}
                    className="ml-2 text-indigo-600 focus:outline-none hover:text-indigo-500">
                    <InformationCircleIcon width={20} height={20} />
                  </button>
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  {editModeThemeColor ? (
                    <>
                      {/* For the ColorPicker */}
                      <div>
                        <div
                          className="primary-color-box"
                          style={{ backgroundColor: primaryColor }}>
                          Primary Color
                        </div>

                        <ColorPicker
                          selectedColor={primaryColor}
                          onColorChange={handleColorChange}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="text-gray-900">
                      {activeAgent.theme_color}
                    </div>
                  )}
                  {editModeThemeColor ? (
                    <button
                      type="button"
                      className="font-semibold text-primary-color hover:text-indigo-500"
                      onClick={handleSave}
                      disabled={!isSubscribed}>
                      Save Theme Color
                    </button>
                  ) : (
                    <button
                      type="button"
                      className={`font-semibold ${
                        !isSubscribed ? "text-gray-400" : "text-primary-color"
                      } hover:text-indigo-500`}
                      onClick={toggleEditModeThemeColor}
                      disabled={!isSubscribed}>
                      Edit
                    </button>
                  )}
                </dd>
              </div>
            </dl>
          </div>
        </div>
        {/* </div> */}
      </div>

      <div>
        <RegisterModal
          isOpen={isCompanyLogoModalOpen}
          onClose={closeCompanyLogoModal}
          type="logo"
        />

        <RegisterModal
          isOpen={isSubdomainModalOpen}
          onClose={closeSubdomainModal}
          type="subdomain"
        />

        <RegisterModal
          isOpen={isColorModalOpen}
          onClose={closeColorModal}
          type="color"
        />

        <RegisterModal
          isOpen={isVVLinkModalOpen}
          onClose={closeVVLinkModal}
          type="vv_link"
        />
      </div>
    </>
  );
};

export default Settings;
