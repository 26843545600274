import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';

const stripe = require('stripe')('pk_test_51NXq0mDRfKHV2OKNjfOUe46jiIZC7iQCSDhDCRODjMU2HXXj5ryoF39SOgdeDOz4pxqWvtN59CXPBCvxPr56GmXD00eEFPKrde');

function InvoiceList() {
    const [invoices, setInvoices] = useState([]);
    const { subscriptionId } = useSelector((state) => state.activeUser.data.user.subscription);

    useEffect(() => {
        async function fetchInvoices() {
            try {
                const invoices = await stripe.invoices.list({
                    limit: 3,
                  });
                setInvoices(invoices);
            } catch (error) {
                console.error("Error fetching invoices:", error);
            }
        }

        fetchInvoices();
    }, []);


    return (
        <>
            <ul
                role="list"
                className="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6"
            >
                {invoices.map((invoice) => (
                    <li
                        key={invoice.id}
                        className="flex justify-between gap-x-6 py-6"
                    >
                        <div className="font-medium text-gray-900">
                            Invoice #{invoice.number}
                        </div>
                        <div className="flex border-t border-gray-100 pt-6">
                            <a
                                href="#"
                                className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                            >
                                View Invoice
                            </a>
                        </div>
                    </li>
                ))}
            </ul>
        </>
    );
}

export default InvoiceList;
