import { createSlice } from "@reduxjs/toolkit";
import { fetchAgentCruises } from "../thunks/fetchAgentCruises"; // Make sure to import the fetchAgentCruises thunk

const agentCruisesSlice = createSlice({
  name: "agentCruises",
  initialState: {
    cruises: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgentCruises.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAgentCruises.fulfilled, (state, action) => {
        state.loading = false;
        state.cruises = action.payload;
      })
      .addCase(fetchAgentCruises.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default agentCruisesSlice.reducer;
