import { createSlice } from "@reduxjs/toolkit";
import { loginAgent } from "../thunks/loginAgent";
import { fetchActiveAgent } from "../thunks/fetchActiveAgent";

const loginAgentSlice = createSlice({
  name: "loginAgentSlice",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  extraReducers(builder) {
    builder
      .addCase(loginAgent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginAgent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload; // Assuming payload contains agent data
      })
      .addCase(loginAgent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(fetchActiveAgent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.agent = action.payload; // Assuming payload contains active agent data
      });
  },
});

// Export any slice-specific actions if needed
// export const { someAgentAction } = agentSlice.actions;
export const { setLoginAgentStatistics } = loginAgentSlice.actions;


export default loginAgentSlice.reducer;
