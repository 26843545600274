import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../store/thunks/logoutUser"; // update with the actual path
import { useNavigate } from "react-router-dom";
import { persistor } from "../store";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selector = useSelector;

  const apiToken = selector((state) => state.activeUser.data.user?.token);
  const activeUser = selector((state) => state.activeUser.data);

  const [countdown, setCountdown] = useState(5);

  console.log("in Logout in src auth");

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timer); // cleanup
  }, []);

  useEffect(() => {
    if (countdown <= 0) {
      const performLogout = async () => {
        try {
          await dispatch(
            logoutUser({ token: apiToken, userId: activeUser._id })
          );
          await persistor.purge();
        } catch (err) {
          console.error(err);
        } finally {
          navigate("/");
        }
      };
      performLogout();
    }
  }, [countdown, dispatch, navigate, apiToken, activeUser]);

  return (
    <div>You've been logged out. Redirecting in {countdown} seconds...</div>
  );
};

export default Logout;
