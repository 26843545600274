import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

const SortDropdown = ({ value, title, onChange, options }) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleChange = (selected) => {
    setSelectedOption(selected);
    onChange(selected.value);
  };

  return (
    <div>
      <label
        htmlFor="sort"
        className="block text-sm font-medium leading-6 text-gray-900">
        {title}
      </label>
      <select
        id="sort"
        name="sort"
        className="mt-2 mb-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        value={selectedOption.value}
        onChange={(e) =>
          handleChange(
            options.find((option) => option.value === e.target.value)
          )
        }>
        {options.map((option) => (
          <option key={option.id} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SortDropdown;
