const portList = [
  { unlocode: "MXCSL", name: "Cabo San Lucas", country: "Mexico" },
  { unlocode: "DOPOP", name: "Puerto Plata", country: "Dominican Republic" },
  { unlocode: "USSIT", name: "Sitka, Alaska", country: "United States" },
  { unlocode: "ITSML", name: "Santa Margherita Ligure", country: "Italy" },
  { unlocode: "AUPEA", name: "Penneshaw", country: "Australia" },
  {
    unlocode: "USFLL",
    name: "Fort Lauderdale, Florida",
    country: "United States",
  },
  { unlocode: "GRSDH", name: "Soúda, Crete", country: "Greece" },
  { unlocode: "JMFMH", name: "Falmouth", country: "Jamaica" },
  { unlocode: "PTFNC", name: "Funchal, Madeira", country: "Portugal" },
  { unlocode: "FRLEH", name: "Le Havre", country: "France" },
  { unlocode: "BQKRA", name: "Kralendijk", country: "Bonaire" },
  { unlocode: "WSAPW", name: "Apia", country: "Samoa" },
  { unlocode: "JPSMZ", name: "Shimizu", country: "Japan" },
  { unlocode: "INBOM", name: "Mumbai (ex Bombay)", country: "India" },
  { unlocode: "VNNHA", name: "Nha Trang", country: "Vietnam" },
  { unlocode: "VGTOV", name: "Tortola", country: "British Virgin Islands" },
  { unlocode: "GRHER", name: "Heraklion (Iraklion), Crete", country: "Greece" },
  { unlocode: "NOBGO", name: "Bergen", country: "Norway" },
  { unlocode: "COCTG", name: "Cartagena", country: "Colombia" },
  { unlocode: "ESCAR", name: "Cartagena", country: "Spain" },
  { unlocode: "USSWD", name: "Seward, Alaska", country: "United States" },
  {
    unlocode: "USSBA",
    name: "Santa Barbara, California",
    country: "United States",
  },
  { unlocode: "ITCAG", name: "Cagliari", country: "Italy" },
  { unlocode: "GRKAK", name: "Katakolon", country: "Greece" },
  {
    unlocode: "USSFO",
    name: "San Francisco, California",
    country: "United States",
  },
  {
    unlocode: "SXPHI",
    name: "Philipsburg",
    country: "Sint Maarten (Dutch part)",
  },
  { unlocode: "NOHAU", name: "Haugesund", country: "Norway" },
  { unlocode: "ITCTA", name: "Catania", country: "Italy" },
  { unlocode: "NZWLG", name: "Wellington", country: "New Zealand" },
  { unlocode: "ITSPE", name: "La Spezia", country: "Italy" },
  { unlocode: "GDSTG", name: "Saint George's", country: "Grenada" },
  { unlocode: "BZBZE", name: "Belize City", country: "Belize" },
  {
    unlocode: "KNBAS",
    name: "Basseterre, Saint Kitts",
    country: "Saint Kitts and Nevis",
  },
  { unlocode: "FRMRS", name: "Marseille", country: "France" },
  { unlocode: "GLJJU", name: "Qaqortoq (Julianehaab)", country: "Greenland" },
  { unlocode: "USKHH", name: "Kailua-Kona, Hawaii", country: "United States" },
  { unlocode: "GBKWL", name: "Kirkwall, Orkney Islands", country: "Scotland" },
  { unlocode: "CWWIL", name: "Willemstad", country: "Curaçao" },
  { unlocode: "MXCZM", name: "Cozumel", country: "Mexico" },
  { unlocode: "MXPVR", name: "Puerto Vallarta", country: "Mexico" },
  { unlocode: "FJLTK", name: "Lautoka", country: "Fiji" },
  { unlocode: "NOSVG", name: "Stavanger", country: "Norway" },
  { unlocode: "USPWM", name: "Portland, Maine", country: "United States" },
  { unlocode: "GRARM", name: "Argostoli", country: "Greece" },
  {
    unlocode: "USBOS",
    name: "Boston, Massachusetts",
    country: "United States",
  },
  { unlocode: "AUSYD", name: "Sydney, New South Wales", country: "Australia" },
  { unlocode: "NZTRG", name: "Tauranga", country: "New Zealand" },
  { unlocode: "FJSUV", name: "Suva", country: "Fiji" },
  { unlocode: "ASPPG", name: "Pago Pago", country: "American Samoa" },
  { unlocode: "JPYOK", name: "Yokohama", country: "Japan" },
  { unlocode: "BSCOC", name: "Coco Cay", country: "Bahamas" },
  { unlocode: "UYPDP", name: "Punta del Este", country: "Uruguay" },
  { unlocode: "GBGRK", name: "Greenock", country: "Scotland" },
  { unlocode: "CASYD", name: "Sydney, Nova Scotia", country: "Canada" },
  { unlocode: "FRTLN", name: "Toulon", country: "France" },
  { unlocode: "GRRHO", name: "Rhodes", country: "Greece" },
  { unlocode: "ITSAL", name: "Salerno", country: "Italy" },
  { unlocode: "ESALC", name: "Alicante", country: "Spain" },
  { unlocode: "AUHBA", name: "Hobart, Tasmania", country: "Australia" },
  { unlocode: "ESSCT", name: "Santa Cruz de Tenerife", country: "Spain" },
  { unlocode: "ESLAZ", name: "Lanzarote", country: "Spain" },
  { unlocode: "PFPPT", name: "Papeete, Tahiti", country: "French Polynesia" },
  { unlocode: "GRVOL", name: "Vólos", country: "Greece" },
  { unlocode: "CASJB", name: "Saint-John, New Brunswick", country: "Canada" },
  { unlocode: "TRBXN", name: "Bodrum", country: "Turkey" },
  { unlocode: "NZDUD", name: "Dunedin", country: "New Zealand" },
  { unlocode: "ESAGP", name: "Málaga", country: "Spain" },
  { unlocode: "ESLCG", name: "La Coruña", country: "Spain" },
  { unlocode: "GBDVR", name: "Dover", country: "England" },
  { unlocode: "TTSCA", name: "Scarborough", country: "Trinidad and Tobago" },
  { unlocode: "USTPA", name: "Tampa, Florida", country: "United States" },
  { unlocode: "CAQUE", name: "Quebec City, Québec", country: "Canada" },
  {
    unlocode: "VICHA",
    name: "Charlotte Amalie",
    country: "U.S. Virgin Islands",
  },
  { unlocode: "GBSOU", name: "Southampton", country: "England" },
  { unlocode: "CRPAS", name: "Puntarenas", country: "Costa Rica" },
  {
    unlocode: "TCGDT",
    name: "Grand Turk Island",
    country: "Turks and Caicos Islands",
  },
  {
    unlocode: "VGRAD",
    name: "Road Town, Tortola",
    country: "British Virgin Islands",
  },
  {
    unlocode: "USMSY",
    name: "New Orleans, Louisiana",
    country: "United States",
  },
  {
    unlocode: "USLAX",
    name: "Los Angeles, California",
    country: "United States",
  },
  { unlocode: "NOTOS", name: "Tromsø", country: "Norway" },
  { unlocode: "VULUG", name: "Luganville", country: "Vanuatu" },
  { unlocode: "AWORJ", name: "Oranjestad", country: "Aruba" },
  { unlocode: "VNCMY", name: "Chan May", country: "Vietnam" },
  { unlocode: "USEYW", name: "Key West, Florida", country: "United States" },
  { unlocode: "GRCFU", name: "Corfu", country: "Greece" },
  { unlocode: "SEVBY", name: "Visby", country: "Sweden" },
  { unlocode: "CLPUQ", name: "Punta Arenas", country: "Chile" },
  { unlocode: "BRSSA", name: "Salvador de Bahia", country: "Brazil" },
  { unlocode: "USMIA", name: "Miami, Florida", country: "United States" },
  { unlocode: "ITPTF", name: "Portofino", country: "Italy" },
  { unlocode: "ESPMI", name: "Palma de Mallorca", country: "Spain" },
  { unlocode: "VUVLI", name: "Port Vila", country: "Vanuatu" },
  { unlocode: "GBHLY", name: "Holyhead", country: "Wales" },
  { unlocode: "ESBIO", name: "Bilbao", country: "Spain" },
  { unlocode: "USGLS", name: "Galveston, Texas", country: "United States" },
  { unlocode: "GRATN", name: "Santorini", country: "Greece" },
  { unlocode: "ESIBZ", name: "Ibiza", country: "Spain" },
  { unlocode: "NOGNR", name: "Geiranger", country: "Norway" },
  {
    unlocode: "AUADL",
    name: "Adelaide, South Australia",
    country: "Australia",
  },
  { unlocode: "ITRAN", name: "Ravenna", country: "Italy" },
  { unlocode: "GBBEL", name: "Belfast", country: "Northern Ireland" },
  { unlocode: "DKCPH", name: "Copenhagen", country: "Denmark" },
  { unlocode: "NZCHC", name: "Christchurch", country: "New Zealand" },
  { unlocode: "GRNAF", name: "Náfplion", country: "Greece" },
  { unlocode: "AUQDN", name: "Eden, New South Wales", country: "Australia" },
  { unlocode: "ARUSH", name: "Ushuaia", country: "Argentina" },
  { unlocode: "ESBCN", name: "Barcelona", country: "Spain" },
  { unlocode: "AUWSY", name: "Airlie Beach", country: "Australia" },
  { unlocode: "MEKOT", name: "Kotor", country: "Montenegro" },
  { unlocode: "NZAKL", name: "Auckland", country: "New Zealand" },
  { unlocode: "GBLER", name: "Lerwick, Shetland Islands", country: "Scotland" },
  { unlocode: "CYLMS", name: "Limassol", country: "Cyprus" },
  { unlocode: "BSBIM", name: "Bimini Island", country: "Bahamas" },
  { unlocode: "USKTN", name: "Ketchikan, Alaska", country: "United States" },
  { unlocode: "ITNAP", name: "Naples", country: "Italy" },
  { unlocode: "NLRTM", name: "Rotterdam", country: "Netherlands" },
  { unlocode: "NOHVG", name: "Honningsvåg", country: "Norway" },
  { unlocode: "ITLIV", name: "Livorno", country: "Italy" },
  { unlocode: "USRKD", name: "Rockland, Maine", country: "United States" },
  { unlocode: "MYLGK", name: "Langkawi Island", country: "Malaysia" },
  { unlocode: "NOAES", name: "Ålesund", country: "Norway" },
  { unlocode: "PTPDL", name: "Ponta Delgada, Azores", country: "Portugal" },
  { unlocode: "EETLL", name: "Tallinn", country: "Estonia" },
  {
    unlocode: "KYGEC",
    name: "Georgetown, Grand Cayman",
    country: "Cayman Islands",
  },
  { unlocode: "ESVGO", name: "Vigo", country: "Spain" },
  { unlocode: "HNRTB", name: "Roatán Island", country: "Honduras" },
  { unlocode: "ESVLC", name: "Valencia", country: "Spain" },
  { unlocode: "INMRM", name: "Mormugao, Goa", country: "India" },
  { unlocode: "IDLOM", name: "Lombok", country: "Indonesia" },
  { unlocode: "USHNS", name: "Haines, Alaska", country: "United States" },
  { unlocode: "BMKWF", name: "Kings Wharf", country: "Bermuda" },
  { unlocode: "NZPCN", name: "Picton", country: "New Zealand" },
  { unlocode: "ITPMO", name: "Palermo", country: "Italy" },
  { unlocode: "AGSJO", name: "Saint John's", country: "Antigua and Barbuda" },
  { unlocode: "JPAOJ", name: "Aomori", country: "Japan" },
  { unlocode: "GPPTP", name: "Pointe-à-Pitre", country: "Guadeloupe" },
  { unlocode: "USSEA", name: "Seattle, Washington", country: "United States" },
  { unlocode: "NOOSL", name: "Oslo", country: "Norway" },
  {
    unlocode: "USPCV",
    name: "Port Canaveral, Florida",
    country: "United States",
  },
  { unlocode: "MQFDF", name: "Fort-de-France", country: "Martinique" },
  { unlocode: "DMRSU", name: "Roseau", country: "Dominica" },
  { unlocode: "ITMSN", name: "Messina", country: "Italy" },
  { unlocode: "NOMOL", name: "Molde", country: "Norway" },
  { unlocode: "TRIST", name: "Istanbul", country: "Turkey" },
  { unlocode: "NOOLD", name: "Olden", country: "Norway" },
  { unlocode: "ARPMY", name: "Puerto Madryn", country: "Argentina" },
  { unlocode: "IECOB", name: "Cobh", country: "Ireland" },
  { unlocode: "IDCEB", name: "Celukan Bawang, Bali", country: "Indonesia" },
  { unlocode: "GRKVA", name: "Kavalla", country: "Greece" },
  { unlocode: "GIGIB", name: "Gibraltar", country: "Gibraltar" },
  { unlocode: "MEBAR", name: "Bar", country: "Montenegro" },
  { unlocode: "GRJMK", name: "Mykonos", country: "Greece" },
  { unlocode: "MXMZT", name: "Mazatlán", country: "Mexico" },
  { unlocode: "HRZAD", name: "Zadar", country: "Croatia" },
  {
    unlocode: "USGLO",
    name: "Gloucester, Massachusetts",
    country: "United States",
  },
  {
    unlocode: "USCHS",
    name: "Charleston, South Carolina",
    country: "United States",
  },
  { unlocode: "KRPUS", name: "Busan", country: "South Korea" },
  { unlocode: "GRSKG", name: "Thessaloníki", country: "Greece" },
  { unlocode: "MATNG", name: "Tangier", country: "Morocco" },
  { unlocode: "LKHBA", name: "Hambantota", country: "Sri Lanka" },
  {
    unlocode: "VIFRD",
    name: "Frederiksted, Saint Croix",
    country: "U.S. Virgin Islands",
  },
  { unlocode: "MTMLA", name: "Valletta", country: "Malta" },
  { unlocode: "ITBDS", name: "Brindisi", country: "Italy" },
  { unlocode: "USITO", name: "Hilo, Hawaii", country: "United States" },
  { unlocode: "NZBOI", name: "Bay of Islands", country: "New Zealand" },
  { unlocode: "AUBNE", name: "Brisbane, Queensland", country: "Australia" },
  { unlocode: "PAONX", name: "Colón", country: "Panama" },
  { unlocode: "TRKUS", name: "Kusadasi", country: "Turkey" },
  { unlocode: "MYPEN", name: "Georgetown, Penang", country: "Malaysia" },
  { unlocode: "TTPOS", name: "Port-of-Spain", country: "Trinidad and Tobago" },
  { unlocode: "MXESE", name: "Ensenada", country: "Mexico" },
  { unlocode: "GBLIV", name: "Liverpool", country: "England" },
  { unlocode: "INCOK", name: "Cochin", country: "India" },
  { unlocode: "GRCHQ", name: "Khania", country: "Greece" },
  { unlocode: "DOLRM", name: "La Romana", country: "Dominican Republic" },
  { unlocode: "ISAKU", name: "Akureyri", country: "Iceland" },
  { unlocode: "ESTCI", name: "Tenerife", country: "Spain" },
  {
    unlocode: "CASJF",
    name: "Saint-John's, Newfoundland and Labrador",
    country: "Canada",
  },
  { unlocode: "ISISA", name: "Isafjørdur", country: "Iceland" },
  { unlocode: "JPHKD", name: "Hakodate", country: "Japan" },
  {
    unlocode: "MXHTO",
    name: "Huatulco (Santa María Huatulco)",
    country: "Mexico",
  },
  { unlocode: "USNIJ", name: "Nawiliwili, Hawaii", country: "United States" },
  { unlocode: "NCLIF", name: "Lifou Island", country: "New Caledonia" },
  { unlocode: "MXPGO", name: "Progreso", country: "Mexico" },
  { unlocode: "NCNOU", name: "Nouméa", country: "New Caledonia" },
  { unlocode: "BBBGI", name: "Bridgetown", country: "Barbados" },
  { unlocode: "PTLIS", name: "Lisbon", country: "Portugal" },
  { unlocode: "ISREY", name: "Reykjavík", country: "Iceland" },
  { unlocode: "HTLAB", name: "Labadee", country: "Haiti" },
  { unlocode: "CAVIC", name: "Victoria, British Columbia", country: "Canada" },
  { unlocode: "MXCOM", name: "Costa Maya", country: "Mexico" },
  { unlocode: "HKHKG", name: "Hong Kong", country: "Hong Kong" },
  { unlocode: "ESSPC", name: "Santa Cruz de La Palma", country: "Spain" },
  { unlocode: "AUPTI", name: "Port Douglas, Queensland", country: "Australia" },
  { unlocode: "GBLXN", name: "Loch Ness", country: "Scotland" },
  { unlocode: "HRDBV", name: "Dubrovnik", country: "Croatia" },
  { unlocode: "GRPIR", name: "Piraeus", country: "Greece" },
  { unlocode: "NZNPE", name: "Napier", country: "New Zealand" },
  { unlocode: "USHNL", name: "Honolulu, Hawaii", country: "United States" },
  { unlocode: "THLCH", name: "Laem Chabang", country: "Thailand" },
  { unlocode: "AUMEL", name: "Melbourne, Victoria", country: "Australia" },
  { unlocode: "DKSKA", name: "Skagen", country: "Denmark" },
  { unlocode: "FIHEL", name: "Helsinki", country: "Finland" },
  { unlocode: "USAST", name: "Astoria, Oregon", country: "United States" },
  { unlocode: "GBEDI", name: "Edinburgh", country: "Scotland" },
  {
    unlocode: "AUYKK",
    name: "Yorkeys Knob, Cairns, Queensland",
    country: "Australia",
  },
  { unlocode: "FRLRH", name: "La Rochelle", country: "France" },
  { unlocode: "NOKRS", name: "Kristiansand", country: "Norway" },
  { unlocode: "USBAL", name: "Baltimore, Maryland", country: "United States" },
  { unlocode: "ITTRS", name: "Trieste", country: "Italy" },
  { unlocode: "ITMDC", name: "Carrara", country: "Italy" },
  {
    unlocode: "USCKI",
    name: "Santa Catalina Island, California",
    country: "United States",
  },
  { unlocode: "GTPRQ", name: "Puerto Quetzal", country: "Guatemala" },
  { unlocode: "GBPTL", name: "Isle of Portland", country: "England" },
  { unlocode: "BSNAS", name: "Nassau", country: "Bahamas" },
  { unlocode: "USJNU", name: "Juneau, Alaska", country: "United States" },
  { unlocode: "PFRFP", name: "Raiatea", country: "French Polynesia" },
  { unlocode: "VNHLG", name: "Ha Long Bay", country: "Vietnam" },
  { unlocode: "CAVAN", name: "Vancouver, British Columbia", country: "Canada" },
  { unlocode: "GRHYD", name: "Hydra", country: "Greece" },
  { unlocode: "PRSJU", name: "San Juan (Puerto Rico)", country: "Puerto Rico" },
  { unlocode: "SGSIN", name: "Singapore", country: "Singapore" },
  { unlocode: "JPOSA", name: "Osaka", country: "Japan" },
  { unlocode: "MACAS", name: "Casablanca", country: "Morocco" },
  { unlocode: "NOFLA", name: "Flåm", country: "Norway" },
  { unlocode: "JPHIJ", name: "Hiroshima", country: "Japan" },
  { unlocode: "LKCMB", name: "Colombo", country: "Sri Lanka" },
  { unlocode: "USSGY", name: "Skagway, Alaska", country: "United States" },
  { unlocode: "PFMOZ", name: "Moorea", country: "French Polynesia" },
  { unlocode: "USICY", name: "Icy Strait Point", country: "United States" },
  { unlocode: "THHKT", name: "Phuket", country: "Thailand" },
  { unlocode: "NZTIU", name: "Timaru", country: "New Zealand" },
  { unlocode: "CRLIO", name: "Puerto Limón", country: "Costa Rica" },
  { unlocode: "BSFPO", name: "Freeport, Grand Bahama", country: "Bahamas" },
  { unlocode: "MYPKG", name: "Port Klang", country: "Malaysia" },
  { unlocode: "VNPHU", name: "Phu My", country: "Vietnam" },
  { unlocode: "UYMVD", name: "Montevideo", country: "Uruguay" },
  { unlocode: "HRSPU", name: "Split", country: "Croatia" },
  {
    unlocode: "VISTX",
    name: "Saint Croix Island",
    country: "U.S. Virgin Islands",
  },
  { unlocode: "ITCVV", name: "Civitavecchia", country: "Italy" },
  { unlocode: "THKSM", name: "Ko Samui", country: "Thailand" },
  { unlocode: "LCCAS", name: "Castries", country: "Saint Lucia" },
  { unlocode: "PTLEI", name: "Leixões", country: "Portugal" },
  { unlocode: "CAHAL", name: "Halifax, Nova Scotia", country: "Canada" },
  { unlocode: "GGSPT", name: "Saint Peter Port", country: "Guernsey" },
  { unlocode: "CLVAP", name: "Valparaiso", country: "Chile" },
  { unlocode: "VUMYS", name: "Mystery Island (Inyeug)", country: "Vanuatu" },
  { unlocode: "SESTO", name: "Stockholm", country: "Sweden" },
  { unlocode: "ESLPA", name: "Las Palmas de Gran Canaria", country: "Spain" },
  { unlocode: "BEZEE", name: "Zeebrugge", country: "Belgium" },
  { unlocode: "FRAJA", name: "Ajaccio, Corsica", country: "France" },
  { unlocode: "FRBOD", name: "Bordeaux", country: "France" },
  { unlocode: "NONFD", name: "Nordfjordeid", country: "Norway" },
  {
    unlocode: "CACHA",
    name: "Charlottetown, Prince Edward Island",
    country: "Canada",
  },
  { unlocode: "BRRIO", name: "Rio de Janeiro", country: "Brazil" },
  { unlocode: "GBPME", name: "Portsmouth", country: "England" },
  { unlocode: "ESCAD", name: "Cádiz", country: "Spain" },
  {
    unlocode: "USOB2",
    name: "Martha's Vineyard, Massachusetts",
    country: "United States",
  },
  { unlocode: "IDBOA", name: "Benoa, Bali", country: "Indonesia" },
  { unlocode: "FRNCE", name: "Nice", country: "France" },
  { unlocode: "FRCEQ", name: "Cannes", country: "France" },
  {
    unlocode: "USBAY",
    name: "Cape Liberty, Bayonne, New Jersey",
    country: "United States",
  },
  { unlocode: "NLAMS", name: "Amsterdam", country: "Netherlands" },
  { unlocode: "FRVFM", name: "Villefranche-sur-Mer", country: "France" },
  { unlocode: "ARBUE", name: "Buenos Aires", country: "Argentina" },
];

module.exports = portList;
