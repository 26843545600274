import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Cookies from "js-cookie";
import CCNavBar from "../components/landingPage/CCNavBar";
import NewBookingSteps from "../components/landingPage/NewBookingSteps";
import NewHeroSection from "../components/landingPage/NewHeroSection";
import NewPlatformOverview from "../components/landingPage/NewPlatformOverview";
import NewFeatureList from "../components/landingPage/NewFeatureList";
import TopDestination from "../components/landingPage/TopDestination";
import NewGetStarted from "../components/landingPage/NewGetStarted";
import Footer from "../components/landingPage/Footer";
import GetStarted from "../components/agentLandingPage/GetStarted";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./LandingPage.css";

function NewLandingPage() {
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const referrer = urlParams.get("referrer");

    if (referrer) {
      Cookies.set("referrer", referrer, { expires: 180 }); // 180 days expiration
    }
  }, [location]);

  return (
    <>
      <CCNavBar />
      <NewHeroSection />
      <NewPlatformOverview />
      <NewBookingSteps />
      <NewFeatureList />
      <TopDestination />
      {/* <NewGetStarted /> */}
      <Footer />
    </>
  );
}

export default NewLandingPage;
