import { CheckIcon } from "@heroicons/react/20/solid";
import { AgentFeatureList } from "./DataLists";

const features = [
  {
    name: AgentFeatureList.features[0].name,
    description: AgentFeatureList.features[0].description,
  },
  {
    name: AgentFeatureList.features[1].name,
    description: AgentFeatureList.features[1].description,
  },
  {
    name: AgentFeatureList.features[2].name,
    description: AgentFeatureList.features[2].description,
  },
  {
    name: AgentFeatureList.features[3].name,
    description: AgentFeatureList.features[3].description,
  },
  {
    name: AgentFeatureList.features[4].name,
    description: AgentFeatureList.features[4].description,
  },
  {
    name: AgentFeatureList.features[5].name,
    description: AgentFeatureList.features[5].description,
  },
  // {
  //   name: AgentFeatureList.features[6].name,
  //   description: AgentFeatureList.features[6].description,
  // },
  // {
  //   name: AgentFeatureList.features[7].name,
  //   description: AgentFeatureList.features[7].description,
  // },
  // {
  //   name: AgentFeatureList.features[8].name,
  //   description: AgentFeatureList.features[8].description,
  // },
];

export default function FeatureList() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-indigo-600">
              {AgentFeatureList.firstText}
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {AgentFeatureList.secondText}
            </p>
            <p className="mt-6 text-base leading-7 text-gray-600">
              {AgentFeatureList.thirdText}
            </p>
            <p className="mt-6 text-base leading-7 text-gray-600">
              {AgentFeatureList.fourthText}
            </p>
            <p className="mt-6 text-base leading-7 text-gray-600">
              {AgentFeatureList.fifthText}
            </p>
          </div>
          <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-9">
                <dt className="font-semibold text-gray-900">
                  <CheckIcon
                    className="absolute left-0 top-1 h-5 w-5 text-indigo-500"
                    aria-hidden="true"
                  />
                  {feature.name}
                </dt>
                <dd className="mt-2">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
