import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFaqBySlug } from '../../store/thunks/faqsThunk'; // Adjust the import path as necessary
import CCNavBar from '../../components/landingPage/CCNavBar';
import Footer from '../../components/landingPage/Footer';

const FAQ = () => {
  const { faq: faqSlug } = useParams();
  const dispatch = useDispatch();

  // Accessing FAQ state using useSelector
  const { currentFaq, isLoading, error } = useSelector((state) => state.faqs);

  useEffect(() => {
    if (faqSlug) {
      dispatch(fetchFaqBySlug(faqSlug));
    }
  }, [dispatch, faqSlug]);

  // Handle loading state
  if (isLoading) {
    return <div>Loading FAQ...</div>;
  }

  // Handle error state
  if (error) {
    return <div>Error fetching FAQ: {error}</div>;
  }

  // Ensure the FAQ data is available
  if (!currentFaq) {
    return <div>FAQ not found.</div>;
  }

  return (
    <>
    <CCNavBar />
    <div>
      <h1>{currentFaq.question}</h1>
      <p>{currentFaq.answer}</p>
    </div>
    <Footer />
    </>
  );
};

export default FAQ;
