import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../config"; // Import the dynamic API base URL

const fetchSubscriptionStatus = createAsyncThunk(
  "subscription/status",
  async (_, { getState }) => {
    const state = getState();
    const { token, _id } = state.activeUser.data.user; // Update this according to your user info structure

    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-api-token": token,
        "x-user-id": _id,
        // Add other headers as needed
      },
    };

    try {
      const response = await axios.get(
        `https://${apiBaseUrl}/subscription/status/${_id}`,
        config
      );
      return response.data;
    } catch (error) {
      // Handle error as needed
      throw error;
    }
  }
);

export { fetchSubscriptionStatus };

