import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ReactGA from "react-ga4";
import { isDev } from "../config";
import CruiseDetailModal from "../components/cruiseDetail/CruiseDetailModal";
import "./QuoteDetailPage.css";

import { fetchCruiseDetails } from "../store/thunks/widgety/fetchCruiseDetails";
import { fetchQuoteById } from "../store/thunks/quotesThunk";

import Logo from "../components/Logo";
import royalLogoSmall from "../assets/royalLogoSmall.png";
import celebrityLogo from "../assets/celebrityLogo.png";
import virginLogo from "../assets/virginLogo.png";

import { Disclosure } from "@headlessui/react";
import {
  EnvelopeIcon,
  PhoneIcon,
  IdentificationIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function QuoteDetailPage() {
  const dispatch = useDispatch();
  const { quoteId } = useParams(); // Extract quoteId from URL parameters
  const [quote, setQuote] = useState(null); // State to store the quote data

  console.log(quote);
  const [cruiseDetailModalOpen, setCruiseDetailModalOpen] = useState(false);
  const [cruiseUnavailableModalOpen, setCruiseUnavailableModalOpen] =
    useState(false);

  const [localCruiseData, setLocalCruiseData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const isMobile = window.innerWidth <= 768;

  // This useEffect fetches the quote data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const action = await dispatch(fetchQuoteById(quoteId));
        const fetchedQuote = action.payload; // Assuming payload contains the fetched quote data
        setQuote(fetchedQuote);
      } catch (error) {
        console.error("Error fetching quote:", error);
      }
    };

    fetchData();
  }, [dispatch, quoteId]);

  // This useEffect fetches the itinerary data
  useEffect(() => {
    const fetchCruiseDetailsData = async () => {
      setIsLoading(true);
      try {
        const sailDateToISO = new Date(quote?.cruiseInformation.SailDate);
        sailDateToISO.setDate(sailDateToISO.getDate() + 1);
        const endDate = sailDateToISO.toISOString().split("T")[0];

        // Fetch cruise details data
        const response = await dispatch(
          fetchCruiseDetails({
            start_date_range_beginning: quote?.cruiseInformation.SailDate,
            start_date_range_end: endDate,
            ship_name: quote?.cruiseInformation.Ship,
          })
        );

        const data = response.payload;

        setLocalCruiseData(data);
      } catch (error) {
        console.error("Error fetching cruise details", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (quote?.cruiseInformation.SailDate && quote?.cruiseInformation.Ship) {
      fetchCruiseDetailsData();
    }
  }, [
    quote?.cruiseInformation.SailDate,
    quote?.cruiseInformation.Ship,
    dispatch,
  ]);

  function formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", { ...options, timeZone: "UTC" });
  }

  const getActivityData = (quote) => {
    const activity = [];

    // Create activity item for invoice creation
    activity.push({
      id: 1,
      type: "created",
      person: { name: quote?.agentInformation?.AgentName },
      date: formatDate(quote?.createdAt),
      dateTime: quote?.createdAt,
    });

    // Create activity item for invoice update, if it's different from creation
    if (quote?.createdAt !== quote?.updatedAt) {
      activity.push({
        id: 2,
        type: "edited",
        person: { name: quote?.agentInformation?.AgentName },
        date: formatDate(quote?.updatedAt),
        dateTime: quote?.updatedAt,
      });
    }

    // Create activity item for sail date
    activity.push({
      id: 3,
      type: "sail",
      person: { name: quote?.cruiseInformation?.name },
      date: formatDate(quote?.cruiseInformation.SailDate),
      dateTime: quote?.cruiseInformation.SailDate,
    });

    // Sort activity by dateTime in descending order
    activity.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));

    return activity;
  };

  const activity = getActivityData(quote);

  const calculateTimeDifference = (dateTime) => {
    const now = new Date();
    const activityDateTime = new Date(dateTime);

    // Adjust for timezone offset
    const timezoneOffset = activityDateTime.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
    const adjustedActivityDateTime = new Date(
      activityDateTime - timezoneOffset
    );

    const differenceInMilliseconds =
      adjustedActivityDateTime.getTime() - now.getTime();
    const differenceInDays = Math.ceil(
      differenceInMilliseconds / (1000 * 60 * 60 * 24)
    );

    return differenceInDays > 0
      ? `in ${differenceInDays} days`
      : `${-differenceInDays} days ago`;
  };

  function toggleCruiseDetailModal(show) {
    console.log("toggleCruiseDetailModal called with:", show);
    if (!isDev) {
      ReactGA.event({
        event: "cruise_search",
        category: "Search",
        action: "Clicked Cruise Detail",
      });
    }
    setCruiseDetailModalOpen(show);
  }

  function toggleCruiseUnavailableModal(show) {
    setCruiseUnavailableModalOpen(show);
  }

  return (
    <>
      {/* Logo header*/}
      <Disclosure as="nav" className="border-b border-gray-200 bg-white">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 justify-between">
                <div className="flex">
                  <div className="flex flex-shrink-0 items-center">
                    <Logo height="auto" width="8rem" />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Disclosure>

      <div>
        {/* Cruise Info header */}
        <header className="relative isolate pt-16 bg-white">
          <div
            className="absolute inset-0 -z-10 overflow-hidden"
            aria-hidden="true">
            <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80">
              <div
                className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC]"
                style={{
                  clipPath:
                    "polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)",
                }}
              />
            </div>
            <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
          </div>

          <div className="mx-auto max-w-7xl px-4 pb-10 sm:px-6 lg:px-8">
            <div className="flex flex-col sm:flex-row items-center justify-between">
              <div className="flex items-center space-x-4">
                <img
                  src={
                    quote?.cruiseInformation.CruiseLine === "Royal Caribbean"
                      ? royalLogoSmall
                      : quote?.cruiseInformation.CruiseLine === "Celebrity"
                      ? celebrityLogo
                      : virginLogo
                  }
                  alt="Cruise Line Logo"
                  className="h-16 w-16 flex-none rounded-full"
                />
                <div>
                  <h1 className="text-lg font-semibold text-gray-900">
                    {quote?.cruiseInformation.name}
                  </h1>
                  <p className="text-sm text-gray-500">
                    {quote?.cruiseInformation.Ship} on{" "}
                    {formatDate(quote?.cruiseInformation.SailDate)}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: 8,
                  paddingTop: isMobile ? 20 : 0,
                }}>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    const { Ship, SailDate } = quote?.cruiseInformation;
                    const url = `/cruise_detail?shipName=${encodeURIComponent(
                      Ship
                    )}&sailDate=${encodeURIComponent(SailDate)}`;
                    window.open(url, "_blank");
                  }}
                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  View Cruise Details
                </button>

                <button
                  onClick={() => setCruiseDetailModalOpen(true)}
                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  View Itinerary Details
                </button>
              </div>
            </div>
          </div>
        </header>

        <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {/* Agent Information */}
            <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5 w-full">
              <div className="pl-6 pt-6 pb-6 border-b border-gray-900/5">
                <dt className="text-sm font-semibold leading-6 text-gray-900">
                  Agent Information
                </dt>
              </div>
              <div className="mb-6">
                <div className="mt-6 flex w-full flex-none gap-x-4 px-6">
                  <dt className="flex-none">
                    <UserCircleIcon
                      className="h-6 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </dt>
                  <dd className="text-sm font-medium leading-6 text-gray-900">
                    {quote?.agentInformation.AgentName}
                  </dd>
                </div>
                <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                  <dt className="flex-none">
                    <EnvelopeIcon
                      className="h-6 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </dt>
                  <dd className="text-sm leading-6 text-gray-500">
                    {quote?.agentInformation.AgentEmail}
                  </dd>
                </div>
                <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                  <dt className="flex-none">
                    <PhoneIcon
                      className="h-6 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </dt>
                  <dd className="text-sm leading-6 text-gray-500">
                    {quote?.agentInformation.AgentCompanyNumber}
                  </dd>
                </div>
                <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                  <dt className="flex-none">
                    <IdentificationIcon
                      className="h-6 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </dt>
                  <dd className="text-sm leading-6 text-gray-500">
                    {quote?.agentInformation.AgentCompanyName}
                  </dd>
                </div>
              </div>
            </div>

            {/* Quote Overview (Table) */}
            <div className="-mx-4 px-4 py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-16 xl:pb-20 xl:pt-16">
              <h2 className="text-lg font-semibold leading-6 text-gray-900">
                Quote{" "}
                <span className="text-gray-700">
                  #{quote?._id && quote?._id.slice(-8)}
                </span>
              </h2>
              <dl className="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
                <div className="sm:pr-4">
                  <dt className="inline text-gray-500">Created on</dt>{" "}
                  <dd className="inline text-gray-700">
                    <time dateTime={quote?.createdAt}>
                      {formatDate(quote?.createdAt)}
                    </time>
                  </dd>
                </div>

                <div className="sm:pl-4 sm:pr-4">
                  <dt className="inline text-gray-500">Last updated on</dt>{" "}
                  <dd className="inline text-gray-700">
                    <time dateTime={quote?.updatedAt || quote?.createdAt}>
                      {formatDate(quote?.updatedAt || quote?.createdAt)}
                    </time>
                  </dd>
                </div>

                <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
                  <dt className="font-semibold text-gray-900">From</dt>
                  <dd className="mt-2 text-gray-500">
                    <span className="font-medium text-gray-900">
                      {quote?.agentInformation.AgentName}
                    </span>
                    <br />
                    {quote?.agentInformation.AgentEmail} <br />
                  </dd>
                </div>
                <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                  <dt className="font-semibold text-gray-900">To</dt>
                  <dd className="mt-2 text-gray-500">
                    <span className="font-medium text-gray-900">
                      {quote?.clientInformation.ClientFirstName}{" "}
                      {quote?.clientInformation.ClientLastName}
                    </span>
                    <br />
                    {quote?.clientInformation.ClientEmail}
                    <br />
                  </dd>
                </div>
              </dl>

              <table
                className="mt-16 w-full whitespace-nowrap text-left text-sm leading-6"
                style={{ width: "100%" }}>
                <colgroup>
                  <col />
                  <col />
                  <col />
                  <col />
                </colgroup>
                {/* Conditionally render the table header based on screen size */}
                {window.innerWidth > 768 && (
                  <thead className="border-b border-gray-200 text-gray-900">
                    <tr>
                      <th scope="col" className="px-0 py-3 font-semibold">
                        Room and Category
                      </th>
                      <th
                        scope="col"
                        className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell">
                        Rooms Required
                      </th>
                      <th
                        scope="col"
                        className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell">
                        Room Price
                      </th>
                      <th
                        scope="col"
                        className="py-3 pl-8 pr-0 text-right font-semibold">
                        Total Price
                      </th>
                    </tr>
                  </thead>
                )}
                <tbody>
                  {quote?.quoteInformation.roomPrices.map(
                    (roomPrice, index) => (
                      <React.Fragment key={index}>
                        {/* Render each row with additional vertical space */}
                        <tr
                          className="room-details"
                          style={{ paddingBottom: "1rem" }}>
                          <td style={{ fontWeight: "bold" }}>
                            {roomPrice.categoryName} ({roomPrice.categoryCode})
                          </td>
                          <td
                            data-label="Rooms Required"
                            className="text-right"
                            style={{ paddingBottom: "1rem" }}>
                            {window.innerWidth <= 768
                              ? `${roomPrice.qty} rooms`
                              : roomPrice.qty}
                          </td>
                          <td
                            data-label="Room Price"
                            className="text-right"
                            style={{ paddingBottom: "1rem" }}>
                            {roomPrice.price.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                            {window.innerWidth <= 768 && " per room"}
                          </td>
                          <td
                            data-label="Total Price"
                            className="text-right"
                            style={{ paddingBottom: "1rem" }}>
                            {(roomPrice.qty * roomPrice.price).toLocaleString(
                              "en-US",
                              {
                                style: "currency",
                                currency: "USD",
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                            {window.innerWidth <= 768 && " total"}
                          </td>
                        </tr>
                        {/* Render room notes if available */}
                        {roomPrice.notes && (
                          <tr className="room-notes">
                            <td colSpan="4">{roomPrice.notes}</td>
                          </tr>
                        )}
                      </React.Fragment>
                    )
                  )}
                </tbody>
              </table>

              <div className="mt-8 sm:mt-6 sm:pt-6">
                <dt className="font-semibold text-gray-900">
                  Additional Notes
                </dt>
                <dd className="mt-2 text-gray-500">
                  {quote?.additionalInformation?.Notes}
                </dd>
              </div>
            </div>

            {/* Timeline and Itinerary  */}
            <div className="lg:col-start-3">
              {/* Timeline and Itinerary  */}
              <div className="lg:col-start-3">
                {/* Timeline */}
                <div className="pb-4 ">
                  <h2 className="text-sm font-semibold leading-6 text-gray-900">
                    Timeline
                  </h2>
                  <ul className="mt-2 space-y-6">
                    {activity
                      .slice()
                      .reverse()
                      .map((activityItem, activityItemIdx) => (
                        <li
                          key={activityItem.id}
                          className="relative flex gap-x-4">
                          <div
                            className={classNames(
                              activityItemIdx === activity.length - 1
                                ? "h-6"
                                : "-bottom-6",
                              "absolute left-0 top-0 flex w-6 justify-center"
                            )}>
                            <div className="w-px bg-gray-200" />
                          </div>
                          <>
                            <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                              <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                            </div>
                            <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                              <span className="font-medium text-gray-900">
                                {activityItem.person.name}
                              </span>{" "}
                              {activityItem.type === "created"
                                ? `created the quote`
                                : activityItem.type === "edited"
                                ? `updated the quote`
                                : `sets sail`}
                            </p>
                            <p className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                              {calculateTimeDifference(activityItem.dateTime)}
                            </p>
                          </>
                        </li>
                      ))}
                  </ul>
                </div>

                {/* Itinerary */}
                <div>
                  <h2 className="text-sm font-semibold leading-6 text-gray-900">
                    Itinerary
                  </h2>
                  <ul className="mt-2 space-y-6">
                    {localCruiseData && localCruiseData.portVisits ? (
                      localCruiseData.portVisits.map((portVisit, index) => (
                        <li key={index} className="relative flex gap-x-4">
                          <div
                            className={classNames(
                              index === localCruiseData.portVisits.length - 1
                                ? "h-6"
                                : "-bottom-6",
                              "absolute left-0 top-0 flex w-6 justify-center"
                            )}>
                            <div className="w-px bg-gray-200" />
                          </div>
                          <>
                            <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                              <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                            </div>
                            <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                              <span className="font-medium text-gray-900">
                                {portVisit.port && portVisit.port.name},{" "}
                                {portVisit.port && portVisit.port.country}
                              </span>
                            </p>
                            <p className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                              {/* Displaying the day of the port visit */}
                              Day {portVisit.day}
                            </p>
                          </>
                        </li>
                      ))
                    ) : (
                      <li>
                        <p className="text-gray-500">No itinerary available</p>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modals */}
        <>
          {cruiseDetailModalOpen && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0,0,0,0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflowY: "auto",
                zIndex: 10,
              }}>
              <CruiseDetailModal
                isOpen={cruiseDetailModalOpen}
                onClose={() => {
                  toggleCruiseDetailModal(false);
                  toggleCruiseUnavailableModal(false);
                }}
                toggleCruiseDetailModal={toggleCruiseDetailModal} // Pass it here
                sailDate={quote.cruiseInformation.SailDate}
                shipName={quote.cruiseInformation.Ship}
              />
            </div>
          )}
        </>
      </div>
    </>
  );

  // return (
  //   <div className="quote-detail">
  //     {/* Logo header*/}
  //     <Disclosure as="nav" className="border-b border-gray-200 bg-white">
  //       {({ open }) => (
  //         <>
  //           <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
  //             <div className="flex h-16 justify-between">
  //               <div className="flex">
  //                 <div className="flex flex-shrink-0 items-center">
  //                   <Logo height="auto" width="8rem" />
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </>
  //       )}
  //     </Disclosure>

  //     <div>
  //       {/* Cruise Info header */}
  //       <header className="relative isolate pt-16 bg-white">
  //         <div
  //           className="absolute inset-0 -z-10 overflow-hidden"
  //           aria-hidden="true">
  //           <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80">
  //             <div
  //               className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC]"
  //               style={{
  //                 clipPath:
  //                   "polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)",
  //               }}
  //             />
  //           </div>
  //           <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
  //         </div>

  //         <div className="mx-auto max-w-7xl px-4 pb-10 sm:px-6 lg:px-8">
  //           <div className="flex flex-col sm:flex-row items-center justify-between">
  //             <div className="flex items-center space-x-4">
  //               <img
  //                 src={
  //                   quote?.cruiseInformation.CruiseLine === "Royal Caribbean"
  //                     ? royalLogoSmall
  //                     : quote?.cruiseInformation.CruiseLine === "Celebrity"
  //                     ? celebrityLogo
  //                     : virginLogo
  //                 }
  //                 alt="Cruise Line Logo"
  //                 className="h-16 w-16 flex-none rounded-full"
  //               />
  //               <div>
  //                 <h1 className="text-lg font-semibold text-gray-900">
  //                   {quote?.cruiseInformation.name}
  //                 </h1>
  //                 <p className="text-sm text-gray-500">
  //                   {quote?.cruiseInformation.Ship} on{" "}
  //                   {formatDate(quote?.cruiseInformation.SailDate)}
  //                 </p>
  //               </div>
  //             </div>
  //             <div className="header-buttons">
  //               <a
  //                 href="/"
  //                 onClick={(e) => {
  //                   e.preventDefault();
  //                   const { Ship, SailDate } = quote?.cruiseInformation;
  //                   const url = `/cruise_detail?shipName=${encodeURIComponent(
  //                     Ship
  //                   )}&sailDate=${encodeURIComponent(SailDate)}`;
  //                   window.open(url, "_blank");
  //                 }}
  //                 className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
  //                 View Cruise Details
  //               </a>
  //               <button
  //                 onClick={() => setCruiseDetailModalOpen(true)}
  //                 className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
  //                 View Itinerary Details
  //               </button>
  //             </div>
  //           </div>
  //         </div>
  //       </header>

  //       <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
  //         <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
  //           {/* Agent Information */}
  //           <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5 w-full">
  //             <div className="pl-6 pt-6 pb-6 border-b border-gray-900/5">
  //               <dt className="text-sm font-semibold leading-6 text-gray-900">
  //                 Agent Information
  //               </dt>
  //             </div>
  //             <div className="mb-6">
  //               <div className="mt-6 flex w-full flex-none gap-x-4 px-6">
  //                 <dt className="flex-none">
  //                   <UserCircleIcon
  //                     className="h-6 w-5 text-gray-400"
  //                     aria-hidden="true"
  //                   />
  //                 </dt>
  //                 <dd className="text-sm font-medium leading-6 text-gray-900">
  //                   {quote?.agentInformation.AgentName}
  //                 </dd>
  //               </div>
  //               <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
  //                 <dt className="flex-none">
  //                   <EnvelopeIcon
  //                     className="h-6 w-5 text-gray-400"
  //                     aria-hidden="true"
  //                   />
  //                 </dt>
  //                 <dd className="text-sm leading-6 text-gray-500">
  //                   {quote?.agentInformation.AgentEmail}
  //                 </dd>
  //               </div>
  //               <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
  //                 <dt className="flex-none">
  //                   <PhoneIcon
  //                     className="h-6 w-5 text-gray-400"
  //                     aria-hidden="true"
  //                   />
  //                 </dt>
  //                 <dd className="text-sm leading-6 text-gray-500">
  //                   {quote?.agentInformation.AgentCompanyNumber}
  //                 </dd>
  //               </div>
  //               <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
  //                 <dt className="flex-none">
  //                   <IdentificationIcon
  //                     className="h-6 w-5 text-gray-400"
  //                     aria-hidden="true"
  //                   />
  //                 </dt>
  //                 <dd className="text-sm leading-6 text-gray-500">
  //                   {quote?.agentInformation.AgentCompanyName}
  //                 </dd>
  //               </div>
  //             </div>
  //           </div>

  //           {/* Quote Overview (Table) */}
  //           <div className="-mx-4 px-4 py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-16 xl:pb-20 xl:pt-16">
  //             <h2 className="text-lg font-semibold leading-6 text-gray-900">
  //               Quote{" "}
  //               <span className="text-gray-700">
  //                 #{quote?._id && quote?._id.slice(-8)}
  //               </span>
  //             </h2>

  //             <dl className="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
  //               <div className="sm:pr-4">
  //                 <dt className="inline text-gray-500">Created on</dt>{" "}
  //                 <dd className="inline text-gray-700">
  //                   <time dateTime={quote?.createdAt}>
  //                     {formatDate(quote?.createdAt)}
  //                   </time>
  //                 </dd>
  //               </div>

  //               <div className="sm:pl-4 sm:pr-4">
  //                 <dt className="inline text-gray-500">Last updated on</dt>{" "}
  //                 <dd className="inline text-gray-700">
  //                   <time dateTime={quote?.updatedAt || quote?.createdAt}>
  //                     {formatDate(quote?.updatedAt || quote?.createdAt)}
  //                   </time>
  //                 </dd>
  //               </div>

  //               <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
  //                 <dt className="font-semibold text-gray-900">From</dt>
  //                 <dd className="mt-2 text-gray-500">
  //                   <span className="font-medium text-gray-900">
  //                     {quote?.agentInformation.AgentName}
  //                   </span>
  //                   <br />
  //                   {quote?.agentInformation.AgentEmail} <br />
  //                 </dd>
  //               </div>
  //               <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
  //                 <dt className="font-semibold text-gray-900">To</dt>
  //                 <dd className="mt-2 text-gray-500">
  //                   <span className="font-medium text-gray-900">
  //                     {quote?.clientInformation.ClientFirstName}{" "}
  //                     {quote?.clientInformation.ClientLastName}
  //                   </span>
  //                   <br />
  //                   {quote?.clientInformation.ClientEmail}
  //                   <br />
  //                 </dd>
  //               </div>
  //             </dl>

  //             <table className="mt-16 w-full whitespace-nowrap text-left text-sm leading-6">
  //               <colgroup>
  //                 <col style={{ width: "50%" }} />
  //                 <col style={{ width: "15%" }} />
  //                 <col style={{ width: "20%" }} />
  //                 <col style={{ width: "15%" }} />
  //               </colgroup>
  //               {/* Conditional rendering based on screen size */}
  //               <thead className="border-b border-gray-200 text-gray-900">
  //                 {window.innerWidth > 768 ? ( // Render th elements on larger screens
  //                   <tr>
  //                     <th scope="col" className="px-0 py-3 font-semibold">
  //                       Room and Category
  //                     </th>
  //                     <th
  //                       scope="col"
  //                       className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell">
  //                       Rooms Required
  //                     </th>
  //                     <th
  //                       scope="col"
  //                       className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell">
  //                       Room Price
  //                     </th>
  //                     <th
  //                       scope="col"
  //                       className="py-3 pl-8 pr-0 text-right font-semibold">
  //                       Total Price
  //                     </th>
  //                   </tr>
  //                 ) : null}
  //               </thead>
  //               <tbody>
  //                 {quote?.quoteInformation.roomPrices.map(
  //                   (roomPrice, index) => (
  //                     <React.Fragment key={index}>
  //                       {/* Render each row */}
  //                       <tr className="room-details">
  //                         {/* Render Room and Category value */}
  //                         <td
  //                           data-label="Room and Category"
  //                           style={{
  //                             fontWeight: "bold",
  //                             paddingRight: "0.5rem",
  //                           }}>
  //                           {roomPrice.categoryName} ({roomPrice.categoryCode})
  //                         </td>
  //                         {/* Render Rooms Required value */}
  //                         <td
  //                           data-label="Rooms Required"
  //                           className="text-right"
  //                           style={{ paddingLeft: "0.5rem" }}>
  //                           {roomPrice.qty} rooms
  //                         </td>
  //                         {/* Render Room Price value */}
  //                         <td
  //                           data-label="Room Price"
  //                           className="text-right"
  //                           style={{ paddingLeft: "0.5rem" }}>
  //                           {roomPrice.price.toLocaleString("en-US", {
  //                             style: "currency",
  //                             currency: "USD",
  //                             minimumFractionDigits: 2,
  //                             maximumFractionDigits: 2,
  //                           })}
  //                         </td>
  //                         {/* Render Total Price value */}
  //                         <td
  //                           data-label="Total Price"
  //                           className="text-right"
  //                           style={{ paddingLeft: "0.5rem" }}>
  //                           {(roomPrice.qty * roomPrice.price).toLocaleString(
  //                             "en-US",
  //                             {
  //                               style: "currency",
  //                               currency: "USD",
  //                               minimumFractionDigits: 2,
  //                               maximumFractionDigits: 2,
  //                             }
  //                           )}
  //                         </td>
  //                       </tr>
  //                       {/* Render room notes if available */}
  //                       {roomPrice.notes && (
  //                         <tr className="room-notes">
  //                           <td colSpan="4" style={{ paddingLeft: "0.5rem" }}>
  //                             {roomPrice.notes}
  //                           </td>
  //                         </tr>
  //                       )}
  //                     </React.Fragment>
  //                   )
  //                 )}
  //               </tbody>
  //             </table>

  //             <div className="mt-8 sm:mt-6 sm:pt-6">
  //               <dt className="font-semibold text-gray-900">
  //                 Additional Notes
  //               </dt>
  //               <dd className="mt-2 text-gray-500">
  //                 {quote?.additionalInformation?.Notes}
  //               </dd>
  //             </div>
  //           </div>

  //           {/* Timeline and Itinerary  */}
  //           <div className="lg:col-start-3">
  //             <div className="pb-4 ">
  //               <h2 className="text-sm font-semibold leading-6 text-gray-900">
  //                 Timeline
  //               </h2>
  //               <ul className="mt-2 space-y-6">
  //                 {activity
  //                   .slice()
  //                   .reverse()
  //                   .map((activityItem, activityItemIdx) => (
  //                     <li
  //                       key={activityItem.id}
  //                       className="relative flex gap-x-4">
  //                       <div
  //                         className={classNames(
  //                           activityItemIdx === activity.length - 1
  //                             ? "h-6"
  //                             : "-bottom-6",
  //                           "absolute left-0 top-0 flex w-6 justify-center"
  //                         )}>
  //                         <div className="w-px bg-gray-200" />
  //                       </div>
  //                       <>
  //                         <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
  //                           <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
  //                         </div>
  //                         <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
  //                           <span className="font-medium text-gray-900">
  //                             {activityItem.person.name}
  //                           </span>{" "}
  //                           {activityItem.type === "created"
  //                             ? `created the quote`
  //                             : activityItem.type === "edited"
  //                             ? `updated the quote`
  //                             : `sets sail`}
  //                         </p>
  //                         <p className="flex-none py-0.5 text-xs leading-5 text-gray-500">
  //                           {calculateTimeDifference(activityItem.dateTime)}
  //                         </p>
  //                       </>
  //                     </li>
  //                   ))}
  //               </ul>
  //             </div>

  //             <div>
  //               <h2 className="text-sm font-semibold leading-6 text-gray-900">
  //                 Itinerary
  //               </h2>
  //               <ul className="mt-2 space-y-6">
  //                 {localCruiseData && localCruiseData.portVisits ? (
  //                   localCruiseData.portVisits.map((portVisit, index) => (
  //                     <li key={index} className="relative flex gap-x-4">
  //                       <div
  //                         className={classNames(
  //                           index === localCruiseData.portVisits.length - 1
  //                             ? "h-6"
  //                             : "-bottom-6",
  //                           "absolute left-0 top-0 flex w-6 justify-center"
  //                         )}>
  //                         <div className="w-px bg-gray-200" />
  //                       </div>
  //                       <>
  //                         <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
  //                           <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
  //                         </div>
  //                         <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
  //                           <span className="font-medium text-gray-900">
  //                             {portVisit.port && portVisit.port.name},{" "}
  //                             {portVisit.port && portVisit.port.country}
  //                           </span>
  //                         </p>
  //                         <p className="flex-none py-0.5 text-xs leading-5 text-gray-500">
  //                           {/* Displaying the day of the port visit */}
  //                           Day {portVisit.day}
  //                         </p>
  //                       </>
  //                     </li>
  //                   ))
  //                 ) : (
  //                   <li>
  //                     <p className="text-gray-500">No itinerary available</p>
  //                   </li>
  //                 )}
  //               </ul>
  //             </div>
  //           </div>
  //         </div>
  //       </div>

  //       {/* Modals */}
  //       <>
  //         {cruiseDetailModalOpen && (
  //           <div
  //             style={{
  //               position: "fixed",
  //               top: 0,
  //               left: 0,
  //               width: "100%",
  //               height: "100%",
  //               backgroundColor: "rgba(0,0,0,0.5)",
  //               display: "flex",
  //               justifyContent: "center",
  //               alignItems: "center",
  //               overflowY: "auto",
  //               zIndex: 10,
  //             }}>
  //             <CruiseDetailModal
  //               isOpen={cruiseDetailModalOpen}
  //               onClose={() => {
  //                 toggleCruiseDetailModal(false);
  //                 toggleCruiseUnavailableModal(false);
  //               }}
  //               toggleCruiseDetailModal={toggleCruiseDetailModal} // Pass it here
  //               sailDate={quote.cruiseInformation.SailDate}
  //               shipName={quote.cruiseInformation.Ship}
  //             />
  //           </div>
  //         )}
  //       </>
  //     </div>
  //   </div>
  // );
}
