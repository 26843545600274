import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateQuote, deleteQuote } from "../../store/thunks/quotesThunk";

function EditQuoteModal({
  isOpen,
  onClose,
  quote,
  onEditSuccess,
  onDeleteSuccess,
}) {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState(quote);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEsc);

    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, [onClose]);

  const handleClientInfoChange = (e) => {
    setFormData({
      ...formData,
      clientInformation: {
        ...formData.clientInformation,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleCruiseInfoChange = (e) => {
    setFormData({
      ...formData,
      cruiseInformation: {
        ...formData.cruiseInformation,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleQuoteInfoChange = (index, e) => {
    const { name, value } = e.target;

    // Convert the price to an integer if the name is 'price'
    const parsedValue =
      name === "price" || name === "qty" ? parseInt(value, 10) : value;

    const updatedRoomPrices = formData.quoteInformation.roomPrices.map(
      (item, idx) => {
        if (idx !== index) return item;
        return { ...item, [name]: parsedValue };
      }
    );

    setFormData({
      ...formData,
      quoteInformation: {
        ...formData.quoteInformation,
        roomPrices: updatedRoomPrices,
      },
    });
  };

  const handleAdditionalInfoChange = (e) => {
    setFormData({
      ...formData,
      additionalInformation: {
        ...formData.additionalInformation,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleAgentInfoChange = (e) => {
    setFormData({
      ...formData,
      agentInformation: {
        ...formData.agentInformation,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleSaveClick = () => {
    // Filter out items with empty price or qty
    const filteredRoomPrices = formData.quoteInformation.roomPrices.filter(
      (item) => item.price !== "" && item.qty !== ""
    );

    // Update formData with the filtered roomPrices
    const updatedFormData = {
      ...formData,
      quoteInformation: {
        ...formData.quoteInformation,
        roomPrices: filteredRoomPrices,
      },
    };

    dispatch(updateQuote({ id: formData._id, updatedQuote: updatedFormData }))
      .then(() => {
        console.log("Quote updated successfully");
        onClose();
        onEditSuccess();
      })
      .catch((error) => {
        console.error("Failed to update quote:", error);
      });
  };

  const handleDeleteClick = () => {
    dispatch(deleteQuote({ id: quote._id }))
      .then(() => {
        console.log("Quote deleted successfully");
        onClose();
        onDeleteSuccess();
      })
      .catch((error) => {
        console.error("Failed to delete quote:", error);
      });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black opacity-60"></div>
      <div className="relative z-10 bg-white p-6 rounded-lg shadow-xl max-w-sm md:max-w-3xl w-full overflow-y-auto max-h-full">
        <form>
          <div className="space-y-12">
            <div>
              <>
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  Quote Information
                </h2>
                <p className="mt-1 mb-4 text-sm leading-6 text-gray-600">
                  Edit fields as necessary. Some fields are immutable.
                </p>
              </>
            </div>

            <div className="pb-12 ">
              {/* Client Information */}
              <div className="pb-10">
                <h2 className="pb-2 text-base font-semibold leading-6 text-gray-900">
                  Client Information
                </h2>
                <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-8">
                  <div>
                    <label
                      htmlFor="client-first-name"
                      className="pb-2 block text-sm font-medium leading-5 text-gray-700">
                      First Name
                    </label>
                    <input
                      id="client-first-name"
                      name="ClientFirstName"
                      type="text"
                      value={formData?.clientInformation?.ClientFirstName || ""}
                      onChange={handleClientInfoChange}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="client-last-name"
                      className="pb-2 block text-sm font-medium leading-5 text-gray-700">
                      Last Name
                    </label>
                    <input
                      id="client-last-name"
                      name="ClientLastName"
                      type="text"
                      value={formData?.clientInformation?.ClientLastName || ""}
                      onChange={handleClientInfoChange}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="client-email"
                      className="pb-2 block text-sm font-medium leading-5 text-gray-700">
                      Email
                    </label>
                    <input
                      id="client-email"
                      name="ClientEmail"
                      type="email"
                      value={formData?.clientInformation?.ClientEmail || ""}
                      onChange={handleClientInfoChange}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              {/* Cruise Information */}
              <div className="pb-10 border-t border-gray-900/10 pt-8">
                <h2 className="pb-2 text-base font-semibold leading-7 text-gray-900">
                  Cruise Information
                </h2>
                <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-8">
                  <div>
                    <label
                      htmlFor="cruise-line"
                      className="pb-2 block text-sm font-medium leading-5 text-gray-700">
                      Cruise Line
                    </label>
                    <input
                      id="cruise-line"
                      name="CruiseLine"
                      type="text"
                      value={formData?.cruiseInformation.CruiseLine || ""}
                      onChange={handleCruiseInfoChange}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="cruise-ship"
                      className="pb-2 block text-sm font-medium leading-5 text-gray-700">
                      Cruise Ship
                    </label>
                    <input
                      id="cruise-ship"
                      name="Ship"
                      type="text"
                      value={formData?.cruiseInformation.Ship || ""}
                      onChange={handleCruiseInfoChange}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="sail-date"
                      className="pb-2 block text-sm font-medium leading-5 text-gray-700">
                      Sail Date
                    </label>
                    <input
                      type="date"
                      name="SailDate"
                      id="sail-date"
                      value={
                        formData.cruiseInformation.SailDate
                          ? formData.cruiseInformation.SailDate.split("T")[0]
                          : ""
                      } // Extracting only the date part
                      onChange={handleCruiseInfoChange}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>

                  {/* <div>
                    <label
                      htmlFor="name"
                      className="pb-2 block text-sm font-medium leading-5 text-gray-700">
                      Cruise Name
                    </label>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      value={formData?.cruiseInformation.name || ""}
                      onChange={handleCruiseInfoChange}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div> */}

                  <div className="sm:col-span-3">
                    {/* Expanded to span 3 columns on small screens */}
                    <label
                      htmlFor="name"
                      className="pb-2 block text-sm font-medium leading-5 text-gray-700">
                      Cruise Name
                    </label>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      value={formData?.cruiseInformation.name || ""}
                      onChange={handleCruiseInfoChange}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              {/* Room Price Information */}
              <div className="pb-10 border-t border-gray-900/10 pt-8">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Room Price Information
                  </h2>
                  <div className="mt-4 flex justify-end">
                    <button
                      onClick={() => {
                        const updatedRoomPrices = [
                          ...formData.quoteInformation.roomPrices,
                        ];

                        // Check if roomPrices array is empty or if the last row is non-empty before adding a new row
                        if (
                          updatedRoomPrices.length === 0 ||
                          (updatedRoomPrices[
                            updatedRoomPrices.length - 1
                          ]?.categoryName.trim() !== "" &&
                            updatedRoomPrices[
                              updatedRoomPrices.length - 1
                            ]?.categoryCode.trim() !== "" &&
                            String(
                              updatedRoomPrices[updatedRoomPrices.length - 1]
                                ?.price
                            ).trim() !== "")
                        ) {
                          updatedRoomPrices.push({
                            categoryName: "",
                            categoryCode: "",
                            price: "",
                          });
                        }

                        setFormData({
                          ...formData,
                          quoteInformation: {
                            ...formData.quoteInformation,
                            roomPrices: updatedRoomPrices,
                          },
                        });
                      }}
                      type="button"
                      className="mr-2 block rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                      Add Row
                    </button>

                    <button
                      onClick={() => {
                        if (formData.quoteInformation.roomPrices.length > 0) {
                          const updatedRoomPrices = [
                            ...formData.quoteInformation.roomPrices,
                          ];
                          updatedRoomPrices.pop(); // Remove the last row
                          setFormData({
                            ...formData,
                            quoteInformation: {
                              ...formData.quoteInformation,
                              roomPrices: updatedRoomPrices,
                            },
                          });
                        }
                      }}
                      type="button"
                      className="inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
                      Remove Last Row
                    </button>
                  </div>
                </div>
                <div className="mt-2">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                          Category Name
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 text-left text-sm font-semibold text-gray-900">
                          Category Code
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 text-left text-sm font-semibold text-gray-900">
                          Quantity
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 text-left text-sm font-semibold text-gray-900">
                          Price (per room)
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {formData.quoteInformation.roomPrices.map(
                        (row, index) => (
                          <tr key={index}>
                            <td className="pr-3 py-4 whitespace-nowrap">
                              <input
                                type="text"
                                placeholder="Eg: Concierge Class"
                                className="border-gray-200 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm"
                                value={row.categoryName}
                                onChange={(e) =>
                                  handleQuoteInfoChange(index, e)
                                }
                                name="categoryName"
                              />
                            </td>
                            <td className="pr-3 py-4 whitespace-nowrap">
                              <input
                                type="text"
                                placeholder="Eg: C1"
                                className="border-gray-200 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm"
                                value={row.categoryCode}
                                onChange={(e) =>
                                  handleQuoteInfoChange(index, e)
                                }
                                name="categoryCode"
                              />
                            </td>
                            <td
                              className="pr-3 py-4 whitespace-nowrap"
                              style={{ width: "10%" }}>
                              <input
                                type="number"
                                min="0"
                                step="1"
                                placeholder="Eg: 2"
                                className="border-gray-200 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm"
                                value={row.qty}
                                onChange={(e) =>
                                  handleQuoteInfoChange(index, e)
                                }
                                name="qty"
                              />
                            </td>
                            <td className="pr-3 py-4 whitespace-nowrap">
                              <input
                                type="number"
                                min="0"
                                step="0.01"
                                placeholder="Eg: 1800.00"
                                className="border-gray-200 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm"
                                value={row.price}
                                onChange={(e) =>
                                  handleQuoteInfoChange(index, e)
                                }
                                name="price"
                              />
                            </td>
                            <td className="pr-3 py-4 whitespace-nowrap">
                              <input
                                type="text"
                                placeholder="Notes"
                                className="border-gray-200 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm"
                                value={row.notes}
                                onChange={(e) =>
                                  handleQuoteInfoChange(index, e)
                                }
                                name="notes"
                              />
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Additional Information */}
              <div className="pb-10 border-t border-gray-900/10 pt-8">
                <h2 className="pb-2 text-base font-semibold leading-7 text-gray-900">
                  Additional Information
                </h2>
                <div>
                  <label
                    htmlFor="additional-notes"
                    className="pb-2 block text-sm font-medium leading-5 text-gray-700">
                    Notes
                  </label>
                  <textarea
                    id="additional-notes"
                    name="Notes"
                    rows="3"
                    value={formData?.additionalInformation.Notes || ""}
                    onChange={handleAdditionalInfoChange}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-600 focus:ring focus:ring-indigo-600 focus:ring-opacity-50 placeholder-gray-400 sm:text-sm"
                    placeholder="Enter additional notes here..."></textarea>
                </div>
              </div>

              {/* Agent Information */}
              <div className="pb-10 border-t border-gray-900/10 pt-8">
                <h2 className="pb-2 text-base font-semibold leading-7 text-gray-900">
                  Agent Information
                </h2>
                <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-8">
                  <div>
                    <label
                      htmlFor="agent-name"
                      className="pb-2 block text-sm font-medium leading-5 text-gray-700">
                      Agent Name
                    </label>
                    <input
                      id="agent-name"
                      name="AgentName"
                      type="text"
                      readOnly
                      value={formData?.agentInformation?.AgentName || ""}
                      onChange={handleAgentInfoChange}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="agent-email"
                      className="pb-2 block text-sm font-medium leading-5 text-gray-700">
                      Agent Email
                    </label>
                    <input
                      id="agent-email"
                      name="AgentEmail"
                      type="text"
                      readOnly
                      value={formData?.agentInformation?.AgentEmail || ""}
                      onChange={handleAgentInfoChange}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              {/* Delete, Cancel, Save button */}
              <div className="mt-10 flex sm:items-center sm:justify-between flex-wrap gap-3 sm:flex-nowrap">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md bg-gray-100 border border-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-full sm:w-auto"
                  onClick={onClose}>
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleSaveClick}
                  className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 w-full sm:w-auto">
                  Save
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 w-full sm:w-auto"
                  onClick={handleDeleteClick}>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditQuoteModal;
