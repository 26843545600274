import { useState, useEffect } from "react";
import { Switch } from "@headlessui/react";
import { useSelector, useDispatch } from "react-redux";
import { fetchSubscriptionStatus } from "../../store/thunks/fetchSubscriptionStatus";
import { cancelSubscription } from "../../store/thunks/stripe/cancelSubscription";
import InvoiceList from "./InvoiceList";
import EmailMarketing from "../email/emailMarketing";
import { EyeIcon } from "@heroicons/react/24/outline";
import ColorPicker from "../ColorPicker";
import axios from "axios";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Profile = () => {
  const dispatch = useDispatch();
  const activeUser = useSelector((state) => state.activeUser.data.user);
  const activeAgent = useSelector((state) => state.activeAgent.data.agent);

  const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] =
    useState(true);
  const subscriptionActive = useSelector((state) => {
    const subscriptionStatus =
      state.subscriptionStatus.data.status === "active";
    const userIsAdmin = state.activeUser.data.user.user_type === "admin";

    return subscriptionStatus || userIsAdmin;
  });

  const [companyLogo, setCompanyLogo] = useState(null);
  const [isCompanyLogoModalOpen, setIsCompanyLogoModalOpen] = useState(false);

  const [showVVCruises, setShowVVCruises] = useState(true);

  const [primaryColor, setPrimaryColor] = useState("#4f46e5"); // Default color

  // This one works to change it live but on local storage
  const handleColorChange = (color) => {
    setPrimaryColor(color);
    localStorage.setItem("primaryColor", color); // Save to local storage
    document.documentElement.style.setProperty("--primary-color", color); // Update the CSS variable
  };

  const openCompanyLogoModal = () => {
    setIsCompanyLogoModalOpen(true);
    console.log("logo modal OPEN");
  };

  const closeCompanyLogoModal = () => {
    setIsCompanyLogoModalOpen(false);
    console.log("logo modal CLOSED");
  };

  return (
    <>
      <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
        <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
          {/* Main profile for all users */}
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Profile
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              Your information that you registered with.
            </p>

            <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                  First Name
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">{activeUser.first_name}</div>
                  {/* <button
                    type="button"
                    className="font-semibold text-primary-color hover:text-indigo-500">
                    Update
                  </button> */}
                </dd>
              </div>
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                  Last Name
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">{activeUser.last_name}</div>
                  {/* <button
                    type="button"
                    className="font-semibold text-primary-color hover:text-indigo-500">
                    Update
                  </button> */}
                </dd>
              </div>
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                  Email address
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">{activeUser.email}</div>
                  {/* <button
                    type="button"
                    className="font-semibold text-primary-color hover:text-indigo-500">
                    Update
                  </button> */}
                </dd>
              </div>

              {/* THIS IS DUPLICATED BELOW IN THE AGENT EXCLUSIVE SECTION. JUST PUTTING THIS HERE SO YOU KNOW WHAT IT LOOKS LIKE */}
              {/* <div className="pt-6 sm:flex items-center justify-between">
                <div className="pt-6 sm:flex items-center">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900 pr-4">
                    Show Virgin Voyages
                  </label>

                  <Switch
                    checked={showVVCruises}
                    onChange={setShowVVCruises}
                    className={classNames(
                      showVVCruises ? "bg-primary-color" : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                    )}>
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        showVVCruises ? "translate-x-5" : "translate-x-5",
                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>

                  {showVVCruises ? (
                    <div className="mt-0 ml-4 sm:flex items-center pl-10">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 italic shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="LetsGoBook link"
                        aria-describedby="email-description"
                      />
                      <div>
                        <button
                          type="button"
                          className="font-semibold text-primary-color hover:text-indigo-500">
                          Update
                        </button>
                      </div>
                    </div>
                  ) : null}

                  <div>
                    <div
                      className="primary-color-box"
                      style={{ backgroundColor: primaryColor }}>
                      Primary Color
                    </div>

                    <ColorPicker
                      selectedColor={primaryColor}
                      onColorChange={handleColorChange}
                    />
                  </div>
                </div>
              </div> */}
            </dl>
          </div>

          {/* Company profile - render for Agents only */}
          {activeAgent && (
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Company Profile Information
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-500">
                View information about your company profile.
              </p>

              <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                    Company Subdomain
                  </dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">{activeUser.agent}</div>
                  </dd>
                </div>
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                    Company Logo
                  </dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">{activeUser.agentEmail}</div>
                    <div className="mt-2 flex items-center gap-x-3">
                      {companyLogo && (
                        <div className="h-12 w-12 text-gray-300 relative">
                          <img
                            src={URL.createObjectURL(companyLogo)}
                            alt="Company Logo"
                            className="w-full h-full object-contain cursor-pointer"
                            onClick={() =>
                              window.open(URL.createObjectURL(companyLogo))
                            }
                          />
                          <button
                            type="button"
                            onClick={() =>
                              window.open(URL.createObjectURL(companyLogo))
                            }
                            className="absolute top-0 right-0 p-2 bg-white rounded-full text-gray-500 hover:bg-gray-100">
                            <EyeIcon className="w-6 h-6" />
                          </button>
                        </div>
                      )}
                      <label
                        htmlFor="company-logo-upload"
                        className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500 pb-2">
                        <span>{companyLogo ? "Change" : "Upload"}</span>
                        <input
                          id="company-logo-upload"
                          name="company-logo-upload"
                          type="file"
                          accept="image/*"
                          className="sr-only"
                          onChange={(e) => setCompanyLogo(e.target.files[0])}
                        />
                      </label>
                      {companyLogo && (
                        <button
                          type="button"
                          onClick={closeCompanyLogoModal}
                          className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500 pb-2">
                          Remove
                        </button>
                      )}
                    </div>
                  </dd>
                </div>
                <div className="pt-6 sm:flex items-center">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900 pr-4">
                    Show Virgin Voyages
                  </label>

                  <Switch
                    checked={showVVCruises}
                    onChange={setShowVVCruises}
                    className={classNames(
                      showVVCruises ? "bg-indigo-600" : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                    )}>
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        showVVCruises ? "translate-x-5" : "translate-x-5",
                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>

                  {showVVCruises ? (
                    <div className="mt-0 ml-4">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 italic shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="LetsGoBook link"
                        aria-describedby="email-description"
                      />
                    </div>
                  ) : null}
                </div>
              </dl>
            </div>
          )}

          {/* Agent inforamation */}
          {/* {activeUser.agent && activeUser.agent.trim() !== "" && ( */}
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Agent
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              You were either referred by or have been assigned to the following
              agent.
            </p>

            <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                  Agent Name
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">{activeUser.agent}</div>
                </dd>
              </div>
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                  Agent Email address
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">{activeUser.agentEmail}</div>
                </dd>
              </div>
            </dl>
          </div>
          {/* )} */}

          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Email Subscriptions
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              Here you can manage your email subscriptions. If you want to
              unsubscribe from all emails uncheck all options.
            </p>
            <EmailMarketing userId={activeUser._id} />
          </div>
        </div>
      </main>
    </>
  );
};

export default Profile;
