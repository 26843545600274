import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../../config"; // Import the dynamic API base URL

const fetchAdminMetrics = createAsyncThunk(
  "admin/fetchMetrics",
  async (_, { getState }) => {
    const state = getState();
    const { token, _id } = state.activeUser.data.user;

    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-api-token": token,
        "x-user-id": _id,
        // Add other headers as needed
      },
    };

    try {
      // Make an API call to fetch the admin metrics data
      const response = await axios.get(`https://${apiBaseUrl}/admin/metrics`, config);

      return response.data;
    } catch (error) {
      // You can handle errors here or re-throw them as needed
      throw error;
    }
  }
);

export { fetchAdminMetrics };
