import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRouteAgent = (props) => {
    const apiToken = useSelector(state => state.activeAgent?.data?.agent?.token);
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    
    const checkAgentToken = () => {
        if (!apiToken || apiToken === "undefined") {
            setIsLoggedIn(false);
            return navigate("/agent/login");
        }
        setIsLoggedIn(true);
    };

    useEffect(() => {
        checkAgentToken();
    }, [isLoggedIn]);

    return (
        <React.Fragment>{isLoggedIn ? props.children : null}</React.Fragment>
    );
};
export default ProtectedRouteAgent;
