import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

export default function NoSubscriptionWarning() {
  return (
    <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 mt-6">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className="text-sm text-yellow-700">
            You are not subscribed.{" "}
            <a
              href="/agent/subscriptions"
              className="font-medium text-yellow-700 underline hover:text-yellow-600">
              Subscriptions are required to access full agent functionality.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
